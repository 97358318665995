import React, { useEffect, useState } from "react";
import Image from "../common/atoms/Image";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import Icon from "../../Icon";
import { t } from "../CentralDataMangement/translation/Translation";
import UserImage from "../../static/images/User.svg";
import InputTextfield from "../common/atoms/InputTextField";
import {
  calculateShiftsTotalTime,
  formatTimeInput,
  sanitizedInputLength,
} from "./Services/PlanningTimingHelper";
import { useSelector } from "react-redux";
import { ApiCall } from "../../services/ApiServices";
import { CREATE_PLANNING } from "../../routes/ApiEndpoints";
import CustomNotify from "../common/atoms/CustomNotify";
import { useLocation, useNavigate } from "react-router-dom";
import { selectAuth, selectCurrentCompany } from "../../features/auth/AuthSlice";
import { validatePlanningFieldsForDate } from "./Validations/PlanningValidations";
import { RootState } from "../../store";
import { ageLevelWarnings } from "./Validations/PlanningTimingValidations";
import { fetchPcInfo } from "./Services/PlanningServices";
import { useDispatch } from "react-redux";
import { selectPCData } from "../../features/planning/employeeSlice";
import CustomAlert from "../common/atoms/CustomAlert";
import { SUCCESS } from "../../Constants";
import CheckBoxField from "../common/atoms/CheckBoxField";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import { updatesalaryFormat } from "./Services/PlanningSalaryHelper";
import EditIcon from "../common/molecules/Actions/CustomEdit";
import LayoutEditPopup from "./LayoutEditPopup";


interface Shift {
  start_time: string;
  end_time: string;
  sequence: number;
}

interface Schedule {
  shifts: Shift[];
  project_id?: number;
  cost_center_id?: number;
  error?: string;
  warning?: string[];
}

interface PlanningLayoutData {
  planning_id?: number;
  planning_employee_detail_id?: number;
  employee_id: number;
  employee_name?: string;
  employee_type_id?: number;
  new_salary?: string;
  employee_type?: string;
  company_id?: number;
  location_id?: number;
  pc_id?: number;
  pc?: string;
  age: number;
  function_id?: number;
  function?: string;
  salary?: string;
  start_date?: string;
  end_date?: string;
  schedule: {
    [date: string]: Schedule;
  };
}


interface Props {
  emp: PlanningLayoutData;
  refreshOverview: (employeeIds: number[]) => void;
  selectedEmployeesForPlan: (employeeIds: number[]) => void;
  saveLayoutData: (employee: any) => void;
}

const EmployeeLayoutRow: React.FC<Props> = ({
  emp,
  refreshOverview,
  selectedEmployeesForPlan,
  saveLayoutData,
}) => {
  const dynamicValue = useResponsiveValue("100%", "100%", "5%");
  const imageStyle = useResponsiveValue("5vw", "2.5vw", "2vw");
  const [employeeData, setEmployeeData] = useState<PlanningLayoutData>(emp);
  const state = useSelector((state: RootState) => state);
  const pcDetails = useSelector(selectPCData);
  const [editLayout, setEditLayout] = useState<any>(null);
  const authData = useSelector(selectAuth);
  const [error, setError] = useState<any>({
    scheduleError: ""
  })
  // Add state for multi send checkbox
  const [isMultiSend, setIsMultiSend] = useState<number[]>([]);
  const companyId = useSelector(selectCurrentCompany);
  const dispatch = useDispatch();
  // Check if any errors exist in the schedule
  const hasErrors = Object.values(employeeData.schedule).some(
    (daySchedule) => daySchedule.error
  );
  const handleMultiSendChange = (employeeId: number) => {
    setIsMultiSend((prevState) => {
      if (prevState.includes(employeeId)) {
        // Remove the employeeId if it's already in the array
        return prevState.filter((id) => id !== employeeId);
      } else {
        // Add the employeeId if it's not in the array
        return [...prevState, employeeId];
      }
    });
    selectedEmployeesForPlan(isMultiSend);
  };

  useEffect(() => {
    if (pcDetails?.length == 0) {
      fetchPcInfo(companyId.id, dispatch, state);
    }
  }, [companyId]);
  const handleShiftChange = (
    date: string,
    index: number,
    key: string,
    value: string
  ) => {
    const formattedValue = formatTimeInput(value);

    if (sanitizedInputLength(value) <= 4) {
      setEmployeeData((prevState) => {
        const updatedSchedule = { ...prevState.schedule };
        const updatedShifts = [...updatedSchedule[date].shifts];
        updatedShifts[index] = {
          ...updatedShifts[index],
          [key]: formattedValue,
        };
        updatedSchedule[date].shifts = updatedShifts;

        // Validate and update error and warnings directly within the schedule
        const errorMessage = validatePlanningFieldsForDate(
          state,
          updatedSchedule[date],
          employeeData.pc_id!,
          date
        );

        const warningMessages = ageLevelWarnings(
          employeeData.pc_id!,
          employeeData.age,
          state,
          updatedSchedule[date].shifts
        );

        // Update the schedule with error and warning messages
        updatedSchedule[date].error = errorMessage || "";
        updatedSchedule[date].warning = warningMessages || [];

        return { ...prevState, schedule: updatedSchedule };
      });
    }
  };

  // Validate required fields
  const validateRequiredFields = () => {
    const missingFields = [];

    if (!employeeData.employee_type) {
      missingFields.push(t("Employee type is missing"));
    }
    if (!employeeData.function) {
      missingFields.push(t("Function is missing"));
    }
    if (!employeeData.pc) {
      missingFields.push(t("Paritair Committee is missing"));
    }
    if (!employeeData.salary) {
      missingFields.push(t("Salary information is missing"));
    }

    return missingFields;
  };
  const handleSendPlanning = async () => {

    const missingFields = validateRequiredFields();

    if (hasErrors) {
      return;
    }
    // Check if schedule is empty
    const isScheduleEmpty = Object.keys(employeeData.schedule).length === 0 ||
      Object.values(employeeData.schedule).every(schedule =>
        !schedule.shifts || schedule.shifts.length === 0
      );

    // Set errors if any
    if (isScheduleEmpty || missingFields.length > 0 || hasErrors) {
      setError({
        scheduleError: isScheduleEmpty
          ? t("No shifts have been scheduled for this employee")
          : undefined,
        generalError: missingFields.length > 0
          ? missingFields.join(", ")
          : undefined
      });
      return;
    } try {
      employeeData.company_id = parseInt(companyId.id);
      // Ensure salary is sent as a properly parsed float value
      const preparedEmployeeData = {
        ...employeeData,
        salary: parseFloat(employeeData.salary?.toString().replace(",", ".") || "0"),
      };
      let dataObj = {
        company_id: parseInt(companyId.id),
        user_id: authData?.userId,
        planning: [preparedEmployeeData],
      };

      const response = await ApiCall.service(
        CREATE_PLANNING,
        "POST",
        dataObj,
        false
      );

      if (response.status === SUCCESS) {
        CustomNotify({
          type: "success",
          message: t(response.message),
          autoClose: 2500,
        });
        refreshOverview([employeeData.employee_id]);
      } else if (response.status === 422) {
        CustomNotify({
          type: "error",
          message: t(response.message),
          autoClose: 2500,
        });
      }

    } catch (error) {
      console.error("Error making API call:", error);
    }
  };

  const handleAddShift = (date: string) => {
    setError({
      scheduleError: ""
    }); 
    setEmployeeData((prevState) => {
      const updatedSchedule = { ...prevState.schedule };
      // Initialize the date's schedule if it doesn't exist
      if (!updatedSchedule[date]) {
        updatedSchedule[date] = { shifts: [] };
      }
      const existingShifts = updatedSchedule[date].shifts || [];

      if (existingShifts.length === 0) {
        const newShift = {
          start_time: "",
          end_time: "",
          sequence: 1,
        };
        updatedSchedule[date].shifts = [...existingShifts, newShift];
      }
      if (existingShifts.length === 1) {
        const newShift = {
          start_time: "",
          end_time: "",
          sequence: 2,
        };
        updatedSchedule[date].shifts = [...existingShifts, newShift];
      }

      return { ...prevState, schedule: updatedSchedule };
    });
  };


  const shiftCells = (daySchedule: Schedule, date: string) => {
    return (
      <>
        {daySchedule?.shifts?.map((shift, index) => (
          <React.Fragment key={index}>
            <td className="align-middle" data-label={t("Start time")} style={{ width: dynamicValue }}>
              <InputTextfield
                value={shift.start_time}
                className="me-2"
                handleChange={(e) =>
                  handleShiftChange(date, index, "start_time", e.target.value)
                }
              />
            </td>

            <td className="align-middle" data-label={t("End time")} style={{ width: dynamicValue }}>
              <InputTextfield
                value={shift.end_time}
                handleChange={(e) =>
                  handleShiftChange(date, index, "end_time", e.target.value)
                }
              />
            </td>
          </React.Fragment>
        ))}

        {daySchedule?.shifts?.length < 2 && (
          <td
            className="align-middle px-2 shiftAddIcon"
            colSpan={daySchedule.shifts.length === 0 ? 4 : 2}
          >
            <CustomActionIcon title={t("Add shift")} handleClick={() => handleAddShift(date)} iconName="add" buttonClass="searchBtn" marginBottom="mb-0" />
          </td>
        )}

        <td className="align-middle" data-label={t("Break total")}>{"00:00"}</td>
        <td className="align-middle" data-label={t("Total work done")}>
          <div className="totalHours text-white poppins-medium">
            {calculateShiftsTotalTime(daySchedule.shifts)}
          </div>
        </td>
      </>
    );
  };

  return (
    <>
      {Object.keys(employeeData?.schedule).map((date) => {
        const daySchedule = employeeData?.schedule[date];
        const errorMessage = daySchedule?.error;
        const warningMessages = daySchedule?.warning || [];
        return (
          <React.Fragment key={date}>
            <tr className="mb-2">
              <td>
                <Image
                  src={UserImage}
                  imageWidth={imageStyle}
                  imageHeight={imageStyle}
                  imageBorderRadius="50%"
                />
              </td>
              <td data-label={t("Employee")}>
                <div
                  className="textEllipsisName textEllipsis"
                  title={employeeData.employee_name}
                >
                  {employeeData.employee_name}
                </div>
              </td>
             
              <td data-label={t("Status")}>
                <div
                  className="textEllipsisEmpType textEllipsis"
                  title={employeeData?.employee_type}
                >
                  {employeeData.employee_type}
                </div>
              </td>
              <td data-label={t("Function")}>
                <div
                  title={employeeData.function}
                  className="textEllipsisFunName textEllipsis"
                >
                  {employeeData.function}
                </div>
              </td>
              <td data-label={t("Paritair committee")}>
                <div
                  title={employeeData.pc}
                  className="textEllipsisFunName textEllipsis"
                >
                  {employeeData.pc}
                </div>
              </td>
              <td data-label={t("Hourly wage")} >€
                {employeeData.new_salary != undefined && employeeData.new_salary != '' ?
                  updatesalaryFormat(employeeData.new_salary) :
                  employeeData.salary && updatesalaryFormat(employeeData.salary)}
              </td>
              {shiftCells(daySchedule, date)}
              <td></td>
              <td className="p-2">
                <div>
                  <EditIcon handleClick={() => setEditLayout(employeeData)} iconName="EditIcon" title={t("Edit")} />
                  <CustomActionIcon handleClick={handleSendPlanning} title={t("Send")} iconName="sendIcon" buttonClass="searchBtn" />

                  {/* <CheckBoxField
										name="multiSend" // Change to a suitable name if needed
										isChecked={isMultiSend.includes(employeeData.employee_id)}
										onChangeHandler={() => handleMultiSendChange(employeeData.employee_id)} // Use the handler here

										checkBoxWrapper="ps-4"
									/> */}
                </div>
              </td>
            </tr>
            {window.innerWidth > 1023 && <TableHeaderSpace className="bg-transparent" headerLength={15} />}

            {/* Display General Errors */}
            {(error.generalError || error.scheduleError) && (
              <tr>
                <td colSpan={15} className="p-0 pb-2 bg-transparent">
                  <CustomAlert className="alert-danger" iconName="warningIcon">
                    {error.generalError || error.scheduleError}
                  </CustomAlert>
                </td>
              </tr>
            )}
            {/* Display Error Messages */}
            {errorMessage && (
              <tr>
                <td colSpan={15} className="p-0 pb-2 bg-transparent">
                  <CustomAlert className="alert-danger" iconName="warningIcon">
                    {errorMessage}
                  </CustomAlert>
                </td>
              </tr>
            )}
            {/* Display Warning Messages */}
            {warningMessages.length > 0 && (
              <tr>
                <td colSpan={15} className="p-0 pt-2">
                  <CustomAlert className="alert-warning" iconName="warningIcon">
                    {warningMessages.map((warning, idx) => (
                      <div key={idx}>{warning}</div>
                    ))}
                  </CustomAlert>
                </td>
              </tr>
            )}
          </React.Fragment>
        );
      })}
      {editLayout != null && (
        <LayoutEditPopup
          employeeData={editLayout}
          clearPopup={() => {
            setEditLayout(null);
          }}
          saveEmployeeData={(empData: any) => setEmployeeData(empData)}
        />
      )}
    </>
  );
};

export default EmployeeLayoutRow;
