import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../../Icon";
import { EMPLOYEE_TYPES } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";
import CheckBoxField from "../../common/atoms/CheckBoxField";
import CustomNotify from "../../common/atoms/CustomNotify";
import LabelField from "../../common/atoms/LabelField";
import { t } from "../../common/atoms/translation";
import Header from "../../common/layout/Header";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import CustomActionIcon from "../../common/molecules/Actions/CustomActionIcon";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import CustomDelete from "../../common/molecules/Actions/CustomDelete";

export default function EmployeeTypeAdd() {
  const [employees, setEmployees] = useState([
    { name: "", day_contract: 0, type: "", status: 1 },
  ]);
  const [error, setError] = useState({});
  const navigate = useNavigate();

  const handleChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    const updatedEmployees = [...employees];
    updatedEmployees[index][name] =
      type === "checkbox" ? (checked ? 1 : 0) : value || "";
    setEmployees(updatedEmployees);
  };

  const addEmployeeType = () => {
    setEmployees([
      ...employees,
      { name: "", day_contract: 0, type: "", status: 1 },
    ]);
  };

  const removeEmployeeType = (index) => {
    setEmployees((prevState) => {
      // Remove the item at the specified index
      const updatedEmployees = [...prevState];
      updatedEmployees.splice(index, 1);
      return updatedEmployees;
    });
  };

  const validateFields = () => {
    const errors = {};
    employees.forEach((employee, index) => {
      if (!employee.name) {
        errors[`name-${index}`] = t("Employee type name is required");
      }
      if (!employee.type) {
        errors[`type-${index}`] = t("Job type is required");
      }
    });
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const sendData = async () => {
    try {
      const response = await ApiCall.service(
        EMPLOYEE_TYPES,
        "POST",
        { data: employees },
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.success) {
        navigate("/manage/employee-types");
      } else {
        CustomNotify({ type: "warning", message: t(response.message[0]) });
        console.error("Failed to store the employee types.");
      }
    } catch (error) {
      console.error(
        "An error occurred while storing the employee type:",
        error
      );
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateFields()) {
      sendData();
    }
  };

  return (
    <>
      <Header
        headerName={t("Add employee types")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <div className="row">
          <BackLink backLink="/manage/employee-types" />
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit}>
          <OverFlowWrapper className="addEmployeeTypeClass container-fluid ps-0">
            <div className="row">
              <div className="col-12">
                {employees.map((employee, index) => (
                  <div className="boxShadow">
                    <div className="row pb-3">
                      <div className="col-lg-4 col-md-12 mb-3 mb-lg-0">
                        <LabelWithInputField
                          isMandatory={true}
                          name="name"
                          handleChange={(event) => handleChange(index, event)}
                          value={employee.name}
                          id={`name-${index}`}
                          label={t("Name")}
                          placeholder={t("Name")}
                          type="text"
                          error={error[`name-${index}`]}
                          className="ps-2"
                          isTop={true}
                        />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <div>
                          <LabelField title={t("Job type")} />
                        </div>
                        <SelectWithSearch
                          search={false} // No search functionality needed here
                          options={[
                            { value: "", label: t("Select Job Type") },
                            { value: "1", label: t("White Collar") },
                            { value: "2", label: t("Blue Collar") },
                          ]}
                          value={{
                            value: employee.type,
                            label: t(
                              employee.type === "1"
                                ? "White Collar"
                                : employee.type === "2"
                                ? "Blue Collar"
                                : "Select Job Type"
                            ),
                          }}
                          onChange={(selected) =>
                            handleChange(index, {
                              target: { name: "type", value: selected?.value },
                            })
                          }
                          name="type"
                          id={`type-${index}`}
                          placeHolder={t("Select Job Type")}
                          isMandatory={true}
                          error={error[`type-${index}`]}
                        />
                      </div>
                      <div
                        className="col-lg-3 col-md-6"
                        style={{
                          marginTop:
                            window.innerWidth <= 767
                              ? "3.5vw"
                              : window.innerWidth <= 1023
                              ? "3.95vw"
                              : "1.7vw",
                        }}
                      >
                        <CheckBoxField
                          name={employee.day_contract}
                          value={employee.day_contract}
                          isChecked={employee.day_contract === 1}
                          onChangeHandler={(event) =>
                            handleChange(index, event)
                          }
                          label={t("Day contract")}
                          checkBoxWrapper="ps-4"
                          error={error[`day_contract-${index}`]}
                        />
                      </div>
                      <div
                        className="col-lg-1 col-md-12"
                        style={{ marginTop: "1.6vw" }}
                      >
                        <div className="d-flex align-items-center gap-4 d-lg-block">
                          {index > 0 && (
                            <CustomDelete
                              handleDelete={() => removeEmployeeType(index)}
                              buttonClass="mb-3"
                            />
                          )}
                          {employees.length - 1 === index && (
                            <CustomActionIcon
                              handleClick={addEmployeeType}
                              iconName="add"
                              title={t("Add employee type")}
                              buttonClass="searchBtn"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </OverFlowWrapper>
          <div className="row py-3">
            <div className="col-12 text-end">
              <Button
                type="submit"
                className="searchBtn px-3"
                handleClick={() => {}}
                title={t("Save")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
