import React from "react";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import { t } from "../../../CentralDataMangement/translation/Translation";
import { InviteFormProps } from "../Interfaces";
import LabelField from "../../../common/atoms/LabelField";
import Calender from "../../../common/molecules/Calender";

const InviteForm: React.FC<InviteFormProps> = ({
  formValues,
  formErrors,
  handleChange,
}) => {
  return (
    <div className="mb-3">
      <div className="row mt-3">
        <div className="col-lg-6 mb-3 mb-lg-0">
          <div className="row">
            <LabelWithInputField
              label={t("First name") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={formValues.first_name}
              handleChange={handleChange}
              name="first_name"
              id="first_name"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
              error={formErrors["first_name"] ?? ""}
              isDisabled
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <LabelWithInputField
              label={t("Last name") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={formValues.last_name}
              handleChange={handleChange}
              name="last_name"
              id="last_name"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
              error={formErrors["last_name"] ?? ""}
              isDisabled
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-6 mb-3 mb-lg-0">
          <div className="row">
            <LabelWithInputField
              label={t("E-mail") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={formValues.email}
              handleChange={handleChange}
              name="email"
              id="email"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
              isDisabled
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row">
            <LabelWithInputField
              label={t("Phone number") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={formValues.phone_number}
              handleChange={handleChange}
              name="phone_number"
              id="phone_number"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
              error={formErrors["phone_number"] ?? ""}
              isDisabled
            />
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-lg-6 mb-3 mb-lg-0">
          <div className="row">
            <LabelWithInputField
              label={t("Social security number") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={formValues.registry_number}
              handleChange={handleChange}
              name="registry_number"
              id="registry_number"
              labelWrapperClassName="col-4 align-self-center"
              autoComplete="off"
              error={formErrors["registry_number"] ?? ""}
              isDisabled
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="row mb-3">
            <div className="col-4 leftlabelClass">
              <LabelField title={t("Date of birth") + ":"} />
            </div>
            <div className="col-8 position-relative calendarSectionWrapper">
              <div className="position-relative">
                <Calender
                  onChange={() => {console.log(formValues?.date_of_birth)}}
                  selectedDate={
                    formValues?.date_of_birth
                      ? new Date(formValues?.date_of_birth)
                      : null
                  }
                  error={formErrors["date_of_birth"] ?? ""}
                  name="date_of_birth"
                  isDisabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InviteForm;
