import React, { useState } from "react";
import Icon from "../../Icon";
import Image from "../common/atoms/Image";
import { t } from "../common/atoms/translation";
import UserImage from "../../static/images/User.svg";
import "./css/contracts.css";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import { format } from "date-fns";

interface ContractTableProps {
  contract_id?: number;
  user_first_name?: string;
  user_last_name?: string;
  user_profile_picture_url?: string;
  from_date?: Date | string;
  to_date?: Date | string;
  file_url?: string;
  viewContract?: (contractId: number) => void;
  downloadContract?: (contractId: number) => void;
}

const ContractTable: React.FC<ContractTableProps> = ({
  contract_id,
  user_first_name,
  user_last_name,
  user_profile_picture_url,
  from_date,
  to_date,
  viewContract,
  downloadContract
}) => {
  const IconProps = {
    isIcon: true,
  };

  return (
    <>

      <tr className="spaced-row">
        <td className="p-0 align-middle text-center">
          <Image
            src={user_profile_picture_url ?? UserImage}
            imageWidth={window.innerWidth <= 767
              ? "3.5vw"
              : window.innerWidth <= 1023
                ? "4vw"
                : "2vw"}
            imageHeight={window.innerWidth <= 767
              ? "3.5vw"
              : window.innerWidth <= 1023
                ? "4vw"
                : "2vw"}
            imageBorderRadius="50%"
          />
        </td>
        <td className="align-middle p-2">{user_first_name} {user_last_name}</td>
        <td className="align-middle p-2">
          {from_date && to_date
            ? `${format(new Date(from_date), "dd/MM/yyyy")} to ${format(new Date(to_date), "dd/MM/yyyy")}`
            : "N/A"}
        </td>
        <td className="align-middle p-2">
          <div className="d-flex">
            <span
              data-bs-toggle="tooltip"
              title={t("View")}
              onClick={contract_id ? () => viewContract?.(contract_id) : () => { }}
            >
              <Icon
                name="viewIcon"
                {...IconProps}
                className="me-2 cursor-pointer secondaryColorHoverEffect"
                width={window.innerWidth <= 767
                  ? "3.5vw"
                  : window.innerWidth <= 1023
                    ? "2vw"
                    : "0.9vw"}
                height={window.innerWidth <= 767
                  ? "3.5vw"
                  : window.innerWidth <= 1023
                    ? "2vw"
                    : "0.9vw"}
              />
            </span>
            <span
              data-bs-toggle="tooltip"
              title={t("Download")}
              onClick={contract_id ? () => downloadContract?.(contract_id) : () => { }}
            >
              <Icon
                name="downloadIcon"
                {...IconProps}
                className="cursor-pointer secondaryColorHoverEffect"
                width={window.innerWidth <= 767
                  ? "3.5vw"
                  : window.innerWidth <= 1023
                    ? "1.95vw"
                    : "0.9vw"}
                height={window.innerWidth <= 767
                  ? "3.5vw"
                  : window.innerWidth <= 1023
                    ? "1.95vw"
                    : "0.9vw"}
              />
            </span>
          </div>
        </td>
      </tr>
      <TableHeaderSpace headerLength={4} />

    </>
  );
};

export default ContractTable;
