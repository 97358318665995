import React from "react";
import { t } from "../CentralDataMangement/translation/Translation";
import LabelField from "../common/atoms/LabelField";
import RadioField from "../common/atoms/RadioField";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import Navigation from "./Navigation";
import { OnlineDetailsProps } from "./Interfaces";
import { emailFields, radioFields } from "./State";
import { validateOnlineDetails } from "./Service";

const OnlineDetails: React.FC<OnlineDetailsProps> = ({
  agreementData,
  handleChange,
  section,
  handleRadioChange,
  errors,
  updateErrors,
  handleNext,
  handlePrevious,
}) => {
  
  const handleNextButton = () => {
    const newErrors = validateOnlineDetails(agreementData);
    updateErrors(section, newErrors);
    if (Object.keys(newErrors).length > 0) {
      console.log("Errors are present: ", newErrors);
      return; // Stop execution if there are errors
    }
    handleNext(); // Proceed if no errors
  };

  const handlePreviousButton = () => {
    console.log("Previous clicked");
    handlePrevious();
  };

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 22vw)" }}>
        <div className="row">
          {emailFields.map((field, index) => (
            <div className="col-4 mb-3" key={index}>
              <LabelWithInputField
                type="email"
                label={field?.label}
                value={agreementData?.[field.name] || ""}
                name={field.name}
                id={field.name}
                handleChange={(e) => handleChange(e, section)}
                // error={field.error ?? ""}
                error={errors?.[field.name] || ""}
                className="ps-2"
                isMandatory
                isDisabled={field?.isDisabled ?? false}
              />
            </div>
          ))}

          {radioFields.map((field, index) => (
            <div className="col-4 mb-3" key={index}>
              <div className="mb-2">
                <LabelField title={field.title} isMandatory />
              </div>
              {["yes", "no"].map((option, optIndex) => (
                <RadioField
                  key={optIndex}
                  name={field.name}
                  label={t(option.charAt(0).toUpperCase() + option.slice(1))}
                  className="radioBtn"
                  ischecked={agreementData?.[field.name] === (option === "yes")}
                  handleChange={(e) =>
                    handleRadioChange?.(field.name, option === "yes", section)
                  }
                  id={field.ids[optIndex]}
                  containerClassName={`companyLanguage ${
                    optIndex === 0 ? "me-3" : ""
                  }`}
                />
              ))}
              {errors?.[field.name] && (
                <div className="text-danger mt-1">{errors?.[field.name]}</div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Navigation
        isLastTab={false}
        onPrevious={handlePreviousButton}
        onNext={handleNextButton}
      />
    </>
  );
};

export default OnlineDetails;
