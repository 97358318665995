import React, { ChangeEvent, useRef, useEffect, useState } from "react";
import { t } from "../../../common/atoms/translation";
import LabelField from "../../../common/atoms/LabelField";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import SelectWithSearch from "../../../common/atoms/SelectWithSearch";
import Button from "../../../common/atoms/Button";
import { ApiCall } from "../../../../services/ApiServices";
import {
  GET_PC_BY_COMPANY,
  OPTIONS_LIST_BY_GROUP_OF_COMPANIES,
} from "../../../../routes/ApiEndpoints";
import { useSelector } from "react-redux";
import { DefaultEmpLegal, EmployeeSalaryInfoProps, FunctionOptionType, Option } from "../Interfaces";
import { selectAuth } from "../../../../features/auth/AuthSlice";
import Icon from "../../../../Icon";
import { defaultEmpLegalDefaults } from "../State";
import { getEmployeePreviousSalaryByEmployeeType } from "../../../Planning/Services/PlanningSalaryHelper";
import { formatDate } from "../../../../services/common/CommonServices";
import CompanyList from "./CompanyList";

const EmployeeSalaryInfo: React.FC<EmployeeSalaryInfoProps> = ({
  user_id = 0,
  companies,
  default_emp_legal,
  errors,
  handleSelectChange,
  updatePcValues,
  missing_companies,
  dob,
  default_employee_id_option,
  currentPage = "",
  fetched_companies = [],
}) => {
  const [checkedItems, setCheckedItems] = useState<Set<number>>(new Set()); // For Checkbox's selected items
  const [pcOptions, setPCOptions] = useState<Option[][]>([]); // For Paritaire Commitee options
  const [fuctionsOptions, setFuctionsOptions] = useState<
    FunctionOptionType[][]
  >([]); // For Function options
  const [employeeTypeOptions, setEmployeeTypeOptions] = useState<
  Option[][]
  >([]); // For Employee Type options
  const [companyData, setCompanyData] = useState<{ companies: any[] }>({
    companies: [],
  }); // For Showing Companies for Checkbox's
  const [companiesOptions, setCompaniesOptions] = useState<Option[]>([]); // For Desired Company Options
  const user = useSelector(selectAuth);
  const [pcData, setPcData] = useState<
    {
      employeeTypes: Record<number, { label: string; value: number }[]>;
      functions: Record<number, { label: string; value: number }[]>;
    }[]
  >([]);

  // For Setting companies data from redux
  useEffect(() => {
    setCompanyData((prevState) => ({
      ...prevState,
      companies: user?.companies,
    }));
  }, [user?.companies]);

  // Used while ------ Edit/Add Employee ------
  const updatePcDataState = (
    responseData: Record<number, any>, // Data grouped by company_id from response
    defaultEmpLegal: { company_id: number | null }[]
  ) => {
    const newPcData = defaultEmpLegal.map((empLegal) => {
      const companyId = empLegal.company_id;

      // Check if companyId exists in response data
      if (companyId && responseData[companyId]) {
        const companyData = responseData[companyId];

        // Include all data related to the company (all PCs, functions, and employee types)
        return {
          company_id: companyId,
          pcOptions: companyData.pcs || [],
          employeeTypes: companyData.employee_types || {},
          functions: companyData.functions || {},
        };
      }

      // If no data exists for the company, return an empty structure
      return {
        company_id: companyId,
        pcOptions: [],
        employeeTypes: {},
        functions: {},
      };
    });

    setPcData(newPcData); // Update the pcData state
  };

  // Used while ------ Edit/Add Employee ------
  const fetchPcInfogroupBycompany = async () => {
    const body = {
      companies: companies,
    };
    try {
      const response = await ApiCall.service(
        OPTIONS_LIST_BY_GROUP_OF_COMPANIES,
        "POST",
        body
      );
      if (response.status === "success") {
        const { data } = response; // Assuming the response format is { "data": { ... } }

        if (data && Object.keys(data).length > 0) {
          // Map over the state to set the options
          const updatedPCOptions = default_emp_legal.map((item) => {
            // Ensure company_id is not null before accessing data
            return item?.company_id && data[item.company_id]
              ? data[item.company_id].pcs || []
              : [];
          });

          const updatedFunctionsOptions = default_emp_legal.map((item) => {
            // Ensure company_id and paritaire_commitee_id are valid
            return item?.company_id &&
              item?.paritaire_commitee_id &&
              data[item.company_id]?.functions[item.paritaire_commitee_id]
              ? data[item.company_id].functions[item.paritaire_commitee_id]
              : [];
          });

          const updatedEmployeeTypeOptions = default_emp_legal.map((item) => {
            // Ensure company_id and paritaire_commitee_id are valid
            return item?.company_id &&
              item?.paritaire_commitee_id &&
              data[item.company_id]?.employee_types[item.paritaire_commitee_id]
              ? data[item.company_id].employee_types[item.paritaire_commitee_id]
              : [];
          });

          // Set the options states
          setPCOptions(updatedPCOptions);
          setFuctionsOptions(updatedFunctionsOptions);
          setEmployeeTypeOptions(updatedEmployeeTypeOptions);
          // Update the pcData state by mapping response data to default_emp_legal
          updatePcDataState(response?.data, default_emp_legal);
        } else {
          // If data is empty, reset the options to empty arrays
          setPCOptions([]);
          setFuctionsOptions([]);
          setEmployeeTypeOptions([]);
        }
      }
    } catch (error) {
      console.error("Error fetching Pc Info group By company:", error);
    }
  };

  // Used while ------ Edit Employee ------
  useEffect(() => {
    if (user_id !== 0 && !isNaN(user_id)) {
      fetchPcInfogroupBycompany();
    }
  }, [user_id]);


  const removeFunctionEmployeeTypeOptions = (index: number) => {
    setFuctionsOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      newOptions[index] = [];
      return newOptions;
    });
    setEmployeeTypeOptions((prevOptions) => {
      const newOptions = [...prevOptions];
      // if (default_employee_id_option) {
      //   newOptions[index] = default_employee_id_option;
      // } else {
      newOptions[index] = [];
      // }
      return newOptions;
    });
  };

  const fetchPCOptions = async (index: number, selectedCompanyId: number) => {
    if (selectedCompanyId !== 0) {
      try {
        const res = await ApiCall.getService(
          `${GET_PC_BY_COMPANY}/${selectedCompanyId}?type=extend`,
          "GET"
        );
        if (res.status !== "success") {
          throw new Error("Network response was not ok");
        }
        if (res.status === "success") {
          // Map the API response to the desired PC options format
          const mappedPCOptions = res.data
            ? res?.data?.map((item: any) => ({
                value: item?.id,
                label: `${item?.pc_number} - ${item?.pc_name}`,
              }))
            : [];

          // Map additional data like employee_types and functions
          const mappedData = res.data
            ? res?.data?.reduce(
                (acc: any, item: any) => {
                  acc.pcOptions?.push({
                    value: item?.id,
                    label: `${item?.pc_number} - ${item?.pc_name}`,
                  });
                  acc.employeeTypes[item?.id] = item?.employee_types || [];
                  // Combine direct functions and functions from categories
                  const categoryFunctions =
                    item?.categories?.flatMap(
                      (category: any) => category?.functions || []
                    ) || [];
                  acc.functions[item?.id] = [
                    ...(item?.functions || []),
                    ...categoryFunctions,
                  ];
                  return acc;
                },
                { pcOptions: [], employeeTypes: {}, functions: {} }
              )
            : { pcOptions: [], employeeTypes: {}, functions: {} };
          console.log(mappedPCOptions);

          // Update `pcOptions` for the specific index
          setPCOptions((prevOptions) => {
            const newOptions = [...prevOptions];
            newOptions[index] = mappedPCOptions;
            return newOptions;
          });
          console.log(mappedData);
          // Update `pcData` to store additional details for this index
          setPcData((prevData) => {
            const newData = [...prevData];
            newData[index] = {
              employeeTypes: mappedData.employeeTypes || {},
              functions: mappedData.functions || {},
            };
            return newData;
          });
        } else {
          throw new Error(res.message);
        }
      } catch (error) {
        console.error("Error fetching PC options:", error);
      }
    } else {
      // If company is not selected, reset the PC options and make company id 0
      updatePcValues({
        companies: companies,
        errors: errors,
        default_emp_legal: default_emp_legal.map((item, i) =>
          i === index
            ? {
                ...item,
                id: null,
                company_id: 0,
              }
            : item
        ),
        missing_companies: missing_companies,
      });
      setPCOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions[index] = [];
        return newOptions;
      });
    }
  };

  // Handler for PC change
  const CompanyHandler = async (index: number, selectedCompanyId: number) => {
    fetchPCOptions(index, selectedCompanyId);
    // If company is changed, reset the value and options of Functions and Employee-types
    updatePcValues({
      companies: companies,
      errors: errors,
      default_emp_legal: default_emp_legal.map((item, i) =>
        i === index
          ? {
              ...item,
              id: null,
              paritaire_commitee_id: null,
              employee_type_id: null,
              function_id: null,
              minWage: "",
              actual_salary: "",
              is_actual_added: false,
            }
          : item
      ),
      missing_companies: missing_companies,
    });
    removeFunctionEmployeeTypeOptions(index);
  };

  const getPcDataById = (pcId: number, index: number) => {
    const pcEntry = pcData[index]; // Retrieve data for the given index
    if (!pcEntry) {
      console.warn(`No data found for index ${index}`);
      return { employeeTypes: [], functions: [] };
    }

    const employeeTypes = pcEntry.employeeTypes[pcId] || [];
    const functions = pcEntry.functions[pcId] || [];

    return { employeeTypes, functions };
  };

  // Featching Functions and Employee-types by PC ID
  const fetchOptions = async (index: number, selectedPcId: number) => {
    if (selectedPcId !== 0) {
      const { employeeTypes, functions } = getPcDataById(selectedPcId, index);
      setFuctionsOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions[index] = functions;
        return newOptions;
      });
      setEmployeeTypeOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions[index] = employeeTypes;
        return newOptions;
      });
    } else {
      removeFunctionEmployeeTypeOptions(index);
    }
    // If any change happen pc id then clear the function and employee type and set the min wage to default value
    updatePcValues({
      companies: companies,
      errors: errors,
      default_emp_legal: default_emp_legal.map((item, i) =>
        i === index
          ? {
              ...item,
              employee_type_id: null,
              function_id: null,
              minWage: "",
              actual_salary: "",
              is_actual_added: false,
            }
          : item
      ),
      missing_companies: missing_companies,
    });
  };

  const handleFunctionChange = async (
    index: number,
    fieldPath: string,
    selectedOption: FunctionOptionType | null
  ) => {
    let minWage = "";
    let dobError = null; // Initialize dobError variable

    // Validate dob
    if (!dob || dob.trim() === "") {
      dobError = "Date of Birth is required to get minimum wage."; // Assign error if dob is empty
    }

    // Check if all required parameters are not null or undefined
    if (
      default_emp_legal[index].paritaire_commitee_id != null &&
      default_emp_legal[index].company_id != null &&
      selectedOption?.value != null &&
      default_emp_legal[index].employee_type_id != null &&
      !dobError // Check if dobError is falsy (i.e., no error)
    ) {
      // Await the resolved value of the promise
      minWage = await getEmployeePreviousSalaryByEmployeeType(
        default_emp_legal[index].paritaire_commitee_id,
        default_emp_legal[index].company_id,
        selectedOption?.value,
        default_emp_legal[index].employee_type_id,
        user_id ? user_id : -999,
        formatDate(dob)
      );
    } else {
      // If any of the required params are null/undefined or dobError exists,
      // you might want to handle this scenario, e.g., by not updating the state
      console.log("Required parameters are missing or dob error exists.");
    }

    // Update `default_emp_legal` with the new minWage
    const updatedEmpLegal = default_emp_legal.map((item: any, i: number) => {
      if (i === index) {
        return {
          ...item,
          [fieldPath]: selectedOption && dob ? selectedOption.value : null,
          minWage: minWage, // Set the resolved minWage here
          actual_salary: "",
          is_actual_added: false,
        };
      }
      return item;
    });

    // Update errors state with the new dobError (if any)
    const updatedErrors = { ...errors }; // Assuming 'errors' is an object
    updatedErrors[`default_emp_legal.${index}.${fieldPath}`] = dobError; // Assign dobError to the errors object

    updatePcValues({
      companies: companies,
      errors: updatedErrors, // Pass the updated errors object
      default_emp_legal: updatedEmpLegal,
      missing_companies: missing_companies,
    });
  };

  // For handling company checkboxes while ------Edit Employee---- filling Checkboxes
  useEffect(() => {
    if (Array.isArray(companies) && companyData?.companies) {
      // Initialize checked items as a Set from companies
      const initialCheckedItems = new Set(companies);
      setCheckedItems(initialCheckedItems);

      // Filter and map company data to create the initial companiesOptions
      const initialCompaniesOptions = companyData.companies
        .filter((company) => initialCheckedItems.has(company.id)) // Use Set for O(1) lookup
        .map((company) => ({
          label: company.name,
          value: company.id,
        }));
      setCompaniesOptions(initialCompaniesOptions);
    }
  }, [companyData, companies]);

  const handleCheckBoxClick = (company: any, isChecked: boolean) => {
    setCheckedItems((prev) => {
      const updated = new Set(prev);
      if (isChecked) {
        updated.add(company.id); // Add to checked items
      } else {
        updated.delete(company.id); // Remove from checked items
      }
      return updated;
    });

    // Call handleCompanyChange directly to update `companies` and `companiesOptions`
    handleCompanyChange(company, isChecked);
  };

  const handleCompanyChange = (company: any, isChecked: boolean) => {
    const updatedCompanies = isChecked
      ? [...companies, company.id]
      : companies.filter((id) => id !== company.id);

    const updatedMissedCompanies = isChecked
      ? [...missing_companies] // If checked, keep the existing list unchanged
      : missing_companies.filter((id) => id !== company.id); // If unchecked, remove the company ID

    const updatedCompaniesOptions = isChecked
      ? [...companiesOptions, { label: company.name, value: company.id }]
      : companiesOptions?.filter((option) => option.value !== company.id);

    let updatedDefaultEmpLegal;
    let index = -1;
    if (
      isChecked &&
      default_emp_legal.length > 0 &&
      default_emp_legal[default_emp_legal.length - 1].company_id === null
    ) {
      // Update the last index with the selected company_id if it's null
      updatedDefaultEmpLegal = [
        ...default_emp_legal.slice(0, default_emp_legal.length - 1),
        {
          ...default_emp_legal[default_emp_legal.length - 1],
          id: null,
          company_id: company.id,
        },
      ];
      index = default_emp_legal.length - 1;
    } else if (isChecked) {
      // Add a new entry for the selected company
      updatedDefaultEmpLegal = [
        ...default_emp_legal,
        {
          id: null,
          company_id: company.id,
          paritaire_commitee_id: null,
          // employee_type_id: default_employee_id_option?.[0]?.value,
          employee_type_id: null,
          function_id: null,
          minWage: "",
          actual_salary: "",
          is_actual_added: false,
        },
      ];
      index = updatedDefaultEmpLegal.length - 1;
    } else if (!isChecked) {
      // Collect indexes of entries in default_emp_legal with the matching company_id
      const indexesToRemove = default_emp_legal
        .map((entry, index) => (entry.company_id === company.id ? index : -1))
        .filter((index) => index !== -1);

      // Clear errors for each index to remove and update errors state
      indexesToRemove.forEach((index) => {
        errors = clearOneIndexErrors(index, errors);
      });
      updatedDefaultEmpLegal = default_emp_legal;
    }

    // Check if an index is valid for fetchPCOptions and call the function
    if (index !== -1) {
      fetchPCOptions(index, company.id);
    }

    // Update state with new values
    setCompaniesOptions(updatedCompaniesOptions);
    updatePcValues({
      companies: updatedCompanies,
      errors: errors,
      default_emp_legal: updatedDefaultEmpLegal,
      missing_companies: updatedMissedCompanies,
    });
  };

  // Used to Remopve errors related to the removed entry
  const removeDefaultEmpLegalErrors = (indexToRemove: number) => {
    const updatedErrors = Object.keys(errors).reduce((acc, key) => {
      // Check if the error key starts with `default_emp_legal.[indexToRemove]`
      const pattern = `default_emp_legal.${indexToRemove}.`;
      if (!key.startsWith(pattern)) {
        acc[key] = errors[key]; // Keep errors that don't match the pattern
      }
      return acc;
    }, {} as Record<string, string>);

    // Update the errors state
    setErrors(updatedErrors);
  };

  // Filtering selected companies id in default_emp_legal array of objects
  const validateDefaultEmpLegal = () => {
    const indicesToRemove: number[] = [];
    let updatedData = default_emp_legal.filter((entry, index) => {
      const isValid =
        entry.company_id === null || companies.includes(entry.company_id);

      // If the entry is invalid, mark the index for removal
      if (!isValid) {
        indicesToRemove.push(index);
      }

      return isValid; // Only keep valid entries
    });
    if (updatedData.length === 0) {
      updatedData = [
        {
          ...defaultEmpLegalDefaults,
          id: null,
          company_id: null, // Explicitly set company_id to null
        },
      ];
    }
    // Remove errors for each invalid index
    indicesToRemove.forEach((index) => removeDefaultEmpLegalErrors(index));

    const allPCOptions = [...pcOptions]; // Use the state variable directly
    const allFunctionsOptions = [...fuctionsOptions]; // Use the state variable directly
    const allEmployeeTypeOptions = [...employeeTypeOptions]; // Use the state variable directly
    const allPcDataOptions = [...pcData]; // Use the state variable directly

    // Filter options based on indices to remove
    const updatedPCOptions = allPCOptions.filter(
      (_, i) => !indicesToRemove.includes(i)
    );
    const updatedFunctionsOptions = allFunctionsOptions.filter(
      (_, i) => !indicesToRemove.includes(i)
    );
    const updatedEmployeeTypeOptions = allEmployeeTypeOptions.filter(
      (_, i) => !indicesToRemove.includes(i)
    );
    const updatedPcDataOptions = allPcDataOptions.filter(
      (_, i) => !indicesToRemove.includes(i)
    );

    // Update options arrays in the state
    setPCOptions(updatedPCOptions);
    setFuctionsOptions(updatedFunctionsOptions);
    setEmployeeTypeOptions(updatedEmployeeTypeOptions);
    setPcData(updatedPcDataOptions);

    updatePcValues({
      companies: companies,
      errors: errors,
      default_emp_legal: updatedData,
      missing_companies: missing_companies,
    });
  };

  useEffect(() => {
    validateDefaultEmpLegal();
  }, [companies]);

  const validateFields = () => {
    const updatedErrors: Record<string, string | undefined> = { ...errors };
    let isValid = true;

    // Iterate through each entry in default_emp_legal
    default_emp_legal.forEach((entry, index) => {
      const companyIdError = entry.company_id
        ? undefined
        : "This field is required";
      const paritaireCommiteeIdError = entry.paritaire_commitee_id
        ? undefined
        : "This field is required";
      const employeeTypeIdError = entry.employee_type_id
        ? undefined
        : "This field is required";
      const functionIdError = entry.function_id
        ? undefined
        : "This field is required";
      const minWageError =
        entry.minWage?.trim() !== "" || null
          ? undefined
          : "This field is required";

      updatedErrors[`default_emp_legal.${index}.company_id`] = companyIdError;
      updatedErrors[`default_emp_legal.${index}.paritaire_commitee_id`] =
        paritaireCommiteeIdError;
      updatedErrors[`default_emp_legal.${index}.employee_type_id`] =
        employeeTypeIdError;
      updatedErrors[`default_emp_legal.${index}.function_id`] = functionIdError;
      updatedErrors[`default_emp_legal.${index}.minWage`] = minWageError;
      // Remove fields with no errors
      Object.keys(updatedErrors).forEach((key) => {
        if (updatedErrors[key] === undefined) {
          delete updatedErrors[key];
        }
      });
      setErrors(updatedErrors);
      // Update isValid flag based on the errors
      if (
        companyIdError ||
        paritaireCommiteeIdError ||
        employeeTypeIdError ||
        functionIdError ||
        minWageError
      ) {
        isValid = false; // At least one field is invalid
      }
    });
    return isValid;
  };

  const handleEmployeeTypeChangeForClearFunction = (index: number) => {
    // Create a copy of `default_emp_legal` to avoid mutating the original array
    const updatedEmpLegal = [...default_emp_legal];

    // Clear `function_id` at the specified index
    if (updatedEmpLegal[index]) {
      updatedEmpLegal[index].function_id = null;
      updatedEmpLegal[index].minWage = "";
      updatedEmpLegal[index].actual_salary = "";
      updatedEmpLegal[index].is_actual_added = false;
    }
    updatePcValues({
      companies: companies,
      default_emp_legal: updatedEmpLegal, // Pass the modified array here
      errors: errors,
      missing_companies: missing_companies,
    });
  };

  const setErrors = (updatedErrors: Record<string, string | undefined>) => {
    // Update state with all errors at once
    updatePcValues({
      companies: companies,
      default_emp_legal: default_emp_legal,
      errors: updatedErrors,
      missing_companies: missing_companies,
    });
  };

  // Used to clear Options, Section for a specific index
  const clearingOptions = (index: number) => {
    setPCOptions((prevOptions) => prevOptions.filter((_, i) => i !== index));
    setFuctionsOptions((prevOptions) =>
      prevOptions.filter((_, i) => i !== index)
    );
    setEmployeeTypeOptions((prevOptions) =>
      prevOptions.filter((_, i) => i !== index)
    );
    setPcData((prevOptions) => prevOptions.filter((_, i) => i !== index));
  };

  const clearOneIndexErrors = (
    index: number,
    errorsState: Record<string, string | undefined>
  ) => {
    const updatedErrors = Object.keys(errorsState).reduce((acc, key) => {
      if (key.startsWith(`default_emp_legal.`)) {
        const newIndex = Number(key.split(".")[1]);

        if (newIndex < index) {
          // Keep the keys that are less than the specified index
          (acc as any)[key] = errorsState[key];
        } else if (newIndex > index) {
          // Adjust the keys that are greater than the specified index
          const newKey = `default_emp_legal.${newIndex - 1}`;
          (acc as any)[newKey] = errorsState[key];
        }
      } else {
        // Keep keys that don't start with `default_emp_legal.`
        (acc as any)[key] = errorsState[key];
      }

      return acc;
    }, {});
    return updatedErrors;
  };

  // Used for the add field button
  const handleAddField = () => {
    const isValid = validateFields();
    if (isValid || default_emp_legal.length === 0) {
      const newIndex = default_emp_legal.length;
      // Add a new entry to the default_emp_legal array in state
      updatePcValues({
        companies: companies,
        errors: errors,
        default_emp_legal: [
          ...default_emp_legal.slice(0, newIndex),
          {
            id: null,
            company_id: null,
            paritaire_commitee_id: null,
            // employee_type_id: default_employee_id_option?.[0]?.value,
            employee_type_id: null,
            function_id: null,
            minWage: "",
            actual_salary: "",
            is_actual_added: false,
          },
          ...default_emp_legal.slice(newIndex),
        ],
        missing_companies: missing_companies,
      });
    }
  };

  // Used for the remove button
  const handleRemoveField = (index: number) => {
    clearingOptions(index);
    const updatedEmpLegal = default_emp_legal.filter(
      (_: any, i: number) => i !== index
    );

    updatePcValues({
      companies: companies,
      default_emp_legal: updatedEmpLegal,
      errors: clearOneIndexErrors(index, errors),
      missing_companies: missing_companies,
    });
  };

  const handleSalaryChange = (value: string, index: number) => {
    const defaultEmpLegalDefaults: DefaultEmpLegal = {
      id: null,
      company_id: null,
      paritaire_commitee_id: null,
      employee_type_id: null,
      function_id: null,
      minWage: "",
      actual_salary: "",
      is_actual_added: false,
    };

    // Check if updating 'default_emp_legal' array
    if (index !== undefined) {
      const updatedArray = [...default_emp_legal]; // Shallow copy the array

      // Ensure the object at the specified index is initialized with default values
      const item = updatedArray[index] || defaultEmpLegalDefaults;
      updatedArray[index] = {
        ...item,
        actual_salary: value,
      };

      updatePcValues({
        companies: companies,
        errors: errors,
        default_emp_legal: updatedArray,
        missing_companies: missing_companies,
      });
    }
  };

  //Used for filtering functions by unique combination
  const filterFunctionsByUniqueCombination = (
    index: number,
    functionOptions: FunctionOptionType[]
  ) => {
    const currentItem = default_emp_legal[index];
    const { company_id, paritaire_commitee_id, employee_type_id } = currentItem;

    return functionOptions.filter((option) => {
      const isAlreadySelectedForCombination = default_emp_legal.some(
        (item, idx) =>
          idx !== index &&
          item.company_id === company_id &&
          item.paritaire_commitee_id === paritaire_commitee_id &&
          item.employee_type_id === employee_type_id &&
          item.function_id === option.value
      );
      return !isAlreadySelectedForCombination;
    });
  };

  return (
    <>
      <div className="row mb-3">
        <div className="col-12 mb-3">
          <LabelField
            title={t("To which company would you like to add this employee")}
            className="tab-title"
            isMandatory
          />
          {errors?.companies && (
            <div className="text-danger"> {errors?.companies}</div>
          )}
        </div>
        <div>
          <CompanyList
            companies={companyData?.companies}
            fetchedCompanies={fetched_companies}
            missingCompanies={missing_companies}
            checkedItems={checkedItems}
            handleCheckBoxClick={handleCheckBoxClick}
          />
        </div>
        <div className="col-12">
          <LabelField
            title={t("Function & hourly wage")}
            className="tab-title"
          />
        </div>
        {errors?.default_emp_legal && (
          <span className="text-danger">{errors?.default_emp_legal}</span>
        )}
      </div>
      <div>
        {default_emp_legal.map((field, index) => (
          <div key={index} className="border rounded-3 mb-3 p-3">
            <div className="row position-relative">
              {default_emp_legal.length !== 1 &&
                !(
                  currentPage === "employee-invite" &&
                  default_emp_legal[index]?.id !== null
                ) && (
                  <div className="col-12 text-end mb-3">
                    <span
                      onClick={() => handleRemoveField(index)}
                      title={t("Remove")}
                      className="secondaryColorHoverEffect"
                    >
                      <Icon
                        name="close"
                        isIcon={true}
                        width={
                          window.innerWidth <= 767
                            ? "3.5vw"
                            : window.innerWidth <= 1023
                            ? "2.5vw"
                            : "0.8vw"
                        }
                        height={
                          window.innerWidth <= 767
                            ? "3.5vw"
                            : window.innerWidth <= 1023
                            ? "2.5vw"
                            : "0.8vw"
                        }
                      />
                    </span>
                  </div>
                )}
              <div className="col-lg-4 col-md-12 mb-3">
                <div className="row multiSelectWrapper">
                  <SelectWithSearch
                    fieldLabel={t("Desired company") + ":"}
                    title={t("Desired company") + ":"}
                    name="company_id"
                    placeHolder="Select"
                    isMandatory
                    search
                    isDisabled={
                      currentPage === "employee-invite" &&
                      default_emp_legal[index]?.id !== null
                        ? true
                        : false
                    }
                    options={companiesOptions || []} // Pass the options here
                    value={
                      companiesOptions?.find(
                        (option) =>
                          option.value === default_emp_legal[index]?.company_id
                      ) || null
                    }
                    onChange={(selectedOption: any) => {
                      CompanyHandler(index, Number(selectedOption.value));
                      handleSelectChange(
                        selectedOption,
                        `default_emp_legal.${index}.company_id`,
                        index
                      );
                    }}
                    error={
                      errors[`default_emp_legal.${index}.company_id`] ?? ""
                    }
                    isTranslate
                    labelWrapperClassName="col-4"
                    colClassName="col-8"
                    className="leftlabelClass"
                  />
                </div>
              </div>

              <div className="col-lg-4 col-md-12 mb-3">
                <div className="row multiSelectWrapper">
                  <SelectWithSearch
                    fieldLabel={t("Paritair committee") + ":"}
                    title={t("Paritair committee") + ":"}
                    name="paritaire_commitee_id"
                    placeHolder="Select"
                    isMandatory
                    search
                    isDisabled={
                      currentPage === "employee-invite" &&
                      default_emp_legal[index]?.id !== null
                        ? true
                        : false
                    }
                    options={pcOptions[index] || []} // Pass the options here
                    value={
                      (pcOptions[index] || []).find(
                        (option: Option) =>
                          option.value ===
                          default_emp_legal[index]?.paritaire_commitee_id
                      ) || null
                    }
                    onChange={(selectedOption: Option) => {
                      if (selectedOption.value !== undefined) {
                        fetchOptions(index, Number(selectedOption.value));
                      }
                      handleSelectChange(
                        selectedOption,
                        `default_emp_legal.${index}.paritaire_commitee_id`,
                        index
                      );
                    }}
                    error={
                      errors[
                        `default_emp_legal.${index}.paritaire_commitee_id`
                      ] || null
                    }
                    isTranslate
                    labelWrapperClassName="col-4"
                    colClassName="col-8"
                    className="leftlabelClass"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12 mb-3">
                <div className="row multiSelectWrapper">
                  <SelectWithSearch
                    fieldLabel={t("Employee type") + ":"}
                    title={t("Employee type") + ":"}
                    name="employee_type_id"
                    placeHolder="Select"
                    isMandatory
                    search
                    isDisabled={
                      currentPage === "employee-invite" &&
                      default_emp_legal[index]?.id !== null
                        ? true
                        : false
                    }
                    options={(
                      employeeTypeOptions[index] ||
                      // default_employee_id_option ||
                      []
                    ) // Fallback to empty array
                      .sort((a: Option, b: Option) =>
                        a.label.localeCompare(b.label)
                      )}
                    value={
                      (
                        employeeTypeOptions[index] ||
                        // default_employee_id_option ||
                        []
                      ).find(
                        (option: Option) =>
                          option.value ===
                          default_emp_legal[index]?.employee_type_id
                      ) || null
                    }
                    onChange={(selectedOption: Option) => {
                      handleEmployeeTypeChangeForClearFunction(index);
                      handleSelectChange(
                        selectedOption,
                        `default_emp_legal.${index}.employee_type_id` // Correct dynamic field path
                      );
                    }}
                    isMulti={false}
                    error={
                      errors[`default_emp_legal.${index}.employee_type_id`] ||
                      ""
                    }
                    isTranslate
                    labelWrapperClassName="col-4"
                    colClassName="col-8"
                    className="leftlabelClass"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12 mb-3">
                <div className="row multiSelectWrapper">
                  <SelectWithSearch
                    fieldLabel={t("Function") + ":"}
                    title={t("Function") + ":"}
                    name="function_id"
                    placeHolder="Select"
                    isMandatory
                    search
                    isDisabled={
                      currentPage === "employee-invite" &&
                      default_emp_legal[index]?.id !== null
                        ? true
                        : false
                    }
                    options={
                      default_emp_legal[index]?.employee_type_id
                        ? filterFunctionsByUniqueCombination(
                            index,
                            fuctionsOptions[index] || []
                          ).sort((a, b) => a.label.localeCompare(b.label))
                        : []
                    }
                    value={
                      (fuctionsOptions[index] || []).find(
                        (option: FunctionOptionType) =>
                          option.value === default_emp_legal[index]?.function_id
                      ) || null
                    }
                    onChange={(selectedOption: FunctionOptionType | null) => {
                      handleFunctionChange(
                        index,
                        "function_id",
                        selectedOption
                      );
                    }}
                    isTranslate
                    error={
                      errors[`default_emp_legal.${index}.function_id`] || ""
                    }
                    labelWrapperClassName="col-4"
                    colClassName="col-8"
                    className="leftlabelClass"
                  />
                </div>
              </div>
              <div className="col-lg-4 col-12 mb-3">
                <div className="row">
                  <LabelWithInputField
                    label={t("Minimum wage") + ":"}
                    className="inputFieldColor ps-2"
                    colClassName="col-8"
                    type="text"
                    id={`minWage-${index}`}
                    value={default_emp_legal[index]?.minWage || ""} // Access the value from the array
                    name="minWage"
                    error={errors[`default_emp_legal.${index}.minWage`] || ""}
                    isDisabled={true}
                    labelWrapperClassName="col-4"
                    autoComplete="off"
                    isMandatory={true}
                  >
                    <span className="colorPrimary helpText">
                      *{t("If flexi excl holiday pay")}
                    </span>
                  </LabelWithInputField>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 mb-3">
                <div className="row">
                  <LabelWithInputField
                    label={t("Actual wage") + ":"}
                    className="inputFieldColor ps-2"
                    colClassName="col-8"
                    type="text"
                    id={`actual_salary-${index}`}
                    value={default_emp_legal[index]?.actual_salary || ""} // Access the value from the array
                    handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleSalaryChange(e.target?.value, index)
                    }
                    name="actual_salary"
                    error={
                      errors[`default_emp_legal.${index}.actual_salary`] || ""
                    }
                    isDisabled={
                      (currentPage === "employee-invite" &&
                        default_emp_legal[index]?.id !== null) ||
                      (user_id !== 0 &&
                        default_emp_legal[index]?.id !== null &&
                        default_emp_legal[index]?.actual_salary.trim() !== "")
                        ? true
                        : false
                    }
                    labelWrapperClassName="col-4"
                    autoComplete="off"
                  >
                    <span className="colorPrimary helpText">
                      *{t("If flexi excl holiday pay")}
                    </span>
                  </LabelWithInputField>
                </div>
              </div>
            </div>
          </div>
        ))}
        <div className="col-12 mb-3 text-center">
          {currentPage !== "employee-invite" && (
            <Button
              title={"+ Add another PC"}
              className="modalSaveBtn"
              handleClick={handleAddField}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default EmployeeSalaryInfo;
