import React, { useState, useEffect } from "react";
import HeaderWIthFilter from "./HeaderWIthFilter";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DateAndTime from "../common/atoms/DateWithTime";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import { useSelector } from "react-redux";
import { selectCurrentLocations } from "../../features/auth/AuthSlice";
import PlanningTabs from "./PlanningTabs";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { PLANNING_OVERVIEW } from "../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import { ApiCall } from "../../services/ApiServices";
import dayjs from 'dayjs';
import UserImage from "../../static/images/User.svg";

// Event interface
interface CustomEvent {
  title: string;
  start: Date;
  end: Date;
  image?: string;
  allDay?: boolean;
  schedule?: string;
  pc_name?: string;
}

const MonthlyPlanningOverview: React.FC = () => {
  const localizer = momentLocalizer(moment);
  const show = useSelector(selectPlanningShow);
  const locations = useSelector(selectCurrentLocations) ?? [];

  const [events, setEvents] = useState<CustomEvent[]>([]);
  const [date, setDate] = useState(new Date());

  const [selectedLocationIndex, setSelectedLocationIndex] = useState(0);
  const companyId = useSelector((state: any) => state.auth.currentCompany.id);
  const [monthData, setMonthData] = useState<any>([]);

  const handleDateChange = (newDate: Date) => {
    console.log("New Date Selected:", newDate);
    setDate(newDate);
  };
  /**Auto refresh */
  const [autorefresh, setAutorefresh] = useState(false);

  setTimeout(() => {
    setAutorefresh(!autorefresh);
  }, 60000);
  
  useEffect(() => {
    const fetchMonthlyData = async () => {
      try {
        const startOfMonth = dayjs(date).startOf('month').format('YYYY-MM-DD');
        const endOfMonth = dayjs(date).endOf('month').format('YYYY-MM-DD');
        console.log(`Fetching data from ${startOfMonth} to ${endOfMonth}`);

        let dataObj = {
          company_id: companyId,
          location_id: locations[selectedLocationIndex]?.id,
          start_date: startOfMonth,
          end_date: endOfMonth,
        };

        let result = await ApiCall.service(
          PLANNING_OVERVIEW,
          "POST",
          dataObj,
          true,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );

        if (result.status === 'success') {
          setMonthData(result.data);
        }
      } catch (error) {
        console.error("Error fetching monthly data:", error);
      }
    };

    fetchMonthlyData();
  }, [date, companyId, locations, selectedLocationIndex,autorefresh]);

  useEffect(() => {
    if (monthData && typeof monthData === 'object') {
      const parsedEvents = Object.entries(monthData).flatMap(([date, dayData]: [string, any]) =>
        Array.isArray(dayData) ? dayData.map((item: any) => {
          let serviceCoupe = item.schedule.length > 1 ?
            `${item.schedule[0].shift_starttime} - ${item.schedule[0].shift_endtime} | ${item.schedule[1].shift_starttime} - ${item.schedule[1].shift_endtime}` :
            `${item.schedule[0].shift_starttime} - ${item.schedule[0].shift_endtime}`;

          return {
            title: item.name,
            start: new Date(`${date}T${item.schedule[0].shift_starttime}`),
            end: new Date(`${date}T${item.schedule[0].shift_endtime}`),
            image: item.employee_profile_pic ?? "https://www.pngall.com/wp-content/uploads/5/Profile-PNG-Images.png",
            schedule: serviceCoupe,
            pc_name: item.pc_name,
          }
        }) : []
      );
      console.log("Parsed Events:", parsedEvents);
      setEvents(parsedEvents);
    }
  }, [monthData]);

  // Remove the title attribute when the DOM changes
  useEffect(() => {
    const observer = new MutationObserver(() => {
      document
        .querySelectorAll<HTMLElement>(".rbc-event-content")
        .forEach((element) => {
          element.removeAttribute("title");
        });
    });

    observer.observe(document, {
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  // Custom Event component to display employee information
  const Event: React.FC<{ event: CustomEvent }> = ({ event }) => (
    <div className="event-container">
      <span className="event-image">
        <img src={"https://www.pngall.com/wp-content/uploads/5/Profile-PNG-Images.png"} alt={event.title} />
      </span>
      <div className="eventTooltip">
        <div className="event-name">{event.title}</div>
        <div className="event-timings">{event.schedule}</div>
        <div className="event-pc-name">{event.pc_name}</div>
      </div>
    </div>
  );

  const handleLocationChange = (index: number) => {
    setSelectedLocationIndex(index); // Update selected location index
  };
  const dayPropGetter = (date: Date) => {
    const isToday = dayjs().isSame(date, 'day');
    return {
      style: {
        backgroundColor: isToday ? '#ffeb3b' : 'inherit', // Yellow background for today
        borderRadius: '50%', // Makes the highlighted day circular
      },
    };
  };


  return (
    <>
      <HeaderWIthFilter
        headerName={t("Planning - Month")}
        type="month"
        selectedLocation={locations[selectedLocationIndex]}
        onLocationChange={handleLocationChange}
      >
        <DateAndTime
          showYear={true}
          isMonth={true}
          isIcon={true}
          monthFormat="long"
          dataTimeWrapperClassName="d-inline-flex align-items-center colorSkyBlue bg-white planningDateWrapper"
          dataTimeClassName="mx-3 planningPlannedDate"
          dayClassName="me-1"
          iconFillColor="#8571b9"
          onDateChange={handleDateChange}
        />
      </HeaderWIthFilter>
      <div className="row">
        <div className="col-12">
          <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            date={date}
            views={["month"]}
            events={events}
            style={{ height: "100vh" }}
            toolbar={false}
            className="planningMonthCalendar mb-3"
            components={{
              event: Event,
            }}
          />
        </div>
      </div>
      {show && <PlanningTabs />}
    </>
  );
};

export default translate(MonthlyPlanningOverview);