import React, { ChangeEvent, ReactNode, useRef, useEffect } from "react";
import LabelField from "../atoms/LabelField"; // Assuming LabelField is already defined elsewhere

interface InputWithLabelAndAddonProps {
  type?: string;
  className?: string;
  placeholder?: string;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  value?: string | number;
  name?: string;
  id?: string;
  isDisabled?: boolean;
  customStyle?: React.CSSProperties;
  isMandatory?: boolean;
  labelClassName?: string;
  error?: string;
  min?: number;
  step?: number;
  max?: number;
  readOnly?: boolean;
  autoComplete?: string;
  inputAddonText?: string; // Prop for the input group addon text
  inputAddonId?: string; // ID for aria-describedby
  multiLabel?: string;
  isTop?: boolean;
  colClassName?: string;
  labelWrapperClassName?: string;
  children?: ReactNode;
}

const InputWithLabelAndAddon: React.FC<InputWithLabelAndAddonProps> = ({
  type = "text",
  className = "",
  placeholder = "",
  handleChange,
  handleBlur,
  label,
  value,
  name,
  id = "",
  isDisabled = false,
  customStyle = {},
  isMandatory = false,
  labelClassName = "",
  error,
  min,
  step,
  max,
  readOnly = false,
  autoComplete = "off",
  inputAddonText = "",
  inputAddonId = "",
  multiLabel,
  isTop = false,
  colClassName = "",
  labelWrapperClassName = "",
  children,
}) => {
  const defaultRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      event.preventDefault();
    };
    const handleInspect = () => {
      if (isDisabled || readOnly) {
        defaultRef.current?.blur();
      }
    };

    document.addEventListener("contextmenu", handleContextMenu);
    document.addEventListener("keydown", handleInspect);

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      document.removeEventListener("keydown", handleInspect);
    };
  }, [isDisabled, readOnly]);

  const shouldUseFullWidth = !label || (label && isTop);

  return (
    <>
      {label && (
        <div
          className={`text-start ${
            isTop ? "col-12" : `${labelWrapperClassName}`
          }`}
        >
          <LabelField
            title={label}
            className={labelClassName}
            isMandatory={isMandatory}
            htmlfor={id}
            subtitle={multiLabel}
          />
        </div>
      )}

      <div
        className={`text-start ${
          shouldUseFullWidth ? "col-12" : `${colClassName}`
        }`}
      >
        <div className={`input-group inputFieldPrefix ${className}`}>
          {inputAddonText && (
            <span
              className="rounded-end-0 bodyBg d-flex align-items-center justify-content-center rounded-start-3"
              id={inputAddonId}
              style={{ width: window.innerWidth <= 767
                ? "3.5vw"
                : window.innerWidth <= 1023
                  ? "5vw"
                  : "2vw" }}
            >
              {inputAddonText}
            </span>
          )}

          <input
            ref={defaultRef}
            style={{ ...customStyle }}
            id={id}
            type={type}
            className={`form-control inputFieldColor rounded-start-0`}
            disabled={isDisabled}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            min={min}
            max={max}
            step={step}
            readOnly={readOnly}
            autoComplete={autoComplete}
            aria-describedby={inputAddonId}
          />
        </div>

        {error && <div className="text-danger">{error}</div>}
        {children}
      </div>
    </>
  );
};

export default InputWithLabelAndAddon;
