import React, { useState, useEffect } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import LabelField from "../atoms/LabelField";
import InputTextAreaField from "../atoms/InputTextAreaField";
import { html } from 'js-beautify';
import Button from "../atoms/Button";


interface LabelWithCKEditorFieldProps {
  id?: string;
  label?: string;
  name: string;
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
  isMandatory?: boolean;
  handleChange?: (event: any, editor: any) => void;
  error?: string;
  className?: string;
  Content?: string;
  showSourceView?: boolean;
}

const LabelWithCKEditorField: React.FC<LabelWithCKEditorFieldProps> = ({
  label,
  id,
  name,
  value = "",
  placeholder = "",
  isDisabled = false,
  isMandatory,
  handleChange,
  error,
  className,
  Content,
  showSourceView = true,
}) => {
  const [isSourceView, setIsSourceView] = useState(false);
  const [sourceContent, setSourceContent] = useState(value);

  // Editor config for CKEditor
  const config = {
    toolbar: {
      items: [
        'heading',
        'fontFamily',
        'fontSize',
        'fontColor',
        'fontBackgroundColor',
        '|',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'subScript',
        'superScript',
        '|',
        'highlight',
        'link',
        '|',
        'blockQuote',
        'alignment',
        '|',
        'outdent',
        'indent',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'undo',
        'redo'
      ],
      placeholder: placeholder,
    },
  };

  useEffect(() => {
    if (Content) {
      setSourceContent(Content);
    }
  }, [Content]);

  // Handle changes in CKEditor
  const handleEditorChange = (event: any, editor: any) => {
    const data = editor?.getData();
    setSourceContent(data);
    if (handleChange) {
      handleChange(event, editor);
    }
  };

  // Handle changes in source view (textarea)
  const handleSourceChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSourceContent(e?.target?.value);

    if (handleChange) {
      const mockEditor = {
        getData: () => e?.target?.value,
        setData: (data: string) => {
          setSourceContent(data);
        }
      };
      handleChange(e, mockEditor);
    }
  };

  //Show data in html format
  const prettifyHTML = (htmlString: string): string => {
    try {
      return html(htmlString, { indent_size: 2 });
    } catch (err) {
      console.error("Error beautifying HTML:", err);
      return htmlString;
    }
  };
  const prettifiedContent = prettifyHTML(sourceContent);

  return (
    <div className={className}>
      <div className="d-flex justify-content-between mb-2">
        <LabelField title={label} isMandatory={isMandatory} className="my-4" />
        {showSourceView && (
          <Button
            type="button"
            handleClick={() => setIsSourceView(!isSourceView)}
            className={`btn border-dark btn-rounded-pill my-3 btn-outline-secondary`}
            title={'<>Source'}
          />
        )}
      </div>

      {isSourceView ? (
        <div className="w-full">
          <InputTextAreaField
            id={id}
            value={prettifiedContent}
            handleChange={handleSourceChange}
            isDisabled={isDisabled}
            placeholder={placeholder}
            className="w-100 p-2 border rounded text-sm resize-none"
            customStyle={{
              height: '80vh',
              overflow: 'auto',
              fontFamily: 'Courier New, Courier, monospace',
              fontSize: '14px',
              lineHeight: '1.5',
            }}
          />
        </div>
      ) : (
        <CKEditor
          editor={ClassicEditor as any}
          id={id}
          data={sourceContent}
          disabled={isDisabled}
          config={config}
          onChange={handleEditorChange}
        />
      )}

      {error && (
        <div className="mt-2 mb-2">
          <div className="text-danger">{error}</div>
        </div>
      )}
    </div>
  );
};

export default LabelWithCKEditorField;
