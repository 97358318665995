import React, { ChangeEvent } from "react";
import Image from "../../../common/atoms/Image";
import userIcon from "../../../../static/images/User.svg";
import { t } from "../../../common/atoms/translation";
import Button from "../../../common/atoms/Button";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import SelectWithSearch from "../../../common/atoms/SelectWithSearch";
import Calender from "../../../common/molecules/Calender";
import LabelField from "../../../common/atoms/LabelField";
import ToggleButton from "../../../common/atoms/ToggleButton";
import LoadingIcon from "../../../common/utlis/LoadingIcon";
import InputWithLabelAndAddon from "../../../common/molecules/InputWithLabelAndAddon";
import {
  EmployeeFormData,
  EmployeeProfileProps,
  Option,
} from "../../registration/Interfaces";
import { euCountries } from "../../registration/State";
import { LabelWithMaskInputField } from "../../../common/molecules/LabelWithMaskInputField";
import FileUpload from "../../../common/molecules/FileUpload";
import Icon from "../../../../Icon";
import EmployeeCommunication from "../organisms/EmployeeCommunication";

const EmployeeProfile: React.FC<EmployeeProfileProps> = ({
  state,
  setState,
  handleChange,
  handleToggle,
  handleDateFormat,
  optionsData,
  countryOptions,
  handleSelectChange,
  handleVerifySSN,
  handleVerifyEmail,
  handleFileUpload,
  validateIban,
  handleFileUploadResponse,
  handleFileDeleteResponse,
}) => {
  const handleProfilePictureChange = (event: any) => {
    const file = event.target.files?.[0];
    const allowedFormats = ["image/png", "image/jpeg", "image/jpg"];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes
    let error = "";

    if (file) {
      // Validate file format
      if (!allowedFormats.includes(file.type)) {
        error =
          "Invalid file format, please upload only .png, .jpeg, and .jpg file formats.";
      }

      // Validate file size
      else if (file.size > maxSize) {
        error = "File size is greater than 2MB, please upload a smaller file.";
      }
    }

    setState((prevData) => ({
      ...prevData,
      profile_picture: file,
      errors: {
        ...prevData.errors,
        profile_picture: error,
      },
    }));

    if (!error) {
      handleFileUpload(
        file,
        "profile_picture",
        1,
        "profile_user",
        "profile_picture"
      );
    }
  };

  const handleDeleteImage = () => {
    setState((prevData: any) => ({
      ...prevData,
      profile_picture_url: "",
      profile_picture_id: null,
      errors: {
        ...prevData.errors,
        profile_picture_url: null,
        profile_picture_id: null,
      },
    }));
  };

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    // Update the state for verify_email
    setState((prevData) => ({
      ...prevData,
      verify_email: value,
    }));
  };

  return (
    <>
      <div className="row mb-3">
        {/* Profile image */}
        <div className="col-lg-2 mb-3 mb-lg-0">
          <div
            className={`addCompanyImage d-flex align-items-center justify-content-center cursor-pointer position-relative ${state.profile_picture_url ? "userIconPresent" : "p-2"
              }`}
            onClick={() => document.getElementById("file-input")?.click()}
          >
            {state.loading ? (
              <LoadingIcon
                iconType="bars"
                color="#8571b9"
                className="fileUploadLoading"
              />
            ) : (
              <>
                <Image
                  src={state.profile_picture_url || userIcon}
                  imageWidth="100%"
                  imageHeight="100%"
                  imageBorderRadius="50%"
                />
                <input
                  id="file-input"
                  type="file"
                  name="profile_picture"
                  accept="image/png, image/jpeg, image/jpg"
                  style={{ display: "none" }}
                  onChange={(event) => handleProfilePictureChange(event)}
                  autoComplete="off"
                />
              </>
            )}
            <div>
              {state.profile_picture_id && (
                <div
                  onClick={(e) => {
                    e.stopPropagation(); // Prevents click event from bubbling to the parent
                    handleDeleteImage();
                  }}
                  className="delete-icon position-absolute deleteIcon secondaryColorHoverEffect"
                  role="button"
                  data-bs-toggle="tooltip"
                  title={t("Delete Profile")}
                >
                  <Icon
                    name="delete"
                    isIcon={true}
                    width="0.8vw"
                    height="0.8vw"
                  />
                </div>
              )}
            </div>
          </div>
          {state.errors.profile_picture && (
            <span className="text-danger">{state.errors.profile_picture}</span>
          )}
        </div>

        {/* User details */}
        <div className="col-lg-10 ps-lg-0">
          <div className="row">
            <div className="col-lg-5 multiSelectWrapper mb-3 mb-lg-0">
              <div className="row">
                <SelectWithSearch
                  fieldLabel={t("Nationality") + ":"}
                  title={t("Nationality") + ":"}
                  name="nationality_id"
                  placeHolder={t("Select")}
                  isMandatory={true}
                  search={true}
                  options={countryOptions.countries}
                  value={
                    countryOptions.countries.find(
                      (option) => option.value === state.nationality_id
                    ) || null
                  }
                  onChange={(selectedOption: Option) => {
                    const isNational = euCountries.includes(
                      selectedOption?.value?.toString()
                    );
                    if (isNational) {
                      setState((prevData: EmployeeFormData) => ({
                        ...prevData,
                        work_permit_file_url: "",
                        work_permit_file_id: null,
                        work_permit: {
                          ...prevData.work_permit,
                          end_date: "",
                          number: "",
                        },
                        errors: {
                          ...prevData.errors,
                          work_permit: null,
                        },
                      }));
                    }

                    setState((prevData: EmployeeFormData) => ({
                      ...prevData,
                      country_of_birth_id: Number(selectedOption.value),
                      address: {
                        ...prevData.address,
                        domicile: {
                          ...prevData.address.domicile,
                          country_id: Number(selectedOption.value),
                        },
                        residence: {
                          ...prevData.address.residence,
                          country_id: Number(selectedOption.value),
                        },
                      },
                      is_national: isNational, // Set is_national based on the check
                    }));

                    handleSelectChange(selectedOption, "nationality_id");
                  }}
                  isMulti={false}
                  className="select-field"
                  error={state?.errors?.nationality_id ?? undefined}
                  isTranslate={true}
                  labelWrapperClassName="col-4 leftlabelClass"
                  colClassName="col-8"
                />
              </div>
            </div>
            <div className="col-lg-2 euCitizenWrapper mb-3 mb-lg-0">
              <div className="d-flex align-items-center">
                <LabelField
                  title={t("EU citizen") + ":"}
                  className="mb-0 colorPrimary poppins-medium me-3"
                />
                <ToggleButton
                  value={state?.is_national}
                  disabled={true}
                  onToggle={handleToggle("is_national")}
                />
              </div>
            </div>
            {!state.is_national && (
              <>
                <div className="col-lg-5">
                  <div className="row">
                    <LabelWithInputField
                      label={t("Work Permit Number") + ":"}
                      className="inputFieldColor ps-2"
                      colClassName="col-8"
                      type="text"
                      value={state?.work_permit?.number}
                      handleChange={handleChange("work_permit", "number")}
                      name="work_permit_number"
                      id="work_permit_number"
                      isMandatory={true}
                      error={state?.errors?.["work_permit.number"] ?? undefined}
                      labelWrapperClassName="col-4 leftlabelClass"
                      autoComplete="off"
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="row mt-3">
                    <div className="col-4 leftlabelClass pe-0">
                      <LabelField
                        title={t("End date of work permit") + ":"}
                        isMandatory={true}
                      />
                    </div>
                    <div className="col-8 position-relative ">
                      <div className="position-relative calendarSectionWrapper">
                        <Calender
                          onChange={(date) =>
                            handleDateFormat(date, "work_permit.end_date")
                          }
                          minDate={(() => {
                            const date = new Date();
                            date.setDate(date.getDate() + 1); // Set to tomorrow
                            return date;
                          })()}
                          name="end_date"
                          selectedDate={
                            state?.work_permit?.end_date
                              ? new Date(state?.work_permit?.end_date)
                              : null
                          }
                          error={
                            state?.errors?.["work_permit.end_date"] ?? undefined
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 my-3">
                  <FileUpload
                    id="work_permit_file"
                    name="work_permit_file"
                    label={t("Upload work permit") + ":"}
                    file_type={5}
                    isMandatory={true}
                    edit={true}
                    fileId={state.work_permit_file_id}
                    fileName={state.work_permit_file_name}
                    fileUrl={state.work_permit_file_url}
                    multiple={false}
                    uploadPath="work_permit"
                    formats=".pdf,.jpg,.jpeg,.png"
                    maxFileSize={8}
                    dontDelete={true}
                    className="d-flex align-items-center"
                    textclassName="mx-2"
                    returnFileUploadErrors={(errorInfo: any) => {
                      setState((prevData) => ({
                        ...prevData,
                        errors: {
                          ...prevData.errors,
                          work_permit_file_url: errorInfo?.error,
                        },
                      }));
                    }}
                    fileUploadResponse={handleFileUploadResponse}
                    deleteResponse={handleFileDeleteResponse}
                  />
                  {state?.errors?.work_permit_file_url && (
                    <div className="text-danger">
                      {state?.errors?.work_permit_file_url}
                    </div>
                  )}
                </div>
              </>
            )}

            <div
              className={`${state.nationality_id !== 29 ? "col-12" : "col-lg-5"}`}
            >
              <div className={`${state.nationality_id !== 29 ? "" : ""} row`}>
                <div
                  className={`${state.nationality_id !== 29 ? "col-lg-5 mt-3" : "col-12 "
                    }`}
                >
                  <div className="row">
                    <LabelWithMaskInputField
                      isDisabled={state.id !== 0}
                      label={t("Social security number") + ":"}
                      className="inputFieldColor ps-2"
                      colClassName="col-8 d-flex align-items-baseline"
                      type="text"
                      value={state?.registry_number ?? ""}
                      handleChange={handleChange("registry_number")}
                      name="registry_number"
                      id="registry_number"
                      labelWrapperClassName="col-4 leftlabelClass pe-0"
                      error={state?.errors?.registry_number ?? undefined}
                      isMandatory={state.nationality_id === 29 ? true : false}
                      autoComplete="off"
                      // Updated mask configuration for IMask
                      mask={{
                        mask: "00.00.00-000.00", // This replaces your array of patterns
                        definitions: {
                          "0": /[0-9]/, // This defines what characters are allowed in place of '0'
                        },
                        prepare: (str: string) => str.toUpperCase(), // Optional: converts input to uppercase
                      }}
                    >
                      <Button
                        title={t("Verify")}
                        className="saveBtn ms-3 vatVerifyBtn mobileFont btnWidthPwa"
                        handleClick={() =>
                          handleVerifySSN(state.registry_number)
                        }
                      />
                    </LabelWithMaskInputField>
                  </div>
                </div>
                {state.nationality_id !== 29 && (
                  <>
                    <div className="col-lg-6">
                      <div className="row mt-3">
                        <LabelWithInputField
                          label={t("E-mail") + ":"}
                          className="inputFieldColor ps-2"
                          colClassName="col-lg-10 col-md-8 d-flex align-items-start"
                          type="text"
                          value={state.verify_email}
                          handleChange={handleEmailChange}
                          name="verify_email"
                          id="verify_email"
                          labelWrapperClassName=" col-lg-2 col-md-4 leftlabelClass"
                          error={state.errors?.verify_email ?? undefined}
                        >
                          <Button
                            title={t("Verify")}
                            className="saveBtn ms-3 vatVerifyBtn mobileFont btnWidthPwa"
                            handleClick={() =>
                              handleVerifyEmail(state.verify_email)
                            }
                          />
                        </LabelWithInputField>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-4">
          <div className="row multiSelectWrapper mb-3">
            <SelectWithSearch
              fieldLabel={t("Title") + ":"}
              title={t("Title") + ":"}
              name="title_id"
              placeHolder={t("Select")}
              isMandatory={true}
              search={true}
              options={optionsData.initial}
              value={
                optionsData.initial.find(
                  (option) => option.value === state.title_id
                ) || null
              }
              onChange={(selectedOption: Option) =>
                handleSelectChange(selectedOption, "title_id")
              }
              isMulti={false}
              className="select-field"
              error={state?.errors?.title_id ?? undefined}
              isTranslate={true}
              labelWrapperClassName="col-4 leftlabelClass"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row mb-3">
            <LabelWithInputField
              label={t("First name") + ":"}
              className="inputFieldColor ps-2"
              colClassName="col-8"
              type="text"
              value={state?.first_name}
              handleChange={handleChange("first_name")}
              name="first_name"
              isMandatory={true}
              error={state?.errors?.first_name ?? undefined}
              id="first_name"
              labelWrapperClassName="col-4 leftlabelClass"
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-lg-4">
          <div className="row mb-3">
            <LabelWithInputField
              label={t("Last name") + ":"}
              className="inputFieldColor ps-2"
              colClassName="col-8"
              type="text"
              value={state?.last_name}
              handleChange={handleChange("last_name")}
              name="last_name"
              isMandatory={true}
              error={state?.errors?.last_name ?? undefined}
              id="last_name"
              labelWrapperClassName="col-4 leftlabelClass"
              autoComplete="off"
            />
          </div>
        </div>
      </div>
      <EmployeeCommunication
        state={state}
        setState={setState}
        handleChange={handleChange}
      />
      <div className="row">
        <div className="col-lg-4 mb-3">
          <div className="row multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={t("IBAN country") + ":"}
              title={t("IBAN country") + ":"}
              name="iban_country_code"
              placeHolder={t("Select")}
              isMandatory={true}
              error={
                state?.errors?.["bank_account.iban_country_code"] ?? undefined
              }
              search={true}
              options={optionsData.ibancountries}
              value={
                optionsData.ibancountries.find(
                  (option) =>
                    option?.value === state?.bank_account?.iban_country_code
                ) || null
              }
              onChange={(selectedOption: Option) => {
                if (state?.bank_account?.iban?.length > 0) {
                  let value = state?.bank_account?.iban || "";
                  let valueSend = value;
                  if (
                    valueSend &&
                    valueSend.toUpperCase().startsWith(selectedOption.value)
                  ) {
                    valueSend = valueSend.slice(2);
                  }
                  validateIban(selectedOption.value + valueSend);
                }
                handleSelectChange(
                  selectedOption,
                  "bank_account.iban_country_code"
                );
              }}
              isMulti={false}
              className="select-field"
              // error={errors.iban}
              isTranslate={true}
              labelWrapperClassName="col-4 leftlabelClass"
              colClassName="col-8"
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row">
            <InputWithLabelAndAddon
              label={t("IBAN number") + ":"}
              colClassName="col-8"
              type="text"
              value={state?.bank_account?.iban}
              handleChange={(e) => {
                let value = e.target.value;
                let valueSend = value;
                // Check if the first two characters match the selected country code
                if (
                  valueSend
                    .toUpperCase()
                    .startsWith(state.bank_account.iban_country_code)
                ) {
                  // Slice off the first two characters if they match the country code
                  valueSend = valueSend.slice(2);
                }
                // Validate the IBAN with the country code prepended
                validateIban(state.bank_account.iban_country_code + valueSend);
                setState((prevState) => ({
                  ...prevState,
                  bank_account: {
                    ...prevState.bank_account,
                    iban: value,
                  },
                }));
              }}
              name="iban"
              id="iban"
              placeholder={
                state.bank_account.iban_country_code === ""
                  ? "Please select country"
                  : ""
              }
              labelWrapperClassName="col-4 leftlabelClass"
              error={state.errors?.["bank_account.iban"] ?? undefined}
              isMandatory={true}
              inputAddonText={state.bank_account.iban_country_code}
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("BIC") + ":"}
              className="inputFieldColor"
              colClassName="col-8"
              type="text"
              value={state?.bank_account?.bic}
              handleChange={handleChange("bank_account", "bic")}
              name="bic"
              id="bic"
              labelWrapperClassName="col-4 leftlabelClass"
              error={state.errors?.["bank_account.bic"] ?? undefined}
              autoComplete="off"
              isDisabled={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default EmployeeProfile;
