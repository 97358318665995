import React from "react";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import { t } from "../../../CentralDataMangement/translation/Translation";
import Button from "../../../common/atoms/Button";
import { EmployeeDetails, SearchEmailProps } from "../Interfaces";
import { ApiCall } from "../../../../services/ApiServices";
import { GET_EMPLOYEE_BY_EMAIL } from "../../../../routes/ApiEndpoints";
import CustomNotify from "../../../common/atoms/CustomNotify";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";
import { formatRegistryNumber } from "../../../../services/common/CommonServices";
import { validateEmail } from "../../../../services/ValidationService";

const SearchEmail: React.FC<SearchEmailProps> = ({
  email,
  handleChange,
  updateValues,
  verify_email_error,
  updateErrors,
}) => {
  const handleVerify = async () => {
    let error: any={};
    error['verify_email'] = validateEmail(email);
    updateErrors(error);
    if (email && error['verify_email'] === null) {
      error = null;
      const response = await ApiCall.getService(
        `${GET_EMPLOYEE_BY_EMAIL}/${email}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );

      let user_not_found: boolean = false;
      if (response?.status === "success") {
        let data: EmployeeDetails = {
          user_id: response.data.id,
          first_name: response.data.first_name,
          last_name: response.data.last_name,
          phone_number: response.data.phone_number,
          email: response.data.email,
          date_of_birth: response?.data?.date_of_birth,
          registry_number: formatRegistryNumber(
            response?.data?.registry_number
          ),
          default_emp_legal: response?.data?.default_emp_legal,
        companies: response?.data?.companies,
        };
        updateValues(data, user_not_found);
      } else {
        CustomNotify({
          type: "error",
          message: response.message,
        });
        if (response?.message === "Employee not found") {
          user_not_found = true;
        }
        let data: EmployeeDetails = {
          user_id: 0,
          first_name: "",
          last_name: "",
          email: "",
          phone_number: "",
          date_of_birth: "",
          registry_number: "",
          companies: [],
          default_emp_legal: [],
        };
        updateValues(data, user_not_found);
      }
    }
  };

  return (
    <div className="row mb-3">
      <div className="col-lg-6 col-md-6">
        <LabelWithInputField
          label={t("E-mail") + ":"}
          className="inputFieldColor"
          value={email}
          handleChange={handleChange}
          name="verify_email"
          id="verify_email"
          isMandatory={true}
          error={verify_email_error || ""}
        />
      </div>
      <div className="col-lg-1 col-md-4 seachBtnWrapper">
        <Button
          title={t("Search")}
          className="saveBtn mobileFont"
          handleClick={handleVerify}
        />
      </div>
    </div>
  );
};

export default SearchEmail;
