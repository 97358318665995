import React, { ReactNode, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
    t,
    translate,
} from "../../CentralDataMangement/translation/Translation";
import Button from "../../common/atoms/Button";
import InputTextFieldWithLabel from "../molecules/InputTextFieldWithLabel";
import { formatTime, isValidTime, isValidTimeFormat } from "../../CentralDataMangement/shifts/shifthelper";

export interface Widget {
    name?: string;
    plannedDateTime?: string;
    actualDate?: string;
    actualTime?: string;
    type?: string;
}

interface ConfirmPopupProps {
    size?: 'sm' | 'lg' | 'xl';
    show: boolean;
    onHide?: () => void;
    title: string;
    body: ReactNode | string;
    onCloseButtonClick?: () => void;
    onConfirmButtonClick?: (event?: any) => void;
    closeTitle?: string;
    confirmTitle?: string;
    className?: string;
    inputClass?: string;
    headerClassName?: string;
    showCloseButton?: boolean;
    isInnerHTML?: boolean;
    widgetData?: Widget;
    widget?: boolean;
}


const ConfirmPopup: React.FC<ConfirmPopupProps> = ({
    size,
    show,
    onHide,
    title,
    body,
    onCloseButtonClick,
    onConfirmButtonClick,
    closeTitle,
    confirmTitle,
    className,
    inputClass,
    headerClassName,
    showCloseButton = true,
    isInnerHTML = false,
    widgetData,
    widget = false
}) => {


    const [formData, setFormData] = useState<Widget>(
        widgetData || {
            name: '',
            plannedDateTime: '',
            actualDate: '',
            actualTime: '',
            type: ''
        }
    );

    const [errors, setErrors] = useState<{ [key: string]: string }>({});


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    // const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     const { name, value } = e.target;
    //     let time = formatTime(value);


    //     if (!isValidTimeFormat(time)) {
    //         setErrors(prev => ({
    //             ...prev,
    //             [name]: 'Invalid time',
    //         }));
    //     } else {
    //         setErrors(prev => ({
    //             ...prev,
    //             [name]: '',
    //         }));
    //     }

    //     setFormData(prev => ({
    //         ...prev,
    //         [name]: time
    //     }));

    // };

    const handleTimeInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        let time = formatTime(value.trim());

        if (time === '') {
            // Clear the error if the input is empty
            setErrors(prev => ({
                ...prev,
                [name]: '',
            }));
        } else if (time.length !== 5 || !isValidTimeFormat(time)) {
            // Show error for invalid format or incomplete time (length not 5)
            setErrors(prev => ({
                ...prev,
                [name]: 'Invalid time format',
            }));
        } else {
            // Clear error if the format is correct
            setErrors(prev => ({
                ...prev,
                [name]: '',
            }));
        }

        setFormData(prev => ({
            ...prev,
            [name]: time,
        }));
    };


    const handleSubmit = () => {
        if (onConfirmButtonClick) {
            onConfirmButtonClick(formData);
        }
    };

    useEffect(() => {
        if (widgetData) {
            setFormData(widgetData);
        }
    }, [widgetData]);

    console.log(widgetData);


    const getTodayAndTomorrow = () => {
        const today = new Date();
        const tomorrow = new Date(today);
        tomorrow.setDate(today.getDate() + 1);

        const formatDate = (date: any) => date.toISOString().split("T")[0];

        return {
            today: formatDate(today),
            tomorrow: formatDate(tomorrow),
        };
    };

    const { today, tomorrow } = getTodayAndTomorrow();


    return (
        // <Modal
        //     show={true}
        //     onHide={popupActionNo}
        //     centered
        //     backdrop="static"
        //     className="modalWithoutTabs customModal"
        // >
        <Modal size={size} show={show} onHide={onHide} centered className={`${className} modalWithoutTabs customModal`}>
            <Modal.Header closeButton style={{ backgroundColor: "rgb(133, 113, 185)" }} className="p-0 text-white">
                <Modal.Title className="text-center w-100 py-2 modal-title">
                    {t(title)}
                </Modal.Title>
            </Modal.Header>

            {/* <Modal.Body className="border-0">
                {t(body)}
            </Modal.Body> */}


            <Modal.Body className="border-0">
                {!widget && body && (
                    <div>{isInnerHTML ? <div dangerouslySetInnerHTML={{ __html: body as string }} /> : t(body)}</div>
                )}

                {widget && (
                    <div className="row g-3">
                        <div className="col-6">
                            <InputTextFieldWithLabel
                                label="Name"
                                name="name"
                                value={formData.name || ''}
                                handleChange={handleInputChange}
                                type="text"
                                isMandatory={true}
                                isTop={true}
                                className={inputClass}
                                isDisabled={true}
                            />
                        </div>

                        <div className="col-6">
                            <InputTextFieldWithLabel
                                label={`Actual ${formData.type} date`}
                                name="actualDate"
                                value={formData.actualDate || ''}
                                handleChange={handleInputChange}
                                type="date"
                                isMandatory={true}
                                isTop={true}
                                className={inputClass}
                                min={today}
                                max={formData.type === 'breakin' || formData.type === 'breakout' ? today : tomorrow}
                            />
                        </div>

                        <div className="col-6">
                            <InputTextFieldWithLabel
                                label="Planned timings"
                                name="plannedDateTime"
                                value={formData.plannedDateTime || ''}
                                handleChange={handleInputChange}
                                type="text"
                                isMandatory={true}
                                isTop={true}
                                className={inputClass}
                                isDisabled={true}
                            />
                        </div>

                        <div className="col-6">
                            <InputTextFieldWithLabel
                                label={`Actual ${formData.type} time`}
                                name="actualTime"
                                value={formData.actualTime || ''}
                                handleChange={handleTimeInputChange}
                                type="text"
                                isMandatory={true}
                                isTop={true}
                                className={inputClass}
                            />
                            {errors.actualTime && <div className="error-message">{errors.actualTime}</div>}

                        </div>
                    </div>
                )}

            </Modal.Body>

            {/* <Modal.Footer className="border-0 pt-0 pb-3">
                <Button className="resetBtn" handleClick={onCloseButtonClick}>
                    {t("No")}
                </Button>
                <Button className="searchBtn px-3" handleClick={popupActionYes}>
                    {t("Yes")}
                </Button>
            </Modal.Footer> */}
            <Modal.Footer className="border-0">
                {closeTitle && onCloseButtonClick && (
                    <button
                        onClick={onCloseButtonClick}
                        className="resetBtn px-3"
                    >
                        {closeTitle}
                    </button>
                )}
                {confirmTitle && onConfirmButtonClick && (
                    <button
                        onClick={handleSubmit}
                        className="searchBtn px-3"
                    >
                        {confirmTitle}
                    </button>
                )}
            </Modal.Footer>
        </Modal>
    );
}

export default translate(ConfirmPopup);
