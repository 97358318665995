import React, { useEffect, useState, useMemo } from "react";
import Icon from "../../Icon";
import DateAndTime from "../common/atoms/DateWithTime";
import InputTextfield from "../common/atoms/InputTextField";
import OverlayTooltip from "./TypeBasedPopup";
import { useDispatch, useSelector } from "react-redux";
import {
  selectContractType,
  selectLocationId,
  selectTab,
  updateEmployeeSchedule,
  updateEmployeeScheduleForDate,
} from "../../features/planning/planningSlice";
import {
  formatTimeInput,
  updateSchedule,
  calculateShiftsTotalTime,
  sanitizedInputLength,
} from "./Services/PlanningTimingHelper";
import { t } from "../CentralDataMangement/translation/Translation";
import { validatePlanningFieldsForDate } from "./Validations/PlanningValidations";
import { ageLevelWarnings } from "./Validations/PlanningTimingValidations";
import { RootState } from "../../store";
import { Shift } from "../CentralDataMangement/shifts/shifthelper";
import CustomAlert from "../common/atoms/CustomAlert";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import NoRecords from "../common/atoms/NoRecords";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";
import EditIcon from "../common/molecules/Actions/CustomEdit";

interface Shifts {
  start_time: string;
  end_time: string;
  sequence: number;
}

interface Schedule {
  location_id: number;
  project_id?: number;
  cost_center_id?: number;
  shifts: Shifts[];
  error?: string;
  warning?: string[];
}

interface ScheduleMap {
  [date: string]: Schedule;
}

interface Props {
  employee: number;
  pcId: number;
  planningSchedule: ScheduleMap;
  age: number;
}

const PlanningShiftsTimings: React.FC<Props> = ({
  employee,
  planningSchedule,
  pcId,
  age,
}) => {
  const addIconStyle = useResponsiveValue("3vw", "2.5vw", "0.8vw");
  const state = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const [schedule, setSchedule] = useState<ScheduleMap>(planningSchedule || {});
  const [validationErrors, setValidationErrors] = useState<
    Record<string, boolean>
  >({});
  const locationId = useSelector(selectLocationId);
  const contractType = useSelector(selectContractType);

  const tab = useSelector(selectTab);

  useEffect(() => {
    setSchedule(planningSchedule || {});
  }, [planningSchedule]);

  const handleShiftChange = (
    date: string,
    index: number,
    key: string,
    value: string
  ) => {
    const formattedValue = formatTimeInput(value);
    if (sanitizedInputLength(value) <= 4) {
      setSchedule((prevSchedule: ScheduleMap) => {
        const currentDateSchedule = prevSchedule[date] || {
          shifts: [],
          location_id: locationId,
        };
        const newShifts = currentDateSchedule.shifts.map(
          (shift: Shifts, i: number) =>
            i === index ? { ...shift, [key]: formattedValue } : shift
        );
        const warning =
          sanitizedInputLength(value) === 4
            ? ageLevelWarnings(pcId, age, state, newShifts)
            : [];
        const updatedSchedule: ScheduleMap = {
          ...prevSchedule,
          [date]: {
            ...currentDateSchedule,
            shifts: newShifts,
            warning,
          },
        };

        const error =
          sanitizedInputLength(value) === 4
            ? validatePlanningFieldsForDate(
              state,
              updatedSchedule[date],
              pcId,
              date
            )
            : undefined;

        if (sanitizedInputLength(value) === 4) {
          dispatch(
            updateEmployeeScheduleForDate({
              employee_id: employee,
              date: date,
              schedule: updatedSchedule[date].shifts,
              warnings: warning ? warning : [],
              error,
            })
          );
        }

        return updatedSchedule;
      });
    }
  };
  const handleAddShift = (date: string) => {
    setSchedule((prevSchedule) => {
      const currentShifts = prevSchedule[date]?.shifts || [];
      const lastShiftLocationId = prevSchedule[date]?.location_id || locationId;

      const newShift: Shifts = {
        start_time: "",
        end_time: "",
        sequence: currentShifts.length + 1,
      };

      const newSchedule: Schedule = {
        shifts: [...currentShifts, newShift],
        location_id: lastShiftLocationId,
      };

      if (newSchedule.shifts.length <= 2) {
        updateSchedule(
          date,
          newSchedule,
          schedule,
          setSchedule,
          employee,
          dispatch,
          updateEmployeeSchedule
        );
      }

      return { ...prevSchedule, [date]: newSchedule };
    });
  };

  const handleRemoveShift = (date: string, index: number) => {
    setSchedule((prevSchedule) => {
      const daySchedule = prevSchedule[date];

      // Check if daySchedule exists
      if (!daySchedule) return prevSchedule;

      // Create a new array for the shifts with the selected shift removed
      const updatedShifts = daySchedule.shifts.filter((_, i) => i !== index);

      // Create a new schedule object with updated shifts
      const updatedSchedule: Schedule = {
        ...daySchedule,
        shifts: updatedShifts,
      };

      // Remove the entry if no shifts are left
      const newSchedule =
        updatedShifts.length > 0
          ? { ...prevSchedule, [date]: updatedSchedule }
          : { ...prevSchedule };
      if (updatedShifts.length === 0) delete newSchedule[date];

      if (employee) {
        dispatch(
          updateEmployeeSchedule({
            employee_id: employee,
            scheduleMap: newSchedule,
          })
        );
      }

      return newSchedule;
    });
  };

  // Function to handle project clicks
  const OnProjectClick = (projectId: number, type: number, field: string, date?: string) => {
    if (type === 1 && date && field) {
      // Update project ID for a specific day's schedule
      setSchedule((prevSchedule) => {
        const daySchedule = prevSchedule[date];
        if (daySchedule) {
          const updatedSchedule = {
            ...daySchedule,
            [field]: (daySchedule as any)[field] === projectId ? 0 : projectId, // Toggle project ID
          };
          updateSchedule(
            date,
            updatedSchedule,
            schedule,
            setSchedule,
            employee,
            dispatch,
            updateEmployeeSchedule
          );
        }
        return prevSchedule;
      });
    } else if (type === 2) {
      // Update project ID for all schedules of the employee
      setSchedule((prevSchedule) => {
        const updatedSchedules: ScheduleMap = { ...prevSchedule };

        // Iterate over each schedule date and update the project_id
        for (const scheduleDate in updatedSchedules) {
          if (updatedSchedules.hasOwnProperty(scheduleDate)) {
            updatedSchedules[scheduleDate] = {
              ...updatedSchedules[scheduleDate],
              [field]: projectId, // Update project ID for all schedules
            };

            // Update the schedule with the new project ID for each date
          }
        }
        if (employee) {
          dispatch(
            updateEmployeeSchedule({
              employee_id: employee,
              scheduleMap: updatedSchedules,
            })
          );
        }
        return updatedSchedules; // Return the updated schedule
      });
    }
  };

  const getShiftDates = (mainDate: string) => {
    let schedules = Object.keys(schedule);
    return schedules.filter((date) => {
      if (date === mainDate) return false;
      const daySchedule = schedule[date];
      // Check if the day has any shifts, regardless of start or end time
      return daySchedule.shifts.length > 0;
    });
  };

  const handleCloneDates = (
    sourceDateString: string,
    targetDates: string[]
  ) => {
    const sourceShifts = schedule[sourceDateString]?.shifts;
    const sourceWarning = schedule[sourceDateString]?.warning;
    const sourceError = schedule[sourceDateString]?.error;
    if (!sourceShifts) return;

    const updatedSchedule = { ...schedule };

    targetDates.forEach((targetDate) => {
      const newShifts = sourceShifts.map((shift) => ({ ...shift }));

      // Validate the cloned shifts for each target date
      const clonedSchedule: Schedule = {
        ...updatedSchedule[targetDate],
        shifts: newShifts,
        warning: sourceWarning, // Add warning logic if applicable
        error: sourceError,
      };

      updatedSchedule[targetDate] = clonedSchedule;
    });

    setSchedule(updatedSchedule);

    // Dispatch the updated schedule to Redux
    if (employee) {
      dispatch(
        updateEmployeeSchedule({
          employee_id: employee,
          scheduleMap: updatedSchedule,
        })
      );
    }
  };
  // New function to sort schedule entries by date
  const sortedScheduleEntries = useMemo(() => {
    return Object.entries(schedule).sort(([dateA], [dateB]) => {
      return new Date(dateA).getTime() - new Date(dateB).getTime();
    });
  }, [schedule]);

  const shiftCells = (daySchedule: Shifts[], date: string) => (
    <>
      {daySchedule.map((shift, index) => (
        <td key={index} className="bodyBg align-middle" data-label={t("Shift") + " 1"}
        >
          <div className="col-12 d-flex align-items-center">
            <InputTextfield
              value={shift.start_time}
              // value={extractTimeFromUTCString(shift.start_time)}
              className={`form-control employeeDailyOverviewTable ${validationErrors[`${date}-${index}`] ? "error-border" : ""}`}
              handleChange={(e) => handleShiftChange(date, index, "start_time", e.target.value)}
              isDisabled={tab === "tab_3"}
            />
            <div className="mx-1">{" - "}</div>
            <InputTextfield
              // value={extractTimeFromUTCString(shift.end_time)}
              value={shift.end_time}
              className={`form-control employeeDailyOverviewTable ${validationErrors[`${date}-${index}`] ? "error-border" : ""}`}
              handleChange={(e) => handleShiftChange(date, index, "end_time", e.target.value)}
              isDisabled={tab === "tab_3"}
            />
          </div>
        </td>
      ))}
      {daySchedule.length < 2 && (
        <td className="bodyBg align-middle" data-label={t("Shift") + " 2"} >
          <CustomActionIcon handleClick={() => handleAddShift(date)} iconName="add" marginBottom="mb-0" buttonClass="searchBtn" title={t("Add")} />
        </td>
      )}
      <td className="bodyBg align-middle" data-label={t("Total")}>
        <div className="totalHours text-white poppins-medium">
          {calculateShiftsTotalTime(daySchedule)}
        </div>
      </td>
    </>
  );

  const [expandedDay, setExpandedDay] = useState<string | null>(null);

  const toggleAccordion = (day: string) => {
    setExpandedDay((prev) => (prev === day ? null : day));
  };
  return (
    <>
      {sortedScheduleEntries.length > 0 ? (
        <table className="shiftTimingTable table table-borderless mb-0 pwa">
          <thead>
            <tr>
              <th></th>
              <th>{t("Shift 1")}</th>
              <th>{t("Shift 2")}</th>
              <th className="text-start">{t("Total")}</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {window.innerWidth > 1023 ? (sortedScheduleEntries.map(([day, daySchedule]) => (
              <React.Fragment key={day}>
                <tr
                  className={`spaced-row shiftTimingTableData ${daySchedule.error != undefined && daySchedule.error != ""
                    ? "error-border-card"
                    : ""
                    }`}
                >
                  <td className="p-0 planningDateWrapper">
                    <DateAndTime
                      isDay={true}
                      isDate={true}
                      showDate={true}
                      showMonth={true}
                      showYear={true}
                      dataTimeClassName="d-flex flex-column align-items-center shiftDate"
                      monthFormat="short"
                      value={day}
                    />
                  </td>
                  {shiftCells(daySchedule.shifts, day)}

                  <td className="actionWrapper bodyBg align-middle">
                    <div className="d-flex">
                      {tab === "tab_3" ? (
                        <>
                          {/* <span
                            data-bs-toggle="tooltip"
                            title={t("Edit")}
                            className="cursor-pointer"
                          >
                            <Icon
                              name="EditIcon"
                              width="0.8vw"
                              height="0.8vw"
                              fillColor="#8571b9"
                              className="me-2 cursor-pointer"
                              isIcon={true}
                            />
                          </span> */}
                          <span
                            data-bs-toggle="tooltip"
                            title={t("Remove shift")}
                            onClick={() =>
                              handleRemoveShift(
                                day,
                                daySchedule.shifts.length - 1
                              )
                            }
                            className="me-2 cursor-pointer removeUploadedFile"
                          >
                            <Icon
                              name="RemoveIcon"
                              width="0.8vw"
                              height="0.8vw"
                              fillColor="#8571b9"
                              className="cursor-pointer"
                              isIcon={true}
                            />
                          </span>
                        </>
                      ) : (
                        <>
                          <OverlayTooltip
                            tooltipText="Projects"
                            selectedProject={(
                              projectId: number,
                              type: number,
                              field: string
                            ) => OnProjectClick(projectId, type, field, day,)}
                            selectedProjectId={daySchedule.project_id} // Pass `day` here
                            selectedCcId={daySchedule.cost_center_id} // Pass `day` here

                          >
                            <span className="actionWrapper">
                              <Icon
                                name="LocationIcon"
                                width="1vw"
                                height="1vw"
                                className="cursor-pointer"
                                isIcon={true}
                              />
                            </span>
                          </OverlayTooltip>
                          {Object.keys(schedule).length > 1 && (
                            <OverlayTooltip
                              tooltipText="Clone"
                              cloneDates={getShiftDates(day)}
                              selectedCloneDates={(dates: string[]) =>
                                handleCloneDates(day, dates)
                              }
                            >
                              <Icon
                                name="CloneIcon"
                                width="1vw"
                                height="1vw"
                                className="cursor-pointer"
                                fillColor="#8571b9"
                                isIcon={true}
                              />
                            </OverlayTooltip>
                          )}
                          <OverlayTooltip tooltipText="Shifts">
                            <Icon
                              name="shiftsIcon"
                              width="1vw"
                              height="1vw"
                              fillColor="#8571b9"
                              className="cursor-pointer"
                              isIcon={true}
                            />
                          </OverlayTooltip>
                          <span
                            data-bs-toggle="tooltip"
                            title={t("Remove shift")}
                            onClick={() =>
                              handleRemoveShift(
                                day,
                                daySchedule.shifts.length - 1
                              )
                            }
                            className="me-2 cursor-pointer actionWrapper removeUploadedFile"
                          >
                            <Icon
                              name="RemoveIcon"
                              width="1vw"
                              height="1vw"
                              fillColor="#8571b9"
                              className="cursor-pointer"
                              isIcon={true}
                            />
                          </span>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
                {daySchedule.warning != undefined && daySchedule.warning.length > 0 && daySchedule.warning.map((warn) => (
                  <tr>
                    <td colSpan={5} className="p-0 pt-2">
                      <CustomAlert className={` ${warn != "" ? "alert-warning" : ""}`} iconName="warningIcon">
                        {warn}
                      </CustomAlert>
                    </td>
                  </tr>
                ))}
                {daySchedule.error != undefined && daySchedule.error != "" && (
                  <tr>
                    <td colSpan={5} className="p-0 pt-2">
                      <CustomAlert
                        className={`${daySchedule.error != "" ? "alert-danger" : ""}`}
                        iconName="warningIcon"
                      >
                        {daySchedule.error}
                      </CustomAlert>
                    </td>
                  </tr>
                )}
                <TableHeaderSpace headerLength={5} />
              </React.Fragment>
            ))) : (
              sortedScheduleEntries.map(([day, daySchedule]) => (
                <React.Fragment key={day}>
                  {/* Accordion Header */}
                  <tr className={`spaced-row shiftTimingTableData ${daySchedule.error != undefined && daySchedule.error != "" ? "error-border-card" : ""}`}>
                    <td className={`p-0 planningDateWrapper cursor-pointer d-flex align-items-center justify-content-between ${expandedDay === day ? "dayExpanded" : "dayCollapsed"} `} onClick={() => toggleAccordion(day)} >
                      <DateAndTime
                        isDay={true}
                        isDate={true}
                        showDate={true}
                        showMonth={true}
                        showYear={true}
                        dataTimeClassName="d-flex flex-column align-items-center shiftDate"
                        monthFormat="short"
                        value={day}
                      />
                      {/* Expand/Collapse Icon */}
                      <Icon
                        name={expandedDay === day ? "ExpandLessIcon" : "ExpandMoreIcon"}
                        width="3vw"
                        height="3vw"
                        className="ms-2"
                        isIcon={true}
                      />
                    </td>
                    {/* Accordion Body */}
                    {expandedDay === day && (
                      <>
                        <>
                          {shiftCells(daySchedule.shifts, day)}
                          <td className="actionWrapper bodyBg align-middle px-2">
                            <div className={`d-flex justify-content-between justify-content-lg-start ${Object.keys(schedule).length > 1 ? "overViewButtonStyle" : "overViewButtonStyleThree"}`}>
                              {tab === "tab_3" ? (
                                <>
                                  <CustomActionIcon
                                    handleClick={() =>
                                      handleRemoveShift(day, daySchedule.shifts.length - 1)
                                    }
                                    title={t("Remove shift")}
                                    iconName="RemoveIcon"
                                    buttonClass="resetBtn"
                                  />
                                </>
                              ) : (
                                <>
                                  <OverlayTooltip
                                    tooltipText="Projects"
                                    selectedProject={(projectId: number, type: number) =>
                                      OnProjectClick(projectId, type, day)
                                    }
                                    selectedId={daySchedule.project_id}
                                  >
                                    <div className="d-inline-flex align-items-center justify-content-center searchBtn px-3 w-100">
                                      {t("Location")}
                                    </div>
                                  </OverlayTooltip>
                                  {Object.keys(schedule).length > 1 && (
                                    <OverlayTooltip
                                      tooltipText="Clone"
                                      cloneDates={getShiftDates(day)}
                                      selectedCloneDates={(dates: string[]) =>
                                        handleCloneDates(day, dates)
                                      }
                                    >
                                      <div className="d-inline-flex align-items-center justify-content-center searchBtn px-3 w-100">
                                        {t("Clone")}
                                      </div>
                                    </OverlayTooltip>
                                  )}
                                  <OverlayTooltip tooltipText="Shifts">
                                    <div className="d-inline-flex align-items-center justify-content-center searchBtn px-3 w-100">
                                      {t("Shifts")}
                                    </div>
                                  </OverlayTooltip>
                                  <span
                                    data-bs-toggle="tooltip"
                                    title={t("Remove shift")}
                                    onClick={() =>
                                      handleRemoveShift(
                                        day,
                                        daySchedule.shifts.length - 1
                                      )
                                    }
                                    className="me-2 cursor-pointer actionWrapper removeUploadedFile"
                                  >
                                    <div className="d-inline-flex align-items-center justify-content-center resetBtn px-3 w-100">
                                      {t("Remove")}
                                    </div>
                                  </span>
                                </>
                              )}
                            </div>
                          </td>
                        </>
                        {daySchedule.warning != undefined &&
                          daySchedule.warning.length > 0 &&
                          daySchedule.warning.map((warn) => (
                            <td colSpan={5} className="p-0 pt-2">
                              <CustomAlert
                                className={`
                          ${warn != "" ? "alert-warning" : ""}`}
                                iconName="warningIcon"
                              >
                                {warn}
                              </CustomAlert>
                            </td>
                          ))}
                        {daySchedule.error != undefined && daySchedule.error != "" && (
                          <td colSpan={5} className="p-0 pt-2">
                            <CustomAlert
                              className={`  ${daySchedule.error != "" ? "alert-danger" : ""
                                }`}
                              iconName="warningIcon"
                            >
                              {daySchedule.error}
                            </CustomAlert>
                          </td>
                        )}
                      </>
                    )}
                  </tr>

                </React.Fragment>
              ))
            )}
          </tbody >
        </table>
      ) : (
        <NoRecords message={t("No schedule for current employee.")} isDiv={true} className="no-schedule-message" />
      )}
    </>
  );
};

export default PlanningShiftsTimings;
