import React, { useState, useEffect } from "react";
import Icon from "../../Icon";
import Button from "../common/atoms/Button";
import { useSelector } from "react-redux";
import {
  selectEmployees,
  selectEmployeesWithScheduleErrors,
  selectTab,
} from "../../features/planning/planningSlice";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import PlanningEmployeeSearch from "../common/molecules/PlanningEmployeeSearch";
import NoRecords from "../common/atoms/NoRecords";

interface Employee {
  company_id: number;
  cost_center_id: number;
  employee_id: number;
  employee_name: string;
  employee_type: string;
  employee_type_id: number;
  function: string;
  function_id: number;
  location: string;
  location_id: number;
  paritair_commitee: number;
  pc: string;
  project_id: number;
  salary: string;
}

interface SelectedEmployeesNameCardProps {
  currentEmployeeIndex?: number; // Current index of the selected employee
  onEmployeeSelect?: (index: number) => void; // Pass index of the selected employee
}

const SelectedEmployeesNameCard: React.FC<SelectedEmployeesNameCardProps> = ({
  currentEmployeeIndex,
  onEmployeeSelect,
}) => {
  const selectedEmployees = useSelector(selectEmployees);
  const errorEmployeeIds = useSelector(selectEmployeesWithScheduleErrors);
  const [searchValue, setSearchValue] = useState<string>("");
  const [currentIndex, setCurrentEmployeeIndex] = useState<number>(
    currentEmployeeIndex ?? 0
  ); // Initialize with currentEmployeeIndex
  const [filteredEmployees, setFilteredEmployees] =
    useState<Employee[]>(selectedEmployees); // New state for filtered employees

  const [showSearch, setShowSearch] = useState(false);
  const tab = useSelector(selectTab);

  useEffect(() => {
    // Synchronize currentEmployeeIndex prop with local currentIndex state
    if (currentEmployeeIndex !== undefined) {
      setCurrentEmployeeIndex(currentEmployeeIndex);
    }
  }, [currentEmployeeIndex]);

  const handleButtonClick = (index: number) => {
    setCurrentEmployeeIndex(index); // Update the local currentIndex state
    if (onEmployeeSelect) {
      setShowSearch(false);
      onEmployeeSelect(index); // Notify parent component with the selected employee index
    }
  };

  const handleFilteredEmployees = (values: string) => {
    // Filter employees based on search value
    const filtered = selectedEmployees.filter((emp: Employee) =>
      emp.employee_name.toLowerCase().includes(values.toLowerCase())
    );

    // Find index of the first filtered employee in the original list
    const index = selectedEmployees.findIndex(
      (emp: Employee) => emp.employee_name === filtered[0]?.employee_name
    );

    // Update state with filtered employees
    setFilteredEmployees(filtered);

    // Notify parent component with the selected employee index
    if (onEmployeeSelect && index !== -1) {
      onEmployeeSelect(index);
    }
    setCurrentEmployeeIndex(0); // Reset index after filtering
    setSearchValue(values);
  };

  return (
    <div className="selectedEmployees">
      <div className="row">
        <div className="col-12">
          <div className={`${filteredEmployees.length > 0 ? "d-grid" : ""}`}>
            {filteredEmployees.length > 0 ? (
              filteredEmployees.map((employee: Employee, index: number) => (
                <Button
                  key={employee.employee_id}
                  handleClick={() => handleButtonClick(index)} // Pass the index
                  className={`planningEmployeeButton mobileFont ${index === currentIndex
                      ? "activeEmployee"
                      : "inActiveEmployee"
                    }
                   ${errorEmployeeIds.includes(employee.employee_id)
                      ? "border border-danger"
                      : ""
                    }`} // Correctly highlight the active employee
                >
                  {employee.employee_name}
                </Button>
              ))
            ) : (
              <NoRecords isDiv={true} />
            )}
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-lg-8 ms-auto">
          <div className="d-flex align-items-center justify-content-end">
            {tab != "tab_3" && (
              <PlanningEmployeeSearch
                value={searchValue}
                onEmployeeSelect={handleFilteredEmployees}
              />
            )}
            <div className="selectedNumberOfEmployees ms-2 mobileFont">
              {t("Number selected") + ": " + selectedEmployees.length}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default translate(SelectedEmployeesNameCard);
