import React, { useState, useEffect, useMemo } from "react";
import LinkCustom from "../../common/atoms/Link";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { MANAGE_PC_OVERVIEW_DETAILS } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import styles from "./Pc.module.css";
import { useNavigate } from "react-router-dom";
import { FullScreenLoading } from "../../common/utlis/FullScreenLoading";
import Header from "../../common/layout/Header";
import Search from "../../common/atoms/Search";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import Icon from "../../../Icon";
import BackLink from "../../common/atoms/BackLink";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import Pagination from "../../common/atoms/Pagination";
import TableHeaderSpace from "../../common/atoms/TableHeaderSpace";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import EditIcon from "../../common/molecules/Actions/CustomEdit";
import CustomView from "../../common/molecules/Actions/CustomView";
import LabelField from "../../common/atoms/LabelField";

/**
 * this will project all the partire committee's data.
 * @returns
 */

const ManagePc = () => {
  const [data, setData] = useState([]);
  const [temp, setTemp] = useState([]);
  const [temp2, setTemp2] = useState([]);

  const [searchPcnum, setSearchPcnum] = useState("");
  const [searchPcname, setSearchPcname] = useState("");
  // const [itemsPerPage, setItemsPerPage] = useState(8);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(0);
  const PER_PAGE = 10;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall.getService(
          MANAGE_PC_OVERVIEW_DETAILS,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.success) {
          setData(response.data);
          setTemp(response.data);
          setTemp2(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  let handleSearch = () => {
    setSearch(true);

    const res = temp.filter((val) => {
      const pcNumberMatch = val["pc_number"]
        .trim()
        .includes(searchPcnum.trim());
      const pcAliasNameMatch = val["pc_alias_name"]
        ? val["pc_alias_name"]
            .trim()
            .toLowerCase()
            .includes(searchPcname.trim().toLowerCase())
        : false;
      const pcNameMatch = val["pc_name"]
        ? val["pc_name"]
            .trim()
            .toLowerCase()
            .includes(searchPcname.trim().toLowerCase())
        : false;

      if (searchPcnum && searchPcname) {
        return (
          pcNumberMatch &&
          (pcAliasNameMatch || (!val["pc_alias_name"] && pcNameMatch))
        );
      }

      if (searchPcnum) {
        return pcNumberMatch;
      }

      if (searchPcname) {
        return pcAliasNameMatch || (!val["pc_alias_name"] && pcNameMatch);
      }

      return false;
    });

    setData(res);
    setCurrentPage(0);
  };

  function handleReset() {
    setData(temp);
    setSearchPcnum("");
    setSearchPcname("");
    setSearch(false);
    setCurrentPage(0); // Reset to first page
  }

  //------------------- Pagination code -------------------------//

  const pageCount = Math.ceil(data.length / PER_PAGE);

  useEffect(() => {
    const offset = currentPage * PER_PAGE;
    const currentpgData = data.slice(offset, offset + PER_PAGE);
    setTemp2(currentpgData);
  }, [currentPage, data, search]);
  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber - 1); // Adjust for zero-based index
  };

  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };
  return (
    <div className="container-fluid p-0">
      {loading === true ? (
        <FullScreenLoading />
      ) : (
        <>
          <Header
            headerName={t("Manage PC")}
            headerClassName="myAccountCardTitle"
          />
          <div className="search-bar pb-2">
            <div className="row mb-2">
              <BackLink backLink="/" />
              <div className="col-8 align-self-center text-center d-block d-lg-none">
                <LabelField
                  title={t("Manage PC")}
                  className="myAccountCardTitle mb-0"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <Search
                  value={searchPcnum}
                  placeholder={t("PC number")}
                  handleChange={(e) => setSearchPcnum(e.target.value)}
                />
              </div>
              <div className="col-lg-4 col-md-6 mb-3 mb-lg-0">
                <Search
                  value={searchPcname}
                  placeholder={t("PC name")}
                  handleChange={(e) => setSearchPcname(e.target.value)}
                />
              </div>
              <div className="col-lg-4 col-12 mb-3 mb-lg-0">
                <div className="d-flex align-items-center justify-content-md-end justify-content-lg-start">
                  <ResetBtn handleResetClick={() => handleReset()} />
                  <SearchBtn handleSearchClick={() => handleSearch()} />
                </div>
              </div>
            </div>
          </div>
          <div className="managepagesBg">
            <OverFlowWrapper className="table-responsive managePcTable">
              <table className="table pwa table-hover customTableProperties">
                <thead className="headSticky">
                  <tr className="TableHeader">
                    <th style={{ width: "15%" }}>{t("PC number")}</th>
                    <th style={{ width: "65%" }}>{t("PC name")}</th>
                    <th style={{ width: "25%" }}>{t("Actions")}</th>
                  </tr>
                  <TableHeaderSpace headerLength={4} />
                </thead>
                <tbody>
                  {temp2.length > 0 ? (
                    temp2.map((val, key) => (
                      <tr className="border" key={key}>
                        <td data-label={t("PC number")}>{val.pc_number}</td>
                        <td data-label={t("PC name")}>
                          {val.pc_alias_name ? val.pc_alias_name : val.pc_name}
                        </td>
                        <td className="px-2">
                          <EditIcon href={`/editpc/${val.pc_unique_key}`} />
                          <CustomView href={`/viewpc/${val.pc_unique_key}`} />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td
                        className="border text-center text-danger"
                        colSpan={4}
                      >
                        {t("No paritair committee")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </OverFlowWrapper>
            {pageCount > 1 && (
              <div className="pagination justify-content-center mt-4 px-4">
                <Pagination
                  currentPage={currentPage + 1}
                  totalPages={pageCount}
                  onPageChange={handlePageClick}
                />
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default translate(ManagePc);
