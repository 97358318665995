import { validateEmail, validatePassword, validatePhoneNumber } from "../../../../../services/ValidationService";
import { Address, FormErrors } from "../../../../Dashboard/services/CompanyInterface";
import { isRequired, validateAddressFields } from "../../../../Dashboard/services/Companyutils";
import { Communication, FormValues, MyAccount } from "../MyAccountInterface";

export const formValidation = (formState: MyAccount) => {
    const errors: FormErrors = {};

    const fields = {
        string: ["first_name", "last_name", "email", "date_of_birth"] as (keyof FormValues)[],
        number: ["phone_number", "language_id"] as (keyof FormValues)[],
        address: ["street", "postal_code", "number", "country"] as (keyof Address)[],
        communication: ["contracts_email", "invoice_email", "planning_contact_person", "planning_contact_number"] as (keyof Communication)[]
    };

    // Validate string fields
    fields.string.forEach((field) => {
        const value = formState?.formValues[field];
        if (isRequired(value)) {
            errors[field] = 'This field is required.';
        }
    });

    // Validate number fields
    fields.number.forEach((field) => {
        const value = formState?.formValues[field];
        if (isRequired(value) || value === 'Select') {
            errors[field] = 'This field is required.';
        } else if (field === 'phone_number' && !/^[+0-9\s/\-()]{10,50}$/.test(value)) {
            errors[field] = 'Invalid phone number.';
        }
    });

    // Validate emergency contact
    validateCommunication(
        formState?.formValues?.communication,
        errors,
        fields.communication
    );

    // Validate address fields (for residence)
    validateAddressFields(
        formState?.formValues?.address?.residence,
        errors,
        'residence',
        fields.address
    );

    // Validate passwords
    if (formState?.formValues?.security?.password) {
        const passwordError = validatePassword(formState?.formValues?.security?.password);
        if (passwordError) {
            errors.password = passwordError;
        }

        const confirmPasswordError = validatePassword(
            formState?.formValues?.security?.confirm_password || ""
        );
        if (confirmPasswordError) {
            errors.confirm_password = confirmPasswordError;
        }

        if (
            formState.formValues.security.confirm_password !==
            formState?.formValues?.security?.password
        ) {
            errors.confirm_password = "The two password fields didn't match.";
        }
    }

    return errors;
};

export const validateCommunication = (
    communication: Communication | undefined,
    errors: FormErrors = {},
    fields: (keyof Communication)[]
): void => {
    // Iterate over each field to validate
    fields.forEach((field) => {
        const value = communication?.[field];

        if (!value) {
            return;
        }

        // Email validation for contracts_email and invoice_email
        if (field === 'contracts_email' || field === 'invoice_email') {
            const emailError = validateEmail(value);
            if (emailError) {
                errors[field] = emailError;
            } else {
                delete errors[field];
            }
        }
        else if (field === 'planning_contact_number') {
            const phoneError = validatePhoneNumber(value);
            if (phoneError) {
                errors[field] = phoneError;
            } else {
                delete errors[field];
            }
        }
        else {
            delete errors[field];
        }
    });
};


export const formatUserData = (data: any) => {
    return {
        function: data?.function ?? '',
        first_name: data?.first_name,
        last_name: data?.last_name,
        email: data?.email,
        phone_number: data?.phone_number,
        gender: data?.gender,
        date_of_birth: data?.date_of_birth,
        nationality_id: data?.nationality_id,
        is_national: data?.is_national,
        role: "employer",
        profile: true,
        profile_picture_url: data?.profile_picture_url,
        profile_picture_id: data?.profile_picture_id,
        language_id: data?.language_id,
        signature: data?.signature,
        source: "web",
        address: {
            residence: {
                name: data?.address?.residence?.name,
                street: data?.address?.residence?.street,
                number: data?.address?.residence?.number,
                addition: data?.address?.residence?.addition,
                postal_code: data?.address?.residence?.postal_code,
                box: data?.address?.residence?.box,
                city: data?.address?.residence?.city ?? "City",
                country: data?.address?.residence?.country_id,
                latitude: "",
                longitude: ""
            }
        },
        communication: {
            user_id: data?.communication?.user_id ?? null,
            contracts_email: data?.communication?.contracts_email ?? '',
            invoice_email: data?.communication?.invoice_email ?? '',
            planning_contact_person: data?.communication?.planning_contact_person ?? '',
            planning_contact_number: data?.communication?.planning_contact_number ?? ''
        },
        privacy: {
            via_phone: data?.privacy?.via_phone,
            via_email: data?.privacy?.via_email
        }
    };
};