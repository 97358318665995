import React from "react";
import FileUpload from "../../../common/molecules/FileUpload";

interface IDFileUploadsProps {
  state: any; // Replace `any` with your specific type for `state`
  setState: React.Dispatch<React.SetStateAction<any>>; // Update with specific type if known
  handleFileUploadResponse: (response: any) => void;
  handleFileDeleteResponse: (response: any) => void;
}

const IDFileUploads: React.FC<IDFileUploadsProps> = ({
  state,
  setState,
  handleFileUploadResponse,
  handleFileDeleteResponse,
}) => {
  return (
    <div className="row">
      <div className="col-lg-4 mb-3 mb-lg-0 idHeight">
        <FileUpload
          id="front_pic"
          name="front_pic"
          label="Front ID:"
          file_type={2}
          isMandatory={true}
          edit={true}
          fileId={state.front_pic_id}
          fileName={state.front_pic_name}
          fileUrl={state.front_pic_url}
          multiple={false}
          dontDelete={true}
          uploadPath="front"
          formats=".pdf,.jpg,.jpeg,.png"
          maxFileSize={8}
          className="d-flex align-items-center"
          textclassName="mx-2"
          returnFileUploadErrors={(errorInfo: any) => {
            setState((prevData: any) => ({
              ...prevData,
              errors: {
                ...prevData.errors,
                front_pic_url: errorInfo?.error,
              },
            }));
          }}
          fileUploadResponse={handleFileUploadResponse}
          deleteResponse={handleFileDeleteResponse}
        />
        {state?.errors?.front_pic_url && (
          <div className="text-danger">{state?.errors?.front_pic_url}</div>
        )}
      </div>
      <div className="col-lg-4 idHeight">
        <FileUpload
          id="back_pic"
          name="back_pic"
          label="Back ID:"
          file_type={3}
          isMandatory={true}
          edit={true}
          fileId={state.back_pic_id}
          fileName={state.back_pic_name}
          fileUrl={state.back_pic_url}
          multiple={false}
          uploadPath="back"
          formats=".pdf,.jpg,.jpeg,.png"
          maxFileSize={8}
          dontDelete={true}
          className="d-flex align-items-center"
          textclassName="mx-2"
          returnFileUploadErrors={(errorInfo: any) => {
            setState((prevData: any) => ({
              ...prevData,
              errors: {
                ...prevData.errors,
                back_pic_url: errorInfo?.error,
              },
            }));
          }}
          fileUploadResponse={handleFileUploadResponse}
          deleteResponse={handleFileDeleteResponse}
        />
        {state?.errors?.back_pic_url && (
          <div className="text-danger">{state?.errors?.back_pic_url}</div>
        )}
      </div>
    </div>
  );
};

export default IDFileUploads;
