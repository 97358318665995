import React, { ReactNode, useEffect, useState } from "react";
import Image from "../common/atoms/Image";
import LoadImage from "../../static/images/DummyImage.jpg";
import TimePicker from "../common/atoms/TimePicker";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import Icon from "../../Icon";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import { formatDateTime } from "../common/utlis/dateHelper";
import { ApiCall } from "../../services/ApiServices";
import { PLANNING_LAYOUT, PLANNING_OVERVIEW } from "../../routes/ApiEndpoints";
import {
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
  PLANNING_MICROSERVICE,
} from "../../Constants";
import EmployeeLayoutRow from "./EmployeeLayoutRow";
import { useSelector } from "react-redux";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import {
  formatFloatSalary,
  updatesalaryFormat,
} from "./Services/PlanningSalaryHelper";
import CancelPlanningPopup from "./CancelPlanningPopup";
import EditPlanningPopup from "./EditPlanningPopup";
import CustomNotify from "../common/atoms/CustomNotify";
import NoRecords from "../common/atoms/NoRecords";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import StatusCircle from "../common/atoms/StatusCircle";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";
import EditIcon from "../common/molecules/Actions/CustomEdit";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";
import CustomOverlayTrigger from "../common/molecules/Actions/CustomOverlayTrigger";

interface Timing {
  shift_id: number;
  shift_starttime: string;
  shift_endtime: string;
  shift_sequence: number;
}

interface EventData {
  length: number;
  map(arg0: (employee: EventData) => React.JSX.Element): React.ReactNode;
  id: number;
  employee_id: number;
  name: string;
  employee_profile_pic: string;
  employee_type: string;
  employee_type_id: number;
  function_name: string;
  function_id: number;
  planning_id: number;
  pc_id: number;
  pc_name: string;
  salary: string;
  pdate: string;
  planning_status: string | null;
  failed_reason: string | null;
  location_name: string;
  location_id: number;
  total_break: number;
  total_hrs: number;
  schedule: Timing[];
}
interface PlanningTableProps {
  selectedDate?: Date;
  page?: string;
  companyId: number;
  selectedLocation?: {
    // Add prop for selected location
    address_id: number;
    id: number;
    location_image_id: number;
    name: string;
  };
  selectedEmployees?: EventData[];
  updateEmployeeSchedule: (employeeIds: number[]) => void;
}
interface Shift {
  start_time: string;
  end_time: string;
  sequence: number;
  // Add other shift fields if necessary
}

interface Schedule {
  shifts: Shift[];
  project_id?: number;
  cost_center_id?: number;
  error?: "";
  warning?: [];
}

interface PlanningLayoutData {
  planning_id?: number;
  planning_employee_detail_id?: number;
  employee_id: number;
  employee_name?: string;
  employee_type_id?: number;
  employee_type?: string;
  company_id?: number;
  age: number;
  location_id?: number;
  pc_id?: number;
  pc?: string;
  function_id?: number;
  function?: string;
  salary?: string;
  start_date?: string;
  end_date?: string;
  schedule: {
    [date: string]: Schedule;
  };
}
const PlanningTable: React.FC<PlanningTableProps> = ({
  page = "default",
  selectedDate,
  companyId,
  selectedLocation,
  selectedEmployees = undefined,
  updateEmployeeSchedule,
}) => {
  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };
  const imageStyle = useResponsiveValue("8vw", "5vw", "2vw");
  const [data, setData] = useState<EventData[]>([]);
  const [layoutData, setLayoutData] = useState<PlanningLayoutData[]>([]);
  const [refresh, setRefresh] = useState<boolean>(true);
  const [multiPlan, setMultiPlan] = useState<number[]>([]);
  const [sendMulti, setSendMulti] = useState<boolean>(false);
  const [cancel, setCancel] = useState<EventData | null>(null);
  const [edit, setEdit] = useState<EventData | null>(null);

  /**Auto refresh */
  const [autorefresh, setAutorefresh] = useState(false);

  // setTimeout(() => {
  //   setAutorefresh(!autorefresh);
  // }, 60000);

  const show = useSelector(selectPlanningShow); // Access the `show` state

  const fetchData = async () => {
    if (selectedDate !== undefined) {
      setCancel(null); // after cancelling contract overview refresh
      const currentDate = formatDateTime(selectedDate);
      try {
        const dataObj = {
          company_id: companyId,
          location_id: selectedLocation?.id,
          start_date: currentDate,
          end_date: currentDate,
        };
        const result = await ApiCall.service(
          PLANNING_OVERVIEW,
          "POST",
          dataObj,
          true,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (result.status === "success") {
          setRefresh(false);
          setData(result.data[currentDate]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }
  };

  useEffect(() => {
    fetchData(); // Call fetchData whenever selectedDate changes
  }, [selectedDate, selectedLocation, refresh, autorefresh]);
  useEffect(() => {
    if (selectedDate !== undefined && selectedEmployees !== undefined) {
      const currentLayoutEmployeeIds = layoutData.map((emp) => emp.employee_id);
      const selectedEmployeeIds = selectedEmployees.map((emp) => emp.id);

      // Find employees to add (in selectedEmployees but not in layoutData)
      const employeesToAdd = selectedEmployees.filter(
        (emp) => !currentLayoutEmployeeIds.includes(emp.id)
      );

      // Find employees to remove (in layoutData but not in selectedEmployees)
      const employeesToRemove = layoutData.filter(
        (emp) => !selectedEmployeeIds.includes(emp.employee_id)
      );

      // Remove deselected employees from layoutData
      if (employeesToRemove.length > 0) {
        setLayoutData((prevLayoutData) =>
          prevLayoutData.filter(
            (emp) =>
              !employeesToRemove.some(
                (removeEmp) => removeEmp.employee_id === emp.employee_id
              )
          )
        );
      }

      const fetchLayoutData = async () => {
        try {
          if (employeesToAdd.length > 0) {
            const dataObj = {
              company_id: companyId,
              location_id: selectedLocation?.id,
              dates: [formatDateTime(selectedDate)],
              employee_ids: employeesToAdd.map((emp) => emp.id),
            };
            const result = await ApiCall.service(
              PLANNING_LAYOUT,
              "POST",
              dataObj,
              true,
              CENTRAL_DATA_MANAGEMENT_MICROSERVICE
            );
            if (result.status === "success") {
              if (Array.isArray(result.data)) {
                setLayoutData((prev) => [
                  ...prev, // Spread the previous state
                  ...result.data, // Spread the new data
                ]);

                // Filter out these employees from data
                const newLayoutEmployeeIds = result.data.map(
                  (emp: PlanningLayoutData) => emp.employee_id
                );
                setData((prevData) =>
                  prevData?.filter(
                    (emp) => !newLayoutEmployeeIds.includes(emp.employee_id)
                  )
                );
              } else {
                console.error("Error fetching data:", result.message);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
      fetchLayoutData();
    } else if (selectedEmployees == null) {
      setLayoutData([]);
    }
  }, [selectedEmployees]); // Added other dependencies
  const handleRefresh = (employeeIds: number[]) => {
    setLayoutData((prevLayoutData) =>
      prevLayoutData.filter((emp) => !employeeIds.includes(emp.employee_id))
    );
    updateEmployeeSchedule(employeeIds);
    setRefresh(true);
  };

  // const handleSendAll = () => {
  //     // Trigger the send action for each employee in the multiPlan array
  //     multiPlan.forEach((employeeId) => {
  //         const employeeRow = layoutData.find(emp => emp.employee_id === employeeId);
  //         if (employeeRow) {
  //             employeeRow.triggerSendPlan(); // Call the function to trigger the send plan for the employee
  //         }
  //     });
  // };
  return (
    <>
      <table className="table planningTableWrapper table-borderless pwa">
        <thead className="positionSticky">
          <tr className="align-middle">
            <th></th>
            <th>{t("Employee")}</th>
            <th>{t("Statute")}</th>
            <th>{t("Function")}</th>
            <th>{t("Paritair committee")}</th>
            <th>{t("Hourly wage")}</th>
            <th>{t("Start shift")}</th>
            <th>{t("End shift")}</th>
            <th>{t("Start shift")}</th>
            <th>{t("End shift")}</th>
            <th>{t("Break Total")}</th>
            <th>{t("Total work done")}</th>
            <th>{t("Status")}</th>
            <th>{t("Action")}</th>
          </tr>
          {window.innerWidth > 1023 && <TableHeaderSpace className="bg-transparent" headerLength={15} />}
        </thead>
        <tbody>
          {/* <AccessControl
            renderNoAccess={false}
            requiredPermissions={[
              {
                permission: "planing_layout",
              },
            ]}
          > */}
          {page !== "dashboard" &&
            layoutData.length > 0 &&
            layoutData.map((emp) => (
              <EmployeeLayoutRow
                key={emp.employee_id}
                emp={emp}
                refreshOverview={(employeeIds: number[]) =>
                  handleRefresh(employeeIds)
                }
                selectedEmployeesForPlan={(employeeIds: number[]) =>
                  setMultiPlan(employeeIds)
                }
                saveLayoutData={(updatedEmployee: PlanningLayoutData) => {
                  // Update the specific employee in the layoutData
                  setLayoutData(prevLayoutData =>
                    prevLayoutData.map(employee =>
                      employee.employee_id === updatedEmployee.employee_id
                        ? updatedEmployee
                        : employee
                    )
                  );
                }}
              />
            ))}
          {/* </AccessControl> */}
          {data != undefined &&
            (data.length > 0 ? (
              data.map((emp: EventData) => (
                <>
                  <tr key={emp.id}>
                    {window.innerWidth <= 1023 && <CustomOverlayTrigger status={emp.planning_status} />}
                    <td className="px-2">
                      <Image
                        src={LoadImage}
                        imageWidth={imageStyle}
                        imageHeight={imageStyle}
                        imageBorderRadius="50%"
                      />
                    </td>
                    <td data-label={t("Employee")}>
                      <div
                        className="textEllipsisName textEllipsis"
                        title={emp.name}
                      >
                        {emp.name}
                      </div>
                    </td>
                 
                    <td data-label={t("Statute")}>
                      <div
                        className="textEllipsisEmpType textEllipsis"
                        title={emp.employee_type}
                      >
                        {emp.employee_type}
                      </div>
                    </td>
                    <td data-label={t("Function")}>
                      <div
                        title={emp.function_name}
                        className="textEllipsisFunName textEllipsis"
                      >
                        {emp.function_name}
                      </div>
                    </td>
                    <td data-label={t("Paritair committee")}>
                      <div
                        title={emp.pc_name}
                        className="textEllipsisFunName textEllipsis"
                      >
                        {emp.pc_name}
                      </div>
                    </td>
                    <td data-label={t("Hourly wage")}>€{updatesalaryFormat(emp.salary)}</td>
                    <td data-label={t("Start shift")}>
                      {page === "dashboard" ? (
                        <TimePicker
                          setHourMin={() => { }}
                          type={""}
                          index={0}
                          value={emp.schedule[0].shift_starttime}
                          hour={emp.schedule[0].shift_starttime.split(":")[0]}
                          minute={emp.schedule[0].shift_starttime.split(":")[0]}
                        />
                      ) : (
                        emp.schedule[0].shift_starttime
                      )}
                    </td>
                    <td data-label={t("End shift")}>
                      {page === "dashboard" ? (
                        <TimePicker
                          setHourMin={() => { }}
                          type={""}
                          index={0}
                          value={emp.schedule[0].shift_endtime}
                          hour={emp.schedule[0].shift_endtime.split(":")[0]}
                          minute={emp.schedule[0].shift_endtime.split(":")[0]}
                        />
                      ) : (
                        emp.schedule[0].shift_endtime
                      )}
                    </td>
                    <td data-label={t("Start shift")}>
                      {page === "dashboard" && emp.schedule[1] != undefined ? (
                        <TimePicker
                          setHourMin={() => { }}
                          type={""}
                          index={0}
                          value={emp.schedule[0].shift_starttime}
                          hour={emp.schedule[0].shift_starttime.split(":")[0]}
                          minute={emp.schedule[0]?.shift_starttime.split(":")[1]}
                        />
                      ) : emp.schedule[1] != undefined ? (
                        emp.schedule[1].shift_starttime
                      ) : (
                        "-"
                      )}
                    </td>
                    <td data-label={t("End shift")}>
                      {page === "dashboard" && emp.schedule[1] != undefined ? (
                        <TimePicker
                          setHourMin={() => { }}
                          type={""}
                          index={0}
                          value={emp.schedule[0].shift_endtime}
                          hour={emp.schedule[0].shift_endtime.split(":")[0]}
                          minute={emp.schedule[0].shift_endtime.split(":")[1]}
                        />
                      ) : emp.schedule[1] != undefined ? (
                        emp.schedule[1].shift_endtime
                      ) : (
                        "-"
                      )}
                    </td>
                    <td data-label={t("Break Total")}>
                      <div className="totalHours text-white poppins-medium">
                        {emp.total_break ?? "00:00"}
                      </div>
                    </td>
                    <td data-label={t("Total work done")}>
                      <div className="totalHours text-white poppins-medium">
                        {emp.total_hrs ?? "00:00"}
                      </div>
                    </td>
                    {window.innerWidth > 1023 && <td>
                      <div className="text-white poppins-medium">
                        <StatusCircle status={emp.planning_status} failedReason={emp.failed_reason} />
                      </div>
                    </td>}
                    {emp.pdate >= formatDateTime(new Date()) ? ( // Check if emp.pdate is not less than the current date
                      <td className="actionWrapper px-2">
                        <div>
                          {/* Clone */}
                          <CustomActionIcon handleClick={() => {
                            CustomNotify({
                              type: "warning",
                              message: t(
                                "Clone planning functionality not yet done. Please dont check this."
                              ),
                            });
                          }}
                            title={t("Clone")}
                            iconName="CloneIcon"
                            className="me-1" />
                          {/* Edit */}
                          <EditIcon handleClick={() => setEdit(emp)} iconName="EditIcon" title={t("Edit")} />
                          {/* Remove */}
                          <CustomActionIcon handleClick={() => setCancel(emp)} iconName="RemoveIcon" title={t("Remove")} buttonClass="resetBtn" />
                        </div>
                      </td>
                    ) : (
                      <td></td>
                    )}
                  </tr>
                  {window.innerWidth > 1023 && <TableHeaderSpace
                    headerLength={15}
                    className="p-0 bg-transparent"
                  />}
                </>
              ))
            ) : (
              <NoRecords
                message={t("There are no schedules for this day")}
                headerLength={15}
              />
            ))}
        </tbody>
      </table>
      {/* {multiPlan.length > 0 &&
                < button className="btn btn-primary" onClick={() => handleSendAll()}>{t(" Send all") + '(' + multiPlan.length + '/' + layoutData.length + ')'}</button >
            } */}
      {cancel != null && (
        <CancelPlanningPopup
          employeeData={cancel}
          clearPopup={() => {
            setCancel(null);
            fetchData(); // Refresh the overview after canceling
          }}
        />
      )}
      {edit != null && (
        <EditPlanningPopup
          employeeData={edit}
          clearPopup={() => {
            setEdit(null);
            fetchData(); // Refresh the overview after canceling
          }}
        />
      )}
    </>
  );
};

export default translate(PlanningTable);
