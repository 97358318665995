import React from "react";

const ValidationService = {

    dateComparision: function (timestamp1, timestamp2, typee = 0) {
        let a = new Date(timestamp1);
        let b = new Date(timestamp2);
        let result = false;

        if (a > b && typee == 0) {
            result = true;
        }
        if (a >= b && typee != 0) {
            result = true;
        }
        return result;
    },
    differBetweenTwoTimeStamps: function (timestamp1, timestamp2, tpe = 0) {
        // Convert the timestamp strings to Date objects
        const date1 = new Date(timestamp1.replace(/-/g, "/"));
        const date2 = new Date(timestamp2.replace(/-/g, "/"));

        // Calculate the difference in milliseconds
        const timeDifference = Math.abs(date1 - date2);
        if (isNaN(timeDifference)) {
            return 0;
        }

        // Convert the difference to hours
        let hoursDifference = timeDifference / (1000 * 60 * 60);

        // Handle case for tpe = 1
        if (tpe === 1) {
            if (hoursDifference < 0) {
                hoursDifference = Math.abs(hoursDifference);
            }
            hoursDifference = hoursDifference.toFixed(2);
        }
        return hoursDifference;
    },

    toDay: function (type = 0, d = 0) {
        let date = new Date();

        let day = date.getDate().toString();
        if (day.length == 1) {
            day = "0" + day;
        }
        let month = date.getMonth() + 1;
        month = month.toString();
        if (month.length == 1) {
            month = "0" + month;
        }
        const year = date.getFullYear();
        if (type == 1) {
            let hours = date.getHours();
            let minutes = date.getMinutes();
            if (minutes < 10) {
                minutes = "0" + minutes;
            }
            if (hours <= 9) {
                hours = "0" + hours;
            }
            let datetimestamp =
                year +
                "-" +
                month +
                "-" +
                day +
                " " +
                hours +
                ":" +
                minutes +
                ":" +
                "00";
            if (d != 0) {
                datetimestamp =
                    day +
                    "-" +
                    month +
                    "-" +
                    year +
                    " " +
                    hours +
                    ":" +
                    minutes +
                    ":" +
                    "00";
            }
            return datetimestamp;
        } else if (type == 2) {
            return day + "-" + month + "-" + year;
        }
        return `${year}-${month}-${day}`;
    },

    addDays: function (value, days) {
        let date = new Date(value);
        const da = new Date(date.setDate(date.getDate() + days));

        let day = da.getDate().toString();
        if (day.length == 1) {
            day = "0" + day;
        }
        let month = da.getMonth() + 1;
        month = month.toString();
        if (month.length == 1) {
            month = "0" + month;
        }
        const year = da.getFullYear();

        const hours = da.getHours();
        const minutes = da.getMinutes();

        return (
            year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + "00"
        );
    },

    removeDays: function (value, days) {
        let date = new Date(value);
        const da = new Date(date.setDate(date.getDate() - days));

        let day = da.getDate().toString();
        if (day.length == 1) {
            day = "0" + day;
        }
        let month = da.getMonth() + 1;
        month = month.toString();
        if (month.length == 1) {
            month = "0" + month;
        }
        const year = da.getFullYear();

        const hours = da.getHours();
        const minutes = da.getMinutes();

        return (
            year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + "00"
        );
    },

    getDate: function (value, type = 0) {
        var parts = value.split(/[- :]/);
        let date = new Date(
            parseInt(parts[0]),
            parseInt(parts[1]) - 1,
            parseInt(parts[2])
        );
        return date.toLocaleDateString("en-GB").split("/").reverse().join("-");

        let day = date.getDate().toString();
        /* A ternary operator. It is a shorthand for an if-else statement. */
        if (day.length === 1) {
            day = "0" + day;
        }

        /* Getting the month from the date object and adding 1 to it. */
        let month = date.getMonth() + 1;

        month = month.toString();
        if (month.length == 1) {
            month = "0" + month;
        }
        const year = date.getFullYear().toString();
        if (type != 0) {
            let fuldate = new Date(year, month, day);

            return fuldate.toLocaleDateString("en-GB");
        }

        return new Date(year, month, day).toLocaleDateString("en-GB");
    },
    getDate2: function (value, type = 0) {
        let date = new Date(value);
        // return date.toLocaleDateString('en-GB');

        let day = date.getDate().toString();
        /* A ternary operator. It is a shorthand for an if-else statement. */
        if (day.length === 1) {
            day = "0" + day;
        }

        /* Getting the month from the date object and adding 1 to it. */
        let month = date.getMonth() + 1;

        month = month.toString();
        if (month.length == 1) {
            month = "0" + month;
        }
        const year = date.getFullYear().toString();
        if (type != 0) {
            let fuldate = year + "-" + month + "-" + day;
            return fuldate;
        }

        return day + "-" + month + "-" + year;
    },

    getTime: function (value, s) {
        // let date = new Date(value);
        const [datePart, timePart] = value.split(" ");
        const [hours, minutes, seconds] = timePart.split(":");

        if (minutes < 10) {
            minutes = minutes;
        }
        if (hours < 10) {
            hours = hours;
        }
        if (s == 1) {
            return datePart + "T" + hours + ":" + minutes + ":" + "00";
        } else {
            return hours + ":" + minutes;
        }
    },

    timeFOrmating: function (value) {
        let date = new Date(value);
        let day = date.getDate().toString();
        if (day.length == 1) {
            day = "0" + day;
        }
        let month = date.getMonth() + 1;
        month = month.toString();
        if (month.length == 1) {
            month = "0" + month;
        }
        const year = date.getFullYear();
        let hours = date.getHours();
        let minutes = date.getMinutes();
        minutes = minutes < 10 ? "0" + minutes : minutes;
        const seconds = date.getSeconds();

        return (
            day + "-" + month + "-" + year + " " + hours + ":" + minutes + ":" + "00"
        );
    },

    emptyValidationMethod: function (value) {
        let scriptCondition =
            typeof value === "string"
                ? value.startsWith("<script>") || value.endsWith("</script>")
                : true;

        if (value === null || value.length == 0) {
            return "This field is required.";
        } else if (value != undefined && value.length > 0 && scriptCondition) {
            return "The html tag mentioned in input field is not accepted";
        }
        return "";
    },

    numberValidationMethod: function (value) {
        if (!value.match(/^[0-9]+$/)) {
            return "This field is invalid.";
        }
        return "";
    },

    nameValidationMethod: function (value) {
        if (!value.match(/^[a-zA-Z\u0080-\uFFFF0-9 ]+$/)) {
            return "This field is invalid.";
        }
        return "";
    },

    salaryValidationMethod: function (value) {
        if (!value.match(/^[0-9]+$/)) {
            return "This field is invalid.";
        }
        return true;
    },

    pcnumberValidationMethod: function (value) {
        if (
            !value.match(/^([0-9]{3})+$/) &&
            !value.match(/^([0-9]{3}[.][0-9]{2})+$/)
        ) {
            return "This field is invalid.";
        }
        return "";
    },

    percentageValidationMethod: function (value) {
        if (
            value.match(/(^(100([.][0]{1,2})?)$)|(^[1-9][0-9]{0,1}([.]\d{1,2})?)$/) ||
            value.match(/(^(100([,][0]{1,2})?)$)|(^[1-9][0-9]{0,1}([,]\d{1,2})?)$/)
        ) {
            return "";
        } else {
            return "This field is invalid.";
        }
    },

    minSalaryValidationMethod: function (value) {
        if (value.match(/(^[1-9][0-9]{0,2}(([.]|[,])\d{1,4})?)$/)) {
            return "";
        } else {
            return "This field is invalid.";
        }
    },

    hoursperdayValidationMethod: function (value) {
        if (
            value.match(/^(0?[0-9]|1[0-9]|2[0-3])([.,]\d{1,2})?$/) ||
            value.match(/^24([.,]0{1,2})?$/) ||
            value.match(/(^[0]?\d{1})$/) ||
            value.match(/(^[1]{1}\d{1})$/) ||
            value.match(/(^[2]{1}[0-3]{1})$/) ||
            value.match(/(^([2][4]){1})$/)
        ) {

            return "";
        } else {
            return "This field is invalid.";
        }
    },

    maximumhourperweekValidationMethod: function (value) {
        const truncatedValue = value.split(/[.,]/)[0];
        if (truncatedValue.length > 3) {
            return "This field is invalid.";
        } else if (!value.match(/^[0-9]{1,3}([.,][0-9]{1,2})?$/)) {
            return "This field is invalid.";
        } else {
            return "";
        }
    },

    postalCodeValidationMethod: function (value) {
        if (value.length > 6 || value.length < 4) {
            return "This field is invalid";
        } else if (!value.match(/^[a-zA-Z\u0080-\uFFFF0-9 ]+$/)) {
            return "This field is invalid";
        } else if (isNaN(value)) {
            return "This field is invalid";
        } else {
            return "";
        }
    },

    projectNameValidationMethod: function (value) {
        if (
            value &&
            value.replace(" ", "").match(/^[a-zA-Z\u0080-\uFFFF0-9 .,'\- ]+$/)
        ) {
            return "";
        } else {
            return "This field is invalid.";
        }
    },

    streetValidationMethod: function (value) {
        if (
            value &&
            value.replace(" ", "").match(/^[a-zA-Z\u0080-\uFFFF0-9 .,'\-()]+$/)
        ) {
            return "";
        } else {
            return "This field is invalid.";
        }
    },
    houseNumberValidation: function (value) {
        // Remove spaces and convert the value to uppercase for validation
        const cleanedValue = value.replace(/\s+/g, "").toUpperCase();

        // Define a regular expression pattern to match valid house numbers
        const pattern = /^[A-Z0-9/\-]+$/;

        if (pattern.test(cleanedValue)) {
            return ""; // Valid house number
        } else {
            return "Invalid house number format.";
        }
    },
    houseNumberValidation2: function (value) {
        // Remove spaces and convert the value to uppercase for validation
        const cleanedValue = value.replace(/\s+/g, "").toUpperCase();

        // Define a regular expression pattern to match valid alphanumeric house numbers
        const pattern = /^[A-Za-z0-9]+$/;

        if (pattern.test(cleanedValue)) {
            return ""; // Valid house number
        } else {
            return "Invalid house number format.";
        }
    },
    getYear: function (value) {
        let year = value.split("-");
        return year;
    },
    getDateString: function (value, year = 0) {
        let datearr = value.split("-");
        return datearr[0] + "-" + datearr[1] + "-" + year;
    },

    onlyFutureDateValidationMethod: function (value) {
        let dateObj = new Date();
        var currentdate = new Date(value);
        var oneyearpast = new Date(dateObj.getTime() - 31556952000);
        var oneyearfuture = new Date(dateObj.getTime() + 31470552000);
        if (
            oneyearpast.getTime() < currentdate.getTime() &&
            currentdate.getTime() < oneyearfuture.getTime()
        ) {
            return "";
        } else {
            var oneyearpast = new Date(dateObj.getTime() - 31556952000 + 86400000);
            let date = oneyearpast.getDate();
            let month = oneyearpast.getMonth() + 1;
            let year = oneyearpast.getFullYear();
            let fdate = oneyearfuture.getDate();
            let fmonth = oneyearfuture.getMonth() + 1;
            let fyear = oneyearfuture.getFullYear();
            return (
                "Please select a date between " +
                date +
                "/" +
                month +
                "/" +
                year +
                " and " +
                fdate +
                "/" +
                fmonth +
                "/" +
                fyear
            );
        }
    },

    emailValidationMethod: function (value) {
        if (
            value.replace(" ", "").match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,9}$/i)
        ) {
            return "";
        } else {
            return "This field is invalid";
        }
    },

};
export default ValidationService;
