import React from "react";
import Select, { components, MultiValueProps, SingleValueProps, OptionProps } from "react-select";
import Button from "../atoms/Button";
import { t } from "../../CentralDataMangement/translation/Translation";
import Image from "../atoms/Image";

interface Option {
  value?: number | string | null;
  label?: string;
  profile_picture_url: string;
  first_name: string;
  last_name: string;
}

interface MultiSelectCardProps {
  placeholder?: string;
  options: Option[];
  value: Option[]; // Selected values passed from the parent
  onChange: (selectedOptions: Option[]) => void; // Handle change callback
  onRemove: (itemToRemove: Option) => void; // Handle remove callback
  className?: string;
  style?: any;
}

const MultiSelectCard: React.FC<MultiSelectCardProps> = ({
  placeholder = "Select an option",
  options,
  value,
  onChange,
  onRemove,
  className,
  style
}) => {

  const CustomOption = ({ data, innerRef, innerProps }: OptionProps<Option>) => (
    <div ref={innerRef} {...innerProps} className="custom-option">
      <Image
        src={data?.profile_picture_url}
        imageWidth="1.5vw"
        imageHeight="1.5vw"
        imageBorderRadius="50%"
      />
      <span style={{ marginLeft: "10px" }}>{data?.label}</span>
    </div>
  );

  const CustomSingleValue = ({ data }: SingleValueProps<Option>) => (
    <div className="custom-single-value">
      <Image
        src={data?.profile_picture_url}
        imageWidth="1.5vw"
        imageHeight="1.5vw"
        imageBorderRadius="50%"
      />
      <span style={{ marginLeft: "10px" }}>
        {data?.first_name} {data?.last_name}
      </span>
    </div>
  );

  const CustomMultiValue = ({ data }: MultiValueProps<Option>) => (
    <div className="custom-multi-value">
      <Image
        src={data?.profile_picture_url}
        imageWidth="1.5vw"
        imageHeight="1.5vw"
        imageBorderRadius="50%"
      />
      <span style={{ marginLeft: "10px" }}>
        {data?.first_name} {data?.last_name}
      </span>
      <Button
        handleClick={() => onRemove(data)} // Ensure onRemove is passed and updates state
        className="close-button btn-close"
        toolTipName={t("Close button")}
        type="button"
      />
    </div>
  );

  return (
    <div className="MultiSelectCardWrapper multiSelectWrapper">
      <Select
        isSearchable={true}
        id="id"
        defaultValue={null}
        className={`${className}`}
        options={options}
        isMulti={true}
        value={value} // Ensure this reflects the selected employees
        placeholder={placeholder}
        onChange={(selectedOptions) => onChange(selectedOptions as Option[])} // Make sure onChange updates state
        components={{
          Option: CustomOption,
          SingleValue: CustomSingleValue,
          MultiValue: CustomMultiValue,
        }}
        classNamePrefix={`customSelect multiSelectDropdown`}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            ...style,
            fontSize: window.innerWidth <= 768 ? "3.5vw" : "0.9vw", // Mobile vs desktop font size
          }),
          menu: (provided) => ({
            ...provided,
            overflow: "visible",
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          menuList: (provided) => ({
            ...provided,
            maxHeight: "200px",
            overflowY: "auto",
          }),
          indicatorSeparator: (baseStyles) => ({
            ...baseStyles,
            display: "none",
          }),
          noOptionsMessage: (baseStyles) => ({
            ...baseStyles,
            color: "red",
            fontSize: window.innerWidth <= 767
              ? "3.5vw"
              : window.innerWidth <= 1023
                ? "1.95vw"
                : "0.9vw"
          }),
        }}
      />
    </div>
  );
};

export default MultiSelectCard;
