import React, { useContext } from "react";
// import LinkCoeffEmpContext from '../../Contexts/LinkCoeffDeviationEmp/LinkCoeffDeviationEmpContext';
import LinkCoeffEmpContext from "./LinkCoeffDeviationEmp/LinkCoeffDeviationEmpContext";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";

const DEFAULT = 2;
const CoeffcientValuesFirstPart = (props) => {
  const { state } = useContext(LinkCoeffEmpContext);
  const { selectedDevaitionObj, valueTypeArray } = state;

  const getTableContent = () => {
    let htmlContent = [];
    selectedDevaitionObj.map((coefficient) => {
      htmlContent.push(
        <tr key={coefficient.value} id={coefficient.value} className="ps-3">
          <td
            rowSpan="1"
            title={coefficient.label}
            className="employee-type-coefficient"
          >
            {coefficient.label}
          </td>
          <td className="employee-type-coefficient">
            <div
              className="poppins-medium"
              style={{
                fontSize:
                  window.innerWidth <= 767
                    ? "3.5vw"
                    : window.innerWidth <= 1023
                    ? "1.95vw"
                    : "0.85vw",
              }}
            >
              {t("Default")}
            </div>
          </td>
        </tr>
      );
    });
    return htmlContent;
  };

  return (
    <table className="table mb-0 defaultCoefficientTableLeftSection table-bordered customTableProperties">
      <thead>
        <tr className="TableHeader">
          <th
            colSpan="2"
            style={{
              height:
                window.innerWidth <= 767
                  ? "3.5vw"
                  : window.innerWidth <= 1023
                  ? "10.99vw"
                  : "5.99vw",
            }}
            className="p-0"
          >
            <div className="firstpart-cell">
              <div
                key={`tablecolindex`}
                scope="col"
                className="text-end poppins-medium pt-4 pe-3"
                style={{
                  fontSize:
                    window.innerWidth <= 767
                      ? "3.5vw"
                      : window.innerWidth <= 1023
                      ? "1.95vw"
                      : "0.9vw",
                }}
              >
                {t("Employee types")}
              </div>
              <hr />
              <div
                key={`tablecolindex2`}
                scope="col"
                style={{
                  fontSize:
                    window.innerWidth <= 767
                      ? "3.5vw"
                      : window.innerWidth <= 1023
                      ? "1.95vw"
                      : "0.9vw",
                }}
                className="poppins-medium pb-3 d-flex align-self-end ps-3"
              >
                {t("Coefficient deviations")}
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="pclinking-table-tbody table-first-part-tbody">
        {getTableContent()}
      </tbody>
    </table>
  );
};

export default translate(CoeffcientValuesFirstPart);
