import React, { useState, useEffect, useMemo } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useNavigate } from "react-router-dom";
import { ApiCall } from "../../../../services/ApiServices";
import { DELETE_COEFFICIENT_PCS } from "../../../../routes/ApiEndpoints";
import Header from "../../../common/layout/Header";
import InputTextfield from "../manageCoefficientDeviations/InputTexfield";
import BackLink from "../../../common/atoms/BackLink";
import ResetBtn from "../../../common/atoms/ResetBtn";
import SearchBtn from "../../../common/atoms/SearchBtn";
import OverFlowWrapper from "../../../common/OverFlowWrapper";
import Pagination from "../../../common/atoms/Pagination";
import Icon from "../../../../Icon";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";
import ConfirmPopup from "../../../common/atoms/ConfirmPopup";
import TableHeaderSpace from "../../../common/atoms/TableHeaderSpace";
import NoRecords from "../../../common/atoms/NoRecords";
import LabelField from "../../../common/atoms/LabelField";
import EditIcon from "../../../common/molecules/Actions/CustomEdit";
import CustomDelete from "../../../common/molecules/Actions/CustomDelete";

const ITEMS_PER_PAGE = 8;

const TableRenderer = ({ headers, rows }) => {
  const [filteredRows, setFilteredRows] = useState(rows);
  const [searchTermName, setSearchTermName] = useState("");
  const [searchTermNumber, setSearchTermNumber] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    setFilteredRows(rows);
  }, [rows]);

  const handleSearch = () => {
    const filtered = rows.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTermName.toLowerCase()) &&
        item.pc_number.toString().includes(searchTermNumber)
    );
    setFilteredRows(filtered);
    setCurrentPage(0);
  };

  const handleReset = () => {
    setSearchTermName("");
    setSearchTermNumber("");
    setFilteredRows(rows);
    setCurrentPage(0);
  };

  const pageCount = Math.ceil(filteredRows.length / ITEMS_PER_PAGE);

  const currentItems = useMemo(() => {
    const offset = currentPage * ITEMS_PER_PAGE;
    return filteredRows.slice(offset, offset + ITEMS_PER_PAGE);
  }, [currentPage, filteredRows]);

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber - 1);
  };

  const handleDelete = async (id) => {
    try {
      const result = await ApiCall.service(
        `${DELETE_COEFFICIENT_PCS}/${id}`,
        "DELETE"
      );
      if (result.success) {
        setFilteredRows((prev) => prev.filter((row) => row.id !== id));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const confirmDelete = () => {
    if (selectedRow) {
      handleDelete(selectedRow.id);
    }
    setShowDeletePopup(false);
    setSelectedRow(null);
  };

  const closeDeletePopup = () => {
    setShowDeletePopup(false);
    setSelectedRow(null);
  };

  const getNeededActions = (eachRow) => (
    <>
      <EditIcon
        handleClick={() =>
          navigate(
            `/manage/coefficient-employee-type-linking/?pcid=${eachRow.id}`
          )
        }
      />
      <CustomDelete
        handleDelete={() => {
          setSelectedRow(eachRow);
          setShowDeletePopup(true);
        }}
      />
    </>
  );

  return (
    <>
      <Header
        headerName={t("Manage coefficients per PC")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <div className="row mb-2">
          <BackLink backLink="/config/settings" />
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Manage coefficients per PC")}
              className="myAccountCardTitle mb-0"
            />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-3 mb-lg-0">
            <InputTextfield
              value={searchTermNumber}
              handleChange={(e) => setSearchTermNumber(e.target.value)}
              placeholder={t("Paritair committee number")}
              onKeyUp={(e) => (e.key === "Enter" ? handleSearch() : null)}
              className="ps-2"
            />
          </div>
          <div className="col-lg-4 col-md-6">
            <InputTextfield
              value={searchTermName}
              handleChange={(e) => setSearchTermName(e.target.value)}
              placeholder={t("Paritair committee name")}
              onKeyUp={(e) => (e.key === "Enter" ? handleSearch() : null)}
              className="ps-2"
            />
          </div>
          <div className="col-lg-4 col-12 mb-3 mb-lg-0">
            <div className="d-flex align-items-center justify-content-md-end justify-content-lg-start">
              <ResetBtn handleResetClick={handleReset} />
              <SearchBtn handleSearchClick={handleSearch} />
            </div>
          </div>
        </div>
      </div>
      <div className="managepagesBg">
        <OverFlowWrapper className="table-responsive height-25">
          <table className="table pwa table-hover customTableProperties">
            <thead>
              <tr className="TableHeader">
                {headers.map((eachHeader, index) => (
                  <th
                    key={`tablecol${index}`}
                    scope="col"
                    style={{ width: "33%" }}
                  >
                    {t(eachHeader)}
                  </th>
                ))}
              </tr>
              <TableHeaderSpace headerLength={headers.length} />
            </thead>
            <tbody>
              {currentItems.length > 0 ? (
                currentItems.map((eachRow) => (
                  <tr key={eachRow.id} className="border">
                    <td data-label="PC number">{eachRow.pc_number}</td>
                    <td data-label="PC name">{eachRow.name}</td>
                    <td className="px-2">{getNeededActions(eachRow)}</td>
                  </tr>
                ))
              ) : (
                <NoRecords headerLength={headers.length} />
              )}
            </tbody>
          </table>
          {pageCount > 1 && (
            <div className="pagination justify-content-center mt-4 px-4">
              <Pagination
                currentPage={currentPage + 1}
                totalPages={pageCount}
                onPageChange={handlePageClick}
              />
            </div>
          )}
        </OverFlowWrapper>

        {showDeletePopup && (
          <ConfirmPopup
            show={showDeletePopup}
            onHide={closeDeletePopup}
            title={t("Delete Coefficient")}
            body={t(`Are you sure you want to delete coefficients per pc?`)}
            confirmTitle={t("Delete")}
            closeTitle={t("Cancel")}
            onConfirmButtonClick={confirmDelete}
            onCloseButtonClick={closeDeletePopup}
          />
        )}
      </div>
    </>
  );
};

export default translate(TableRenderer);
