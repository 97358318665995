import React, { useState, useEffect, useContext } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { ApiCall } from '../../../../services/ApiServices';
import { t, translate } from '../../../CentralDataMangement/translation/Translation';
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from '../../../../Constants';
import { FullScreenLoading } from '../../../common/utlis/FullScreenLoading';
import TableRendererHolidayList from './TableRendererHolidayList';
import { FETCH_ALL_HOLIDAYS } from '../../../../routes/ApiEndpoints';
import OperationHolidaysList from './OperationHolidaysList';


const ManageHolidayList = () => {


    const [state, setState] = useState({ loaded: false, rows: [], headers: [] })
    const location = useLocation();
    const resolvedUrl = location.pathname + location.search;


    // // Extract id from query parameters
    const query = new URLSearchParams(location.search);
    const id = query.get("id");

    // // Determine if the operation is an edit or an add based on the URL
    let editId = id ? parseInt(id) : 0;
    // const edit = (action == 'add' || action == 'edit') ? true : false;
    const edit = location.pathname.includes("edit") || location.pathname.includes("add");


    useEffect(() => {
        loadData();
    }, [id]);


    const loadData = async () => {
        ApiCall.getService(
            `${FETCH_ALL_HOLIDAYS}${editId ? '/' + editId : ''}`,
            "GET",
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
            true
        )
            .then((result) => {
                console.log(result.data, "result");
                setState({
                    ...state,
                    loaded: true,
                    rows: result.status ? result.data : [],
                    headers: ['Date', 'Holiday name', 'Type', 'Actions'],
                    id: editId,
                    edit: edit,
                });
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const isEditingOrAdding = resolvedUrl.includes('edit') || resolvedUrl.includes('add');


    return (
        <>
            <div className='container-fluid p-0 m-0'>
                <div className='md-3 col-md-12'>
                    {state.loaded ? (
                        state.edit ? (
                            <OperationHolidaysList {...state} />
                        ) : (
                            <TableRendererHolidayList {...state} loadData={loadData} />
                        )
                    ) : (
                        <FullScreenLoading />
                    )}
                </div>
            </div>
        </>
    )

}

export default translate(ManageHolidayList);