import { useState, useEffect } from "react";

export const useResponsiveValue = (
  value1: string,
  value2: string,
  value3: string
): string => {
  const [responsiveValue, setResponsiveValue] = useState<string>("");

  useEffect(() => {
    const updateValue = () => {
      const width = window.innerWidth;
      if (width <= 767) {
        setResponsiveValue(value1);
      } else if (width <= 1023) {
        setResponsiveValue(value2);
      } else {
        setResponsiveValue(value3);
      }
    };
    // Initialize the value
    updateValue();
    // Update on resize
    window.addEventListener("resize", updateValue);
    return () => {
      window.removeEventListener("resize", updateValue);
    };
  }, [value1, value2, value3]);

  return responsiveValue || value1; // Ensure this returns a string
};