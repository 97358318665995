import React, { useState, useEffect } from "react";
import {
  validateForm,
  validateRequired,
  validateSelectField,
} from "../../../services/ValidationService";
import Button from "../../common/atoms/Button";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import LabelWithCKEditorField from "../../common/molecules/LabelWithCKEditorField";
import { ApiCall } from "../../../services/ApiServices";
import { useNavigate } from "react-router-dom";
import {
  EMAIL_TEMPLATES,
  GET_APP_LANG,
  MODULES,
  STORE_TEMPLATE,
} from "../../../routes/ApiEndpoints";
import AccessControl from "../../../services/RolesAndPermissions/AccessControl";
import Header from "../../common/layout/Header";
import secureLocalStorage from "react-secure-storage";
import LabelWithSelectField from "../../common/molecules/LabelwithSelectField";
import {
  ABSOLUTE_CORE_APP,
  CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
} from "../../../Constants";
import {
  t,
  setLanguage,
  getLanguage,
  translate,
} from "../translation/Translation";
interface TemplateProps {
  //Properties/ fields and there respective data type in email template form
  name?: string;
  subject?: string;
  body?: string;
  signature?: string;
}

interface OptionProps {
  //fields related to module dropdown and their datatype
  value?: number;
  name?: string;
}

interface ValidationRules {
  [key: string]: Function[];
}

const CreateEmailTemplate: React.FC = () => {
  //component
  const [formData, setFormData] = useState<TemplateProps>({
    //formData with intial values
    name: "",
    subject: "",
    body: "",
    signature: "",
  });
  const navigate = useNavigate();
  const [errors, setErrors] = useState<TemplateProps>({}); //to maintain errors
  const [optionList, setOptionList] = useState<OptionProps[]>([]); //module dropdown values
  const appLang = secureLocalStorage.getItem("site_lang");

  const validation = (
    //validation function related field validations
    name: string,
    value: string | boolean,
    isSingleFieldValidation: boolean = false
  ) => {
    const validationRules: ValidationRules = {
      name: [validateRequired],
      subject: [validateRequired],
      body: [validateRequired],
      // signature: [validateRequired],
    };

    const validationErrors = validateForm(
      { ...formData, [name]: value },
      validationRules,
      isSingleFieldValidation ? name : undefined
    );

    if (isSingleFieldValidation && Object.keys(errors).length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validationErrors[name],
      }));
    } else {
      setErrors(validationErrors);
    }

    if (Object.keys(validationErrors).length > 0) {
      return false;
    }

    return true;
  };

  const changeHandler = (
    event: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    const { name, value } = event.target as HTMLInputElement;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    validation(name, value, true);
  };

  const handleCKEditorChange = (event: any, editor: any, fieldName: string) => {
    const content = editor.getData(); // Get the content from the editor
    const name = fieldName; // Use the fieldName parameter
    setFormData((prevData) => ({ ...prevData, [name]: content }));
    validation(name, content, true);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const { name, value } = e.target as HTMLInputElement;
    if (validation(name, value)) {
      const response = await ApiCall.service(
        EMAIL_TEMPLATES,
        "POST",
        formData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      ); //api call to store formdata
      if (response.status === 200) {
        navigate("/manage-email-templates");
      }
    }
  };

  return (
    <AccessControl
      requiredPermissions={[
        {
          permission: "Email templates",
          create: true,
        },
      ]}
      renderNoAccess={true}
    >
      <Header
        headerName={t("Create email template")}
        headerClassName="myAccountCardTitle"
      />
      <form onSubmit={handleSubmit}>
        <div className="contentBg">
          <div className="row">
            <div className="col-lg-6 col-md-12 mb-3 mb-lg-0">
              {/* Name field */}
              <LabelWithInputField
                isMandatory={true}
                name="name"
                handleChange={changeHandler}
                value={formData.name}
                id="name"
                label={t("Name")}
                placeholder={t("Name")}
                type="text"
                error={errors.name}
                className="inputFieldColor"
              />
            </div>
            {/* Subject field */}
            <div className="col-lg-6 col-md-12">
              <LabelWithInputField
                isMandatory={true}
                name="subject"
                handleChange={changeHandler}
                value={formData.subject}
                id="subject"
                label={t("Subject")}
                placeholder={t("Subject")}
                type="text"
                error={errors.subject}
                className="inputFieldColor"
              />
            </div>
            <div className="col-md-12 mt-3">
              {/* Body field */}
              <LabelWithCKEditorField
                label={t("Body")}
                name="body"
                value={formData.body}
                placeholder={t("Type here") + "..."}
                handleChange={(event, editor) =>
                  handleCKEditorChange(event, editor, "body")
                }
                isMandatory={true}
                error={errors.body}
              />
            </div>
            {/* <div className="col-md-12 mt-3">
              {/* Signature field */}
            {/* <LabelWithCKEditorField */}
            {/* //  / label={t("Signature")} */}
            {/* // /  name="signature" */}
            {/* //   value={formData.signature} */}
            {/* //   placeholder={t("Type here") + "..."} */}
            {/* //   handleChange={(event, editor) => */}
            {/* //     handleCKEditorChange(event, editor, "signature") */}
            {/* //  / } */}
            {/* //   isMandatory={true} */}
            {/* //   error={errors.signature} */}
            {/* // /> */}
            {/* </div> */}
          </div>
          <div className="row pt-4">
            <div className="col-md-12 text-end text">
              <Button
                title={t("Create email template")}
                type="submit"
                className="searchBtn px-3"
              />
            </div>
          </div>
        </div>
      </form>
    </AccessControl >
  );
};

export default translate(CreateEmailTemplate);
