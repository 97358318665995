import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { FaInfoCircle } from 'react-icons/fa'
import StatusCircle from '../../atoms/StatusCircle'

interface props {
  status?: any;
}
const CustomOverlayTrigger: React.FC<props> = ({
  status
}) => {
  return (
    <td className='p-0 position-relative'>
      <OverlayTrigger
        overlay={
          <Tooltip className="planningDayTooltip">
            {status}
          </Tooltip>
        }
        placement="top"
        trigger="click" // Trigger the tooltip on click
        rootClose // Close the tooltip when clicking outside
      >
        <div className="p-0">
          <StatusCircle />
          <span className="mt-4 ms-2">
            <FaInfoCircle />
          </span>
        </div>
      </OverlayTrigger>
    </td>
  )
}

export default CustomOverlayTrigger