import React, { MouseEventHandler, useEffect, useRef, useState } from "react";
import Image from "../atoms/Image";
import Icon from "../../../Icon";
import UserImage from "../../../static/images/User.svg";
import { selectAuth } from "../../../features/auth/AuthSlice";
import { useSelector } from "react-redux";
import LabelField from "../atoms/LabelField";
import { t } from "../../CentralDataMangement/translation/Translation";
import defaultCompanyIcon from "../../../static/images/emptyCompany.png";
import { useResponsiveValue } from "../atoms/ResponsivWidth";
import StatusCircle from "../atoms/StatusCircle";
import TextEllipsis from "./TextEllipsis";
interface Employee {
  id: number;
  first_name?: string;
  last_name?: string;
  is_fav?: boolean;
  profile_picture_url: string | null;
  employee_type?: string;
  employee_type_id?: number;
  employee_type_category?: string;
  employee_type_category_id?: number;
  paritaire_commitee?: string;
  paritaire_commitee_id?: number;
  functional_salary?: string;
  function_id?: number;
  function_name?: string;
  locations: [];
  name: string;
  email?: string;
  official_name: string;
  projects: [];
}

interface CardConfig {
  cardClassName: string;
  employeeInformationClass: string;
  cardMainClassName: string;
  iconClassName?: string;
  isCardIcon?: boolean;
  iconName?: string;
  favouriteIconName: string;
  noFavouriteIconName: string; // Add this for the "unfavorited" state
  isFavourite?: boolean;
  isRelatedCompany?: boolean;
  employeeFavClick?: () => Promise<void>;
  status?: any;
  iconWrapperClassName?: string;
}

interface Company {
  id: number;
  name: string;
  official_name: string;
  profile_picture_url: string;
  locations: [];
  projects: [];
}

interface EmployeeDetailsCardProps {
  employee_data: any;
  cardConfig: CardConfig;
  handleClick?: MouseEventHandler<HTMLButtonElement>;
  handleFavIcon?: MouseEventHandler<HTMLButtonElement>;
  favouriteIconClassName?: string;
  Company?: Company[];
}

const EmployeeDetailsCard: React.FC<EmployeeDetailsCardProps> = ({
  employee_data,
  cardConfig,
  handleClick,
  handleFavIcon,
  favouriteIconClassName,
  Company,
}) => {
  const { profile_picture_url } = employee_data;
  const {
    cardClassName,
    employeeInformationClass,
    cardMainClassName,
    iconClassName,
    isCardIcon,
    iconName,
    favouriteIconName,
    noFavouriteIconName, // Add unfavorited icon
    isFavourite,
    isRelatedCompany,
    status,
    iconWrapperClassName
  } = cardConfig;
  const EditIconStyle = useResponsiveValue("5vw", "2.5vw", "1vw");
  // Optionally, you can use this for conditional rendering based on the user
  const currentUser = useSelector(selectAuth);
  const cardRef = useRef<HTMLDivElement>(null);
  const [tooltipPosition, setTooltipPosition] = useState<string>("bottom");
  useEffect(() => {
    if (cardRef.current) {
      const { top, bottom } = cardRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - bottom;
      const spaceAbove = top;

      if (spaceBelow < 200 && spaceAbove > 200) {
        setTooltipPosition("top");
      } else {
        setTooltipPosition("bottom");
      }
    }
  }, []);
  return (
    <div className={`${cardMainClassName} ${tooltipPosition}`} ref={cardRef}>
      {isCardIcon && (
        <div className={`${iconClassName} ${iconWrapperClassName}`}>
          < >
            <span onClick={handleClick}>
              <Icon isIcon={true} width={EditIconStyle} height={EditIconStyle} name={iconName} />
            </span>
          </>
        </div>
      )}
      <div className={`${cardClassName}`}>
        {handleFavIcon && (
          <div className={favouriteIconClassName}>
            <span onClick={handleFavIcon}>
              <Icon
                className="cursor-pointer"
                isIcon={true}
                width={EditIconStyle}
                height={EditIconStyle}
                name={isFavourite ? favouriteIconName : noFavouriteIconName} // Toggle between icons
              />
            </span>
          </div>
        )}
        <div
          className={`${isRelatedCompany ? "me-3" : "mx-3 employeeCardIcon"}`}
        >
          <Image
            src={profile_picture_url || UserImage} // Default image if none provided
            imageWidth="3vw"
            imageHeight="3vw"
            imageBorderRadius="50%"
          />
        </div>
        <div className={employeeInformationClass}>
          <div>
            <TextEllipsis title={employee_data?.first_name + " " + employee_data?.last_name} label={employee_data?.first_name + " " + employee_data?.last_name} width="10vw" />
          </div>
          <div className="empDetails">
            <div><TextEllipsis title={employee_data?.employee_type} label={employee_data?.employee_type} width="10vw" /></div>
            <div><TextEllipsis title={employee_data?.function_name} label={employee_data?.function_name} width="11vw" /></div>
            <div>
              {employee_data?.paritaire_commitee
                ? employee_data.paritaire_commitee
                  .match(/\b\d+(\.\d+)*\b/g)
                  ?.map((num: string) => `PC${num}`)
                  .join(', ') || ''
                : ''}
            </div>
            <div>€{employee_data?.functional_salary}</div>
            <div><TextEllipsis title={employee_data?.email} label={employee_data?.email} width="11wvw" /></div>
          </div>

          <div style={{ height: "1.25vw" }}>
            <div style={{ color: status?.color, textAlign: "end" }} className="poolEmployeeStatus float-end" title={status?.label === 'Inactive' && t("Archive by Employer")}>{status?.label}</div>
          </div>
        </div>
      </div>
      {isRelatedCompany && (
        <>
          <div className="adminCardDetails mt-2">
            <span>{t("Administrator since") + ":"}</span>
            <span className="ms-2 colorDarkPurple">
              {/* {new Date(employee_data?.admin_from).toLocaleDateString("en-GB")} */}
              {new Date(employee_data?.admin_from)
                .toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
                .replace(/\//g, "-")}
            </span>
          </div>
          {Company && Company?.length > 0 && (
            <div>
              <div className="related-company-data adminCardDetails scrollBar">
                <div className="managerOf">
                  <LabelField title={t("Administrator of") + ":"} />
                </div>
                {Company.map((company) => (
                  <div className="mb-2 d-block me-2" key={company.id}>
                    <div className="d-flex align-items-center">
                      <div style={{
                        minWidth: window.innerWidth <= 767
                          ? "3.5vw"
                          : window.innerWidth <= 1023
                            ? "5.5vw"
                            : "3.5vw"
                      }}>
                        <Image
                          src={
                            company.profile_picture_url || defaultCompanyIcon
                          } // Default image if none provided
                          imageWidth={window.innerWidth <= 767
                            ? "3.5vw"
                            : window.innerWidth <= 1023
                              ? "4.5vw"
                              : "2vw"}
                          imageHeight={window.innerWidth <= 767
                            ? "3.5vw"
                            : window.innerWidth <= 1023
                              ? "4.5vw"
                              : "2vw"}
                          imageBorderRadius="50%"
                        />
                      </div>
                      <div className="flex-1">
                        <LabelField title={company.name} />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EmployeeDetailsCard;
