import React, { useState, useEffect } from "react";
import Icon from "../../Icon";
import DateAndTime from "../common/atoms/DateWithTime";
import TimePicker from "../common/atoms/TimePicker";
// import { t } from "../common/atoms/translation";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import InputTextfield from "../common/atoms/InputTextField";
import { addConvertDecimalToTime } from "../common/utlis/dateHelper";
import CheckBoxField from "../common/atoms/CheckBoxField";
import { formatTimeInput, calculateShiftsTotalTime } from "./Services/PlanningTimingHelper";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";

interface Props {
  showLastAction?: boolean;
  isTimeRegistration?: boolean;
  category?: "open" | "closed";
  activeEmployee?: any;
  schedule?: any;
  date?: string;
  shiftIndex: number;
  onEncodageChange: (updatedEncodage: any) => void;
  onCheckboxChange: (employee_id: number, date: string) => void;
  selectedDates: string[];
}

const ShiftTiming: React.FC<Props> = ({
  showLastAction = false,
  isTimeRegistration = false,
  category = "open",
  activeEmployee,
  schedule,
  date,
  shiftIndex,
  onEncodageChange,
  onCheckboxChange,
  selectedDates = [] // Default to empty array if not provided


}) => {

  const iconStyle = useResponsiveValue("3vw", "2.5vw", "0.8vw");
  const IconProps = {
    width: iconStyle,
    height: iconStyle,
    isIcon: true,
  };

  const [errorStartTime, setErrorStartTime] = useState<string>("");
  const [errorStartTime2, setErrorStartTime2] = useState<string>("");

  const [errorEndTime, setErrorEndTime] = useState<string>("");
  const [errorEndTime2, setErrorEndTime2] = useState<string>("");


  const handleChange = (field: string, value: string, index: number) => {
    const formattedValue = formatTimeInput(value);

    const updatedEncodage = schedule.encodage.map((enc: any, encIndex: number) =>
      encIndex === index ? { ...enc, [field]: formattedValue } : enc
    );
    onEncodageChange(updatedEncodage);
  };
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (date) {
      onCheckboxChange(activeEmployee.employee_id, date); // Pass employee ID and date to parent
    }
  };

  const shiftCells = (index: number, typeIndex: number, type: string, data: any) => {
    const categories = ["Planned hours", "Clocked-in hours", "Effective hours worked"];
    return (
      <>
        {window.innerWidth <= 1023 &&
          <tr className="mt-3">
            <td
              key={category}
              colSpan={3}
              className="text-center categorySection categorySectionPwa px-0"
            >
              <div className="d-flex align-items-center justify-content-center">
                <Icon name="lockIcon" {...IconProps} fillColor="#87abb8" />
                <span className="ms-2 mobileFont">{t(categories[typeIndex])}</span>
              </div>
            </td>
          </tr>
        }
        <td
          key={`shift1-${0}`}
          className="bodyBg align-middle"
          data-label={t("Shift") + " 1"}
        >
          <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex">
              <InputTextfield
                value={data?.[0]?.start_time}
                className={`form-control employeeDailyOverviewTable ${typeIndex === 2 && errorStartTime ? "border border-danger" : ""}`}
                handleChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^([01]?\d|2[0-3])?:?([0-5]?\d)?$/.test(inputValue)) {
                    setErrorStartTime("");
                    handleChange("start_time", inputValue, 0);
                  } else {
                    setErrorStartTime(t("Invalid time format. Please use HH:MM format."));
                  }
                }}
                isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
              />
              {typeIndex === 2 && errorStartTime && (
                <div className="text-danger ms-2 helpDangerIcon" title={errorStartTime}>
                  <div className="d-none d-lg-block">
                    <Icon isIcon={true} width={"0.8vw"} height={"0.8vw"} name="infoIcon" />
                  </div>
                </div>
              )}
            </div>

            <div className="mx-1">{" - "}</div>

            <div className="d-flex">
              <InputTextfield
                value={data?.[0]?.end_time}
                className={`form-control employeeDailyOverviewTable ${typeIndex === 2 && errorEndTime ? "border border-danger" : ""}`}
                handleChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^([01]?\d|2[0-3])?:?([0-5]?\d)?$/.test(inputValue)) {
                    setErrorEndTime("");
                    handleChange("end_time", inputValue, 0);
                  } else {
                    setErrorEndTime(t("Invalid time format. Please use HH:MM format."));
                  }
                }}
                isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
              />
              {typeIndex === 2 && errorEndTime && (
                <div className="text-danger ms-2 helpDangerIcon" title={errorEndTime}>
                  <div className="d-none d-lg-block">
                    <Icon isIcon={true} width={"0.8vw"} height={"0.8vw"} name="infoIcon" />
                  </div>
                </div>
              )}
            </div>
          </div>
          {typeIndex === 2 && errorEndTime && (
            <div className="d-block d-lg-none text-danger">
              {errorEndTime}
            </div>
          )}
          {typeIndex === 2 && errorStartTime && (
            <div className="d-block d-lg-none text-danger">
              {errorStartTime}
            </div>
          )}
        </td>

        <td
          key={`shift2-${1}`}
          className="bodyBg align-middle"
          data-label={t("Shift") + " 2"}
        >
          <div className="d-flex align-items-center justify-content-center">
            <div className="d-flex">
              <InputTextfield
                value={data?.[1]?.start_time}
                className={`form-control employeeDailyOverviewTable ${typeIndex === 2 && errorStartTime2 ? "border border-danger" : ""}`}
                handleChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^([01]?\d|2[0-3])?:?([0-5]?\d)?$/.test(inputValue)) {
                    setErrorStartTime2("");
                    handleChange("start_time", inputValue, 1);
                  } else {
                    setErrorStartTime2(t("Invalid time format. Please use HH:MM format."));
                  }
                }}
                isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
              />
              {typeIndex === 2 && errorStartTime2 && (
                <div className="text-danger ms-2 helpDangerIcon" title={errorStartTime2}>
                  <div className="d-none d-lg-block">
                    <Icon isIcon={true} width={"0.8vw"} height={"0.8vw"} name="infoIcon" />
                  </div>
                </div>
              )}
            </div>

            <div className="mx-1">{" - "}</div>

            <div className="d-lg-flex">
              <InputTextfield
                value={data?.[1]?.end_time}
                className={`form-control employeeDailyOverviewTable ${typeIndex === 2 && errorEndTime2 ? "border border-danger" : ""}`}
                handleChange={(e) => {
                  const inputValue = e.target.value;
                  if (/^([01]?\d|2[0-3])?:?([0-5]?\d)?$/.test(inputValue)) {
                    setErrorEndTime2("");
                    handleChange("end_time", inputValue, 1);
                  } else {
                    setErrorEndTime2(t("Invalid time format. Please use HH:MM format."));
                  }
                }}
                isDisabled={typeIndex === 0 || typeIndex === 1 || category === 'closed'}
              />
              {typeIndex === 2 && errorEndTime2 && (
                <div className="text-danger ms-lg-2 helpDangerIcon" title={errorEndTime2}>
                  <div className="d-none d-lg-block">
                    <Icon isIcon={true} width={"0.8vw"} height={"0.8vw"} name="infoIcon" />
                  </div>
                </div>
              )}
            </div>
          </div>
          {typeIndex === 2 && errorStartTime2 && (
            <div className="d-block d-lg-none text-danger">
              {errorStartTime2}
            </div>
          )}
          {typeIndex === 2 && errorEndTime2 && (
            <div className="d-block d-lg-none text-danger">
              {errorEndTime2}
            </div>
          )}
        </td>

        <td
          key={`total-${index}`}
          className="bodyBg align-middle"
          data-label={t("Total")}
        >
          <div className="totalHours text-white poppins-medium">
            {(typeIndex === 0 || typeIndex === 1) &&
              addConvertDecimalToTime(
                data?.[0]?.total_count || "0",
                data?.[1]?.total_count || "0"
              )}
            {typeIndex === 2 && calculateShiftsTotalTime(schedule.encodage)}
          </div>
        </td>
      </>
    );

  };




  return (
    <table
      className={`${isTimeRegistration ? "timeRegistrationTable" : "shiftTimingTable"
        } table table-borderless mb-0 pwa`}
    >
      <thead>
        {isTimeRegistration ? (
          <>
            <tr>
              <th></th>
              {[
                "Planned hours",
                "Clocked-in hours",
                "Effective hours worked",
              ].map((category) => (
                <th
                  key={category}
                  colSpan={3}
                  className="text-center categorySection"
                >
                  <div className="d-flex align-items-center justify-content-center">
                    <Icon name="lockIcon" {...IconProps} fillColor="#87abb8" />
                    <span className="ms-2">{t(category)}</span>
                  </div>
                </th>
              ))}
              <th></th>
            </tr>
            <tr>
              <th></th>
              {[...Array(3)].map((_, index) => (
                <React.Fragment key={index}>
                  <th className="text-center shiftNumber">
                    {t("Shift") + " 1"}
                  </th>
                  <th className="text-center shiftNumber">
                    {t("Shift") + " 2"}
                  </th>
                  <th className="text-start text-center shiftNumber">
                    {t("Total")}
                  </th>
                </React.Fragment>
              ))}
              <th></th>
            </tr>
          </>
        ) : (
          <tr>
            <th></th>
            <th>{t("Shift") + " 1"}</th>
            <th>{t("Shift3") + " 2"}</th>
            <th className="text-start">{t("Total")}</th>
            <th></th>
          </tr>
        )}
      </thead>
      <tbody>
        {isTimeRegistration &&

          <React.Fragment key={shiftIndex}>
            <tr className="spaced-row align-middle">
              <td className="p-0 timeRegistrationDate pe-2" style={{ whiteSpace: "nowrap" }}>{date}</td>
              {shiftCells(shiftIndex, 0, category, schedule?.schedule)}
              {shiftCells(shiftIndex, 1, category, schedule?.time_registraion)}
              {shiftCells(shiftIndex, 2, category, schedule?.encodage)}
              {category === 'open' && (
                <td className="px-2">
                  {window.innerWidth > 1023 ? (
                    <CheckBoxField
                      name="validate"
                      isChecked={date ? selectedDates.includes(date) : false}
                      onChangeHandler={handleCheckboxChange}
                    />
                  ) : (
                    <div
                      className="searchBtn d-flex align-items-center justify-content-center w-75 mx-auto"
                      onClick={() => {
                        if (date) {
                          handleCheckboxChange({
                            target: { checked: !selectedDates.includes(date) }
                          } as React.ChangeEvent<HTMLInputElement>);
                        }
                      }}
                    >
                      {t("Validate")}
                    </div>
                  )}
                </td>
              )}

            </tr>
            {window.innerWidth > 1023 && <TableHeaderSpace headerLength={`${isTimeRegistration ? 11 : 5}`} />}
          </React.Fragment>
        }

      </tbody>
    </table>
  );
};

export default translate(ShiftTiming);
