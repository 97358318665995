import React, { useRef, useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { BsZoomIn, BsZoomOut, BsDownload, BsPrinter } from 'react-icons/bs';
import '@react-pdf-viewer/core/lib/styles/index.css';
import Button from '../atoms/Button';
import DownloadFile from './DownloadFile';
import PrintFile from './PrintFile';
import { MdClose } from 'react-icons/md';

interface PdfViewerProps {
  fileUrl: string;
  setShowPdf: any | null;
  fileNumber: string | number | null;
  fileType?: string;
}

const PdfViewer: React.FC<PdfViewerProps> = ({ fileUrl, setShowPdf, fileNumber, fileType }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const [scale, setScale] = useState(1);

  const closePdf = () => {
    setShowPdf(null);
  };

  const handleZoomIn = () => {
    setScale((prevScale) => prevScale + 0.05);
  };

  const handleZoomOut = () => {
    if (scale > 0.5) setScale((prevScale) => prevScale - 0.05);
  };

  return (
    <>
      <div className="pdf-viewer-wrapper relative" ref={wrapperRef}>
        <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
          <Button
            className="close-btn bg-transparent border-0 position-fixed border-0 fs-1"
            handleClick={closePdf}
          ><MdClose className='fs-2' /></Button>
          <div style={{ transform: `scale(${scale})`, transformOrigin: 'top left' }}>
            <Viewer fileUrl={fileUrl} />
          </div>
        </Worker>

        <div className="pdf-controls d-flex align-items-center p-2 rounded-pill position-fixed">
          <Button
            handleClick={handleZoomIn}
            className="icon-btn btn btn-link text-white p-1 fs-6"
            title={<BsZoomIn />}
          />
          <Button
            handleClick={handleZoomOut}
            className="icon-btn btn btn-link text-white p-1 fs-6"
            title={<BsZoomOut />}
          />
          <span className='text-white mx-2'>|</span>
          <DownloadFile fileUrl={fileUrl} fileNumber={fileNumber} fileType={fileType} />
          <PrintFile fileUrl={fileUrl} />
        </div>
      </div>
    </>
  );
};

export default PdfViewer;
