import React, { useEffect, useState } from "react";
import ModalPopup from "../common/atoms/ModalPopup";
import Title from "../common/atoms/Title";
import SelectedEmployeesNameCard from "./SelectedEmployeesNameCard";
import "./css/planning.css";
import { useDispatch } from "react-redux";
import { resetAllData, selectCompanyId, selectEmployees, setTab } from "../../features/planning/planningSlice";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { useSelector } from "react-redux";
import EmpployeeShiftTable from "./EmpployeeShiftTable";
import { ApiCall } from "../../services/ApiServices";
import { CREATE_PLANNING } from "../../routes/ApiEndpoints";
import {  SUCCESS } from "../../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { handleValidationErrors } from "./Validations/PlanningValidations";
import PlanningEmployeeDetailsCard from "../common/molecules/PlanningEmployeeDetailsCard";
import CustomNotify from "../common/atoms/CustomNotify";
import { selectAuth } from "../../features/auth/AuthSlice";

const PlanningOverview = () => {
  const [showModal, setShowModal] = useState(true);
  const [currentEmployeeIndex, setCurrentEmployeeIndex] = useState<number | null>(0);
  const selectedEmployees = useSelector(selectEmployees);
  const companyId = useSelector(selectCompanyId);
  const [disableBtn, setDisableBtn] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const loc = useLocation();
  const authData = useSelector(selectAuth);

  const normalizeEmployeeData = (employee: any) => {
    if (!employee) return {};
    return {
      ...employee,
      salary: parseFloat(employee.salary?.toString().replace(",", ".") || "0"),
      new_salary: parseFloat(employee.new_salary?.toString().replace(",", ".") || "0"),
    };
  };
  const [activeEmployee, setActiveEmployee] = useState(normalizeEmployeeData(selectedEmployees[0]));


  useEffect(() => {
    if (currentEmployeeIndex !== null) {
      setActiveEmployee(normalizeEmployeeData(selectedEmployees[currentEmployeeIndex]));
    } else {
      setActiveEmployee(normalizeEmployeeData(selectedEmployees[0]));
    }
  }, [currentEmployeeIndex, selectedEmployees]);

  const handleCloseModal = () => {
    setShowModal(false);
    dispatch(resetAllData());
    window.location.reload(); // Reloads the page when the modal is closed
  };

  const handleBackClick = () => {
    dispatch(setTab('tab_2'));
  };

  const handlePlanningPost = async () => {
    try {
      setDisableBtn(true);

      const normalizedEmployees = selectedEmployees.map(normalizeEmployeeData);
      const dataObj = {
        company_id: companyId,
        user_id: authData?.userId,
        planning: normalizedEmployees,
      };

      const response = await ApiCall.service(CREATE_PLANNING, "POST", dataObj, false);

      if (response.status == SUCCESS) {
        CustomNotify({ type: 'success', message: t(response.message), autoClose: 2500 });
        handleCloseModal();
      } else if (response.status == "planning-validation-error") {
        setDisableBtn(false);
        CustomNotify({ type: 'error', message: t("something went wrong."), autoClose: 2500 });
        handleValidationErrors(response, dispatch);
      }
    } catch (error) {
      setDisableBtn(false);
      console.error('Error making API call:', error);
    }
  };

  return (
    <>
      <ModalPopup
        modalTitle={t("Planning overview")}
        show={true}
        handleClose={handleCloseModal}
        modalBackgroundColor="#8571b9"
        className="planningModal planningOverviewModal"
        buttonText={t("Send all")}
        onNextClick={handlePlanningPost}
        showBackIcon={true}
        handleBack={handleBackClick}
        disableBack={disableBtn}
        disableNext={disableBtn}
      >
        <Title
          title={t("Overview of the entered planning") + ":"}
          className="bodyTitle colorPrimary mb-2"
        />
        <SelectedEmployeesNameCard
          currentEmployeeIndex={currentEmployeeIndex}
          onEmployeeSelect={(index: number) => setCurrentEmployeeIndex(index)}
        />
        <div className="row mb-3">
          <div className="col-lg-3 align-self-center mb-3 mb-lg-0">
            <PlanningEmployeeDetailsCard
              cardClassName="employeeDetailsCardPlanning colorPrimary text-break text-start d-flex align-items-center justify-content-center"
              employeeInformationClass="planningEmployeeDetails w-75"
              employeeName={activeEmployee.employee_name}
              employeeEmpType={activeEmployee.employee_type}
              employeeLocation={activeEmployee.location}
              employeeFunction={activeEmployee.function}
              employeePC={activeEmployee.pc}
              employeeSalary={activeEmployee.new_salary || activeEmployee.salary}
              employeeImage={activeEmployee.image}
            />
          </div>
          <div className="col-lg-9 align-self-center">
            <EmpployeeShiftTable
              onEmployeeSelected={activeEmployee}
              page={'overview'}
            />
          </div>
        </div>
      </ModalPopup>
    </>
  );
};

export default translate(PlanningOverview);


