import React, { useEffect, useRef, useState } from "react";
import { Overlay, Tooltip } from "react-bootstrap";
import Icon from "../../Icon";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import OverFlowWrapper from "../common/OverFlowWrapper";
import projectsData from './Projects.json';
import { Calendar, DateObject } from "react-multi-date-picker";
import { formatTimeInput } from "./Services/PlanningTimingHelper";
import { formatDateTime, updateDates } from "../common/utlis/dateHelper";
import LabelField from "../common/atoms/LabelField";
import NoRecords from "../common/atoms/NoRecords";
import { GET_COST_CENTER_BY_COMPANY, GET_PROJECTS_BY_COMPANY } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { useSelector } from "react-redux";
import { selectCompanyId } from "../../features/planning/planningSlice";
import { Link } from "react-router-dom";
import { ADD_COSTCENTER, ADD_PROJECT } from "../../routes/RouteConstants";


interface OverlayTooltipProps {
  children: React.ReactNode;
  tooltipText: string;
  selectedProject?: (projectId: number, type: number, field: string) => void;
  selectedCloneDates?: (dates: string[]) => void;
  placement?: "top" | "right" | "bottom" | "left";
  selectedProjectId?: number
  selectedCcId?: number
  cloneDates: string[],

}

const OverlayTooltip: React.FC<OverlayTooltipProps> = ({
  children,
  tooltipText,
  selectedProject,
  selectedCloneDates,
  placement = "right",
  selectedProjectId,
  selectedCcId,
  cloneDates,
}) => {
  const companyId = useSelector(selectCompanyId);
  const [showTooltip, setShowTooltip] = useState(false);
  const [type, setType] = useState<string | null>(tooltipText);
  const [dropData, setDropData] = useState<any>([]);
  const target = useRef<HTMLSpanElement | null>(null);
  const [selectedDates, setSelectedDates] = useState<string[]>([]);
  const [updatedDates, setUpdatedDates] = useState<string[]>([]); // New state

  useEffect(() => {
    if (showTooltip == false) {
      setUpdatedDates([]);
    }

    type && fetchdataByType(type);
    setShowTooltip(showTooltip)
  }, [showTooltip, type]);

  const handleOnClick = (project: any, type: number, field: string) => {
    if (selectedProject != undefined) {
      selectedProject(project.id, type, field); // Pass project ID and type to the parent
    }
  };
  const handleDateSelect = (date: string) => {
    setSelectedDates((prevDates) => {
      const newDates = prevDates.includes(date)
        ? prevDates.filter(d => d !== date)
        : [...prevDates, date];

      if (selectedCloneDates) {
        selectedCloneDates(newDates);
      }
      return newDates;
    });
    // Update updatedDates state
    setUpdatedDates((prevUpdatedDates) => {
      return prevUpdatedDates?.includes(date)
        ? prevUpdatedDates?.filter(d => d !== date)
        : [...prevUpdatedDates, date];
    });
  };

  const handleSelectAll = () => {
    const isAllSelected = updatedDates?.length === cloneDates.length;
    // Update all dates as updated when "Update all" is clicked
    setUpdatedDates(isAllSelected ? [] : cloneDates);
    const newSelectedDates = cloneDates;
    setSelectedDates(newSelectedDates);
    if (selectedCloneDates && updatedDates) {
      selectedCloneDates(newSelectedDates);
    }
  };
  const fetchdataByType = async (type: string) => {
    const endPoint = getUrlEndPointByType(type);
    if (endPoint != "") {

      const response = await ApiCall.service(endPoint, "POST", { company_id: companyId }, false);
      if (response && response?.status == 'success') {
        setDropData(response?.data);
      }
    }

  }

  const getUrlEndPointByType = ($type: string) => {
    let url = "";
    switch ($type) {
      case "Projects":
        url = GET_PROJECTS_BY_COMPANY;
        break;
      case "Cost center":
        url = GET_COST_CENTER_BY_COMPANY;
        break;
      case "Shifts":
        url = "";
        break;
      default:
        break;
    }
    return url;
  }

  const renderTooltipContent = () => {
    switch (type) {
      case "Projects":
        return (
          <>
            <>
              {dropData?.length > 0 && dropData?.map((project: any) => (
                <div className={`bg-white rounded-3 p-1 ps-3 mb-2 ${selectedProjectId === project?.id ? "employee-selected" : ""}`}>
                  <div className="row">
                    <div className="col-8 text-start text-break">
                      <span className="locationName">{project.name}</span>
                    </div>
                    <div className="col-4">
                      <div className="d-inline-flex align-items-center">
                        <button type="button" title={t("Only for selected day.")} className="locationCountBg me-2" onClick={() => handleOnClick(project, 1, "project_id")} >1</button>
                        <button type="button" title={t("For all days.")} className="locationCountBg" onClick={() => handleOnClick(project, 2, 'project_id')}>{t('All')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
            <div className="bg-white rounded-3 p-1">
              <div className="row">
                <div className="col-8 text-center">
                  <Link
                    to={ADD_PROJECT}
                    target="_blank"
                    className="text-center secondaryColorHoverEffect"
                    title={t("Add project")}
                  >
                    <Icon isIcon={true} width="1vw" height="1vw" name="add" />
                  </Link> 
                </div>
              </div>
            </div>
          </>
        );
      case "Cost center":
        return (
          <>
            {dropData?.length > 0 && dropData?.map((item: any) => (
              <div className={`bg-white rounded-3 p-1 ps-3 mb-2 ${selectedCcId === item?.id ? "employee-selected" : ""}`}>
                <div className="row">
                  <div className="col-8 text-start text-break">
                    <span className="locationName">{t(item?.name)}</span>
                  </div>
                  <div className="col-4">
                    <div className="d-inline-flex align-items-center">
                      <button type="button" title={t("Only for selected day.")} className="locationCountBg me-2" onClick={() => handleOnClick(item, 1, 'cost_center_id')} >1</button>
                      <button type="button" title={t("For all days.")} className="locationCountBg" onClick={() => handleOnClick(item, 2, 'cost_center_id')}>{t('All')}</button>
                    </div>
                  </div>
                </div>
              </div>

            ))}
            <div className="bg-white rounded-3 p-1">
              <div className="row">
                <div className="col-8 text-center">
                  <Link
                    to={ADD_COSTCENTER}
                    target="_blank"
                    className="text-center secondaryColorHoverEffect"
                    title={t("Add Cost center")}
                  >
                    <Icon isIcon={true} width="1vw" height="1vw" name="add" />
                  </Link>
                </div>
                {/* <div className="col-4">
                  <div className="d-inline-flex align-items-center">
                    <div className="locationCountBg me-2">1</div>
                    <div className="locationCountBg">{t('All')}</div>
                  </div>
                </div> */}
              </div>
            </div>
          </>
        );
      case "Clone":
        return (
          renderCloneContent()
        );
      case "Shifts":
        return (
          <>
            {dropData?.length > 0 && dropData?.map((item: any) => (
              <div className={`bg-white rounded-3 p-1 ps-3 mb-2`}>
                <div className="row">
                  <div className="col-8 text-start text-break">
                    <span className="locationName">{item?.name}</span>
                  </div>
                  <div className="col-4">
                    <div className="d-inline-flex align-items-center">
                      {/* <button type="button" title={t("Only for selected day.")} className="locationCountBg me-2" onClick={() => handleOnClick(project, 1)} >1</button>
                        <button type="button" title={t("For all days.")} className="locationCountBg" onClick={() => handleOnClick(project, 2)}>{t('All')}</button> */}
                    </div>
                  </div>
                </div>
              </div>
            ))
            }
          </>
        );

      default:
        return <NoRecords isDiv={true} message={t("No data available")} />;
    }
  };
  const renderCloneContent = () => (
    <>
      <div className="d-flex justify-content-between mb-2 align-items-center">
        <LabelField title={cloneDates?.length > 0 ? t("Select dates to clone:") : t("Not found any dates without timings.")} />
        {cloneDates.length > 0 &&
          <button
            className="searchBtn ms-2 px-1"
            onClick={handleSelectAll}
          >
            {t("Update all")}
          </button>
        }
      </div>
      {cloneDates
        .slice()
        .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
        .map((date) => (
          <div key={date} className="bg-white rounded-3 p-2 mb-2">
            <div className="d-flex justify-content-between align-items-center">
              <span className="locationName mobileFont">{formatDateTime(new Date(date))}</span>
              <button
                type="button"
                className={`${!updatedDates?.includes(date) ? "resetBtn px-3" : "searchBtn"}`}
                onClick={() => handleDateSelect(date)}
              >
                {updatedDates.includes(date) ? t("Updated") : t("Update")}
              </button>
            </div>
          </div>
        ))}
    </>
  );

  return (
    <>
      {showTooltip && (
        <div
          className="overlay-backdrop"
          onClick={() => setShowTooltip(false)}
        />
      )}
      <span
        onClick={() => setShowTooltip(!showTooltip)}
        ref={target}
        className="me-2 cursor-pointer"
      >
        {children}
      </span>

      <Overlay target={target.current} show={showTooltip} placement={placement}>
        {(props) => (
          <Tooltip id="overlay-tooltip" {...props}>
            {!['Clone', 'Shifts'].includes(tooltipText) && (
              <div className="d-flex justify-content-between">
                {tooltipText == "Projects" && (
                  <>
                    <button
                      onClick={() => setType("Projects")}
                      className={`searchBtn me-2 ${type === "Projects" ? "active" : ""
                        }`}
                    >
                      {t("Projects")}
                    </button>
                    <button
                      onClick={() => setType("Cost center")}
                      className={`searchBtn me-2 ${type === "Cost center" ? "active" : ""
                        }`}
                    >
                      {t("Cost center")}
                    </button>
                    <span
                      className="secondaryColorHoverEffect me-2"
                      title={t("Edit")}
                      onClick={() => { }}
                    >
                      <Icon isIcon={true} name="EditIcon" width="1vw" height="1vw" />
                    </span>
                  </>
                )}
                <>
                  {/* <span
                    className="secondaryColorHoverEffect me-2"
                    title={t("Add")}
                    onClick={() => { }}
                  >
                    <Icon isIcon={true} name="add" width="1vw" height="1vw" />
                  </span> */}
                  <span
                    className="secondaryColorHoverEffect me-2"
                    title={t("Settings")}
                    onClick={() => { }}
                  >
                    <Icon isIcon={true} name="Settings" width="1vw" height="1vw" />
                  </span>
                </>
              </div>
            )}
            <OverFlowWrapper className="employeeSchedulingTooltip">
              <div className="mt-2">{renderTooltipContent()}</div>
            </OverFlowWrapper>
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default translate(OverlayTooltip);
