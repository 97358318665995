import React, { useState, useEffect } from "react";
import Icon from "../../Icon";
import { t } from "../CentralDataMangement/translation/Translation";
import AccordionCustom from "../common/atoms/AccordionCustom";
import LabelField from "../common/atoms/LabelField";
import RadioField from "../common/atoms/RadioField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import Calender from "../common/molecules/Calender";
import { LabelWithInputField } from "../common/molecules/LabelWithInputField";
import Navigation from "./Navigation";
import { Formdata } from "./Interfaces";
import { Option } from "./CompanyInformation";
import { validateSection } from "./Service";
import { ContactinputFields } from "./State";

interface RadioOption {
  id: string;
  label: string;
}

interface RadioGroupProps {
  name: string;
  options: RadioOption[];
  title?: string;
  containerClassName?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, section: keyof Formdata, index: number) => void;
  section: keyof Formdata;
  errors: any;
  selectedValue: any;
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  name,
  options,
  title,
  containerClassName,
  handleChange,
  section,
  errors,
  selectedValue
}) => (
  <div className={`col-3 mb-3 ${containerClassName}`}>
    <div className="mb-2">{title && <LabelField title={t(title)} />}</div>
    {options.map((option) => (
      <RadioField
        key={option.id}
        name={name}
        label={option.label}
        className="radioBtn"
        ischecked={selectedValue === option.id}
        handleChange={(e) => handleChange(e, section, 0)}
        id={option.id}
        containerClassName="companyLanguage me-3"
      />
    ))}
  </div>
);

interface InputFieldProps {
  label: string;
  type: string;
  name: string;
  id: string;
  value: string;
  error?: string;
  isMandatory?: boolean;
  className?: string;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>, section: keyof Formdata, index: number) => void;
  section: keyof Formdata;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type,
  name,
  id,
  value,
  error,
  className,
  isMandatory = false,
  handleChange,
  section,
}) => (
  <div className="col-3 mb-3">
    <LabelWithInputField
      type={type}
      label={t(label)}
      value={value}
      name={name}
      isMandatory={isMandatory}
      id={id}
      handleChange={(e) => handleChange(e, section, 0)}
      error={error}
      className={`ps-2 ${className}`}
    />
  </div>
);

interface ContactPersonProps {
  agreementData: any;
  handleChange: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Option, section: keyof Formdata, index: number) => void;
  handleDateFormat?: (dateValue: Date | null, fieldPath: string, section: keyof Formdata, index: number) => void;
  handleContactChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | Option, section: keyof Formdata, index: number) => void;
  section: keyof Formdata;
  errors: any;
  updateErrors: any;
  handleSelectChange: (selectedOption: Option, fieldPath: string, section: keyof Formdata) => void;
  handleContactSelectChange?: (selectedOption: Option, section: keyof Formdata, index: number) => void;
  handleNext: () => void;
  handlePrevious: () => void;
}

const ContactPerson: React.FC<ContactPersonProps> = ({
  agreementData,
  handleChange,
  handleDateFormat,
  handleContactChange,
  section,
  errors,
  updateErrors,
  handleSelectChange,
  handleContactSelectChange,
  handleNext,
  handlePrevious,
}) => {

  const [isLastTab, setIsLastTab] = useState(false);
  const handlenext = () => {
    const allErrors: { [key: string]: any }[] = [];
    const inputFields = ContactinputFields;
    // Validate all accordion sections
    agreementData?.forEach((data: any, index: number) => {
      const errors = validateSection(
        section,
        data,
        inputFields,
        ["contact_person_duties.user.personal_details.date_of_birth", "contact_person_duties.location.label"]
      );
      allErrors?.push(errors);
      updateErrors(section, allErrors);

      // If there's any error in a section, you can act on it here
      if (Object?.keys(errors).length > 0) {
        return;
      } else {
        handleNext();
      }
    });
  };

  const handleprevious = () => {
    handlePrevious();
  };

  return (
    <>
      <div style={{ minHeight: "calc(100vh - 22vw)" }}>
        {/* Iterate over agreementData array */}
        {agreementData?.map((data: any, index: any) => (
          <div key={index} className="d-flex">
            <AccordionCustom
              accordionTitle={`${data?.contact_person_duties?.user?.first_name} ${data?.contact_person_duties?.user?.last_name}`}
              accordionTitleClassName="w-100 poppins-medium dashboardAccordionTitle"
              accordionButton="dashboardAccordionHeader shadow-none"
            >
              <div className="row">
                {/* RadioGroup for Yes/No options */}
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Title")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.contact_title"
                    label={t("Yes")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.contact_title) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage me-4"
                  />
                  <RadioField
                    name="contact_person_duties.contact_title"
                    label={t("No")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.contact_title) === 0}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="noLabourRegulations"
                    value={0}
                    containerClassName="companyLanguage"
                  />
                  {errors?.[index]?.[section]?.contact_person_duties?.contact_title && (
                    <div className="text-danger mt-1">{errors?.[index]?.[section]?.contact_person_duties?.contact_title}</div>
                  )}
                </div>
                {/* Input Fields for contact person details */}
                <InputField
                  label="First name"
                  type="text"
                  name="contact_person_duties.user.first_name"
                  id="firstName"
                  isMandatory
                  value={data?.contact_person_duties?.user?.first_name || ""}
                  error={errors?.[index]?.[section]?.contact_person_duties?.user?.first_name || ""}
                  handleChange={(e) => handleContactChange?.(e, section, index)}
                  section={section}
                />
                <InputField
                  label="Last name"
                  type="text"
                  name="contact_person_duties.user.last_name"
                  id="lastName"
                  isMandatory
                  value={data?.contact_person_duties?.user?.last_name || ""}
                  error={errors?.[index]?.[section]?.contact_person_duties?.user?.last_name || ""}
                  handleChange={(e) => handleContactChange?.(e, section, index)} // Pass index here for each contact person
                  section={section}
                />
                <InputField
                  label="Function title"
                  type="text"
                  name="contact_person_duties.func_title"
                  isMandatory
                  id="functionTitle"
                  value={data?.contact_person_duties?.func_title || ""}
                  error={errors?.[index]?.[section]?.contact_person_duties?.func_title || ""}
                  handleChange={(e) => handleContactChange?.(e, section, index)} // Pass index here for each contact person
                  section={section}
                />
                <InputField
                  label="Phone number"
                  type="tel"
                  name="contact_person_duties.user.phone_number"
                  isMandatory
                  id="phoneNo"
                  value={data?.contact_person_duties?.user?.phone_number || ""}
                  error={errors?.[index]?.[section]?.contact_person_duties?.user?.phone_number || ""}
                  handleChange={(e) => handleContactChange?.(e, section, index)} // Pass index here for each contact person
                  section={section}
                />
                <InputField
                  label="Mobile number"
                  type="tel"
                  name="contact_person_duties.user.phone_number"
                  isMandatory
                  id="mobileNo"
                  value={data.contact_person_duties.user.phone_number || ""}
                  error={errors?.[index]?.[section]?.contact_person_duties?.user?.phone_number || ""}
                  handleChange={(e) => handleContactChange?.(e, section, index)} // Pass index here for each contact person
                  section={section}
                />
                <InputField
                  label="Email address"
                  type="email"
                  name="contact_person_duties.user.email"
                  isMandatory
                  id="email"
                  value={data?.contact_person_duties?.user?.email || ""}
                  error={errors?.[index]?.[section]?.contact_person_duties?.user?.email || ""}
                  handleChange={(e) => handleContactChange?.(e, section, index)} // Pass index here for each contact person
                  section={section}
                />
                <div className="col-3 position-relative agreementStartDate">
                  <Calender
                    onChange={(date: Date | null) => {
                      if (date) {
                        handleDateFormat?.(date, "contact_person_duties.user.personal_details.date_of_birth", section, index);
                      }
                    }}
                    label={t("Date of birth")}
                    selectedDate={data?.contact_person_duties?.user?.personal_details?.date_of_birth ? new Date(data?.contact_person_duties?.user?.personal_details?.date_of_birth) : null}
                    maxDate={new Date()}
                    name="contact_person_duties.user.personal_details.date_of_birth"
                  />
                </div>
                <div className="col-3 multiSelectWrapper">
                  <SelectWithSearch
                    fieldLabel={t("Location")}
                    title={t("Location")}
                    name="contact_person_duties.location.label"
                    search
                    options={Array(data?.contact_person_duties?.location)} // Assuming `location` is an array of options
                    value={Array(data?.contact_person_duties?.location)?.find(
                      (option: Option) => option?.label === data?.contact_person_duties?.location?.label
                    ) || null}
                    isSelectOption={false}
                    onChange={(e) => handleContactSelectChange?.(e, section, index)} // Pass the correct arguments here
                    isMulti={false}
                    className="select-field"
                    error={errors?.country || ""}
                    isTranslate
                    placeHolder={t("Select")}
                  />

                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Language")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.language"
                    label={t("Dutch")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.language) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage me-4"
                  />
                  <RadioField
                    name="contact_person_duties.language"
                    label={t("French")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.language) === 2}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="noLabourRegulations"
                    value={2}
                    containerClassName="companyLanguage"
                  />
                  {errors?.[index]?.[section]?.contact_person_duties?.language && (
                    <div className="text-danger mt-1">{errors?.[index]?.[section]?.contact_person_duties?.language}</div>
                  )}
                </div>
                <InputField
                  label="To be contacted for"
                  type="text"
                  name="contact_person_duties.contacted_for"
                  isMandatory
                  id="contactedFor"
                  value={data?.contact_person_duties?.contacted_for || ""}
                  error={errors?.[index]?.[section]?.contact_person_duties?.contacted_for || ""}
                  handleChange={(e) => handleContactChange?.(e, section, index)} // Pass index here for each contact person
                  section={section}
                />
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Decision maker")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.decision_maker"
                    label={t("Yes")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.decision_maker) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage me-4"
                  />
                  <RadioField
                    name="contact_person_duties.decision_maker"
                    label={t("No")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.decision_maker) === 0}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="noLabourRegulations"
                    value={0}
                    containerClassName="companyLanguage"
                  />
                  {errors?.[index]?.[section]?.contact_person_duties?.decision_maker && (
                    <div className="text-danger mt-1">{errors?.[index]?.[section]?.contact_person_duties?.decision_maker}</div>
                  )}
                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Influencer")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.influencer"
                    label={t("Yes")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.influencer) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage me-4"
                  />

                  <RadioField
                    name="contact_person_duties.influencer"
                    label={t("No")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.influencer) === 0}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="noLabourRegulations"
                    value={0}
                    containerClassName="companyLanguage"
                  />
                  {errors?.[index]?.[section]?.contact_person_duties?.influencer && (
                    <div className="text-danger mt-1">{errors?.[index]?.[section]?.contact_person_duties?.influencer}</div>
                  )}
                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Contact person can be called")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.contact_called"
                    label={t("Yes")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.contact_called) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage me-4"
                  />
                  <RadioField
                    name="contact_person_duties.contact_called"
                    label={t("No")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.contact_called) === 0}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="noLabourRegulations"
                    value={0}
                    containerClassName="companyLanguage"
                  />
                  {errors?.[index]?.[section]?.contact_person_duties?.contact_called && (
                    <div className="text-danger mt-1">{errors?.[index]?.[section]?.contact_person_duties?.contact_called}</div>
                  )}
                </div>
                <div className="col-3">
                  <div className="mb-2">
                    <LabelField title={t("Contact person can be mailed")} isMandatory />
                  </div>
                  <RadioField
                    name="contact_person_duties.contact_mailed"
                    label={t("Yes")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.contact_mailed) === 1}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="yesLabourRegulations"
                    value={1}
                    containerClassName="companyLanguage me-4"
                  />
                  <RadioField
                    name="contact_person_duties.contact_mailed"
                    label={t("No")}
                    className="radioBtn"
                    ischecked={Number(data?.contact_person_duties?.contact_mailed) === 0}
                    handleChange={(e) => handleContactChange?.(e, section, index)}
                    id="noLabourRegulations"
                    value={0}
                    containerClassName="companyLanguage"
                  />
                  {errors?.[index]?.[section]?.contact_person_duties?.contact_mailed && (
                    <div className="text-danger mt-1">{errors?.[index]?.[section]?.contact_person_duties?.contact_mailed}</div>
                  )}
                </div>
              </div>
            </AccordionCustom>
            <span
              onClick={() => { }}
              title={t("Delete contact person")}
              className="secondaryColorHoverEffect mt-2 pt-1"
            >
              <Icon isIcon={true} width="1vw" height="1vw" name="delete" />
            </span>

            {/* Add the Contact Person Selector */}

          </div>

        ))}
        <div className="multiSelectWrapper mt-3">
          <SelectWithSearch
            fieldLabel={t("Select contact person")}
            title={t("Select contact person")}
            name="contactPerson"
            search
            options={[]}
            value={() => { }}
            onChange={(selectedOption) => handleContactSelectChange?.(selectedOption, section, 0)}
            isMulti={false}
            className="select-field"
            error={errors?.contactPerson || ""}
            isTranslate
            placeHolder={t("Select")}
          />
        </div>
      </div>
      <Navigation
        isLastTab={isLastTab}
        onPrevious={handleprevious}
        onNext={handlenext}
      />

    </>
  );
};

export default ContactPerson;
