import React, { useState, useEffect } from "react";
import Popup from "./Popup";
import { ApiCall } from "../../../services/ApiServices";
import {
  MANAGE_CATEGORIES_DELETE,
  MANAGE_CATEGORIES_GET,
} from "../../../routes/ApiEndpoints";
import LinkCustom from "../../common/atoms/Link";
import { t, translate } from "../../CentralDataMangement/translation/Translation";
import { useNavigate } from "react-router-dom";
import Header from "../../common/layout/Header";
import Search from "../../common/atoms/Search";
import ResetBtn from "../../common/atoms/ResetBtn";
import SearchBtn from "../../common/atoms/SearchBtn";
import Icon from "../../../Icon";
import BackLink from "../../common/atoms/BackLink";
import OverFlowWrapper from "../../common/OverFlowWrapper";
import Pagination from "../../common/atoms/Pagination";
import './categories.css';
import TableHeaderSpace from "../../common/atoms/TableHeaderSpace";
import NoRecords from "../../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import EditIcon from "../../common/molecules/Actions/CustomEdit";
import CustomDelete from "../../common/molecules/Actions/CustomDelete";

const ManageCategories = () => {
  const [categories, setCategories] = useState([]);
  const [categoriesTemp, setCategoriesTemp] = useState([]);
  const [search, setSearch] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [updated, setUpdated] = useState(0);
  const [searchPc, setSearchPc] = useState("");
  const [searchCat, setSearchcat] = useState("");
  const [searchSal, setSearchSal] = useState("");
  const [showdeletepopup, setShowdeletepopup] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [catid, setCatid] = useState("");
  const [sno, setSno] = useState(0);
  const PER_PAGE = 10;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await ApiCall.getService(
          MANAGE_CATEGORIES_GET,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.success) {
          setUpdated(0);
          setCategories(response.data);
          setCategoriesTemp(response.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [updated]);

  const deletecat = async () => {
    try {
      const data = { id: catid };
      const response = await ApiCall.service(
        MANAGE_CATEGORIES_DELETE,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.success) {
        setUpdated(updated + 1);
        setShowdeletepopup(false);
      } else {
        console.error("Failed to delete the category.");
      }
    } catch (error) {
      console.error("An error occurred while deleting the category:", error);
    }
  };

  const closePopup = () => {
    setShowdeletepopup(false);
  };

  const showPopup = (id) => {
    setCatid(id);
    setShowdeletepopup(true);
  };

  const handleSearch = () => {
    setSearch(true);
    setCurrentPage(0);
    setCategories([]);
    let res = [];
    let uniqueResults = new Set();

    categoriesTemp.forEach((val) => {
      if (
        val["pc_number"].trim().includes(searchPc.trim()) &&
        val["category_name"]
          .trim()
          .toLowerCase()
          .includes(searchCat.trim().toLowerCase()) &&
        val["min_salary"].toLowerCase().includes(searchSal.trim())
      ) {
        uniqueResults.add(val);
      }
    });

    res = Array.from(uniqueResults);
    setCategories(res);
  };

  const handleReset = () => {
    setSearch(false);
    setCategories(categoriesTemp);
    setSearchPc("");
    setSearchcat("");
    setSearchSal("");
    setCurrentPage(0);
    setCurrentData([]);
  };

  const pageCount = Math.ceil(categories.length / PER_PAGE);

  useEffect(() => {
    const offset = currentPage * PER_PAGE;
    const currentpgData = categories.slice(offset, offset + PER_PAGE);
    setCurrentData(currentpgData);
  }, [currentPage, categories, search]);

  const handlePageClick = (pageNumber) => {
    setCurrentData([]);
    setCurrentPage(pageNumber - 1); // Adjust for zero-based index
  };

  const IconProps = {
    isIcon: true,
    width: "1vw",
    height: "1vw",
  };

  return (
    <>
      <form>
        <Header headerName={t("Manage categories")} headerClassName="myAccountCardTitle" />
        <div className="search-bar pb-2">
          <div className="row mb-2">
            <BackLink backLink="/" />
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 mb-3 mb-lg-0">
              <Search
                value={searchPc}
                placeholder={t("PC number")}
                handleChange={(e) => setSearchPc(e.target.value)}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <Search
                value={searchCat}
                placeholder={t("Category name")}
                handleChange={(e) => setSearchcat(e.target.value)}
              />
            </div>
            <div className="col-lg-3 col-md-6">
              <Search
                value={searchSal}
                handleChange={(e) => setSearchSal(e.target.value)}
                placeholder={t("Minimum salary")}
              />
            </div>
            <div className="col-lg-3 col-md-6 mb-3 mb-lg-0">
              <div className="d-flex align-items-center">
                <ResetBtn handleResetClick={() => handleReset()} />
                <SearchBtn handleSearchClick={handleSearch} />
              </div>
            </div>
          </div>
        </div>
        <div className="managepagesBg">
          <OverFlowWrapper className="table-responsive manageCategoriesTable">
            <table className="table pwa table-hover customTableProperties">
              <thead className="headSticky">
                <tr className="TableHeader">
                  <th style={{ width: "25%" }}>{t("Paritair comite number")}</th>
                  <th style={{ width: "25%" }}>{t("Category name")}</th>
                  <th style={{ width: "25%" }}>{t("Minimum salary")}</th>
                  <th style={{ width: "25%" }}>{t("Action")}</th>
                </tr>
                <TableHeaderSpace headerLength={3} />
              </thead>
              <tbody className="manage-category-data">
                {currentData.map((result) => (
                  <tr className="border" key={result.cat_id}>
                    <td data-label={t("PC number")}>{result.pc_number}</td>
                    <td data-label={t("Category name")}>{result.category_name}</td>
                    <td data-label={t("Minimum salary")}>€ {result.min_salary}</td>
                    <td className="p-2">
                      <EditIcon href={`/editpc/${result.pc_unique_key}?cid=${result.cat_id}`} />
                      <CustomDelete handleDelete={() => showPopup(result.cat_id)} />
                    </td>
                  </tr>
                ))}
                {currentData.length === 0 && (
                  <NoRecords headerLength={4} />
                )}
              </tbody>
            </table>
          </OverFlowWrapper>
          {pageCount > 1 && (
            <div className="pagination justify-content-center mt-4 px-4">
              <Pagination
                currentPage={currentPage + 1} // Adjust for one-based index
                totalPages={pageCount}
                onPageChange={handlePageClick}
              />
            </div>
          )}
        </div>
      </form>
      {showdeletepopup && (
        <Popup
          display={"block"}
          popupActionNo={closePopup}
          popupActionYes={deletecat}
        />
      )}
    </>
  );
};

export default translate(ManageCategories);
