import React, { useEffect, useState } from "react";
import Header from "../common/layout/Header";
import { t } from "../CentralDataMangement/translation/Translation";
import BackLink from "../common/atoms/BackLink";
import { ApiCall } from "../../services/ApiServices";
import { useSelector } from "react-redux";
import {
  ADMIN_DETAILS_BY_COMPANY,
  COMPANY_EMPLOYEES,
  EMPLOYEE_REMOVE_ADMIN,
} from "../../routes/ApiEndpoints";
import OverFlowWrapper from "../common/OverFlowWrapper";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import Button from "../common/atoms/Button";
import Search from "../common/atoms/Search";
import SearchBtn from "../common/atoms/SearchBtn";
import { useNavigate } from "react-router-dom";
import CustomNotify from "../common/atoms/CustomNotify";
import LabelField from "../common/atoms/LabelField";
import "./css/administrator.css";
import NoRecords from "../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import AutoCompleteWithSearch from "../common/molecules/AutoCompleteWithSearch";
import { Debounce } from "../common/utlis/Debounce";

const ManageAdministrator = () => {
  const navigate = useNavigate();
  const companyId = useSelector(
    (state: any) => state?.auth?.currentCompany?.id
  );
  const microService = CENTRAL_DATA_MANAGEMENT_MICROSERVICE;
  interface Company {
    id: number;
    name: string;
    official_name: string;
    profile_picture_url: string;
    locations: [];
    projects: [];
  }

  interface DataItem {
    id: number;
    first_name: string;
    last_name: string;
    locations: [];
    name: string;
    official_name: string;
    profile_picture_url: string | null;
    projects: [];
    email: string;
    companies: Company[];
  }

  const [state, setState] = useState({
    data: [] as DataItem[], // Store all administrators
    filteredData: [] as DataItem[], // Store filtered results
    searchTerm: "", // Store search input
    dataLength: 0,
  });

  // Fetch administrators from API
  const fetchInfo = async (searchTerm = "") => {
    let url = `${ADMIN_DETAILS_BY_COMPANY}?company_id=${companyId}&q=${searchTerm}`;
    try {
      const response = await ApiCall.getService(url, "GET", microService, true);
      if (response?.status === "success") {
        setState({
          ...state,
          data: response?.data,
          dataLength: response?.data?.length,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      searchTerm: value,
    }));
  };


  // Filter data based on search term
  const filteredData = state?.data?.filter((employee) => {
    const searchTerm = state?.searchTerm?.toLowerCase() || "";
    const firstName = employee?.first_name?.toLowerCase() || "";
    const lastName = employee?.last_name?.toLowerCase() || "";
    const email = employee?.email?.toLowerCase() || "";
    const fullName = `${firstName} ${lastName}`;

   return (
        firstName.includes(searchTerm) ||
        lastName.includes(searchTerm) ||
        email.includes(searchTerm) ||
        fullName.includes(searchTerm) ||
        (employee?.email && employee?.email?.toLowerCase()?.includes(searchTerm))
    );
  });
  
  useEffect(() => {
    fetchInfo(); // Fetch initial data without any filters
  }, [companyId]);  
  const removeAdmin = async (employer_id: number, dataLength: number) => {
    let url = `${EMPLOYEE_REMOVE_ADMIN}/${employer_id}`;
    try {
      if (dataLength > 1) {   
        const response = await ApiCall.getService(url, "GET", microService, true);
        if (response?.status === "success") {
          fetchInfo();
          CustomNotify({
            message: t("Admin deleted successfully"),
            type: "success",
            autoClose: 3500,
          });
        }
      } else {
        CustomNotify({
          message: t("Atleast one administrator should be present"),
          type: "warning",
          autoClose: 3500,
        });
      }
    } catch (err) {
      CustomNotify({
        message: t(err, "Fail to delete the admin"),
        type: "error",
        autoClose: 3500,
      });
      console.error(err);
    }
  };
  return (
    <div>
      <Header
        headerName={t("Manage administrators")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <div className="row">
          <BackLink backLink={"/manage-salary-benefits"} />
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Manage administrators")}
              className="myAccountCardTitle mb-0"
            />
          </div>
          <div className="col-6 align-self-center ms-auto">
            <div className="col-12 text-end">
              <Button
                title={t("Add administrator")}
                className="searchBtn px-3"
                handleClick={() => navigate("/add-administrator")}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="createpagesBg manageAdministratorPage">
        <LabelField title={t("Administrators:")} />
        {/* Search Input and Button */}
        <div className="row mb-4">
        <div className="col-lg-6 col-md-8">
            <AutoCompleteWithSearch
							placeholder={t("Search employee")}
							value={state?.searchTerm}
							handleChange={handleSearchChange}
						/>
          </div>
        </div>

        <OverFlowWrapper className="manageAdministratorWrapper pt-1">
          <div
            className="d-grid grid-4 grid-md-3"
            style={{ gap: "1vw" }}
          >
            {filteredData?.length > 0 ? (
              filteredData?.map((item) => (
                <React.Fragment key={item?.id}>
                  <div>
                    <EmployeeDetailsCard
                      employee_data={item}
                      cardConfig={{
                        isRelatedCompany: true,
                        cardClassName:
                          "text-break text-start d-flex align-items-center justify-content-start",
                        employeeInformationClass: "planningEmployeeDetails",
                        cardMainClassName:
                          "employeeDetailsCardPlanning colorPrimary position-relative h-100",
                        iconClassName:
                          "text-end cardAdministratorEditIcon cardEditIcon position-absolute top-0 end-0 cursor-pointer",
                        isCardIcon: true,
                        iconName: "close",
                        favouriteIconName: "starIcon", // Star icon for favorite
                        noFavouriteIconName: "whiteStarIcon", // Empty star icon for not favorite
                        // isFavourite: item?.is_fav,
                      }}
                      Company={item?.companies} // Send the companies array to the card
                      handleClick={() => removeAdmin(item?.id, state?.dataLength)}
                    />
                  </div>
                </React.Fragment>
              ))
            ) : (
              <NoRecords
                message={t("No administrator available")}
                className="col-12"
                isDiv={true}
              />
            )}
          </div>
        </OverFlowWrapper>
      </div>
    </div>
  );
};

export default ManageAdministrator;
