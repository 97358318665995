import React from "react";
import Icon from "../../Icon";
import Link from "../common/atoms/Link";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import { t } from "../common/atoms/translation";

interface Document {
  id: number;
  name: string;
  isUnread: boolean;
  viewLink: string;
  downloadLink: string;
}

interface DocumentsTableProps {
  documents: Document[];
}

const DocumentsTable: React.FC<DocumentsTableProps> = ({ documents }) => {
  return (
    <table className="table contractTable table-borderless mb-0">
      <tbody>
        {documents.map((document) => (
          <React.Fragment key={document.id}>
            <tr className="spaced-row">
              <td className="align-middle ps-lg-4 p-2" style={{ width: "10%" }}>
                {document.isUnread && (
                  <div
                    style={{
                      width:window.innerWidth <= 767
                      ? "3.5vw"
                      : window.innerWidth <= 1023
                        ? "1.95vw"
                        : "0.9vw",
                      height: window.innerWidth <= 767
                      ? "3.5vw"
                      : window.innerWidth <= 1023
                        ? "1.95vw"
                        : "0.9vw",
                      borderRadius: "50%",
                      backgroundColor: "#F6921E",
                    }}
                  ></div>
                )}
              </td>
              <td className="align-middle text-break p-2" style={{ width: "70%" }}>
                {document.name}
              </td>
              <td className="align-middle p-2">
                <div className="d-flex justify-content-end pe-4">
                  <Link
                    href={document.viewLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      data-bs-toggle="tooltip"
                      title={t("View")}
                      onClick={() => {}}
                    >
                      <Icon
                        name="viewIcon"
                        isIcon={true}
                        className="me-2 cursor-pointer secondaryColorHoverEffect"
                        width="16px"
                        height="16px"
                      />
                    </span>
                  </Link>
                  <Link
                    href={document.downloadLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span
                      data-bs-toggle="tooltip"
                      title={t("Download")}
                      onClick={() => {}}
                    >
                      <Icon
                        name="downloadIcon"
                        isIcon={true}
                        className="cursor-pointer secondaryColorHoverEffect"
                        width="14px"
                        height="14px"
                      />
                    </span>
                  </Link>
                </div>
              </td>
            </tr>
            <TableHeaderSpace headerLength={3} />
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default DocumentsTable;
