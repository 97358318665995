import React from "react";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import EmployeeContractsTemplate from "../../components/Contracts/EmployeeContractsTemplate";

const InvoiceOverview: React.FC = () => {
    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "contracts",
                    read: true,
                },
            ]}
            renderNoAccess={true}
        >
            <EmployeeContractsTemplate />
        </AccessControl>
    );
};

export default InvoiceOverview;
