import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Icon from "../../Icon";
import Button from "../common/atoms/Button";
import Link from "../common/atoms/Link";
import Header from "../common/layout/Header";
import AutoCompleteWithSearch from "../common/molecules/AutoCompleteWithSearch";
import EmployeeDetailsCard from "../common/molecules/EmployeeDetailsCard";
import * as ROUTE from "../../routes/RouteConstants";
import {
  selectPlanningShow,
  setShow,
} from "../../features/planning/planningSlice";
import PlanningTabs from "../Planning/PlanningTabs";
import { useDispatch, useSelector } from "react-redux";
import { t } from "../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../services/ApiServices";
import OverFlowWrapper from "../common/OverFlowWrapper";
import BackLink from "../common/atoms/BackLink";
import { COMPANY_EMPLOYEES } from "../../routes/ApiEndpoints";
import NoRecords from "../common/atoms/NoRecords";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import LabelField from "../common/atoms/LabelField";
import SelectWithSearch from "../common/atoms/SelectWithSearch";

const EmployeeOverview = () => {
  const navigate = useNavigate();
  const show = useSelector(selectPlanningShow);

  interface DataItem {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    is_fav: boolean;
    status: string | boolean;
    is_invite_accepted: boolean;
    profile_picture_url: string | null;
    employee_type: string;
    employee_type_id: number;
    employee_type_category: string;
    employee_type_category_id: number;
    paritaire_commitee: string;
    paritaire_commitee_id: number;
    functional_salary: string;
    function_id: number;
    function_name: string;
  }

  interface State {
    data: DataItem[];
    searchTerm: string;
    suggestions: string[];
    companyId: number;
  }

  const [state, setState] = useState<State>({
    data: [],
    searchTerm: "",
    suggestions: [],
    companyId: 0,
  });

  const companyId = useSelector(
    (state: any) => state?.auth?.currentCompany?.id
  );
  const dispatch = useDispatch();

  // Fetch all employee data once
  const fetchEmployees = async () => {
    const baseUrl = `${COMPANY_EMPLOYEES}`;
    const searchParams = new URLSearchParams({
      company_id: companyId,
    });
    const url = `${baseUrl}?${searchParams?.toString()}`;

    try {
      const res = await ApiCall?.getService(
        url,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      );
      if (res?.status !== "success") {
        throw new Error("Network response was not ok");
      }

      setState((prevState) => ({
        ...prevState,
        data: res?.data || [],
        companyId,
      }));
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (companyId) {
      fetchEmployees();
    }
  }, [companyId]);

  const handleEmployeeClick = (employee: DataItem) => {
    navigate(`${ROUTE?.EMPLOYEE_EDIT?.replace(":id", employee?.id?.toString())}`);
  };

  const toggleFavorite = async (employeeId: number, is_fav: boolean) => {
    try {
      const dataObj = {
        employee_id: employeeId,
        company_id: companyId,
        is_favourite: !is_fav,
      };
      const res = await ApiCall?.service(
        "employee-favourite",
        "POST",
        dataObj,
        true
      );
      if (res?.status === "success") {
        const updatedData = state?.data?.map((employee) =>
          employee?.id === employeeId
            ? { ...employee, is_fav: !is_fav }
            : employee
        );
        setState((prevState) => ({
          ...prevState,
          data: updatedData,
        }));
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSearchChange = (value: string) => {
    setState((prevState) => ({
      ...prevState,
      searchTerm: value,
    }));
  };

  // Dropdown options for employee statuses
  const statusOptions = [
    { label: "All", value: "all" },
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
    { label: "Invitation Pending", value: "invitation_pending" },
    { label: "Favorite", value: "favorite" },
  ];
  const [filterStatus, setFilterStatus] = useState<string>("all");

  // Filter data based on search term
  const filteredData = state?.data?.filter((employee) => {
    const searchTerm = state?.searchTerm?.toLowerCase() || "";
    const firstName = employee?.first_name?.toLowerCase() || "";
    const lastName = employee?.last_name?.toLowerCase() || "";
    const email = employee?.email?.toLowerCase() || "";
    const fullName = `${firstName} ${lastName}`;

    // Match against search term
    const matchesSearchTerm =
      firstName?.includes(searchTerm) ||
      lastName?.includes(searchTerm) ||
      email?.includes(searchTerm) ||
      fullName?.includes(searchTerm);

    // Match against filterStatus
    const matchesStatus =
      filterStatus === "all" ||
      (filterStatus === "inactive" && !employee?.status) ||
      (filterStatus === "active" && employee?.status) ||
      (filterStatus === "invitation_pending" && employee?.is_invite_accepted) ||
      (filterStatus === "favorite" && employee?.is_fav === true);

    return matchesSearchTerm && matchesStatus;
  });

  // Dropdown handler
  const handleStatusChange = (selectedOption: { label: string; value: string }) => {
    setFilterStatus(selectedOption?.value); // Update the filterStatus with the label
  };

  const getStatusStyle = (status: string) => {
    switch (status?.toLowerCase()) {
      case "true":
        return { color: "green", label: "Active" };
      case "false":
        return { color: "red", label: "Inactive" };
      case "invitation_pending":
        return { color: "orange", label: "Invitation Pending" };
      default:
        return { color: "gray", label: "Unknown" };
    }
  };

  return (
    <>
      <Header
        headerName={t("Your pool")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <div className="row">
          <BackLink backLink="/" />
          <div className="col-lg-10 text-end">
            <Button
              title={t("Add employee")}
              className="saveBtn mobileFont me-3"
              handleClick={() => navigate(ROUTE?.EMPLOYEE_INVITE)}
            />
          </div>
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField
              title={t("Your pool")}
              className="myAccountCardTitle mb-0"
            />
          </div>
        </div>
      </div>
      <div className="createpagesBg">
        <div className="row mb-3 justify-content-between">
          <div className="col-lg-3 d-flex mb-3 mb-lg-0">
            <Link className="me-3" href="">
              <span title={t("Home")}>
                <Icon
                  isIcon={true}
                  className="secondaryColorHoverEffect"
                  name="houseIcon"
                  width={window?.innerWidth <= 767
                    ? "3.5vw"
                    : window?.innerWidth <= 1023
                      ? "3vw"
                      : "1.3vw"}
                  height={window?.innerWidth <= 767
                    ? "3.5vw"
                    : window?.innerWidth <= 1023
                      ? "3vw"
                      : "1.3vw"}
                />
              </span>
            </Link>
            <AutoCompleteWithSearch
              placeholder={t("Search employee")}
              value={state?.searchTerm}
              handleChange={handleSearchChange}
            />
          </div>

          <div className="col-lg-5 text-end">
            <div className="d-flex align-items-center justify-content-end">
              <div className="multiSelectWrapper w-75">
                <SelectWithSearch
                  options={statusOptions}                  
                  value={statusOptions?.find(option => option?.value === filterStatus) || statusOptions[0]}
                  search
                  onChange={(selectedOptions) => handleStatusChange(selectedOptions)}
                  placeHolder={t("Select")}
                  isSelectOption={false}
                />
              </div>
              <div className="d-inline-block me-4 poppins-medium colorPrimary ms-3">
                {t("Total") + ":"}
                <span className="ms-1 poppins-semibold">
                  {state?.data?.length}
                </span>
              </div>
              <span onClick={() => { }}>
                <Icon name="FilterIcon" width={window?.innerWidth <= 767
                  ? "3.5vw"
                  : window?.innerWidth <= 1023
                    ? "2.5vw"
                    : "1vw"}
                  height={window?.innerWidth <= 767
                    ? "3.5vw"
                    : window?.innerWidth <= 1023
                      ? "2.5vw"
                      : "1vw"} />
              </span>
            </div>
          </div>
        </div>
        <OverFlowWrapper className="yourPoolWrapper">
          <div className="container-fluid ps-0">
            <div className="row">
              {filteredData?.length > 0 ? (
                filteredData?.map((employee_data) => (
                  <div className="col-lg-3 col-md-6 mb-4" key={employee_data?.id}>
                    <EmployeeDetailsCard
                      employee_data={employee_data}
                      cardConfig={{
                        cardClassName:
                          "text-break text-start d-flex align-items-center justify-content-center",
                        employeeInformationClass: "planningEmployeeDetails",
                        cardMainClassName:
                          "employeeDetailsCardPlanning colorPrimary position-relative h-100",
                        iconClassName:
                          "text-end cardEditIcon position-absolute top-0 end-0 cursor-pointer",
                        iconWrapperClassName: "employeeCardIconStyle",
                        isCardIcon: true,
                        iconName: "EditIcon",
                        favouriteIconName: "starIcon",
                        noFavouriteIconName: "whiteStarIcon",
                        isFavourite: employee_data?.is_fav,
                        status: getStatusStyle(`${employee_data?.status}`)
                      }}
                      handleClick={() => handleEmployeeClick(employee_data)}
                      handleFavIcon={() =>
                        toggleFavorite(employee_data?.id, employee_data?.is_fav)
                      }
                    />
                  </div>
                ))
              ) : (
                <NoRecords isDiv={true} message={t("No employees available")} className="text-danger" />
              )}
            </div>
          </div>
        </OverFlowWrapper>
      </div>
      {show && <PlanningTabs />}
    </>
  );
};

export default EmployeeOverview;
