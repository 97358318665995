import React, { useEffect, useState } from "react";
import Header from "../common/layout/Header";
import { t } from "../CentralDataMangement/translation/Translation";
import BackLink from "../common/atoms/BackLink";
import Button from "../common/atoms/Button";
import { useSelector } from "react-redux";
import OverFlowWrapper from "../common/OverFlowWrapper";
import Image from "../common/atoms/Image";
import UserImage from "../../static/images/User.svg";
import CheckBoxField from "../common/atoms/CheckBoxField";
import { EMPLOYEE_ADD_ADMIN, COMPANY_EMPLOYEES_OPTIONS } from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import CustomNotify from "../common/atoms/CustomNotify";
import { useNavigate } from "react-router-dom";
import LabelField from "../common/atoms/LabelField";
import MultiSelectCard from "../common/molecules/MultiSelectCard";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import defaultCompanyIcon from "../../static/images/emptyCompany.png";
import NoRecords from "../common/atoms/NoRecords";

interface Option {
  value?: number | string | null;
  label?: string;
  profile_picture_url: string;
  first_name: string;
  last_name: string;
}

interface Company {
  id: number;
  name: string;
  profile_picture_url: string;
}

interface State {
  data: Option[];
  selectedEmployees: Option[];
  selectedEmployeeValues: number[];
  selectedCompanies: number[];
  searchTerm: string;
}

interface CompanyState {
  companies: Company[];
  currentCompany: {
    id: number;
  };
}

const AddAdministrator = () => {
  const companyData = useSelector((state: { auth: CompanyState }) => state?.auth);
  const companies = companyData?.companies;
  const companyId = companyData?.currentCompany?.id;
  const navigate = useNavigate();

  const [state, setState] = useState<State>({
    data: [],
    selectedEmployees: [],
    selectedEmployeeValues: [],
    selectedCompanies: [],
    searchTerm: "",
  });

  useEffect(() => {
    getAllEmp();
  }, []);

  // Fetch employees based on search term
  const getAllEmp = async () => {
    const url = `${COMPANY_EMPLOYEES_OPTIONS}?company_id=${companyId}`;
    try {
      const res = await ApiCall?.getService(url, "GET", CENTRAL_DATA_MANAGEMENT_MICROSERVICE, true);
      if (res?.status === "success") {
        setState((prevState) => ({
          ...prevState,
          data: res?.data,
        }));
      }
    } catch (err) {
      console.error("Error fetching employees:", err);
    }
  };

  // Handle company checkbox toggle
  const toggleCompanySelection = (companyId: number) => {
    setState((prevState) => {
      const updatedSelectedCompanies = prevState?.selectedCompanies?.includes(companyId)
        ? prevState?.selectedCompanies?.filter((id) => id !== companyId)
        : [...prevState?.selectedCompanies, companyId];

      return {
        ...prevState,
        selectedCompanies: updatedSelectedCompanies,
      };
    });
  };

  // Handle employee selection change
  const handleEmployeeChange = (selected: Option[]) => {
    const selectedValues = selected?.map((item) => item?.value as number);
    setState((prevState) => ({
      ...prevState,
      selectedEmployees: selected,
      selectedEmployeeValues: selectedValues,
    }));
  };

  // Handle employee remove
  const handleEmployeeRemove = (itemToRemove: Option) => {
    const updatedSelection = state?.selectedEmployees?.filter(
      (item) => item?.value !== itemToRemove?.value
    );
    setState((prevState) => ({
      ...prevState,
      selectedEmployees: updatedSelection,
      selectedEmployeeValues: updatedSelection?.map((item) => item?.value as number),
    }));
  };

  // Handle form submission (API call to add admin)
  const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const dataObj = {
      users: state?.selectedEmployeeValues,
      companies: state?.selectedCompanies,
    };

    if (dataObj?.companies?.length > 0 && dataObj?.users?.length > 0) {
      try {
        const res = await ApiCall?.service(
          EMPLOYEE_ADD_ADMIN,
          "POST",
          dataObj,
          true,
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (res?.status === "success") {
          CustomNotify({
            message: t("Admin added successfully"),
            type: "success",
            autoClose: 3500,
          });
          navigate("/manage-administrators");
        } else {
          throw new Error("Failed to add administrators");
        }
      } catch (error) {
        CustomNotify({
          message: t("Failed to add administrators"),
          type: "error",
          autoClose: 3500,
        });
      }
    } else {
      CustomNotify({
        message: t("Employee or company is not selected"),
        type: "warning",
        autoClose: 3500,
      });
    }
  };

  const options = state?.data?.map((employee) => ({
    value: `${employee?.value}`,
    label: `${employee?.label}`,
    profile_picture_url: employee?.profile_picture_url || UserImage,
    first_name: employee?.first_name,
    last_name: employee?.last_name,
  }));

  return (
    <div>
      <Header headerName={t("Add administrators")} headerClassName="myAccountCardTitle" />
      <div className="search-bar pb-2">
        <div className="row">
          <BackLink backLink="/manage-administrators" />
          <div className="col-8 align-self-center text-center d-block d-lg-none">
            <LabelField title={t("Add administrators")} className="myAccountCardTitle mb-0" />
          </div>
        </div>
      </div>
      <div className="createpagesBg">
        <div className="row mb-4">
          <div className="col-12 addAdministratorSearch">
            <LabelField title={t("Add employee from pool") + ":"} />
            <MultiSelectCard
              placeholder="Search and select employees"
              options={options}
              value={state?.selectedEmployees}
              onChange={handleEmployeeChange}
              onRemove={handleEmployeeRemove}
            />
          </div>
        </div>
        <OverFlowWrapper className="manageAdministratorWrapper addAdministratorWrapper">
          <div className="container-fluid px-0">
            <LabelField
              title={t(
                "If you give an employee or another person access to manage the schedule, that person can see confidential information"
              )}
              className="mb-2"
            />
            <LabelField title={t("Select the company to which you would like to add this employee as an administrator")} isMandatory className="mb-2" />
            <div className="mt-3" style={{ paddingLeft: "0.08vw" }}>
              {companies?.length > 0 ? (
                <div className="d-grid grid-6 grid-med-3" style={{ gap: "1vw" }}>
                  {companies?.map((company: Company) => (
                    <div
                      key={company?.id}
                      className={`${
                        state?.selectedCompanies?.includes(company?.id) ? "selectedCompanies" : ""
                      } radioButtonContainer checkBoxAdminContainer cursor-pointer`}
                      onClick={() => toggleCompanySelection(company?.id)}
                    >
                      <CheckBoxField
                        name={`select_${company?.id}`}
                        className="my-checkbox"
                        id={`select_${company?.id}`}
                        lineHeight="1vw"
                        isChecked={state?.selectedCompanies?.includes(company?.id)}
                      />
                      <div className="mt-1">
                        <Image
                          src={company?.profile_picture_url || defaultCompanyIcon}
                          imageWidth="3vw"
                          imageHeight="3vw"
                          imageBorderRadius="50%"
                        />
                        <LabelField title={company?.name} className="mt-2 d-block cursor-pointer" />
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <NoRecords message={t("No companies available")} />
              )}
            </div>
          </div>
        </OverFlowWrapper>
        <div className="row mt-3 pb-3">
          <div className="col-12">
            <Button title={t("Save")} className="searchBtn float-end" handleClick={handleSubmit} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAdministrator;
