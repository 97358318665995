import React, { useState, useEffect, useMemo } from "react";
import { t, translate } from "../../translation/Translation";
import { ApiCall } from "../../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from '../../../../Constants';
import { useNavigate, useLocation, useParams } from "react-router-dom";

import Header from "../../../common/layout/Header";
import BackLink from "../../../common/atoms/BackLink";
import LabelField from "../../../common/atoms/LabelField";
import MultiSelect from "../manageCoefficientEmployeeTypes/SelectComponent";
import { GET_HOLIDAY_TYPES, STORE_HOLIDAYS, UPDATE_HOLIDAY } from "../../../../routes/ApiEndpoints";
import DatePicker from "react-multi-date-picker";
import Button from "../../../common/atoms/Button";
import ValidationService from "./ValidationService";

import EditIcon from "../../../common/molecules/Actions/CustomEdit";
import CustomDelete from "../../../common/molecules/Actions/CustomDelete";




const OperationHolidaysList = (props) => {

  const location = useLocation();

  // Extract id from query parameters
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  const [state, setState] = useState({
    rows: props.rows || [],
    editFlow: id,
    newItems: [],
    nameWarning: false,
    holiday_type_id: id !== 0 && props.rows.length > 0 ? props.rows[0].holiday_type_id : '',
    occasion_id: id !== 0 && props.rows.length > 0 ? props.rows[0].occasion_id : '',
    exportyear: '',
    importyear: '',
    clone: 0,
    holiday_year_id: '',
    date: id && props.rows.length > 0 ? props.rows[0].date : '',
    editIndex: 0,
    options: [],
    cloneErr: false,
  });

  useEffect(() => {
    ApiCall.getService(
      GET_HOLIDAY_TYPES,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
      true
    )
      .then((result) => {
        setState({ ...state, options: result.data });
      })
      .catch((error) => {
        console.error(error);
      });
  }, [props])

  useEffect(() => {
    let exportrow = props.rows.filter((day) => {
      if (state.exportyear == day.holiday_year_id) {
        return day;
      };
    });
    setState({ ...state, newItems: exportrow });
  }, [state.exportyear, props.rows]);


  const onSelect = (obj, e) => {
    setState({ ...state, [e.name]: obj.value, nameWarning: false, uniqueError: false, duplicates: [] });
  }

  const getLabel = (value, obj) => {
    if (obj != undefined) {
      const label = obj.filter((i) => {
        if (i.value === value) {
          return i;
        }
      })
      return label[0].label;
    }
  }

  const handleChange = (formattedDate) => {
    setState({ ...state, date: formattedDate, nameWarning: false, uniqueError: false, duplicates: [] })
  }

  const repeated = (source, target) => {
    const duplicates = [];
    for (var i = 0; i < target.length; i++) {
      for (var j = 0; j < source.length; j++) {
        if (target[i].occasion_id == source[j].occasion_id && (ValidationService.getYear(target[i].date)[2] === source[j].year)) {
          duplicates.push(source[j]);
        }
      }
    }
    console.log(duplicates);
    return duplicates;
  }

  const inertNewItem = () => {
    let newItemsList = [...state.newItems];
    let duplicates = newItemsList.filter((val, index) => (index !== state.editIndex && val.occasion_id === state.occasion_id))
    if (duplicates.length) {
      let stateObj = { ...state };
      stateObj['uniqueError'] = true;
      stateObj['duplicates'] = duplicates.map(obj => getLabel(obj.occasion_id, state.options[1]));
      setState(stateObj);
      return 0;
    } else {
      let year = new Date(state.date).getFullYear();
      if (state.occasion_id && state.holiday_type_id && state.date) {
        newItemsList[state.editIndex] = { holiday_year: year, occasion_id: state.occasion_id, holiday_type_id: state.holiday_type_id, date: state.date };
      }
    }
    return newItemsList;
  }

  const handleSubmit = () => {
    let newItemsList = inertNewItem();
    if (!newItemsList) return;

    // console.log(newItemsList, "newitems");
    if (repeated(props.rows, newItemsList).length > 0) {

      const dupli = repeated(props.rows, newItemsList).map((hol) => {
        return hol.occasion;
      })
      setState({ ...state, uniqueError: true, nameWarning: false, duplicates: dupli });
    } else {
      if (state.editFlow != 0) {
        ApiCall.service(UPDATE_HOLIDAY + "/" + id, 'POST', getPostData(newItemsList))
          .then((result) => {
            console.log("res", result);
          })
          .catch((error) => console.error('Error occured'));
      } else {
        // ApiCall.service( , 'POST',)
        ApiCall.service(STORE_HOLIDAYS, 'POST', getPostData(newItemsList))
          .then((result) => {
            console.log("res", result);
          })
          .catch((error) => console.error('Error occured'));

      }
    }
  }

  const getPostData = (newItemsList = []) => {
    return {
      id: id,
      newItems: newItemsList,
    }
  };

  const cloneHolidays = (holidays, importyear, exportyear) => {
    if (Number(getLabel(importyear, state.options[2])) <= Number(getLabel(exportyear, state.options[2]))) {
      setState({ ...state, cloneErr: true });
    } else {
      let newyear = getLabel(importyear, state.options[2]);
      let cloned = holidays.map((items) => {
        return {
          occasion_id: items.occasion_id,
          holiday_type_id: items.holiday_type_id,
          date: ValidationService.getDateString(items.date, newyear)
        }
      })
      setState({ ...state, newItems: cloned, clone: 1, cloneErr: false });
    }
  }

  const deleteAction = (index) => {
    let stateObj = { ...state };
    stateObj['newItems'].splice(index, 1)
    stateObj['editIndex'] = stateObj['newItems'].length;
    setState(stateObj);
  }


  const handleActionClick = (type, item, index,) => {

    let stateObj = { ...state };

    if (type == 'edit') {

      stateObj['occasion_id'] = item.occasion_id;
      stateObj['holiday_type_id'] = item.holiday_type_id;
      stateObj['date'] = item.date;
      stateObj['editIndex'] = index;

    } else {
      deleteAction(index);
    }

    setState(stateObj);

  }

  const getNeededActions = (item, index) => {
    return (
      <>
        <span className='actions-span mx-0' onClick={() => handleActionClick('edit', item, index)}> <EditIcon /></span>
        <span className='actions-span mx-0' onClick={() => handleActionClick('delete', item, index)}> <CustomDelete /> </span>
      </>
    )
  }

  const errorMessage = () => {
    const { date, holiday_type_id, clone, occasion_id } = state;
    const condition = (date == "" || holiday_type_id == "" || occasion_id == "" || clone == "");
    setState({ ...state, nameWarning: condition })
  }

  const addItemAndUpdateIndex = (stateObj, date, holidayname, holidaytype, clone) => {
    errorMessage();
    let noofRows = stateObj['newItems'].length > -1 ? stateObj['newItems'] : state.rows;

    if (holidayname && date && holidaytype) {
      let duplicates = noofRows.filter((val, index) => (index !== state.editIndex && val.occasion_id == holidayname))
      if (duplicates.length) {
        stateObj['uniqueError'] = true;
        stateObj['duplicates'] = duplicates.map(obj => getLabel(obj.occasion_id, state.options[1]));
      } else {
        state.editIndex = state.editIndex === -1 ? 0 : state.editIndex;
        stateObj['newItems'][state.editIndex] = { 'occasion_id': holidayname, 'holiday_type_id': holidaytype, 'date': date, year: ValidationService.getYear(date) };
        stateObj['occasion_id'] = '';
        stateObj['holiday_type_id'] = '';
        stateObj['date'] = '';
        stateObj['editIndex'] = stateObj['newItems'].length;
      }
    } else {
      stateObj['nameWarning'] = true;
      stateObj['uniqueError'] = false;
    }
    setState(stateObj);
  }

  return (
    <>
      <Header
        headerName={state.editFlow != 0 ? t("Edit holiday list") : t("Add holiday list")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar pb-2">
        <div className="row">
          <BackLink backLink="/manage/holiday-list" />
        </div>
      </div>
      <div className="createpagesBg">
        {state.editFlow == 0 && <div className="row">
          <div className="col-3">
            <LabelField
              title={t("Export year")}
              className="tab-subtitle"
            />
            <MultiSelect
              options={state.options[2] != undefined && state.options[2]}
              standards={state.options.length > 0 && state.options[2].filter(val => val.value === state.exportyear)}
              handleChange={(obj, e) => { onSelect(obj, e) }}
              isMulti={false}
              name='exportyear'
              className='col-md-12'
              placeholder={t('Years')}
            />
          </div>
          <div className="col-3">
            <LabelField
              title={t("Import year")}
              className="tab-subtitle"
            />
            <MultiSelect
              options={state.options[2] != undefined && state.options[2]}
              standards={state.options.length > 0 && state.options[2].filter(val => val.value === state.importyear)}
              handleChange={(obj, e) => { onSelect(obj, e) }}
              isMulti={false}
              name='importyear'
              className='col-md-12'
              placeholder={t('Years')}
            />
            {state.cloneErr && <span className="text-danger">{t("Import year should not be less than or equal to Export Year")}</span>}
          </div>
          <div className="col-3" style={{ marginTop: "1.5vw" }}>
            <Button
              className="searchBtn"
              type="button"
              handleClick={() => cloneHolidays(state.newItems, state.importyear, state.exportyear)}
              title={"Clone"}
            />
          </div>

          {state.newItems.length > 0 && (state.editFlow == 0) &&
            <table>
              {state.newItems.map((item, index) =>
                <tr key={index} id={index}>
                  <td > {getLabel(item.occasion_id, state.options[1])} </td>
                  <td > {getLabel(item.holiday_type_id, state.options[0])} </td>
                  <td > {item.date} </td>
                  <td> {getNeededActions(item, index)} </td>
                </tr>
              )}
            </table>
          }
        </div>}
        <div className="row mt-4">
          {/* edit - holiday name */}
          <div className="col-3">
            <LabelField
              title={`Holiday name`}
              className="tab-subtitle"
            />
            {console.log(state, "st")}
            <MultiSelect
              options={state.options[1]}
              disabled={(state.editFlow != 0) ? true : false}
              handleChange={(obj, e) => { onSelect(obj, e) }}
              standards={state.options.length > 0 && state.options[1].filter(val => val.value === state.occasion_id)}
              isMulti={false}
              name='occasion_id'
            />
            {state.nameWarning && state.occasion_id == '' && <span className="text-danger">{t("This field is required")}</span>}
          </div>

          <div className="col-3">
            {/* edit - date */}
            <LabelField
              title={`Date`}
              className="tab-subtitle"
              isMandatory={true}
            />
            <div className="fullWidthCalander">
              <DatePicker
                disabled={false}
                inputClass='form-control inputFieldColor'
                name='date'
                placeholder={t('Date')}
                minDate={state.importyear ? new Date(getLabel(state.importyear, state.options[2])) : new Date()}
                onChange={(e) => handleChange(e.format('DD-MM-YYYY'))}
                value={(state.editFlow != 0) ? state.date : ""}

                ClassName="form-control"
                style={{ width: '100%', height: '1.301vw', padding: '0.25vw', borderradius: '0px' }}
                format="DD-MM-YYYY"
              />
            </div>
          </div>

          <div className="col-3">
            {/* edit - holiday type */}
            <LabelField
              title={`Holiday type`}
              className="tab-subtitle"
              isMandatory={true}
            />
            <MultiSelect
              options={state.options[0]}
              standards={state.options.length > 0 && state.options[0].filter(val => val.value == state.holiday_type_id)}
              handleChange={(obj, e) => { onSelect(obj, e) }}
              isMulti={false}
              value={state.holiday_type_id}
              name='holiday_type_id'
              placeholder={t('holiday type')}
            />
            {state.nameWarning && state.holiday_type_id == '' && <span className="text-danger">{t("This field is required")}</span>}
          </div>
          <div className="col-3" style={{ marginTop: "1.5vw" }}>
            {state.editFlow == 0 &&
              <Button
                className="searchBtn me-3"
                type="button"
                title={t("Add")}
                handleClick={() => addItemAndUpdateIndex({ ...state }, state.date, state.occasion_id, state.holiday_type_id, state.year, state.clone)}
              />
            }
            <Button
              className="searchBtn"
              type="button"
              handleClick={handleSubmit}
              title={t("Save")}
            // disabled={state.exportyear ? state.importyear ? state.clone == 1 ? false : true : '' : '' || state.newItems.length <= 0}
            />
          </div>
        </div>

        {state.uniqueError && state.duplicates &&
          <small
            className="form-text text-muted col-md-5 error_text error p-0 mt-2">
            {`${state.duplicates.length > 1 ? state.duplicates.join(', ') : state.duplicates[0]} ${state.duplicates.length > 1 ? t(' holiday names already exists') : t(' holiday name already exists')}`}
          </small>}
      </div>
    </>
  )

}

export default translate(OperationHolidaysList);