import React, { useEffect, useState } from "react";
import { t } from "../common/atoms/translation";
import Header from "../common/layout/Header";
import DateAndTime from "../common/atoms/DateWithTime";
import PlanningTable from "../Planning/PlanningTable";
import PlanningTabs from "../Planning/PlanningTabs";
import { selectPlanningShow } from "../../features/planning/planningSlice";
import { useSelector } from "react-redux";
import DashboardPlannings from "../Planning/DashboardPlannings";
import DashboardAccordion from "../common/atoms/DashboardAccordion";
import { selectCurrentLocations } from "../../features/auth/AuthSlice";
import { ApiCall } from "../../services/ApiServices";
import { GET_TODAY_PLANNINGS } from "../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import TimeRegistrationTable from "../TimeRegistration/TimeRegistrationTable";
import LoadingIcon from "../common/utlis/LoadingIcon";
import CustomAlert from "../common/atoms/CustomAlert";

interface User {
  user_id: number;
  profilePhoto: string | null;
  name: string;
  start_time: string;
  end_time: string;
  employee_type: string;
  employee_type_id: number;
  pc_name: string;
  salary: string;
  function_name: string;
  function_id: number;
  planning_id: number;
  location_id: string;
  company_id: string;
  planned_date: string;
  shifts: {
    shift1: { start_time: string; end_time: string };
    shift2?: { start_time: string; end_time: string };
  };
  display_timings: string;
  latest_shift_status?: string;
  status?: string;
}

const LocationDashboard = () => {

  const show = useSelector(selectPlanningShow); // Access the `show` state
  const locations = useSelector(selectCurrentLocations) ?? [];
  const user = useSelector((state: any) => state?.auth);
  const companyId = useSelector((state: any) => state?.auth.currentCompany?.id);
  // const [todayPlanningsByLocation , setTodayPlanningsByLocation] = useState([]);
  const [todayPlanningsByLocation, setTodayPlanningsByLocation] = useState<Record<number, any>>({});
  const [refresh, setRefresh] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [coopStatus,setCoopStatus] = useState<boolean>(false);


  // State to track which accordion is expanded
  const [expandedAccordion, setExpandedAccordion] = useState<number | null>();

  useEffect(() => {
    if (locations.length > 0) {
      handleToggle(locations[0].id)
    }
  }, []);


  const handleToggle = async (locationId: number) => {
    // If the accordion is already expanded, close it by setting the state to null
    if (expandedAccordion === locationId) {
      setExpandedAccordion(null);
      return;
    }

    // Open the clicked accordion and set the state to its ID
    setExpandedAccordion(locationId);

    // Existing API call logic...
    console.log(companyId, locationId);

    // Check if the locationId already exists in the state and has valid user data
    if (todayPlanningsByLocation[locationId] && todayPlanningsByLocation[locationId].length > 0) {
      console.log(`Data for locationId ${locationId} already exists and is valid. Skipping API call.`);
      return; // Skip the API call if the data already exists and is valid
    }

    setLoading(true);
    try {
      const response = await ApiCall.getService(
        `${GET_TODAY_PLANNINGS}?company_id=${companyId}&location_id=${locationId}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == 'success') {

        console.log('API Response:', response.data);

        const { start, pause, stop, yetToStart } = response.data;

        const users: User[] = [];

        const addUsersWithStatus = (statusData: { [key: string]: User }, status: string) => {
          Object.values(statusData).forEach((user) => {
            users.push({
              ...user,
              status,
            });
          });
        };

        addUsersWithStatus(start, "start");
        addUsersWithStatus(pause, "pause");
        addUsersWithStatus(stop, "stop");
        addUsersWithStatus(yetToStart, "yetToStart");

        // Store planning data with users and status in state
        setTodayPlanningsByLocation((prevState) => ({
          ...prevState,
          [locationId]: users,
        }));
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      // Set loading to false after the API call completes (whether successful or not)
      setLoading(false);
    }
  };

  const updateEmployeeStatus = async (locationId: number, employeeId: number) => {
    console.log(companyId, locationId);
    try {
      const response = await ApiCall.getService(
        `${GET_TODAY_PLANNINGS}?company_id=${companyId}&location_id=${locationId}&employee_id=${employeeId}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == 'success') {

        console.log('API Response:', response.data);

        const { start, pause, stop, yetToStart } = response.data;

        const users: User[] = [];

        const addUsersWithStatus = (statusData: { [key: string]: User }, status: string) => {
          Object.values(statusData).forEach((user) => {
            users.push({
              ...user,
              status,
            });
          });
        };

        addUsersWithStatus(start, "start");
        addUsersWithStatus(pause, "pause");
        addUsersWithStatus(stop, "stop");
        addUsersWithStatus(yetToStart, "yetToStart");

        setTodayPlanningsByLocation((prevState) => {
          const existingLocationData = prevState[locationId] || [];
          const existingUserMap = existingLocationData.reduce((acc: any, user: User) => {
            acc[user.user_id] = user;
            return acc;
          }, {});

          users.forEach((user) => {
            if (existingUserMap[user.user_id]) {
              existingUserMap[user.user_id] = {
                ...existingUserMap[user.user_id], // Retain existing data
                ...user, // Merge with new data
              };
            }
          });

          const updatedLocationData = Object.values(existingUserMap);
          console.log(updatedLocationData, "updated location data with employees");

          return {
            ...prevState,
            [locationId]: updatedLocationData,
          };
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <Header headerName={t("Dashboard")} headerClassName="myAccountCardTitle" />
      {!coopStatus &&
        <CustomAlert className="alert alert-warning" iconName="warningIcon">
          {t(`Dear Customer, At this moment, we are unable to create a schedule for you in the system, as your partnership agreement has not been signed yet. You do have access to the platform to consult information, but in order to have a definitive schedule made, it is necessary that you sign the partnership agreement. If it is urgent, a schedule can always be requested via`)}
          <a className="text-decoration-underline" href="mailto:payroll@absoluteyou.be"> payroll@absoluteyou.be</a>.
          {t(`We kindly request you to address this as soon as possible, so that we can fully assist you.`)}
        </CustomAlert>
      }
      {locations.length > 0 ? (
        locations.map((location: any, index: number) => {
          const locationId = location.id;
          if (!locationId) return null;

          return (
            <div className="mb-2" key={locationId}>
              <DashboardAccordion
                accordionTitle={t(location.name)}
                accordionTitleClassName="w-100 text-center poppins-medium dashboardAccordionTitle mobileFont"
                accordionButton="dashboardAccordionHeader shadow-none"
                id={locationId}
                expanded={expandedAccordion === locationId}
                onToggle={() => handleToggle(locationId)}
                accordionBodyClassName="locationDashboardAccordionData"
                ariaExpanded={expandedAccordion === locationId}
              >
                {loading ? (
                  <LoadingIcon />
                ) : (
                  <TimeRegistrationTable
                    selectedDate={new Date()}
                    page="dashboard"
                    companyId={companyId}
                    selectedLocation={locationId}
                    employeesData={todayPlanningsByLocation[locationId]}
                    refresh={(employeeId: number) => updateEmployeeStatus(locationId, employeeId)}
                  />
                )}
              </DashboardAccordion>
            </div>
          );
        })
      ) : (
        <p className="text-center">No locations found for the company</p>
      )}

      {show && <PlanningTabs />}
    </>
  );
};

export default LocationDashboard;
