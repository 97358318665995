import React, { useContext } from "react";
import LinkCoeffEmpContext from "./LinkCoeffEmp/LinkCoeffEmpContext";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";

const DEFAULT = 2;
const CoeffcientValuesFirstPart = (props) => {
  const { state } = useContext(LinkCoeffEmpContext);
  const { coefficientTypeArray, valueTypeArray } = state;
  const getTableContent = () => {
    let htmlContent = [];
    coefficientTypeArray.map((coefficient) => {
      let tempValueTypeArray = [...valueTypeArray];
      htmlContent.push(
        <tr key={coefficient.id} id={coefficient.id}>
          <td
            rowSpan={valueTypeArray.length}
            title={coefficient.name}
            className="ps-3"
          >
            {coefficient.name}
          </td>
          <td className="employee-type-coefficient">
            {tempValueTypeArray.shift().name}
          </td>
        </tr>
      );
      tempValueTypeArray.map((valueType) => {
        let key = `${coefficient.id}-${valueType.id}`;
        htmlContent.push(
          <tr className="" key={key} id={key}>
            <td className="employee-type-coefficient">
              {parseInt(valueType.id) === DEFAULT ? (
                <div className="poppins-medium" style={{ fontSize: window.innerWidth <= 767
                  ? "3.5vw"
                  : window.innerWidth <= 1023
                    ? "1.95vw"
                    : "0.9vw" }}>
                  {valueType.name}
                </div>
              ) : (
                valueType.name
              )}
            </td>
          </tr>
        );
        return 1;
      });
    });
    return htmlContent;
  };

  return (
    <table className="table mb-0 defaultCoefficientTableLeftSection table-bordered customTableProperties">
      <thead>
        <tr className="TableHeader">
          <th colSpan="2" style={{ height: window.innerWidth <= 767
                      ? "3.5vw"
                      : window.innerWidth <= 1023
                      ? "10.99vw"
                      : "5.99vw", }} className="p-0">
            <div className="firstpart-cell">
              <div
                className="text-end pt-lg-4 pt-2 poppins-medium pe-3"
                key={`tablecolindex`}
                style={{
                  fontSize:
                    window.innerWidth <= 767
                      ? "3.5vw"
                      : window.innerWidth <= 1023
                      ? "1.95vw"
                      : "0.9vw",
                }}
                scope="col"
              >
                {t("Employee types")}
              </div>
              <hr />
              <div
                key={`tablecolindex2`}
                scope="col"
                style={{
                  fontSize:
                    window.innerWidth <= 767
                      ? "3.5vw"
                      : window.innerWidth <= 1023
                      ? "1.95vw"
                      : "0.9vw",
                }}
                className="poppins-medium pb-lg-3 pb-2 d-flex align-self-end ps-3"
              >
                {t("Coefficients")}
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody className="pclinking-table-tbody table-first-part-tbody">
        {getTableContent()}
      </tbody>
    </table>
  );
};

export default translate(CoeffcientValuesFirstPart);
