import React from "react";
import Calender from "../../../common/molecules/Calender";
import LabelField from "../../../common/atoms/LabelField";
import SelectWithSearch from "../../../common/atoms/SelectWithSearch";
import { EmployeePersonalProps, Option } from "./../../registration/Interfaces";
import { t } from "../../../CentralDataMangement/translation/Translation";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";

const EmployeePersonal: React.FC<EmployeePersonalProps> = ({
  state,
  countryOptions,
  optionsData,
  handleDateFormat,
  handleChange,
  handleSelectChange,
}) => {
  return (
    <div className="row">
      <div className="col-lg-4">
        <div className="row mb-3">
          <div className="col-4 leftlabelClass">
            <LabelField title={t("Date of birth") + ":"} isMandatory={true} />
          </div>
          <div className="col-8 position-relative calendarSectionWrapper">
            <div className="position-relative">
              <Calender
                onChange={(date) =>
                  date && handleDateFormat(date, "date_of_birth")
                }
                maxDate={new Date()}
                selectedDate={
                  state?.date_of_birth ? new Date(state?.date_of_birth) : null
                }
                error={state?.errors?.date_of_birth ?? undefined}
                name="date_of_birth"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-4 mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Place of birth") + ":"}
            className="inputFieldColor ps-2"
            colClassName="col-8"
            type="text"
            value={state?.place_of_birth}
            handleChange={handleChange("place_of_birth")}
            name="place_of_birth"
            id="place_of_birth"
            error={state?.errors?.place_of_birth ?? undefined}
            labelWrapperClassName="col-4 leftlabelClass"
            isMandatory={true}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="col-lg-4 mb-3 multiSelectWrapper">
        <div className="row">
          <SelectWithSearch
            fieldLabel={`${t("Country of birth")}:`}
            title={t("Country of birth") + ":"}
            name="country_of_birth_id"
            placeHolder={t("Select")}
            error={state?.errors?.country_of_birth_id ?? undefined}
            isMandatory
            search
            options={countryOptions.countries}
            value={
              countryOptions.countries.find(
                (option) => option.value === state.country_of_birth_id
              ) || null
            }
            onChange={(selectedOption: Option) =>
              handleSelectChange(selectedOption, "country_of_birth_id")
            }
            isMulti={false}
            className="select-field"
            isTranslate
            labelWrapperClassName="col-4 leftlabelClass"
            colClassName="col-8"
          />
        </div>
      </div>
      <div className="col-lg-4 mb-3 multiSelectWrapper">
        <div className="row">
          <SelectWithSearch
            fieldLabel={t("Gender") + ":"}
            title={t("Gender") + ":"}
            name="gender_id"
            placeHolder={t("Select")}
            isMandatory={true}
            search={true}
            options={optionsData.gender}
            value={
              optionsData.gender.find(
                (option) => option.value === state.gender_id
              ) || null
            }
            onChange={(selectedOption: Option) =>
              handleSelectChange(selectedOption, "gender_id")
            }
            isMulti={false}
            className="select-field"
            error={state?.errors?.gender_id ?? undefined}
            isTranslate={true}
            labelWrapperClassName="col-4 leftlabelClass"
            colClassName="col-8"
          />
        </div>
      </div>
      <div className="col-lg-4 mb-3 multiSelectWrapper">
        <div className="row">
          <SelectWithSearch
            fieldLabel={t("Desired employee type") + ":"}
            title={t("Desired employee type") + ":"}
            name="desired_employee_type_id"
            placeHolder={t("Select")}
            isMandatory={true}
            search={true}
            options={[...optionsData.employee_type_groups].sort((a, b) =>
              a.label.localeCompare(b.label)
            )}
            value={
              optionsData.employee_type_groups.find(
                (option) => option.value === state.desired_employee_type_id
              ) || null
            }
            onChange={(selectedOption: Option) =>
              handleSelectChange(selectedOption, "desired_employee_type_id")
            }
            isMulti={false}
            className="select-field"
            error={state?.errors?.desired_employee_type_id ?? undefined}
            isTranslate={true}
            labelWrapperClassName="col-4 pe-0 align-self-center"
            colClassName="col-8"
          />
        </div>
      </div>
      <div className="col-lg-4 mb-3 multiSelectWrapper">
        <div className="row">
          <SelectWithSearch
            fieldLabel={t("Education") + ":"}
            title={t("Education") + ":"}
            name="education_id"
            placeHolder={t("Select")}
            isMandatory={true}
            search={true}
            options={optionsData.education}
            value={
              optionsData.education.find(
                (option) => option.value === state.education_id
              ) || null
            }
            onChange={(selectedOption: Option) =>
              handleSelectChange(selectedOption, "education_id")
            }
            isMulti={false}
            className="select-field"
            error={state?.errors?.education_id ?? undefined}
            isTranslate={true}
            labelWrapperClassName="col-4 leftlabelClass"
            colClassName="col-8"
          />
        </div>
      </div>
      <div className="col-lg-4 multiSelectWrapper mb-3">
        <div className="row">
          <SelectWithSearch
            fieldLabel={t("Marital status") + ":"}
            title={t("Marital status") + ":"}
            name="civil_status_id"
            placeHolder={t("Select")}
            isMandatory={true}
            search={true}
            options={optionsData.civil_status}
            value={
              optionsData.civil_status.find(
                (option) => option.value === state.civil_status_id
              ) || null
            }
            onChange={(selectedOption: Option) =>
              handleSelectChange(selectedOption, "civil_status_id")
            }
            isMulti={false}
            className="select-field"
            error={state?.errors?.civil_status_id ?? undefined}
            isTranslate={true}
            labelWrapperClassName="col-4 leftlabelClass"
            colClassName="col-8"
          />
        </div>
      </div>
      <div className="col-lg-4 multiSelectWrapper mb-3">
        <div className="row">
          <SelectWithSearch
            fieldLabel={t("Dependent spouse") + ":"}
            title={t("Dependent spouse") + ":"}
            name="dependent_spouce_id"
            placeHolder={t("Select")}
            isMandatory={true}
            search={true}
            options={optionsData?.dependent_spouce ?? []}
            value={
              optionsData?.dependent_spouce?.find(
                (option) =>
                  option?.value === state?.dependencies?.dependent_spouce_id
              ) || null
            }
            onChange={(selectedOption: Option) =>
              handleSelectChange(
                selectedOption,
                "dependencies.dependent_spouce_id"
              )
            }
            isMulti={false}
            className="select-field"
            error={
              state?.errors?.["dependencies.dependent_spouce_id"] ?? undefined
            }
            isTranslate={true}
            labelWrapperClassName="col-4 leftlabelClass"
            colClassName="col-8"
          />
        </div>
      </div>
      <div className="col-lg-4 multiSelectWrapper mb-3">
        <div className="row">
          <LabelWithInputField
            label={t("Dependent children") + ":"}
            type="number"
            value={
              state.id !== 0
                ? state.dependencies.dependent_children
                : state.dependencies.dependent_children === 0
                  ? null
                  : state.dependencies.dependent_children
            }
            handleChange={handleChange("dependencies", "dependent_children")}
            name={"dependent_children"}
            id="dependent_children"
            isMandatory={true}
            error={
              state?.errors?.["dependencies.dependent_children"] ?? undefined
            }
            className="inputFieldColor ps-2"
            colClassName="col-8"
            labelWrapperClassName="col-4 align-self-center"
            min={0}
            autoComplete="off"
          />
        </div>
      </div>
      <div className="col-lg-4">
        <div className="row mb-3 multiSelectWrapper">
          <SelectWithSearch
            fieldLabel={t("Language") + ":"}
            title={t("Language") + ":"}
            name="language_id"
            placeHolder={t("Select")}
            isMandatory={true}
            search={true}
            options={optionsData.languages}
            value={
              optionsData.languages.find(
                (option) => option.value === state.language_id
              ) || null
            }
            onChange={(selectedOption: Option) =>
              handleSelectChange(selectedOption, "language_id")
            }
            isMulti={false}
            className="select-field"
            error={state?.errors?.language_id ?? undefined}
            isTranslate={true}
            labelWrapperClassName="col-4 leftlabelClass"
            colClassName="col-8"
          />
        </div>
      </div>
      <div className="col-lg-4 mb-3 multiSelectWrapper">
        <div className="row">
          <SelectWithSearch
            fieldLabel={t("Driving licence") + ":"}
            title={t("Driving licence") + ":"}
            name="driving_license_id"
            placeHolder={t("Select")}
            isMandatory={true}
            search={true}
            options={optionsData.driving_license}
            value={
              optionsData.driving_license.find(
                (option) => option.value === state.driving_license_id
              ) || null
            }
            onChange={(selectedOption: Option) =>
              handleSelectChange(selectedOption, "driving_license_id")
            }
            isMulti={false}
            className="select-field"
            error={state?.errors?.driving_license_id ?? undefined}
            isTranslate={true}
            labelWrapperClassName="col-4 leftlabelClass"
            colClassName="col-8"
          />
        </div>
      </div>
      <div className="col-lg-4 mb-3 multiSelectWrapper">
        <div className="row">
          <SelectWithSearch
            fieldLabel={t("Transport to work") + ":"}
            title={t("Transport to work") + ":"}
            name="transport_type_id"
            placeHolder={t("Select")}
            search={true}
            options={optionsData.transportations}
            value={
              optionsData.transportations.find(
                (option) => option.value === state.transport_type_id
              ) || null
            }
            onChange={(selectedOption: Option) =>
              handleSelectChange(selectedOption, "transport_type_id")
            }
            isMandatory={true}
            className="select-field"
            error={state?.errors?.transport_type_id ?? undefined}
            isTranslate={true}
            labelWrapperClassName="col-4 leftlabelClass"
            colClassName="col-8"
          />
        </div>
      </div>
    </div>
  );
};

export default EmployeePersonal;
