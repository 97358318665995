import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  ADD_LOCATION_MANAGERS,
  COMPANY_EMPLOYEES,
  GET_LOCATION_MANAGERS,
  REMOVE_LOCATION_MANAGER,
} from "../../routes/ApiEndpoints";
import { ApiCall } from "../../services/ApiServices";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import SelectWithSearch from "../common/atoms/SelectWithSearch";
import BackLink from "../common/atoms/BackLink";
import Button from "../common/atoms/Button";
import NoRecords from "../common/atoms/NoRecords";
import LocationManagersList from "../common/atoms/LocationManagersList";
import LocationUsersList from "../common/atoms/LocationUsersList";

interface User {
  id: number;
  name: string;
  email: string;
  profile_pic: string | null;
  since: string;
}

interface SelectOption {
  value: number;
  label: string;
}

interface ManageLocationManagersUsersProps {
  role: "employee" | "location_manager";
}

const ManageLocationManagersUsers: React.FC<ManageLocationManagersUsersProps> = ({ role }) => {
  const { id } = useParams<{ id: string }>();
  const companyId = useSelector((state: any) => state?.auth.currentCompany?.id);

  // State management
  const [availableUsers, setAvailableUsers] = useState<SelectOption[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<SelectOption[]>([]);
  const [assignedUsers, setAssignedUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  // Fetch both assigned users and available employees
  const fetchData = async () => {
    setIsLoading(true);
    try {
      // Fetch assigned users (managers or employees)
      const assignedResponse = await ApiCall.getService(
        `${GET_LOCATION_MANAGERS}?location_id=${id}&key=${role}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (assignedResponse.status === 'success') {
        setAssignedUsers(assignedResponse.data);
      }

      // Fetch all company employees
      const employeesResponse = await ApiCall.getService(
        `${COMPANY_EMPLOYEES}?company_id=${companyId}`,
        "GET",
        "api",
        true
      );

      if (employeesResponse.status === 'success') {
        const allUsers: SelectOption[] = employeesResponse.data.map((employee: any) => ({
          value: employee.id,
          label: `${employee.first_name} ${employee.last_name}`,
        }));

        // Filter out already assigned users
        const assignedIds = assignedResponse.data.map((user: User) => user.id);
        const availableOptions = allUsers.filter(user => !assignedIds.includes(user.value));

        setAvailableUsers(availableOptions);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (companyId && role) {
      fetchData();
    }
  }, [companyId, role, id]);

  const handleUserSelection = (selected: SelectOption[]) => {
    console.log(selected);
    setSelectedUsers(selected);
  };

  const handleRemoveUser = async (userId: number) => {
    try {
      const payload = {
        user_ids: [userId],
        company_id: companyId,
        location_id: id,
        key: role
      };

      const response = await ApiCall.service(
        REMOVE_LOCATION_MANAGER,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 'success') {
        await fetchData(); // Refresh both lists
      }
    } catch (error) {
      console.error("Error removing user:", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const payload = {
        company_id: companyId,
        location_id: id,
        user_ids: selectedUsers.map(user => user.value),
        key: role
      };

      const response = await ApiCall.service(
        ADD_LOCATION_MANAGERS,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );

      if (response.status === 'success') {
        setSelectedUsers([]); // Clear selection
        await fetchData(); // Refresh both lists
      }
    } catch (error) {
      console.error("Error adding users:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderUsersList = () => {
    if (assignedUsers.length === 0) {
      return (
        <NoRecords
          isDiv={true}
          message={t(`No ${role === 'location_manager' ? 'manager' : 'user'} found, please add one`)}
        />
      );
    }

    return role === 'location_manager' ? (
      <LocationManagersList
        managers={assignedUsers}
        onRemove={handleRemoveUser}
      />
    ) : (
      <LocationUsersList
        users={assignedUsers}
        onRemove={handleRemoveUser}
      />
    );
  };

  return (
    <>
      <div className="mb-3">
        <SelectWithSearch
          search={true}
          options={availableUsers}
          value={selectedUsers}
          onChange={handleUserSelection}
          placeHolder={t("Select employees")}
          isMulti={true}
          className="country-select"
          isTranslate={false}
          isMandatory={true}
          fieldLabel={t("Select employees")}
          title={t("Select employees")}
        />
      </div>
      {renderUsersList()}
      <div className="row py-4">
        <div className="col-12">
          <Button
            title={t("Save")}
            handleClick={handleSubmit}
            className="searchBtn float-end px-3"
            toolTipName="Click to save"
            disabled={isLoading || selectedUsers.length === 0}
            type="submit"
          />
        </div>
      </div>
    </>
  );
};

export default translate(ManageLocationManagersUsers);