import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import ModalPopup from "../common/atoms/ModalPopup";
import Title from "../common/atoms/Title";
import "./css/planning.css";
import IconWithTitle from "../common/molecules/IconWithTitle";
import PlanningCalendar from "../common/molecules/PlanningCalendar";
import Icon from "../../Icon";
import CalendarIcon from "../common/atoms/CalendarIcon";
import {
  addEmployee,
  removeEmployee,
  resetAllData,
  selectCompanyId,
  selectContractType,
  selectDates,
  selectEmployees,
  selectLocationId,
  setTab,
} from "../../features/planning/planningSlice";
// import employeesData from "./employees.json";
import userIcon from "../../static/images/User.svg";
import { } from "../common/utlis/dateHelper"; // Adjust the import path as needed
import OverFlowWrapper from "../common/OverFlowWrapper";
import PlanningEmployeeSearch from "../common/molecules/PlanningEmployeeSearch";
import { ApiCall } from "../../services/ApiServices";
import {
  selectCompanyEmployees,
  selectPCData,
  setEmployeeData,
  setPCData,
} from "../../features/planning/employeeSlice";
import { generateSchedule } from "./Services/PlanningHelper";
import AgeIcon from "../common/atoms/AgeIcon";
import { fetchPcInfo } from "./Services/PlanningServices";
import { RootState } from "../../store";
import { COMPANY_EMPLOYEES } from "../../routes/ApiEndpoints";
import { useResponsiveValue } from "../common/atoms/ResponsivWidth";

interface Shifts {
  start_time: string;
  end_time: string;
  sequence: number;
}

interface Schedule {
  location_id: number;
  project_id?: number;
  cost_center_id?: number;
  shifts: Shifts[]; // First shift
  error?: string;
  warning?: string;
}

interface ScheduleMap {
  [date: string]: Schedule;
}

interface Employee {
  id: number; // Already exists
  first_name: string; // New field for the first name
  last_name: string; // New field for the last name
  profile_picture_url?: string; // New optional field for profile picture
  employee_type: string; // Already exists, no change
  employee_type_id: number; // Already exists, no change
  employee_type_category: string; // New field for employee type category
  employee_type_category_id: number; // New field for employee type category ID
  paritaire_commitee: string; // Update the name, and it can be null
  paritaire_commitee_id: number; // Already exists, renamed
  functional_salary: string; // New field for functional salary
  function_id: number; // Already exists, no change
  function_name: string;
  age: number;
  pc_max_age: number;
}

const WeeklyPlanning = () => {
  const profileIconStyle = useResponsiveValue("10vw", "5vw", "2vw")
  const state = useSelector((state: RootState) => state);
  const [showModal, setShowModal] = useState(true);
  const [employee, setEmployees] = useState([]);
  const employees = useSelector(selectCompanyEmployees);
  const [filteredEmployees, setFilteredEmployees] =
    useState<Employee[]>(employees); // New state for filtered employees
  const dispatch = useDispatch();
  const monthView = useSelector(selectContractType);
  const selectedEmployees = useSelector(selectEmployees);
  const companyId = useSelector(selectCompanyId);
  const locationId = useSelector(selectLocationId);
  const selectedDates = useSelector(selectDates);
  const [searchValue, setSearchValue] = useState<string>("");
  const [error, setError] = useState({
    datesError: "",
    employeesError: "",
  });
  const CompanyId = useSelector((state: any) => state.auth.currentCompany?.id);
  const fetchInfo = async () => {
    try {
      const res = await ApiCall.getService(
        `${COMPANY_EMPLOYEES}?company_id=${companyId}&bright=true&for=planning`,
        "GET"
      );

      if (res.status == "success") {
        setEmployees(res.data);
        setFilteredEmployees(res.data);
        // dispatch(setEmployeeData(res.data)); // Dispatch the data to Redux
      } else {
        throw new Error(res.message);
      }
      if (res.status !== "success") {
        throw new Error("Network response was not ok");
      }
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchInfo();
    fetchPcInfo(CompanyId, dispatch, state);
  }, [CompanyId]);

  const handleCloseModal = () => {
    dispatch(resetAllData());
    setShowModal(false);
  };

  const handleNextClick = () => {
    const isValid = validation();
    if (isValid) {
      dispatch(setTab("tab_2"));
    }
  };

  const validation = () => {
    let valid = true;
    let errorObj = { datesError: "", employeesError: "" };

    if (selectedDates.length === 0 && monthView != "month") {
      errorObj.datesError = "Please select dates.";
      valid = false;
    }

    if (selectedEmployees.length === 0) {
      errorObj.employeesError = "Please select employees.";
      valid = false;
    }

    setError(errorObj);
    return valid;
  };

  const handleEmployeeClick = (employee: Employee) => {
    setError({
      datesError: "",
      employeesError: "",
    });
    if (selectedDates.length === 0 && monthView != "month") {
      setError({
        datesError: "Please select dates",
        employeesError: "",
      });
      return;
    }

    const isSelected = selectedEmployees?.some(
      (emp: any) => emp.employee_id === employee.id
    );
    if (isSelected) {
      dispatch(removeEmployee(employee.id));
    } else {
      const schedule = generateSchedule(selectedDates, locationId); // Use the custom hook'

      const newEmployee = {
        company_id: companyId,
        employee_id: employee.id,
        employee_name: `${employee.first_name} ${employee.last_name}`,
        location_id: locationId,
        age: employee.age,
        employee_type_id: employee.employee_type_id,
        employee_type: employee.employee_type,
        pc_id: employee.paritaire_commitee_id,
        pc: employee.paritaire_commitee,
        function_id: employee.function_id,
        function: employee.function_name,
        salary: employee.functional_salary,
        start_date: selectedDates[0], // Add start_date
        end_date:
          selectedDates.length > 1 ? selectedDates[1] : selectedDates[0],
        schedule: schedule,
        pc_max_age: employee.pc_max_age,
      };
      dispatch(addEmployee(newEmployee));
    }
  };

  const isEmployeeSelected = (employeeId: number) => {
    return selectedEmployees?.some(
      (emp: any) => emp.employee_id === employeeId
    );
  };

  const handleEmployeeSelect = (employee: Employee) => {
    handleEmployeeClick(employee);
  };

  const handleSearchEmployeeSelect = (values: string) => {
    // If the employee parameter is an array, update the filtered employees state
    console.log(values);
    const filtered = employee?.filter((emp: any) =>
      `${emp.first_name} ${emp.last_name}`
        .toLowerCase()
        .includes(values.toLowerCase())
    );
    setFilteredEmployees(filtered);
    setSearchValue(values);
  };

  return (
    <>
      <ModalPopup
        show={showModal}
        handleClose={handleCloseModal}
        modalTitle={t("Employee scheduling")}
        modalBackgroundColor="#8571b9"
        className="planningModal"
        buttonText={t("Next")}
        showNextIcon={true}
        onNextClick={handleNextClick}
        iconFillColor="#8571b9"
      >
        <div className="mb-2">
          <div className="row">
            <div className="col-10">
              {monthView !== "month" && (
                <Title
                  title={`${t("Choose a day for which you want to plan")}:`}
                  className="bodyTitle colorPrimary"
                />
              )}
            </div>
            <div className="col-2">
              <CalendarIcon isCalendarIcon={true} page="planning" />
            </div>
          </div>
        </div>

        <div>
          {monthView !== "month" && (
            <PlanningCalendar
              isReactCalendar={true}
              customClassName={error.employeesError ? "error-border" : ""}
            />
          )}
          {/* Error Message for Calendar */}
          {error.datesError && (
            <div className="error-message">{error.datesError}</div>
          )}
          {((selectedDates?.length > 0 && monthView != "month") ||
            monthView === "month") && (
              <>
                <>
                  <div className="colorPrimary poppins-medium planningEmployeesTitle mobileFont">
                    {t(
                      "Choose an employee(s) for whom you want to create a schedule:"
                    )}
                  </div>
                  <div className="d-flex align-items-center justify-content-between mb-3">
                    <span
                      data-bs-toggle="tooltip"
                      title={t("Filter")}
                      onClick={() => { }}
                    >
                      <Icon
                        name="FilterIcon"
                        width={window.innerWidth <= 767
                          ? "3.5vw"
                          : window.innerWidth <= 1023
                            ? "2.5vw"
                            : "1vw"}
                        height={window.innerWidth <= 767
                          ? "3.5vw"
                          : window.innerWidth <= 1023
                            ? "2.55vw"
                            : "1vw"}
                        isIcon={true}
                        className="secondaryColorHoverEffect cursor-pointer"
                      />
                    </span>
                    <PlanningEmployeeSearch
                      placeholder={t("Search employee")}
                      onEmployeeSelect={handleSearchEmployeeSelect} // Use the new handler
                      value={searchValue}
                    />
                  </div>
                </>
                <OverFlowWrapper className="planningEmployeeHeight">
                  <div
                    className={`planningEmployeeContainer d-grid overflow-auto ${error.employeesError ? "error-border" : ""
                      }`}
                  >
                    {filteredEmployees?.map((employee) => {
                      const selected = isEmployeeSelected(employee.id);
                      return (
                        <div
                          key={employee.id}
                          className={`employeeNameWithProfile cursor-pointer ${selected ? "employee-selected" : ""
                            } `}
                          onClick={() => handleEmployeeClick(employee)}
                        >
                          <div className="d-flex align-items-center h-100">
                            <div
                              className="cursor-pointer d-flex align-items-center me-2"
                              style={{ width: "85%" }}
                            >
                              <IconWithTitle
                                src={employee.profile_picture_url ?? userIcon}
                                isImage={true}
                                imageWidth={profileIconStyle}
                                imageHeight={profileIconStyle}
                                imageBorderRadius="50%"
                                title={`${employee.first_name} ${employee.last_name}`}
                                isTitle={true}
                                labelClassName={`colorPrimary poppins-medium ms-2 employeeName cursor-pointer`}
                              />
                            </div>
                            <div
                              className="align-self-center"
                              style={{ width: "15%" }}
                            >
                              <AgeIcon
                                age={employee.age}
                                pc_max_age={employee.pc_max_age}
                                type={1}
                              />
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </OverFlowWrapper>
              </>
            )}
          {error.employeesError && (
            <div className="error-message">{error.employeesError}</div>
          )}
        </div>
      </ModalPopup>
    </>
  );
};

export default translate(WeeklyPlanning);
