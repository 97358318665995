import React, { ReactNode, CSSProperties } from "react";

const defaultStyle: CSSProperties = {
  fontSize: window.innerWidth <= 767
    ? "3.5vw"
    : window.innerWidth <= 1023
      ? "1.95vw"
      : "0.9vw",
  marginBottom: "0vw"
};

interface Props {
  title: ReactNode;
  customStyle?: CSSProperties;
  isMandatory?: boolean;
  className?: string;
  htmlfor?: string;
  subtitle?: string;
  pageTitle?: boolean;
}

const LabelField: React.FC<Props> = ({
  title,
  customStyle = {},
  isMandatory = false,
  className,
  htmlfor,
  subtitle,
  pageTitle = false,
}) => {
  const combinedStyle = { ...defaultStyle, ...customStyle }; // Combine default and custom styles

  return (
    <>
      <label
        htmlFor={htmlfor}
        style={combinedStyle}
        className={`${className} form-label field-label colorPrimary  ${pageTitle ? "poppins-semibold" : "poppins-medium"}`}
      >
        {title}
        {subtitle && (
          <>
            <span className="subTitleWrapper">{subtitle}</span>
          </>
        )}
        {isMandatory && <span className="text-danger">*</span>}
      </label>
    </>
  );
};

export default LabelField;
