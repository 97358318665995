import React, { useEffect, useState } from "react";
import ReactCalendar, { OnArgs } from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { startOfWeek, endOfWeek, isWithinInterval } from "date-fns";

interface ContractCalendarProps {
  isReactCalendar?: boolean;
  iscalendarSeperator?: boolean;
  setSelectedDate?: (dates: Date | [Date, Date]) => void | any[];
  selectedDate: Date | [Date, Date];
}

const ContractCalendar: React.FC<ContractCalendarProps> = ({
  setSelectedDate,
  selectedDate,
  isReactCalendar,
}) => {
  const [contractType, setContractType] = useState<string>("day");
  const [doubleClickDate, setDoubleClickDate] = useState<Date | null>(null);

  // Extract startDate and endDate from selectedDate
  const startDate = Array.isArray(selectedDate) ? selectedDate?.[0] : selectedDate;
  const endDate = Array.isArray(selectedDate) && selectedDate?.[1] ? selectedDate[1] : null;
  const [activeStartDate, setActiveStartDate] = useState<Date>(startDate);

  useEffect(() => {
    setActiveStartDate(Array.isArray(selectedDate) ? selectedDate?.[0] : selectedDate);
  }, [selectedDate]);

  // Function to determine the class for each tile
  const tileClassName = ({ date, view }: { date: Date; view: string }) => {
    if (contractType === "week" && isWithinInterval(date, {
      start: startOfWeek(startDate || new Date(), { weekStartsOn: 1 }),
      end: endOfWeek(startDate || new Date(), { weekStartsOn: 1 })
    })) {
      return "date-selected";
    }

    if (contractType === "day" && date.toDateString() === startDate?.toDateString()) {
      return "date-selected";
    }

    if (contractType === "range" && startDate && endDate) {
      if (isWithinInterval(date, { start: startDate, end: endDate })) {
        return "date-selected";
      }
    }

    // Highlight the double-clicked date
    if (doubleClickDate && date.toDateString() === doubleClickDate?.toDateString()) {
      return "date-selected";
    }

    return "date-deselected";
  };

  // Handle onChange event for selecting date or range
  function onChange(value: any, event: React.MouseEvent<HTMLButtonElement>) {
    if (doubleClickDate) {
      setSelectedDate && setSelectedDate([value, value]);
      setContractType("day");
      setDoubleClickDate(null);
      return;
    }

    if (value === null) return;

    // If value is an array (a date range), update the selected date range
    if (Array.isArray(value)) {
      if (value?.length === 2 && value[0] instanceof Date && value?.[1] instanceof Date) {
        setContractType("range");
      }
      return;
    }

    // If a single date is selected
    if (value instanceof Date) {
      setSelectedDate && setSelectedDate(value);
      setContractType("day");
      setDoubleClickDate(null);
    }
  }

  // Handle week number click to update state and select all week days
  function onClickWeekNumber(weekNumber: number, date: Date, event: React.MouseEvent<HTMLButtonElement>) {
    const start = startOfWeek(date, { weekStartsOn: 1 });
    const end = endOfWeek(date, { weekStartsOn: 1 });

    if (setSelectedDate) {
      setSelectedDate([start, end]);
      setContractType("week");
      setDoubleClickDate(null);
    }
  }

  // Handle active start date change when navigating calendar views (months/years)
  const onActiveStartDateChange = ({
    activeStartDate,
  }: OnArgs) => {
    if (activeStartDate) {
      setActiveStartDate(activeStartDate);
    }
  };

  // Handle double-click event to select a single date
  const onTileDoubleClick = (date: Date) => {
    setDoubleClickDate(date);
    setSelectedDate && setSelectedDate(date);
    setContractType("day");

    // If a range or week was previously selected, remove that
    if (contractType === "range" || contractType === "week") {
      setSelectedDate && setSelectedDate(date);
      setContractType("day");
    }
  };

  return (
    <div className="calendarWrapper">
      <div>
        {isReactCalendar && (
          <ReactCalendar
            onChange={onChange}
            value={startDate}
            className="mx-auto"
            showWeekNumbers={true}
            onClickWeekNumber={onClickWeekNumber}
            tileClassName={tileClassName}
            onActiveStartDateChange={onActiveStartDateChange}
            onClickDay={(date: Date) => onTileDoubleClick(date)}
          />
        )}
      </div>
    </div>
  );
};

export default ContractCalendar;
