import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import { InputText } from "primereact/inputtext";
import InputTextFieldWithLabel from "../../common/molecules/InputTextFieldWithLabel";
import FileUpload from "../../common/molecules/FileUpload";
import {
  ProjectFormData,
  fileData,
  formatHouseNumber,
  formatPostalCode,
  formatStreet,
  inputFields,
  intialFileData,
} from "./ProjectUtils";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import {
  FILE_URL,
  GET_COUNTRIES,
  LOCATIONS,
  LOCATIONS_BY_COMPANY,
  PROJECTS,
  SHIFTS,
} from "../../../routes/ApiEndpoints";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import CustomNotify from "../../common/atoms/CustomNotify";
import MultiSelectField from "../../common/atoms/MultiSelectField";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../features/auth/AuthSlice";
import Image from "../../common/atoms/Image";
import LabelField from "../../common/atoms/LabelField";
import RadioField from "../../common/atoms/RadioField";
import CompanySelectCard from "../../common/atoms/CompanyCards";
import Icon from "../../../Icon";
import defaultCompanyIcon from "../../../static/images/emptyCompany.png";

const EditProject: React.FC = () => {
  // const [locationName, setLocationName] = useState("");

  interface Country {
    iso: string;
    value: number;
    label: string;
  }

  interface CompanyObj {
    value: number;
    label: string;
    url: string;
  }
  const navigate = useNavigate();

  const { id } = useParams<{ id: string }>();
  const user = useSelector(selectAuth).userId;

  const initialFormData: ProjectFormData = {
    project_name: "",
    street: "",
    number: "",
    postal_code: "",
    box: "",
    city: "",
    country_id: "",
    location_id: null,
    company_id: [],
  };
  const projectinputFields = inputFields;

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<ProjectFormData>(initialFormData);
  const [selectedCompanies, setSelectedCompanies] = useState<any[]>([]);
  const [countries, setCountries] = useState([]);
  const [locations, setLocations] = useState<Location[]>([]);
  const [companies, setCompanies] = useState<CompanyObj>({
    value: 0,
    label: "",
    url: "",
  });
  // const [selectedCountry, setSelectedCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState<any>(null);
  const [selectedLocation, setSelectedLocation] = useState<Location[] | null>(
    null
  );
  const [relatedCompanies, setRelatedCompanies] = useState<CompanyObj[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
  interface Location {
    // iso: string;
    value: number;
    label: string;
  }

  useEffect(() => {
    // First, load the countries and then fetch the location
    const loadData = async () => {
      await getCompanies();
      await getCountries();
    };
    loadData();
  }, []);

  // useEffect(() => {
  //   getCountries();
  //   // getLocations();
  // }, []);

  const getCompanies = async () => {
    const response = await ApiCall.getService(
      `get-companies-by-user-id/${user}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );

    if (response.status == "success") {
      // Convert the data to match the CompanyObj interface
      const formattedCompanies = response.data.map((company: any) => ({
        value: company.id,
        label: company.name,
        url: company.profile_picture_url ?? defaultCompanyIcon,
      }));
      setRelatedCompanies(formattedCompanies);
    }
  };

  // Fetch countries
  const getCountries = async () => {
    try {
      const response = await ApiCall.getService(
        `${GET_COUNTRIES}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == "success") {
        const countryList = response.data;
        setCountries(countryList); // Set the countries

        // Once countries are set, fetch the location
        fetchProjectById(countryList);
      }
    } catch (error) {
      console.error("Error fetching countries:", error);
    }
  };

  // Fetch the location by ID
  const fetchProjectById = async (countryList: Country[]) => {
    try {
      const response = await ApiCall.getService(
        `${PROJECTS}/${id}`,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == "success") {
        const projectData = response.data;
        setCompanies({
          value: projectData.company_id,
          label: projectData.company.official_name,
          url: defaultCompanyIcon,
        });

        setFormData({
          project_name: projectData.name || "",
          location_id: projectData.location_id || "",
          street: projectData.address?.street || "",
          number: projectData.address?.number || "",
          postal_code: projectData.address?.postal_code || "",
          box: projectData.address?.box || "",
          city: projectData.address?.city || "",
          country_id: projectData.address?.country?.id || "",
          company_id: projectData.company_id ? projectData.company_id : null,
        });

        // Pre-fill selected country and companies once data is loaded
        const countrySelected = countryList.find(
          (country: Country) =>
            country.value == projectData.address?.country?.id
        );

        setSelectedCountry(countrySelected);

        const locationData = projectData?.location;
        // Restructure location to match the Location interface
        const selectedLocation: Location = {
          value: locationData.id, // location ID as the value
          label: locationData.name, // location name as the label
        };

        setSelectedLocation([selectedLocation]);

        setSelectedCompany(projectData.company_id);
      }
    } catch (error) {
      console.error("Error fetching location:", error);
    }
  };

  const getLocations = async (companyId: number) => {
    let payload = {
      company_id: companyId,
    };
    const response = await ApiCall.service(
      LOCATIONS_BY_COMPANY,
      "POST",
      payload,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == "success") {
      const data = response.data;
      // Transform the response data to match the Location interface
      const transformedLocations: Location[] = data.map((location: any) => ({
        value: location.id,
        label: location.name,
      }));
      setLocations(transformedLocations);
      if (transformedLocations.length > 0) {
        // Set the first location in state
        //
        // setSelectedLocation([transformedLocations[0]]);
        setFormData((prevData) => ({
          ...prevData, // Keep the previous form data
          location_id: transformedLocations[0].value, // Update only the project_name key /transformedLocations[0].value
        }));
      } else {
        setLocations([]); // Reset locations if company deselected
        setSelectedLocation(null); // Reset selected location
      }
    }
  };

  const handleCompanySelect = (companyId: number | null) => {
    handleInputChange({ target: { value: companyId } }, "company_id");
    if (companyId == null) {
      setLocations([]); // Reset locations if company deselected
      setSelectedLocation(null); // Reset selected location
    }
  };

  // Handle input change for text fields
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    fieldKey: keyof ProjectFormData
  ) => {
    let value: any = "";
    switch (fieldKey) {
      case "location_id":
        setSelectedLocation(e);
        value = e?.value;
        break;
      case "country_id":
        setSelectedCountry(e);
        // value = e.iso;
        value = e?.value || "";
        break;
      case "postal_code":
        value = formatPostalCode(e.target.value);
        break;
      case "number":
        value = formatHouseNumber(e.target.value);
        break;
      case "street":
        value = formatStreet(e.target.value);
        break;
      case "company_id":
        if (e && e.target) {
          var ids = JSON.parse(e.target.value); // Extract the value from the event (radio button click)
        } else {
          var ids = e; // Use value directly if passed explicitly (e.g., from onClick)
        }
        value = ids;
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [fieldKey]: value,
    }));

    // Clear the error for the updated field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldKey]: value ? "" : prevErrors[fieldKey], // Remove the error if value is not empty
    }));
  };

  const validateForm = () => {
    let errors: Record<string, string> = {};
    // Loop through the form fields to check for validation
    projectinputFields.forEach((field) => {
      if (field.isMandatory && !formData[field.name]) {
        errors[field.name] = `${t(field.label)} is required`;
      }
    });

    if (
      !formData.company_id ||
      (Array.isArray(formData.company_id) && formData.company_id.length === 0)
    ) {
      errors["company_id"] = `${t("Company")} is required`; // Assuming you have a translation for "Company"
    }

    return errors;
  };

  // Handle form submit (with validation)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop the form submission if there are errors
    }
    try {
      let data = {
        data: {
          formData,
        },
      };
      const response = await ApiCall.service(
        `${PROJECTS}/${id}`,
        "PUT",
        formData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == "success") {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
        setFormData(initialFormData);
        setTimeout(() => {
          navigate("/projects-manage");
        }, 1000);
      } else if (
        response.status == "validation-error" ||
        response.status == "error"
      ) {
        const errorData = response.data; // Assuming the error data comes in response.data
        let updatedErrors: Record<string, string> = {};

        // Iterate through the error object and set errors for each field
        Object.keys(errorData).forEach((field) => {
          updatedErrors[field] = errorData[field][0]; // Assign the first error message
        });

        // Update form errors state
        setFormErrors(updatedErrors);

        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving Projects:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Edit project")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/projects-manage" />
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12">
              <div className="row">
                {projectinputFields.map((field, index) =>
                  field.type === "text" && field.name === "project_name" ? (
                    <div key={index} className={`${field.columnSize} mb-3`}>
                      <InputTextFieldWithLabel
                        type="text"
                        placeholder={t(field.placeholder)}
                        label={t(field.label)}
                        value={formData[field.name]}
                        name={field.name}
                        id={field.id}
                        handleChange={(e) =>
                          handleInputChange(
                            e,
                            field.name as keyof ProjectFormData
                          )
                        }
                        isMandatory={field.isMandatory}
                        colClassName="col-12"
                        error={formErrors[field.name]}
                        className="ps-2"
                        isTop={true}
                      />
                    </div>
                  ) : null
                )}
              </div>
            </div>

            {projectinputFields.map(
              (field, index) =>
                field.type === "select" && field.name === "country_id" ? (
                  <div
                    key={index}
                    className={`col-6 multiSelectWrapper mb-3 ${field.columnSize}`}
                  >
                    <SelectWithSearch
                      search={true}
                      options={countries}
                      value={selectedCountry}
                      onChange={(e) => handleInputChange(e, "country_id")}
                      placeHolder={t("Select Country")}
                      isMulti={false}
                      isDisabled={false}
                      className="country-select"
                      isTranslate={false}
                      isMandatory={true} // If mandatory
                      fieldLabel={t("Country")}
                      title={t("Country")}
                      error={formErrors["country"]}
                    />
                  </div>
                ) : field.type === "text" && field.name !== "project_name" ? (
                  <div key={index} className={`${field.columnSize} mb-3`}>
                    <InputTextFieldWithLabel
                      type="text"
                      placeholder={t(field.placeholder)}
                      label={t(field.label)}
                      value={formData[field.name]}
                      name={field.name}
                      id={field.id}
                      handleChange={(e) =>
                        handleInputChange(
                          e,
                          field.name as keyof ProjectFormData
                        )
                      }
                      isMandatory={field.isMandatory}
                      colClassName="col-12"
                      error={formErrors[field.name]}
                      className="ps-2"
                      isTop={true}
                    />
                  </div>
                ) : null // Render nothing if field.type is not "select" or "text"
            )}
            <LabelField
              title={t("To which company would you like to add this project")}
              isMandatory={true}
            />
            {/* Map your data items here */}
            <CompanySelectCard
              relatedCompanies={relatedCompanies}
              onSelect={handleCompanySelect}
              getLocations={getLocations}
              initialSelectedCompanyId={formData.company_id[0]}
              error={formErrors["company_id"]}
            />
            <div className="multiSelectWrapper mt-3 col-6">
              <SelectWithSearch
                search={true}
                options={locations}
                defaultValue={
                  locations.length > 0
                    ? {}
                    : { value: null, label: t("Select") }
                }
                value={selectedLocation}
                onChange={(e) => handleInputChange(e, "location_id")}
                placeHolder={t("Select Location")}
                isMulti={false}
                isDisabled={false} // Disabled by default
                className="location-select"
                isTranslate={false}
                isMandatory={false} // If mandatory
                fieldLabel={t("Location")}
                title={t("Location")}
                error={formErrors["location"]}
              />
            </div>
            <div className="col-12 py-3 ">
              <Button
                type="submit"
                className="searchBtn px-3 float-end"
                title={t("Save")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default translate(EditProject);
