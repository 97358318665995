import React, { useState } from "react";
import DateAndTime from "../common/atoms/DateWithTime";
import { useSelector } from "react-redux";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import { ApiCall } from "../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";
import Image from "../common/atoms/Image";
import LoadImage from "../../static/images/DummyImage.jpg";
import Icon from "../../Icon";
import { EMPLOYER_TIME_REGISTRATION } from "../../routes/ApiEndpoints";
import CustomNotify from "../common/atoms/CustomNotify";
import TableHeaderSpace from "../common/atoms/TableHeaderSpace";
import NoRecords from "../common/atoms/NoRecords";
import CustomActionIcon from "../common/molecules/Actions/CustomActionIcon";
import useWindowWidth from "../common/atoms/WindowWidth";
import ConfirmPopup from "../common/atoms/ConfirmPopup";
import CommonPopUp, { Widget } from "../common/atoms/CommonPopUp";

interface Shift {
  start_time: string;
  end_time: string;
}

interface EventData {
  user_id: number;
  profilePhoto: string | null;
  name: string;
  start_time: string;
  end_time: string;
  employee_type: string;
  employee_type_id: number;
  pc_name: string;
  salary: string;
  function_name: string;
  function_id: number;
  planning_id: number;
  location_id: string;
  company_id: string;
  display_timings: string;
  // timings: Shift[];
  shifts: {
    shift1: Shift;
    shift2?: Shift; // shift2 is optional
  };
  total_break: string;
  total_hrs: string;
  planned_date: string;
  status: string;
  latest_shift_status: string;
}

interface TimeRegistrationTableProps {
  selectedDate?: Date;
  page?: string;
  companyId: number;
  selectedLocation?: {
    address_id: number;
    id: number;
    location_image_id: number;
    name: string;
  };
  selectedEmployee?: EventData;
  employeesData: EventData[];
  // refresh : () => void;
  refresh: (empId?: number) => void;
}

interface ActualTimings {
  date: string; // Format: 'yyyy-mm-dd'
  time: string; // Format: 'hh:mm'
}


const IconProps = {
  isIcon: true,
  width: "1vw",
  height: "1vw",
};

const TimeRegistrationTable: React.FC<TimeRegistrationTableProps> = ({
  selectedDate,
  page,
  companyId,
  selectedLocation,
  selectedEmployee,
  employeesData = [],
  refresh,
}) => {
  // const dats = data ? [data] : [];
  const currentUserId = useSelector((state: any) => state?.auth.userId);

  const [actualTimings, setActualTimings] = useState<ActualTimings>({
    date: '', // Initialize with empty strings
    time: ''
  });

  const [widgetData, setWidgetData] = useState<Widget>({
    name: '',
    plannedDateTime: '',
    actualDate: '',
    actualTime: ''
  });

  const [selectedAction, setSelectedAction] = useState<{ type: string; employee: EventData | null }>({
    type: "",
    employee: null
  });

  const [showConfirmPopup, setShowConfirmPopup] = useState(false);

  const isButtonEnabled = (buttonType: string, shiftStatus: string) => {
    switch (shiftStatus) {
      case "yetToStart":
        return buttonType === "start";
      case "start":
        return buttonType === "breakin" || buttonType === "stop";
      case "breakin":
        return buttonType === "playpause";
      case "breakout":
        return buttonType === "breakin" || buttonType === "pause" || buttonType === "stop";
      case "stop":
      default:
        return false; // Disable all buttons if status is "stop" or unknown
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const date = now.toISOString().split('T')[0];
    const time = now.toTimeString().split(' ')[0].substring(0, 5);
    return { date, time };
  };


  const handleTimeRegistrationClick = (type: string, emp: EventData) => {
    const { date, time } = getCurrentDateTime();
    const widgetData = {
      name: emp.name,
      plannedDateTime: `${emp.planned_date}  ${emp.display_timings}`,
      actualDate: date,
      actualTime: '',
      type: type
    };
    setSelectedAction({ type, employee: emp });
    setShowConfirmPopup(true);
    setActualTimings({
      date,
      time
    });
    setWidgetData(widgetData);
  };

  const getConfirmationTitle = (type: string) => {
    switch (type.toLowerCase()) {
      case "start":
        return "Confirm Check In";
      case "stop":
        return "Confirm Check Out";
      case "breakin":
        return "Confirm Break Start";
      case "breakout":
        return "Confirm Break End";
      default:
        return "Confirm Action";
    }
  };

  const handleClose = () => {
    setShowConfirmPopup(false);
  };

  const handleConfirm = async (formData: Widget) => {

    if (selectedAction.employee && selectedAction.type) {

      const actualTimings = {
        date: formData.actualDate || '',
        time: formData.actualTime || ''
      };

      setActualTimings(actualTimings);

      const timeregistrationResponse = await saveTimeRegistration(selectedAction.type, selectedAction.employee, actualTimings);
      if (timeregistrationResponse.status != 'success') {
        console.log(timeregistrationResponse);
        
      }

      setShowConfirmPopup(false);
    }

  };

  const saveTimeRegistration = async (type: string, emp: EventData, timings: ActualTimings) => {

    const payload = {
      employee_id: emp.user_id,
      location_id: emp.location_id,
      company_id: emp.company_id,
      planning_id: emp.planning_id,
      planned_date: emp.planned_date,
      user_id: currentUserId, // replace with actual user_id
      type, // "START", "STOP", "BREAKIN", "BREAKOUT"
      actual_timings: timings,
      start_date: new Date().toISOString().split("T")[0], // format: YYYY-MM-DD
      end_date: "2024-10-13",
      shifts: [
        {
          start_time: emp.shifts.shift1.start_time,
          end_time: emp.shifts.shift1.end_time,
        },
        emp.shifts.shift2 && {
          start_time: emp.shifts.shift2.start_time,
          end_time: emp.shifts.shift2.end_time,
        },
      ].filter(Boolean), // filter out undefined values (for shift2 if not present)
    };

    try {
      const response = await ApiCall.service(
        EMPLOYER_TIME_REGISTRATION,
        "POST",
        payload,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == "success") {
        refresh(emp.user_id);
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
      }
      return response;
    } catch (error) {
      console.error("Error during time registration:", error);
      // Handle error, e.g., show an error notification
    }
  };

  const isDesktop = useWindowWidth(1024);

  return (
    <div className="time-registration-table">
      {/* <h2>{t("Time Registration Details")}</h2> */}
      <table className="table planningTableWrapper timeRegistrationTableWrapper table-borderless mb-0 pwa">
        <thead className="positionSticky">
          {isDesktop && <TableHeaderSpace headerLength={20} className="bg-transparent" />
          }
          <tr>
            <th></th>
            <th className="ps-0">{t("Employee")}</th>
            <th>{t("Status")}</th>
            <th>{t("Function")}</th>
            <th>{t("Paritair committee")}</th>
            <th>{t("Hourly wage")}</th>
            <th>{t("Start shift")}</th>
            <th>{t("End shift")}</th>
            <th>{t("Start shift")}</th>
            <th>{t("End shift")}</th>
            <th>{t("Break Total")}</th>
            <th>{t("Total work done")}</th>
            <th>{t("Actions")}</th>
          </tr>
          {isDesktop && <TableHeaderSpace headerLength={20} className="bg-transparent" />}
        </thead>
        <tbody>
          {employeesData.map((emp: EventData) => (
            <>
              <tr key={emp.user_id}>
                <td className="p-2">
                  {/* Assuming LoadImage is defined */}
                  <Image
                    src={LoadImage}
                    imageWidth={window.innerWidth <= 767
                      ? "3.5vw"
                      : window.innerWidth <= 1023
                        ? "6vw"
                        : "3vw"}
                    imageHeight={window.innerWidth <= 767
                      ? "3.5vw"
                      : window.innerWidth <= 1023
                        ? "6vw"
                        : "3vw"}
                    imageBorderRadius="50%"
                  />
                </td>
                <td className="ps-lg-0" data-label={t("Employee")}>
                  <div
                    className="textEllipsisName textEllipsis"
                    title={emp.name}
                  >
                    {emp.name}
                  </div>
                </td>
                <td data-label={t("Status")}>
                  <div
                    className="textEllipsisEmpType textEllipsis"
                    title={emp.employee_type}
                  >
                    {emp.employee_type}
                  </div>
                </td>
                <td data-label={t("Function")}>
                  <div
                    className="textEllipsis textEllipsisFunName"
                    title={emp.function_name}
                  >
                    {emp.function_name}
                  </div>
                </td>
                <td data-label={t("Paritair committee")}>
                  <div
                    className="textEllipsis textEllipsisFunName"
                    title={emp.pc_name}
                  >
                    {emp.pc_name}
                  </div>
                </td>
                <td data-label={t("Hourly wage")}>€{emp.salary}</td>
                <td data-label={t("Start shift")}>{emp.shifts.shift1.start_time}</td>
                <td data-label={t("End shift")}>{emp.shifts.shift1.end_time}</td>
                <td data-label={t("Start shift")}>{emp.shifts.shift2?.start_time || "-"}</td>
                <td data-label={t("End shift")}>{emp.shifts.shift2?.end_time || "-"}</td>
                <td data-label={t("Break Total")}>
                  <div className="totalHours text-white poppins-medium mobileFont">
                    {emp.total_break ?? "00:00"}
                  </div>
                </td>
                <td data-label={t("Total work done")}>
                  <div className="totalHours text-white poppins-medium mobileFont">
                    {emp.total_hrs ?? "00:00"}
                  </div>
                </td>
                <td className="actionWrapper p-2">
                  <AccessControl
                    renderNoAccess={false}
                    requiredPermissions={[
                      {
                        permission: "Planning time registration",
                        read: true,
                        create: true,
                        update: true,
                        delete: true,
                      },
                    ]}
                  >
                    <>
                      <AccessControl
                        renderNoAccess={false}
                        requiredPermissions={[{ permission: "Check in", read: true }]}
                      >
                        <CustomActionIcon handleClick={() => isButtonEnabled("start", emp.latest_shift_status) && handleTimeRegistrationClick("start", emp)}
                          className={`me-2 startIcon ${isButtonEnabled("start", emp.latest_shift_status) ? "startEnabled cursor-pointer" : "startDisabled opacity-50"}`}
                          buttonClass={` ${isButtonEnabled("start", emp.latest_shift_status) ? "searchBtn" : "searchBtn opacity-50"}`}
                          iconName="startIcon"
                          title={t("Start")} />
                      </AccessControl>

                      <AccessControl
                        renderNoAccess={false}
                        requiredPermissions={[{ permission: "Break in", read: true }]}
                      >

                        <CustomActionIcon handleClick={() => isButtonEnabled("breakin", emp.latest_shift_status) && handleTimeRegistrationClick("breakin", emp)}
                          className={`me-2 pauseIcon ${isButtonEnabled("breakin", emp.latest_shift_status) ? "pauseEnabled cursor-pointer" : "pauseDisabled opacity-50"}`}
                          buttonClass={` ${isButtonEnabled("breakin", emp.latest_shift_status) ? "searchBtn" : "searchBtn opacity-50"}`}
                          iconName="pauseIcon"
                          title={t("Pause")} />
                      </AccessControl>

                      <AccessControl
                        renderNoAccess={false}
                        requiredPermissions={[{ permission: "Break out", read: true }]}
                      >
                        <CustomActionIcon
                          handleClick={() => isButtonEnabled("playpause", emp.latest_shift_status) && handleTimeRegistrationClick("breakout", emp)}
                          title={t("Play/Pause")}
                          className={`me-2 playPauseIcon ${isButtonEnabled("playpause", emp.latest_shift_status) ? "playPauseEnabled cursor-pointer" : "playPauseDisabled opacity-50"}`}
                          iconName="playPauseIcon"
                          buttonClass={` ${isButtonEnabled("breakin", emp.latest_shift_status) ? "searchBtn" : "searchBtn opacity-50"}`}
                        />
                      </AccessControl>

                      <AccessControl
                        renderNoAccess={false}
                        requiredPermissions={[{ permission: "Check out", read: true }]}
                      >
                        <CustomActionIcon
                          handleClick={() => isButtonEnabled("stop", emp.latest_shift_status) && handleTimeRegistrationClick("stop", emp)}
                          title={t("Stop")}
                          className={`me-2 stopIcon ${isButtonEnabled("stop", emp.latest_shift_status) ? "stopEnabled cursor-pointer" : "stopDisabled opacity-50"}`}
                          iconName="stopIcon"
                          buttonClass={` ${isButtonEnabled("breakin", emp.latest_shift_status) ? "searchBtn" : "searchBtn opacity-50"}`}
                        />
                      </AccessControl>
                    </>
                  </AccessControl>
                </td>
              </tr>
              {isDesktop && <TableHeaderSpace headerLength={15} className="bg-transparent" />
              }
            </>
          ))}
          {employeesData.length === 0 && (
            <NoRecords
              message={t("There is no schedule for this day")}
              headerLength={13}
            />
          )}
        </tbody>
      </table>

      <CommonPopUp
        show={showConfirmPopup}
        onHide={handleClose}
        title={getConfirmationTitle(selectedAction.type)}
        //body={selectedAction.employee ? getConfirmationMessage(selectedAction.type, selectedAction.employee.name) : ""}
        onCloseButtonClick={handleClose}
        onConfirmButtonClick={handleConfirm}
        closeTitle={t("Cancel")}
        confirmTitle={t("Confirm")}
        className="confirmation-popup"
        widget={true}
        widgetData={widgetData}
      />

    </div>
  );
};

export default translate(TimeRegistrationTable);
