// export type Weekdays = 1 | 2 | 3 | 4 | 5 | 6 | 0;
export type Fields = "shift_name" | "shift1" | "shift2" | "total" ;
export type DefaultTimeStates = "from" | "to" | "total";


export type  Shift = {
  startTime: string;
  endTime: string;
  // shift_name: string;
};

export type ShiftRow = {
  shift_name : string;
  shift1: Shift;
  shift2: Shift;
  total: string;
};

export type ShiftRowType = {
  shift_name: string,
  shift1: {
      startTime: string;
      endTime: string;
  };
  shift2: {
      startTime: string;
      endTime: string;
  };
  total: string;
};


export const initialShiftSates: ShiftRowType = {
  shift_name : '',
  shift1: { startTime: '00:00', endTime: '00:00' },
  shift2: { startTime: '00:00', endTime: '00:00' },
  total: '00:00',
};

export const fieldLabels: Record<Fields, string> = {
    shift_name:"Shift name",
    shift1: "Shift 1",
    shift2: "Shift 2",
    // breakFrom: "Break From",
    // breakUntil: "Break To",
    total: "Total",
  };

  export const defaultFields: Fields[] = [
    "shift_name",
    "shift1",
    "shift2",
    "total",
    // "breakFrom",
    // "breakUntil",
  ];

// Structure for time states
export type TimeStates = Record<DefaultTimeStates, string>;

// Initial time states with default values
export const initialTimeStates: TimeStates = {
    from: "00:00",
    to: "00:00",
    total: "0,00",
}

// Format total value
export const formatTotalValue = (value: string) => {
    value = value.replace(/[^0-9,]/g, ""); // Remove non-numeric characters and allow commas
    return value;
  };

export const formatTime = (value: string) => {
  value = value.replace(/[^0-9]/g, ""); // Remove non-numeric characters
  if (value.length <= 2) return value;
  if (value.length <= 4) return `${value.slice(0, 2)}:${value.slice(2, 4)}`;
  return `${value.slice(0, 2)}:${value.slice(2, 4)}`;
};

export const formatName = (value: string) => {
  return value.replace(/[^a-zA-Z0-9\- ]/g, ""); // Remove all characters except letters, numbers, spaces, and hyphens
};


export const calculateTotalTime = (shift1: Shift, shift2: Shift) => {

  // console.log(shift1 , shift2 , "in calci");
  let shiftOneTimeDiff = calculateTimeBetweenShifts(shift1.startTime , shift1.endTime , '00:00' , '00:00');
  let shiftTwoTimeDiff = calculateTimeBetweenShifts(shift2.startTime , shift2.endTime , '00:00' , '00:00');
  // console.log(shiftOneTimeDiff , shiftTwoTimeDiff ,"time diff");
  let totalTime = addTimes(shiftOneTimeDiff , shiftTwoTimeDiff);
  return totalTime;

};




// Validate time format
export const isValidTime = (time: string) =>
  /^([01]\d|2[0-3]):([0-5]\d)$/.test(time);

export const isValidShiftName = (name: string) => !/^\s*$/.test(name);



export const calculateTimeBetweenShifts = (
  from: string,
  to: string,
  breakFrom: string,
  breakUntil: string
) => {
  let totalMinutes = 0;

  if (isValidTime(from) && from) {
    const fromMinutes = timeToMinutes(from);
    let toMinutes = 24 * 60; // Default to 24:00 if until is not provided

    if (isValidTime(to) && to) {
      toMinutes = timeToMinutes(to);
      totalMinutes = toMinutes - fromMinutes;

      if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle overnight shifts
    } else {
      // If 'until' is not provided, assume end of the day
      totalMinutes = 24 * 60 - fromMinutes;
    }

    if (isValidTime(breakFrom) && breakFrom) {
      if (breakFrom === "00:00") {
        // Special case: breakFrom is "00:00", total = until - by
        totalMinutes = toMinutes - fromMinutes;
        if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle overnight shifts
      } else {
        const breakFromMinutes = timeToMinutes(breakFrom);

        if (isValidTime(breakUntil) && breakUntil) {
          if (breakUntil === "00:00") {
            // Special case: breakUntil is "00:00", calculate total as breakFrom - by
            totalMinutes = breakFromMinutes - fromMinutes;
            if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle overnight shifts
          } else {
            const breakUntilMinutes = timeToMinutes(breakUntil);
            const breakDuration = breakUntilMinutes - breakFromMinutes;

            if (breakDuration > 0) {
              totalMinutes -= breakDuration;
            }
          }
        } else {
          totalMinutes = breakFromMinutes - fromMinutes;
          if (totalMinutes < 0) totalMinutes += 24 * 60; // Handle overnight shifts
        }
      }
    }
  }

  return totalMinutes > 0 ? minutesToTime(totalMinutes) : "00:00";
};


// Convert time to minutes
export const timeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  return !isNaN(hours) && !isNaN(minutes) ? hours * 60 + minutes : 0;
};

// Convert minutes to time format
export const minutesToTime = (minutes: number) => {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${String(hours).padStart(2, "0")}:${String(mins).padStart(2, "0")}`;
};


function addTimes(time1:string, time2:string) {
  // Parse the hours and minutes from each time string
  const [hours1, minutes1] = time1.split(":").map(Number);
  const [hours2, minutes2] = time2.split(":").map(Number);

  // Add the minutes and handle overflow into hours
  let totalMinutes = minutes1 + minutes2;
  let extraHours = Math.floor(totalMinutes / 60);
  let finalMinutes = totalMinutes % 60;

  // Add the hours, including any overflow from minutes
  let finalHours = hours1 + hours2 + extraHours;

  // Format the result back to HH:MM
  return `${String(finalHours).padStart(2, '0')}:${String(finalMinutes).padStart(2, '0')}`;
}

export const isValidTimeFormat = (value: string) => {
  const timeRegex = /^([0-1]\d|2[0-3]):([0-5]\d)$/; // Matches HH:MM format (24-hour)
  return timeRegex.test(value);
};
