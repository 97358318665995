import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";


interface Option {
  value: number;
  label: string;
  bright_id: number;
}

interface Settings {
  dependent_spouse: Option[];
  driving_license: Option[];
  employee_types: Option[];
  gender: Option[];
  marital_status: Option[];
  transportations: Option[];
  languages: Option[];
  initial: Option[];
  education: Option[];
  iban_countries: Option[];
  employee_type_groups: Option[];
  timezones: Option[];
  browsers: Option[];
}

const initialState: Settings = {
  dependent_spouse: [],
  driving_license: [],
  employee_types: [],
  gender: [],
  marital_status: [],
  transportations: [],
  languages: [],
  initial: [],
  education: [],
  iban_countries: [],
  employee_type_groups: [],
  timezones: [],
  browsers: [],
};

const SettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    // Set the entire settings object
    setSettings: (state, action: PayloadAction<Settings>) => {
      return { ...action.payload };
    },

    // Update a specific field in the settings object
    updateSettingField: (
      state,
      action: PayloadAction<{ key: keyof Settings; value: Option[] }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

// Export actions
export const { setSettings, updateSettingField } = SettingsSlice.actions;

export const selectSettings = (state: RootState) => state.settings;

// Export individual selectors for each field
export const selectDependentSpouse = (state: RootState) =>
  state.settings.dependent_spouse;

export const selectDrivingLicense = (state: RootState) =>
  state.settings.driving_license;

export const selectEmployeeTypes = (state: RootState) =>
  state.settings.employee_types;

export const selectGender = (state: RootState) => state.settings.gender;

export const selectMaritalStatus = (state: RootState) =>
  state.settings.marital_status;

export const selectTransportations = (state: RootState) =>
  state.settings.transportations;

export const selectLanguages = (state: RootState) => state.settings.languages;

export const selectInitial = (state: RootState) => state.settings.initial;

export const selectEducation = (state: RootState) => state.settings.education;

export const selectIbanCountries = (state: RootState) =>
  state.settings.iban_countries;

export const selectEmployeeTypeGroups = (state: RootState) =>
  state.settings.employee_type_groups;

export const selectTimezones = (state: RootState) => state.settings.timezones;

export const selectBrowsers = (state: RootState) => state.settings.browsers;

// Export the reducer to be used in the store
export default SettingsSlice.reducer;

// const countries = useSelector(selectIbanCountries);
