// StatusCircle.tsx
import React from "react";
import {
  getStatusColorAndTitle,
  Status,
} from "../../Planning/Services/PlanningHelper";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import useWindowWidth from "./WindowWidth";

interface StatusCircleProps {
  status: Status;
  failedReason?: string;
}

const StatusCircle: React.FC<StatusCircleProps> = ({ status, failedReason }) => {
  const isDesktop = useWindowWidth(1024); // Custom hook to check window width
  const { color, title } = getStatusColorAndTitle(status, failedReason);

  return (
    <>
      {isDesktop ? (
        <svg
          width="0.8vw" // Adjust size as needed
          height="0.8vw"
          viewBox="0 0 24 24"
          style={{ display: "inline-block", verticalAlign: "middle" }}
          aria-label="Status"
        >
          <circle cx="12" cy="12" r="10" fill={color} />
          <title>{t(title)}</title>
        </svg>
      ) : (
        <div
          style={{
            width: "100%",
            height: "1vw",
            backgroundColor: color,
            borderRadius: "0%",
          }}
          aria-label={t(title)}
        />
      )}
    </>
  );
};

export default translate(StatusCircle);
