import React, { useState, useEffect, useContext } from "react";
import ValidationService from "./ValidationService";
import {
  t,
  translate,
} from "../../CentralDataMangement/translation/Translation";
import { PcContext } from "./PcContext";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { ApiCall } from "../../../services/ApiServices";
import {
  GET_CATEGORY,
  STORE_CATEGORY,
  UPDATE_CATEGORY,
} from "../../../routes/ApiEndpoints";
import Title from "../../common/atoms/Title";
import { LabelWithInputField } from "../../common/molecules/LabelWithInputField";
import Button from "../../common/atoms/Button";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";

function AddCategory(props) {
  const {
    setCurrent_sec,
    setSec_completed,
    sec_completed,
    pc_unique_key,
    setPc_unique_key,
    setCat_rightsec,
    setCat_leftsec,
    setCat_fun_updated,
    cat_subsec_type,
    setCat_subsec_type,
    cat_subsec_id,
    setCat_subsec_id,
  } = useContext(PcContext);
  const [id, setId] = useState("");
  const [data, setData] = useState({
    id: "",
    category_name: "",
    min_salary: "",
    pc_unique_key: "",
  });
  const [error_category_name, setError_category_name] = useState("");
  const [error_min_salary, setError_min_salary] = useState("");
  const [disableSave, setDisableSave] = useState(false);

  let resetErrors = () => {
    setError_category_name("");
    setError_min_salary("");
  };

  const { uid } = useParams();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const fid = searchParams.get("fid") ?? "";
  const cid = searchParams.get("cid") ?? "";

  let postdata = async (e) => {
    if (id == "") {
      console.log(data, "data for adding category");
      setDisableSave(true);
      ApiCall.service(
        STORE_CATEGORY,
        "POST",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      )
        .then((result) => {
          if (result.success) {
            if (cid !== null && cid !== undefined && cid !== "") {
              navigate("/manage-categories");
            } else {
              setCat_fun_updated("cat" + result.ctid);
              setCat_rightsec("d-none");
              setCat_leftsec("col-md-12");
              setCat_subsec_type(0);
              setCat_subsec_id("");
            }
            setDisableSave(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      setDisableSave(true);
      ApiCall.service(
        UPDATE_CATEGORY + "/" + id,
        "PUT",
        data,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      )
        .then((result) => {
          if (result.success) {
            if (cid !== null && cid !== undefined && cid !== "") {
              navigate("/manage-categories");
            } else {
              setCat_fun_updated("catupdate" + result.ctid);
              setCat_rightsec("d-none");
              setCat_leftsec("col-md-12");
              setCat_subsec_type(0);
              setCat_subsec_id("");
            }
            setDisableSave(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  /**
   *
   * @param {*} event
   */

  useEffect(() => {
    if (id != '') {
      ApiCall.getService(
        GET_CATEGORY + "/" + id,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE,
        true
      )
        .then((result) => {
          if (result.success) {
            setData(result.data);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
    }
  }, [id]);

  useEffect(() => {
    if (cat_subsec_type == 1) {
      if (cat_subsec_id != '')
        setId(cat_subsec_id);
      resetErrors();
    }
    if (cat_subsec_id == "") {
      //create category data for add category flow
      setId(""); // Add mode, clear the id

      var data1 = data;
      data1.id = "";
      data1.category_name = "";
      data1.min_salary = "";
      setData(data1);
    }
  }, [cat_subsec_id]);
  /**
   *
   * @param {*} event
   *
   * it will validates the data of each field
   */
  let submit = async (event) => {
    event.preventDefault();
    if (validate()) {
      postdata();
    }
  };

  let validate = () => {
    var error = [];
    error["error_category_name"] = ValidationService.emptyValidationMethod(
      data.category_name.trim()
    );
    // ValidationService.emptyValidationMethod(data.category_name) == ''
    // 	? ValidationService.nameValidationMethod(data.category_name) == ''
    // 		? ''
    // 		: ValidationService.nameValidationMethod(data.category_name)
    // 	: ValidationService.emptyValidationMethod(data.category_name);
    error["error_min_salary"] =
      ValidationService.emptyValidationMethod(data.min_salary.trim()) === ""
        ? ValidationService.minSalaryValidationMethod(data.min_salary.trim()) ===
          ""
          ? ""
          : "This field is invalid."
        : ValidationService.emptyValidationMethod(data.min_salary.trim());

    if (error["error_category_name"] === "") {
      if (typeof props.categorylist == "object") {
        Object.keys(props.categorylist).map((element) => {
          if (
            props.categorylist[element].type == "2" &&
            props.categorylist[element].id != id &&
            data.category_name.replaceAll(" ", "").toLowerCase() ==
            props.categorylist[element].category_name
              .replaceAll(" ", "")
              .toLowerCase()
          ) {
            error["error_category_name"] = t("Category name already exist.");
          }
        });
      } else {
        props.categorylist.map((element) => {
          if (
            element.type == "2" &&
            element.id != id &&
            data.category_name.replaceAll(" ", "").toLowerCase() ==
            element.category_name.replaceAll(" ", "").toLowerCase()
          ) {
            error["error_category_name"] = t("Category name already exist.");
          }
        });
      }
    }

    // 	if (error['error_min_salary'] == '') {
    // 		if(typeof props.categorylist == 'object'){
    // 			Object.keys(props.categorylist).map((element) => {
    // 				if (props.categorylist[element].type == '2' && props.categorylist[element].id == id){
    // 					props.categorylist[element].childObj && props.categorylist[element].childObj.map((value)=>{
    // 						if(parseFloat(data.min_salary.replace(',', '.')) > parseFloat(value.min_salary.replace(',', '.'))){
    // 							error['error_min_salary'] = t('Category minimum salary cannot be greater than function minimum salary.');
    // 						}
    // 					})
    // 				}

    // 			})
    // 		}else{
    // 			props.categorylist.map((element) => {
    // 				if (element.type == '2' && element.id == id){
    // 					element.childObj && element.childObj.map((value)=>{
    // 						if(parseFloat(data.min_salary.replace(',', '.').trim()) > parseFloat(value.min_salary.replace(',', '.').trim())){
    // 							error['error_min_salary'] = t('Category minimum salary cannot be greater than function minimum salary.');
    // 						}
    // 					})
    // 				}
    // 			// if(typeof props.categorylist == 'object'){
    // 			// Object.keys(props.categorylist).map((element) => {
    // 			// 	if (props.categorylist[element].type == '2' && props.categorylist[element].id != id && data.category_name.replaceAll(' ','').toLowerCase() == props.categorylist[element].category_name.replaceAll(' ','').toLowerCase()) {
    // 			// 		error['error_category_name'] = 'Category name already exist.';
    // 			// 	}
    // 			// });
    // 		})
    // }
    // }
    setError_category_name(error["error_category_name"]);
    setError_min_salary(error["error_min_salary"]);

    if (error["error_category_name"] === "" && error["error_min_salary"] === "") {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <form onSubmit={submit} className="border rounded-3 p-3 mt-1">
        <Title
          title={id != "" ? t("Edit category") : t("Add category")}
          className="colorPrimary poppins-semibold mb-3 pageSubTitle"
        />
        <div className="row">
          <div className="col-12 mb-3">
            <LabelWithInputField
              label={t("Category name")}
              value={data.category_name}
              name="name"
              id="name"
              handleChange={(e) => {
                setData((prev) => ({ ...prev, category_name: e.target.value }));
              }}
              error={t(error_category_name)}
              className="ps-2"
              isMandatory={true}
            />
          </div>
          <div className="col-12 mb-3">
            <div className="input-group position-relative">
              <LabelWithInputField
                label={t("Minimum salary")}
                value={data.min_salary}
                name="salary"
                id="salary"
                handleChange={(e) => {
                  setData((prev) => ({ ...prev, min_salary: e.target.value.replace(".", ",") }));
                }}
                error={t(error_min_salary)}
                className="ps-5"
                isTop={true}
                isMandatory={true}
              />
              <span className="fieldPrefix position-absolute">€</span>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="text-start col-md-6">
            {(router.query.cid) && (
							<Link href={'/manage-categories'}>
								<a className="bg-white  back-btn-text bg-white  back-btn-text  border-0 poppins-regular-20px">
									BACK
								</a>
							</Link>
						)}

						{router.query.fid && (
							<Link href={'/manage-function'}>
								<a className="bg-white  back-btn-text bg-white  back-btn-text  border-0 poppins-regular-20px">
									BACK
								</a>
							</Link>
						)}
          </div> */}
          <div className="text-end ms-auto col-md-6">
            <Button
              title={t("SAVE")}
              className="searchBtn px-3"
              disabled={disableSave}
              handleClick={() => {
                setData((prev) => ({ ...prev, pc_unique_key: pc_unique_key }));
              }}
              type="submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default translate(AddCategory);
