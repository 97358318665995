import React from "react";
import InvoiceOverviewTemplate from "../../components/Invoies/InvoiceOverviewTemplate";
import AccessControl from "../../services/RolesAndPermissions/AccessControl";

const InvoiceOverview: React.FC = () => {
    return (
        <AccessControl
            requiredPermissions={[
                {
                    permission: "document_invoice",
                    read: true,
                },
            ]}
            renderNoAccess={true}
        >
            <InvoiceOverviewTemplate />
        </AccessControl>
    );
};

export default InvoiceOverview;
