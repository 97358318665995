import React from "react";
import Icon from "../../Icon";
import Link from "../common/atoms/Link";
// import { t } from "../common/atoms/translation";
import {t , translate } from "../CentralDataMangement/translation/Translation";

interface invoiceSttingsProps {
  invoiceTitle?: string;

}
const InvoiceSettings:React.FC<invoiceSttingsProps> = ({
  invoiceTitle
}) => {
  return (
    <div className="linkWrapper mb-4">
      <Link
        onClick={() => {}}
        className="invoiceSettingsLink text-decoration-none secondaryHoverEffectText cursor-pointer tableBg rounded-4 p-4 w-100 d-flex align-items-center justify-content-between mobileFont"
      >
        {invoiceTitle}
        <span>
          <Icon
            isIcon={true}
            width={window.innerWidth <= 767
              ? "3.5vw"
              : window.innerWidth <= 1023
                ? "2.5vw"
                : "1vw"}
            height={window.innerWidth <= 767
              ? "3.5vw"
              : window.innerWidth <= 1023
                ? "2.5vw"
                : "1vw"}
            name="RightArrow"
            className="secondaryColorHoverEffect me-1"
          />
        </span>
      </Link>
    </div>
  );
};

export default translate(InvoiceSettings);
