import React, { useState, useEffect, useMemo } from "react";
import { t, translate } from "../../translation/Translation";
import { ApiCall } from "../../../../services/ApiServices";

import { useNavigate } from "react-router-dom";
import Header from "../../../common/layout/Header";
import BackLink from "../../../common/atoms/BackLink";
import Button from "../../../common/atoms/Button";
import ResetBtn from "../../../common/atoms/ResetBtn";
import SearchBtn from "../../../common/atoms/SearchBtn";
import OverFlowWrapper from "../../../common/OverFlowWrapper";
import Icon from "../../../../Icon";
import Pagination from "../../../common/atoms/Pagination";
import ConfirmPopup from "../../../common/atoms/ConfirmPopup";
import TableHeaderSpace from "../../../common/atoms/TableHeaderSpace";
import NoRecords from "../../../common/atoms/NoRecords";
import { DELETE_HOLIDAY_OCCASION } from "../../../../routes/ApiEndpoints";

const PER_PAGE = 8;


const TableRendererHoliday = ({ headers, rows, loadData }) => {
    // console.log(rows, "rows");
    const [filteredRows, setFilteredRows] = useState(rows);
    const [searchTerm, setSearchTerm] = useState("");
    const [showDeletePopup, setShowDeletePopup] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);

    const navigate = useNavigate();
    const basePath = "/manage/holidays";

    useEffect(() => {
        setFilteredRows(rows);
    }, [rows]);

    const handleSearch = () => {
        const filtered = rows.filter((item) =>
            item.occasion.toLowerCase().includes(searchTerm.toLowerCase())
        );
        setFilteredRows(filtered);
        setCurrentPage(0);
    };

    const handleReset = () => {
        setSearchTerm("");
        setFilteredRows(rows);
        setCurrentPage(0);
    };

    const pageCount = Math.ceil(filteredRows.length / PER_PAGE);

    const currentItems = useMemo(() => {
        const offset = currentPage * PER_PAGE;
        return filteredRows.slice(offset, offset + PER_PAGE);
    }, [currentPage, filteredRows]);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber - 1);
    };


    const handleDelete = async (id) => {
        try {
            const result = await ApiCall.service(
                `${DELETE_HOLIDAY_OCCASION}/${id}`,
                "DELETE"
            );
            if (result.status) {
                setFilteredRows((prev) => prev.filter((row) => row.id !== id));
                loadData();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const confirmDelete = () => {
        if (selectedRow) {
            handleDelete(selectedRow.id);
        }
        setShowDeletePopup(false);
        setSelectedRow(null);
    };

    const closeDeletePopup = () => {
        setShowDeletePopup(false);
        setSelectedRow(null);
    };

    const getNeededActions = (eachRow) => (
        <>
            <span
                title={t("Edit")}
                className="secondaryColorHoverEffect me-2"
                onClick={() => navigate(`${basePath}/edit?id=${eachRow.id}`)}
            >
                <Icon isIcon={true} width="1vw" height="1vw" name="EditIcon" />
            </span>
            <span
                title={t("Delete")}
                className="secondaryColorHoverEffect"
                onClick={() => {
                    setSelectedRow(eachRow);
                    setShowDeletePopup(true);
                }}
            >
                <Icon isIcon={true} width="1vw" height="1vw" name="delete" />
            </span>
        </>
    );



    return (
        <>
            <Header
                headerName={t("Manage holidays")}
                headerClassName="myAccountCardTitle"
            />
            <div className="search-bar pb-2">
                <div className="row mb-2">
                    <BackLink backLink="/config/settings" />
                    <div className="col-6 ms-auto text-end">
                        <Button
                            handleClick={() => navigate(`${basePath}/add?id=0`)}
                            className="searchBtn d-inline-flex align-items-center justify-content-center text-decoration-none w-auto px-3"
                        >
                            {t("Add holiday")}
                        </Button>
                    </div>
                </div>

                <div className="row">
                    <div className="col-7">
                        <input
                            type="text"
                            value={searchTerm}
                            className="form-control inputFieldColor ps-2"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder={t("Holiday")}
                            onKeyUp={(e) => (e.key === "Enter" ? handleSearch() : null)}
                        />
                    </div>
                    <div className="col-5">
                        <div className="d-flex align-items-center">
                            <ResetBtn handleResetClick={handleReset} />
                            <SearchBtn handleSearchClick={handleSearch} />
                        </div>
                    </div>
                </div>
            </div>
            <div className="managepagesBg">
                <OverFlowWrapper className="table-responsive manage-coefficients height-25">
                    <table className="table table-hover customTableProperties">
                        <thead>
                            <tr className="TableHeader">
                                {headers.map((header, index) => (
                                    <th
                                        key={`tablecol${index}`}
                                        scope="col"
                                        style={{ width: "50%" }}
                                    >
                                        {t(header)}
                                    </th>
                                ))}
                            </tr>
                            <TableHeaderSpace />
                        </thead>
                        <tbody>
                            {currentItems.length > 0 ? (
                                currentItems.map((eachRow) => (
                                    <tr key={eachRow.id} className="border">
                                        <td>{eachRow.occasion}</td>
                                        <td>{getNeededActions(eachRow)}</td>
                                    </tr>
                                ))
                            ) : (
                                <NoRecords headerLength={headers.length} />
                            )}
                        </tbody>
                    </table>
                </OverFlowWrapper>

                {pageCount > 1 && (
                    <div className="pagination justify-content-center mt-4 px-4">
                        <Pagination
                            currentPage={currentPage + 1}
                            totalPages={pageCount}
                            onPageChange={handlePageClick}
                        />
                    </div>
                )}

                {showDeletePopup && (
                    <ConfirmPopup
                        show={showDeletePopup}
                        onHide={closeDeletePopup}
                        title={t("Delete holiday")}
                        body={t(`Are you sure you want to delete this holiday?`)}
                        confirmTitle={t("Delete")}
                        closeTitle={t("Cancel")}
                        onConfirmButtonClick={confirmDelete}
                        onCloseButtonClick={closeDeletePopup}
                    />
                )}
            </div>

        </>
    )
}

export default translate(TableRendererHoliday);