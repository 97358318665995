import React, { useRef } from "react";
import Icon from "../../../../Icon";
import Image from "../../../common/atoms/Image";
import LabelField from "../../../common/atoms/LabelField";
import CheckBoxField from "../../../common/atoms/CheckBoxField";
import defaultCompanyIcon from "../../../../static/images/emptyCompany.png";
import { CompanyListProps } from "../Interfaces";

const CompanyList: React.FC<CompanyListProps> = ({
  companies,                // For Companies
  checkedItems,             // For Checkbox's selected items
  handleCheckBoxClick,      // For handle Checkbox's selected items
  fetchedCompanies = [],
  missingCompanies = [],     // For Errors - selected companies not have Function & Hourly Wage details
}) => {
  // Use ref to access the scrollable container
  const scrollRef = useRef<HTMLDivElement>(null);

  // Function to handle scroll (left or right)
  const handleScroll = (direction: "left" | "right") => {
    if (scrollRef.current) {
      const scrollAmount = direction === "right" ? 200 : -200;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  return (
    <div className="d-flex align-items-center">
      {companies.length > 7 && (
        <div
          style={{ width: "3%" }}
          className="nextIconWrapper"
          onClick={() => handleScroll("left")}
        >
          <Icon
            name="backIcon"
            isIcon={true}
            width={
              window.innerWidth <= 767
                ? "3.5vw"
                : window.innerWidth <= 1023
                ? "2.5vw"
                : "1.7vw"
            }
            height={
              window.innerWidth <= 767
                ? "3.5vw"
                : window.innerWidth <= 1023
                ? "2.5vw"
                : "1.7vw"
            }
            fillColor="#8571b9"
          />
        </div>
      )}
      <div style={{ width: "94%" }}>
        <div
          ref={scrollRef}
          className="mx-auto d-flex p-2 pb-3 scrollBar"
          style={{
            overflowX: "auto",
            scrollbarWidth: "none", // For Firefox
            gap: "0.5vw",
          }}
        >
          {companies?.map((company) => {
            const isDisabled = fetchedCompanies?.includes(company?.id);

            return (
              <div
                key={company?.id}
                className={`checkBoxContainer text-break text-center ${
                  checkedItems.has(company.id)
                    ? missingCompanies?.includes(company?.id)
                      ? "missedCompanies"
                      : "selectedCompanies"
                    : ""
                } cursor-pointer me-2`}
                onClick={() => {
                  if (!isDisabled) {
                    handleCheckBoxClick(company, !checkedItems.has(company.id));
                  }
                }}
              >
                <CheckBoxField
                  name="addemployee"
                  value="0"
                  id={`company-${company.id}`}
                  lineHeight="1.5vw"
                  className="checkBoxLabel"
                  checkBoxWrapper="addEmployeeToCompany text-center p-2"
                  wrapperClass="d-inline-flex"
                  isChildren
                  isChecked={checkedItems.has(company.id)}
                  disable={isDisabled}
                  onChangeHandler={(event) => {
                    if (!isDisabled) {
                      handleCheckBoxClick(company, event.target.checked);
                    }
                  }}
                >
                  <div title={company?.name}>
                    <div
                      style={{
                        height:
                          window.innerWidth <= 767
                            ? "3.5vw"
                            : window.innerWidth <= 1023
                            ? "8vw"
                            : "3vw",
                      }}
                    >
                      <Image
                        src={company?.profile_picture_url ?? defaultCompanyIcon}
                        imageWidth={
                          window.innerWidth <= 767
                            ? "3.5vw"
                            : window.innerWidth <= 1023
                            ? "7vw"
                            : "3vw"
                        }
                        imageHeight={
                          window.innerWidth <= 767
                            ? "3.5vw"
                            : window.innerWidth <= 1023
                            ? "7vw"
                            : "3vw"
                        }
                        imageBorderRadius="50%"
                      />
                    </div>
                    <div
                      style={{
                        lineHeight:
                          window.innerWidth <= 767
                            ? "3.5vw"
                            : window.innerWidth <= 1023
                            ? "3vw"
                            : "1.5vw",
                      }}
                      className="mt-2"
                    >
                      <LabelField
                        title={company?.name}
                        className="cursor-pointer"
                      />
                    </div>
                  </div>
                </CheckBoxField>
              </div>
            );
          })}
        </div>
      </div>
      {companies.length > 7 && (
        <div
          style={{ width: "3%" }}
          className="text-end nextIconWrapper"
          onClick={() => handleScroll("right")}
        >
          <Icon
            name="nextIcon"
            isIcon={true}
            width={
              window.innerWidth <= 767
                ? "3.5vw"
                : window.innerWidth <= 1023
                ? "2.5vw"
                : "1.7vw"
            }
            height={
              window.innerWidth <= 767
                ? "3.5vw"
                : window.innerWidth <= 1023
                ? "2.5vw"
                : "1.7vw"
            }
            fillColor="#8571b9"
          />
        </div>
      )}
    </div>
  );
};

export default CompanyList;
