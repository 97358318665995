export interface InputField {
    label: string;
    name: keyof CostCenterFormData;
    id: string;
    placeholder: string;
    isMandatory?: boolean;
    type: string;
    className?: string;
    columnSize?: string; // new property to define column size
  }

  export interface CostCenterFormData {
    company_id: number | any;
    costcenter_id:number;
    costcenter_name: string;
    location_id:number;
  }

  export const inputFields: InputField[] = [
    {
      label: "Cost center name",
      name: "costcenter_name",
      id: "costCenterName",
      placeholder: "Enter cost center name",
      isMandatory: true,
      type: "text",
      columnSize: "col-lg-6 col-md-12",
    },
    {
      label: "Unique number",
      name: "costcenter_id",
      id: "streetName",
      placeholder: "Enter street name",
      isMandatory: true,
      type: "text",
      columnSize: "col-lg-6 col-md-12",
    },
    {
      label: "Location",
      name: "location_id",
      id: "box",
      placeholder: "Enter location",
      type: "select",
      isMandatory: true,
      columnSize: "col-md-3",
    },
  ];
