import React, { useState } from "react";
import ModalPopup from "../common/atoms/ModalPopup";
import { t, translate } from "../CentralDataMangement/translation/Translation";
import InputTextFieldWithLabel from "../common/molecules/InputTextFieldWithLabel";
import { ApiCall } from "../../services/ApiServices";
import { CANCEL_PLANNING } from "../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../Constants";
import CustomNotify from "../common/atoms/CustomNotify";
import InputTextAreaField from "../common/atoms/InputTextAreaField";
import LabelWithTextAreaField from "../common/molecules/LabelWithTextAreaField";
import { useSelector } from "react-redux";
import { selectAuth } from "../../features/auth/AuthSlice";

interface Timing {
  shift_id: number;
  shift_starttime: string;
  shift_endtime: string;
  shift_sequence: number;
}

interface EventData {
  length: number;
  id: number;
  employee_id: number;
  name: string;
  employee_profile_pic: string;
  employee_type: string;
  employee_type_id: number;
  function_name: string;
  planning_id: number;
  function_id: number;
  pc_id: number;
  pc_name: string;
  salary: string;
  pdate: string;
  planning_status: string | null;
  failed_reason: string | null;
  location_name: string;
  location_id: number;
  total_break: number;
  total_hrs: number;
  schedule: Timing[];
}

interface CancelPlanningPopupProps extends EventData {
  employeeData: EventData; // Include 'employeeData' as a prop
  clearPopup: () => void;
}

const CancelPlanningPopup: React.FC<CancelPlanningPopupProps> = ({
  employeeData,
  clearPopup,
}) => {
  const authData = useSelector(selectAuth);
  const [showModal, setShowModal] = useState(true);
  const [reason, setReason] = useState<string>(""); // Change to a simple string
  const [reasonError, setReasonError] = useState<string>(""); // State for reason error

  const handleCloseModal = () => {
    setShowModal(false);
    clearPopup();
  };

  const handleReasonChange = (e: any) => {
    console.log(e);
    setReason(e.target.value); // Update the reason state
    if (e.target.value) {
      setReasonError(""); // Clear error if input is valid
    }
  };

  const handleSave = async () => {
    // Check if reason is provided
    if (!reason) {
      setReasonError(t("Reason is required")); // Set error message
      return;
    }

    // Here you can handle the save logic, e.g., sending the reason to an API
    try {
      const dataObj = {
        planning_id: employeeData.planning_id,
        pdate: employeeData.pdate,
        cancel_reason: reason,
        user_id: authData?.userId
      };
      const result = await ApiCall.service(
        CANCEL_PLANNING,
        "POST",
        dataObj,
        true,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (result.status === "success") {
        setShowModal(false);
        clearPopup();
        CustomNotify({
          type: "success",
          message: t("Planning cancelled successfully"),
          autoClose: 2500,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <ModalPopup
      show={showModal}
      handleClose={handleCloseModal}
      modalTitle={t("Cancel Planning") + " - " + employeeData.pdate}
      modalBackgroundColor="#8571b9"
      className="planningModal"
      buttonText={t("Save")}
      onNextClick={handleSave} // Attach the save handler to the button
      iconFillColor="#8571b9"
    >
      <div className="row mb-2">
        <LabelWithTextAreaField
          label={t("Reason") + ":"}
          value={reason}
          handleChange={(e) => handleReasonChange(e)} // Pass the change handler
          name="reason"
          isMandatory
          error={reasonError} // Display error message if any
          rows={4}
          className="shadow-none"
        />
      </div>
    </ModalPopup>
  );
};

export default translate(CancelPlanningPopup);
