import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/layout/Header";
import { t, translate } from "../translation/Translation";
import { InputText } from "primereact/inputtext";
import InputTextFieldWithLabel from "../../common/molecules/InputTextFieldWithLabel";
import FileUpload from "../../common/molecules/FileUpload";
import { CostCenterFormData, inputFields } from "./CostCenterUtils";
import { ApiCall } from "../../../services/ApiServices";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import {
  COST_CENTERS,
  FILE_URL,
  GET_COUNTRIES,
  LOCATIONS,
  LOCATIONS_BY_COMPANY,
  PROJECTS,
  SHIFTS,
} from "../../../routes/ApiEndpoints";
import SelectWithSearch from "../../common/atoms/SelectWithSearch";
import CustomNotify from "../../common/atoms/CustomNotify";
import MultiSelectField from "../../common/atoms/MultiSelectField";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import BackLink from "../../common/atoms/BackLink";
import Button from "../../common/atoms/Button";
import CompanySelectCard from "../../common/atoms/CompanyCards";
import Icon from "../../../Icon";
import { useSelector } from "react-redux";
import { selectAuth } from "../../../features/auth/AuthSlice";
import LabelField from "../../common/atoms/LabelField";
import defaultCompanyIcon from "../../../static/images/emptyCompany.png"

interface CompanyObj {
  value: number;
  label: string;
  url: string;
}

const AddCostCenter: React.FC = () => {
  // const [locationName, setLocationName] = useState("");
  const user = useSelector(selectAuth).userId;
  const navigate = useNavigate();
  const initialFormData: CostCenterFormData = {
    costcenter_name: "",
    costcenter_id: 0,
    location_id: 0,
    company_id: null,
  };
  const projectinputFields = inputFields;

  const [formErrors, setFormErrors] = useState<Record<string, string>>({});
  const [formData, setFormData] = useState<CostCenterFormData>(initialFormData);


  const [locations, setLocations] = useState<Location[]>([]);

  // const [selectedLocation, setSelectedLocation] = useState<Location[]>([]);
  const [selectedLocation, setSelectedLocation] = useState<Location[] | null>(null);
  const [url, setUrl] = useState<string | null>(null);
  const [relatedCompanies, setRelatedCompanies] = useState<CompanyObj[]>([]);

  const companiesObj = [
    { value: 1, label: "Option 1" },
    { value: 2, label: "Option 2" },
    { value: 3, label: "Option 3" },
  ];

  interface Location {
    iso: string;
    value: number;
    label: string;
  }

  useEffect(() => {

    getCompanies();
    // getLocations();
  }, []);

  const getLocations = async (companyId: number) => {

    console.log("triggers", companyId);
    let payload = {
      company_id: companyId,
    }
    const response = await ApiCall.service(
      LOCATIONS_BY_COMPANY,
      "POST",
      payload,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == 'success') {
      const data = response.data;
      // Transform the response data to match the Location interface
      const transformedLocations: Location[] = data.map((location: any) => ({
        value: location.id,
        label: location.name,
      }));
      setLocations(transformedLocations);
      if (transformedLocations.length > 0) {
        // Set the first location in state
        // 
        setSelectedLocation([transformedLocations[0]]);
        setFormData((prevData) => ({
          ...prevData, // Keep the previous form data
          location_id: transformedLocations[0].value, // Update only the project_name key /transformedLocations[0].value
        }));
      }
    }
  };


  const getCompanies = async () => {
    const response = await ApiCall.getService(
      `get-companies-by-user-id/${user}`,
      "GET",
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    );
    if (response.status == 'success') {
      // Convert the data to match the CompanyObj interface
      const formattedCompanies = response.data.map((company: any) => ({
        value: company.id,
        label: company.name,
        url: company.profile_picture_url ?? defaultCompanyIcon,
      }));
      setRelatedCompanies(formattedCompanies);
    }
  };


  // Handle input change for text fields
  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement> | any,
    fieldKey: keyof CostCenterFormData
  ) => {
    let value: any = "";
    switch (fieldKey) {
      case "location_id":
        setSelectedLocation(e);
        value = e?.value;
        break;
      case "costcenter_name":
        value = e.target.value;
        break;
      case "costcenter_id":
        value = e.target.value;
        break;
      case "company_id":
        if (e && e.target) {
          var ids = JSON.parse(e.target.value); // Extract the value from the event (radio button click)
        } else {
          var ids = e; // Use value directly if passed explicitly (e.g., from onClick)
        }
        value = ids;
        break;
      default:
        value = e.target.value;
        break;
    }

    setFormData((prevData) => ({
      ...prevData,
      [fieldKey]: value,
    }));

    // Clear the error for the updated field
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [fieldKey]: value ? "" : prevErrors[fieldKey], // Remove the error if value is not empty
    }));
  };

  const validateForm = () => {
    let errors: Record<string, string> = {};
    // Loop through the form fields to check for validation
    projectinputFields.forEach((field) => {
      if (field.isMandatory && !formData[field.name]) {
        errors[field.name] = `${t(field.label)} is required`;
      }
    });

    if (!formData.company_id || (Array.isArray(formData.company_id) && formData.company_id.length === 0)) {
      errors["company_id"] = `${t("Company")} is required`;
    }

    return errors;
  };


  const handleCompanySelect = (companyId: number | null) => {
    handleInputChange({ target: { value: companyId } }, 'company_id');
    if (companyId == null) {
      setLocations([]); // Reset locations if company deselected
      setSelectedLocation(null); // Reset selected location
    }
  };


  // Handle form submit (with validation)
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return; // Stop the form submission if there are errors
    }
    try {
      let data = {
        data: {
          formData,
        },
      };
      const response = await ApiCall.service(
        COST_CENTERS,
        "POST",
        formData,
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.status == 'success') {
        CustomNotify({
          type: "success",
          message: t(response.message) || t(response.msg),
        });
        setFormData(initialFormData);
        setTimeout(() => {
          navigate("/costcenters-manage");
        }, 1000);
      } else if (response.status == 'error' || response.status == 'validation-error') {
        const errorData = response.data; // Assuming the error data comes in response.data
        let updatedErrors: Record<string, string> = {};

        // Iterate through the error object and set errors for each field
        Object.keys(errorData).forEach((field) => {
          updatedErrors[field] = errorData[field][0]; // Assign the first error message
        });

        // Update form errors state
        setFormErrors(updatedErrors);

        CustomNotify({
          type: "error",
          message: t(response.message) || t(response.msg),
        });
      }
    } catch (error) {
      console.error("Error saving projects:", error);
    }
  };

  return (
    <>
      <Header
        headerName={t("Add cost center")}
        headerClassName="myAccountCardTitle"
      />
      <div className="search-bar">
        <div className="row">
          <BackLink backLink="/costcenters-manage" />
        </div>
      </div>
      <div className="createpagesBg">
        <form onSubmit={handleSubmit}>
          <div className="row">
            {projectinputFields.map((field, index) => {
              // Render non-select fields
              if (field.type !== "select") {
                return (
                  <div className={`${field.columnSize} mb-3`} key={index}>
                    <InputTextFieldWithLabel
                      type="text"
                      className="ps-2"
                      placeholder={t(field.placeholder)}
                      label={t(field.label)}
                      value={formData[field.name]}
                      name={field.name}
                      id={field.id}
                      handleChange={(e) =>
                        handleInputChange(e, field.name as keyof CostCenterFormData)
                      }
                      isMandatory={field.isMandatory}
                      colClassName="col-12"
                      error={formErrors[field.name]}
                    />
                  </div>
                );
              }
              return null; // Return null for select fields
            })}

            <LabelField title={t('To which company would you like to add this project')} isMandatory={true} />

            {/* Map your data items here */}
            <CompanySelectCard
              relatedCompanies={relatedCompanies}
              onSelect={handleCompanySelect}
              getLocations={getLocations}
              error={formErrors['company_id']}
            />

            <div className="multiSelectWrapper mt-3 col-6">
              <SelectWithSearch
                // key={index}
                search={true}
                options={locations}
                value={selectedLocation}
                onChange={(e) => handleInputChange(e, 'location_id')}
                placeHolder={t("Select location")}
                isMulti={false}
                isDisabled={false}
                className={`location-select`}
                isTranslate={false}
                isMandatory={true}
                fieldLabel={t('Location')}
                title={t('Location')}
                error={formErrors['location_id']}
              />
            </div>

            <div className="col-12 py-3 text-end">
              <Button
                type="submit"
                className="searchBtn px-3"
                title={t("Save")}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );

};

export default translate(AddCostCenter);
