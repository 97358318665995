import React from 'react';
import { Link } from 'react-router-dom';
import Icon from '../../../../Icon';
import { t } from '../../atoms/translation';
import useWindowWidth from '../../atoms/WindowWidth';


interface Props {
  href?: string; // For URL paths
  handleClick?: () => void; // For function calls
  title?: string;
  iconName?: string;
  className?: string
}

const EditIcon: React.FC<Props> = ({ href = "", handleClick, title = t("Edit"),
  iconName = "EditIcon",
  className = "secondaryColorHoverEffect" }) => {
  const isDesktop = useWindowWidth(1024);

  return (
    <>
      {isDesktop ? (
        <Link
          to={href && !handleClick ? href : ""} // Only use `href` if there's no `handleClick`
          className={`${className}  me-1`}
          title={title}
          onClick={handleClick || undefined} // If `handleClick` is provided, it will trigger on click.
        >
          <Icon isIcon={true} width="1vw" height="1vw" name={iconName} />
        </Link>
      ) : (
        <Link
          to={href && !handleClick ? href : ""}
          className="searchBtn text-decoration-none w-75 d-flex align-items-center justify-content-center mx-auto mb-3"
          onClick={handleClick || undefined}
        >
         {title}
        </Link>
      )}
    </>
  );
};

export default EditIcon;
