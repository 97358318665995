import { Outlet, useLocation, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addUserDetails, selectAuth } from "../../../features/auth/AuthSlice";
import Sidebar from "../atoms/Sidebar";
import { ApiCall } from "../../../services/ApiServices";
import { GET_USER_PERMISSIONS } from "../../../routes/ApiEndpoints";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../Constants";
import EncryptDecryptService from "../../../services/EncryptDecryptService";

export const NewRootLayout = () => {
  const authData = useSelector(selectAuth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const excludedPaths = [
    "/follow-up",
    "/add-fare",
    "/manage-fares",
    "/manage/follow-up",
    "/dashboard/follow-up",
  ];

  useEffect(() => {
    // Retrieve and decrypt the token from localStorage
    const user = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}"
    );
    const authToken = JSON.parse(
      EncryptDecryptService.decryptData(user)
    )?.token;
    // Check if token exists, if not navigate to login
    if (!authToken) {
      navigate("/login");
    }

    // If token is valid, call getUserPermissions
    if (authToken !== "") {
      getUserPermissions();
    }
  }, [authData.token, location.pathname, navigate]);

  const getUserPermissions = async () => {
    const user = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root") ?? "{}")?.auth ?? "{}"
    );
    const userId = JSON.parse(EncryptDecryptService.decryptData(user))?.token;
    const currentRole = authData?.role[0] ?? "";
    await ApiCall.service(
      GET_USER_PERMISSIONS,
      "POST",
      userId && -999,
      false,
      CENTRAL_DATA_MANAGEMENT_MICROSERVICE
    ).then((response) => {
      if (response && response?.status === 200) {
        if (currentRole !== "") {
          const currentRoleData = response.data[currentRole];
          const userPermissions = {
            userPermissions: currentRoleData?.permission,
            isSuperAdmin: currentRoleData?.isSuperAdmin,
          };
          dispatch(addUserDetails(userPermissions));
        }
      } else {
        console.error(response?.message);
      }
    });
  };

  const shouldApplyStyles = !excludedPaths.includes(location.pathname);

  return (
    <div style={{ display: "flex" }}>
      {authData.role[0] !== "sales_agent" &&
        !excludedPaths.includes(location.pathname) && <Sidebar />}

      <div
        style={
          shouldApplyStyles
            ? { marginLeft: "9.8vw", width: "88.3vw", marginRight: "1.8vw" }
            : {}
        }
        className="container-fluid pageRightSideWrapper"
      >
        <Outlet />
      </div>
    </div>
  );
};

export default NewRootLayout;
