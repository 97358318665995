import React from "react";
import { BsDownload } from "react-icons/bs";
import Button from "../atoms/Button";
import CommonServices from "../../../services/common/CommonServices";

interface DownloadButtonProps {
    fileUrl: string;
    fileNumber: string | number | null;
    fileType?: string;
}

const DownloadFile: React.FC<DownloadButtonProps> = ({ fileUrl, fileNumber, fileType }) => {
    const handleDownload = () => {
        CommonServices?.downloadFile(fileUrl, fileNumber, fileType ?? '');
    }
    return (
        <Button
            handleClick={handleDownload}
            className="icon-btn btn btn-link text-white mx-1 p-1 fs-6"
            title={<BsDownload />}
        />
    );
};

export default DownloadFile;