import React, { CSSProperties, useState, useEffect, useRef } from "react";
import { AxiosCall } from "../../../services/AxiosService";
import LoadingIcon from "../utlis/LoadingIcon";
import axios from "axios";
import * as ENDPOINTS from "../../../routes/ApiEndpoints";
import LabelField from "../atoms/LabelField";
import { t } from "../../CentralDataMangement/translation/Translation";
import Icon from "../../../Icon";
import "../../../static/css/file-upload.css";
type fileUploadErrorsType = (errorInfo: object) => void;
type fileUploadResponseType = (uploadResponse: object, index: number) => void;
type deleteResponseType = (field: string, index: number) => void;

interface fileObject {
  fileName: string | null;
  filePath: string | null;
  fileId: number | null;
  fileUrl: string | null;
}

interface FileUploadProps {
  label?: string;
  isMandatory?: boolean;
  id: string;
  name: string;
  edit: boolean;
  fileId: number | null;
  filePath?: string | null;
  fileUrl?: string | null;
  fileName: string | null;
  style?: CSSProperties;
  multiple: boolean;
  formats: string;
  index?: number;
  uploadPath: string;
  returnFileUploadErrors: fileUploadErrorsType;
  fileUploadResponse: fileUploadResponseType;
  deleteResponse: deleteResponseType;
  maxFileSize: number;
  placeholder?: string;
  className?: string;
  textclassName?: string;
  microserviceURL?: string;
  file_type?: string | number;
  dontDelete?: boolean;
}

const FileUpload: React.FC<FileUploadProps> = (props) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null); // Create a ref for the file input

  const {
    label,
    isMandatory,
    id,
    name,
    style,
    multiple,
    formats,
    index,
    fileId,
    filePath = null,
    fileUrl = null,
    fileName,
    uploadPath,
    returnFileUploadErrors,
    fileUploadResponse,
    deleteResponse,
    maxFileSize,
    className,
    textclassName,
    placeholder,
    microserviceURL,
    file_type,
    dontDelete = false,
  } = props;
  const [fileObject, setFileObject] = useState<fileObject>({
    fileName: "",
    filePath: "",
    fileId: null,
    fileUrl: "",
  });

  useEffect(() => {
    setFileObject((prevObject) => ({
      ...prevObject,
      fileName: fileName,
      filePath: filePath,
      fileId: fileId,
      fileUrl: fileUrl,
    }));
  }, [props]);

  const [loading, setLoading] = useState(false);

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    formats: string,
    id: string,
    index?: number,
    file_type?: string | number
  ) => {
    const file = fileInputRef.current?.files?.[0];

    const allowedFormats = formats.split(",");
    let errorString = "";

    if (file) {
      // File extension validation
      let fileExtension = file.name.split(".").pop()?.toLowerCase();
      fileExtension = `.${fileExtension}`;
      if (fileExtension && !allowedFormats.includes(fileExtension)) {
        errorString = `${t("Invalid file format")}. ${t(
          "Only files with the extensions"
        )} ${formats} ${t("are allowed")}`;
        buildErrorObject(id, index, errorString);
        return;
      }

      // General file size validation
      if (file.size > maxFileSize * 1024 * 1024) {
        errorString = t(`File size is greater than ${maxFileSize}MB!`);
        fileUploadResponse(
          t(`File size is greater than ${maxFileSize}MB!`),
          index ? index : 0
        );
        buildErrorObject(id, index, errorString);
        // Reset the file input to allow the same file to be uploaded again
        fileInputRef.current!.value = "";
        return;
      }

      // No errors, proceed with file upload
      resolveErrors(id, index, "");
      uploadFileToS3(file, id, file_type);

      // Reset the file input to ensure same file triggers onChange again
      fileInputRef.current!.value = "";
    } else {
      errorString = t("Something went wrong!");
      buildErrorObject(id, index, errorString);
    }
  };

  const uploadFileToS3 = async (file: File, name: string, file_type: any) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("file_type", file_type.toString());
    formData.append("file", file);
    formData.append("name", name);
    const response = await AxiosCall.fileUploadService(
      formData,
      uploadPath,
      microserviceURL
    );
    setFileObject((prevObject) => ({
      ...prevObject,
      fileName: response?.data?.fileName,
      filePath: response?.data?.filePath,
      fileId: response?.data?.fileId,
      fileUrl: response?.data?.fileUrl,
    }));
    const responseObj = {
      step: id,
      response: response,
      file: file,
    };
    fileUploadResponse(responseObj, index ? index : 0);
    setLoading(false);
  };

  const removeFile = async (
    event: React.MouseEvent<HTMLSpanElement>,
    id: string
  ) => {
    try {
      setLoading(true);
      if (fileObject.fileId !== null) {
        // For delete service
        if (!dontDelete) {
          await AxiosCall.fileDeleteService(fileObject.fileId, microserviceURL);
        }
      }
      setFileObject({
        fileName: null,
        filePath: null,
        fileId: null,
        fileUrl: null,
      });
      deleteResponse(id, index ? index : 0);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  const downloadFile = async (
    id: number | null,
    name: string | null,
    path: string | null,
    uploadPath: string | null
  ) => {
    try {
      const microService: string =
        microserviceURL !== null &&
          microserviceURL !== "" &&
          microserviceURL !== undefined
          ? microserviceURL
          : `${process.env.REACT_APP_CDM_SERVICE}`;
      const response = await axios.get(
        `${microService}/${ENDPOINTS.FILE_DOWNLOAD}/${id}/${uploadPath}`
      );
      if (response.status === 200) {
        const fileUrl = response.data.url;
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = fileUrl;
        link.target = "_blank";
        link.download = response.data.filename; //Trigger a click event on the anchor element
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error("Error fetching download URL:", error);
    }
  };

  const resolveErrors = (
    id: string,
    index: number | undefined,
    error: string
  ) => {
    const errorObject = {
      step: id,
      index: index ? index : 0,
      error: "",
    };
    returnFileUploadErrors(errorObject);
  };

  const buildErrorObject = (
    id: string,
    index: number | undefined,
    error: string
  ) => {
    const errorObject = {
      step: id,
      index: index ? index : 0,
      error: error,
    };
    returnFileUploadErrors(errorObject);
  };
  const handleFileClick = (fileUrl: string | null) => {
    if (fileUrl) {
      window.open(fileUrl, "_blank");
    } else {
      console.error("File url is null");
    }
  };
  return (
    <>
      <div className={className}>
        <div className="col-4">
          <LabelField title={label} isMandatory={isMandatory} />
        </div>
        {!loading ? (
          fileObject.fileName ? (
            <div className="col-8 d-flex align-items-center">
              <div className="d-flex align-items-center h-38 fileNameUploaded">
                <span
                  className={`file-name d-inline-block certificate-name uploadedFile ${textclassName}`}
                >
                  <span
                    className="color-dark-purple file-upload text-break text-truncate"
                    onClick={() =>
                      handleFileClick(
                        !fileObject.fileUrl
                          ? `${ENDPOINTS.FILE_URL}/${fileObject.filePath}`
                          : fileObject.fileUrl
                      )
                    }
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    title={fileObject.fileName}
                  >
                    {fileObject.fileName}
                  </span>
                </span>
                <div className="d-flex justify-content-end fileUploadDeleteBtn">
                  <span
                    onClick={(event) =>
                      downloadFile(
                        fileObject.fileId,
                        fileObject.fileName,
                        fileObject.filePath,
                        uploadPath
                      )
                    }
                    className="table-action-icons cursor-pointer"
                  >
                    {/* <span className="secondaryColorHoverEffect" title={t("View")}>
                  <Icon
                    isIcon={true}
                    width="1vw"
                    height="1vw"
                    name="viewIcon"
                  />
                </span> */}
                  </span>
                  <span
                    className="secondaryColorHoverEffect removeUploadedFile"
                    onClick={(event) => removeFile(event, id)}
                    title={t("Delete")}
                  >
                    <Icon
                      isIcon={true}
                      width="0.9vw"
                      height="0.9vw"
                      name="delete"
                    />
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="fileUploadWrapper position-relative">
              <button className="upload-button">
                <span className="secondaryColorHoverEffect">
                  <Icon
                    isIcon={true}
                    width="1vw"
                    height="1vw"
                    name="uploadIcon"
                  />
                </span>
                {placeholder && (
                  <span className={`ms-3 mobileFont`} style={{ fontSize: "0.8vw" }}>
                    {placeholder}
                    <span className="text-danger"> *</span>
                  </span>
                )}
              </button>
              <input
                type="file"
                id={id}
                ref={fileInputRef}
                name={name}
                className="cursor-pointer"
                multiple={multiple}
                accept={formats}
                // onChange={(event) => handleFileChange(event, formats, id, index)}
                onChange={(event) =>
                  handleFileChange(event, formats, id, index, file_type)
                }
              />
            </div>
          )
        ) : (
          <>
            <LoadingIcon
              iconType="bars"
              color="#8571b9"
              className="fileUploadLoading"
              height="2.3vw"
              width={"2.3vw"}
            />
          </>
        )}
      </div>
    </>
  );
};

export default FileUpload;
