import React from "react";
import LabelField from "../../../common/atoms/LabelField";
import SelectWithSearch from "../../../common/atoms/SelectWithSearch";
import Title from "../../../common/atoms/Title";
import ToggleButton from "../../../common/atoms/ToggleButton";
import { t } from "../../../common/atoms/translation";
import { LabelWithInputField } from "../../../common/molecules/LabelWithInputField";
import { AddressProps, Option } from "../../registration/Interfaces";

const Address: React.FC<AddressProps> = ({
  state,
  handleChange,
  handleAddressToggle,
  countryOptions,
  handleSelectChange,
}) => {
  const inputFieldSharedProps = {
    isTop: false,
    labelClassName: "poppins-medium colorPrimary ",
  };
  return (
    <>
      <div className="row mb-1">
        <div className="col-12">
          <Title
            title={t("Address") + ":"}
            className="bodyTitle colorPrimary tab-title"
          />
        </div>
      </div>
      <div className="row">
        {/* Residential address */}
        <Title
          title={t("Residential address") + ":"}
          className="bodyTitle colorPrimary tab-title mb-3"
        />
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Street name") + ":"}
              type="text"
              value={state.address.residence.street}
              handleChange={handleChange("address", "residence", "street")}
              name={"residence_streetName"}
              id="residence_streetName"
              {...inputFieldSharedProps}
              className="inputFieldColor ps-2"
              colClassName="col-8"
              labelWrapperClassName="col-4 leftlabelClass"
              isMandatory
              autoComplete="off"
              error={state.errors["address.residence.street"] || ""}
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("House no") + ":"}
              type="text"
              value={state.address.residence.number}
              handleChange={handleChange("address", "residence", "number")}
              name={"residence_houseNo"}
              id="residence_houseNo"
              className="inputFieldColor ps-2"
              colClassName="col-8"
              isMandatory
              autoComplete="off"
              error={state.errors["address.residence.number"] || ""}
              labelWrapperClassName="col-4 leftlabelClass"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Box") + ":"}
              type="text"
              value={state.address.residence.box}
              handleChange={handleChange("address", "residence", "box")}
              name={"residence_box"}
              id="residence_box"
              className="inputFieldColor ps-2"
              colClassName="col-8"
              autoComplete="off"
              error={state.errors["address.residence.box"] || ""}
              labelWrapperClassName="col-4 leftlabelClass"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Postal code") + ":"}
              type="text"
              value={state.address.residence.postal_code}
              handleChange={handleChange("address", "residence", "postal_code")}
              name={"residence_postalCode"}
              id="residence_postalCode"
              className="inputFieldColor ps-2"
              colClassName="col-8"
              isMandatory
              autoComplete="off"
              error={state.errors["address.residence.postal_code"] || ""}
              labelWrapperClassName="col-4 leftlabelClass"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("City") + ":"}
              type="text"
              value={state.address.residence.city}
              handleChange={handleChange("address", "residence", "city")}
              name={"residence_city"}
              id="residence_city"
              isMandatory={true}
              error={state.errors["address.residence.city"] || ""}
              className="inputFieldColor ps-2"
              colClassName="col-8"
              autoComplete="off"
              labelWrapperClassName="col-4 leftlabelClass"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={`${t("Country")}:`}
              title={t("Country") + ":"}
              name="residence_country"
              placeHolder={t("Select")}
              isMandatory
              search
              options={countryOptions.countries}
              value={
                countryOptions.countries.find(
                  (option) => option.value === state.address.residence.country_id
                ) || null
              }
              onChange={(selectedOption: Option) => {
                handleSelectChange(selectedOption, "address.residence.country_id");
                if(state.same_address)
                  handleSelectChange(selectedOption, "address.domicile.country_id");
              }}
              isMulti={false}
              className="select-field"
              error={state.errors["address.residence.country_id"] || ""}
              isTranslate
              labelWrapperClassName="col-4 leftlabelClass"
              colClassName="col-8"
            />
          </div>
        </div>
        {/* checkbox */}
        <div className="col-12 d-flex align-items-center mb-3">
          <LabelField
            title={
              t(
                "Is the domiciliary address the same as the residential address"
              ) + ":"
            }
            className="mb-0 colorPrimary poppins-medium me-3"
          />
          <ToggleButton value={state.same_address} onToggle={handleAddressToggle} />
        </div>
        <div className="col-12">
          <Title
            title={t("Domicile address") + ":"}
            className="bodyTitle colorPrimary tab-title mb-3"
          />
        </div>
        {/* Domicilary address */}
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Street name") + ":"}
              type="text"
              value={state.address.domicile.street}
              handleChange={handleChange("address", "domicile", "street")}
              name={"street"}
              id="street"
              isMandatory={true}
              error={state.errors["address.domicile.street"] || ""}
              {...inputFieldSharedProps}
              className="inputFieldColor ps-2"
              colClassName="col-8"
              labelWrapperClassName="col-4 leftlabelClass"
              autoComplete="off"
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("House no") + ":"}
              type="text"
              value={state.address.domicile.number}
              handleChange={handleChange("address", "domicile", "number")}
              name={"number"}
              id="number"
              isMandatory={true}
              className="inputFieldColor ps-2"
              colClassName="col-8"
              error={state.errors["address.domicile.number"] || ""}
              labelWrapperClassName="col-4 leftlabelClass"
              autoComplete="off"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Box") + ":"}
              type="text"
              value={state.address.domicile.box}
              handleChange={handleChange("address", "domicile", "box")}
              name={"box"}
              id="box"
              className="inputFieldColor ps-2"
              colClassName="col-8"
              labelWrapperClassName="col-4 leftlabelClass"
              autoComplete="off"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("Postal code") + ":"}
              type="text"
              value={state.address.domicile.postal_code}
              handleChange={handleChange("address", "domicile", "postal_code")}
              name={"postal_code"}
              isMandatory={true}
              error={state.errors["address.domicile.postal_code"] || ""}
              id="postal_code"
              className="inputFieldColor ps-2"
              colClassName="col-8"
              labelWrapperClassName="col-4 leftlabelClass"
              autoComplete="off"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row">
            <LabelWithInputField
              label={t("City") + ":"}
              type="text"
              value={state.address.domicile.city}
              handleChange={handleChange("address", "domicile", "city")}
              name={"city"}
              id="city"
              isMandatory={true}
              error={state.errors["address.domicile.city"] || ""}
              className="inputFieldColor ps-2"
              colClassName="col-8"
              labelWrapperClassName="col-4 leftlabelClass"
              autoComplete="off"
              {...inputFieldSharedProps}
            />
          </div>
        </div>
        <div className="col-lg-4 mb-3">
          <div className="row multiSelectWrapper">
            <SelectWithSearch
              fieldLabel={`${t("Country")}:`}
              title={t("Country") +":"}
              name="country"
              placeHolder={t("Select")}
              isMandatory
              search
              options={countryOptions.countries}
              value={
                countryOptions.countries.find(
                  (option) => option.value === state?.address?.domicile?.country_id
                ) || null
              }
              onChange={(selectedOption: Option) => {
                if (state.same_address) {
                  state.same_address = false;
                }
                handleSelectChange(selectedOption, "address.domicile.country_id");
              }}
              isMulti={false}
              className="select-field"
              error={state.errors["address.domicile.country_id"] || ""}
              isTranslate
              labelWrapperClassName="col-4 leftlabelClass"
              colClassName="col-8"
            />
          </div>
        </div>
      </div>
    </>
  );
};
export default Address;
