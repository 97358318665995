import { FLEX_EMPLOYEE_TYPES, STUDENT_EMPLOYEE_TYPES } from "../../../Constants";
import { EMPLOYEE_SALARY } from "../../../routes/ApiEndpoints";
import { ApiCall } from "../../../services/ApiServices";
import EncryptDecryptService from "../../../services/EncryptDecryptService";

// salaryHelper.ts
interface FunctionOptions {
    label: string;
    value: number;
    salary?: string;
}

interface PcAges {
    id: number;
    pc_id: number;
    age: number;
    min_sal_percent: string;
    status: boolean;
    max_hour: string;
}

interface CategoryOptions {
    category_name: string;
    category_id: number;
    min_salary: string;
    label: string;
    value: number;
    functions: FunctionOptions[];
}

interface PcOptions {
    id: number;
    pc_number: number;
    pc_name: string;
    pc_max_age: number;
    student_cat: string;
    flex_salary: string;
    label: string;
    value: number;
    employee_types: FunctionOptions[];
    pc_ages: PcAges[];
    categories: CategoryOptions[];
}
interface Employee {
    company_id: number;
    cost_center_id?: number;
    employee_id: number;
    employee_name?: string;
    employee_type?: string;
    employee_type_id: number;
    profile_picture_url?: string;
    function?: string;
    function_id: number;
    location?: string;
    location_id: number;
    age: number;
    pc_id: number;
    pc_max_age: number;
    pc?: string;
    project_id: number;
    salary: string;
    new_salary?: string;
}
/**
 * Calculates the maximum salary based on the function's salary and employee data.
 * Adjusts the salary based on the employee's age and pc_ages data.
 * 
 * @param functionSalary The base salary for the function.
 * @param employeeData The employee's data, including age and current salary.
 * @param selectedPc The selected PC options, which contains age and salary percentage data.
 * @returns The updated salary after adjustments.
 */
export const calculateMaxSalary = (
    functionSalary: string,
    employeeData: Employee,
    selectedPc: PcOptions,
    pastSalary: string = "0" // Default value if no past salary is provided
): number => {
    let maxSalary = formatFloatSalary(functionSalary);

    // Adjust salary based on pc_ages data
    for (const pcAge of selectedPc.pc_ages) {
        if (employeeData.age === pcAge.age && pcAge.status && pcAge.min_sal_percent) {
            maxSalary = (maxSalary * parseFloat(pcAge.min_sal_percent)) / 100;
        }
    }

    console.log("Calculated Max Salary (before comparison):", maxSalary);

    // Compare the calculated maxSalary with the past salary
    if (pastSalary !== "") {
        maxSalary = Math.max(
            maxSalary,
            formatFloatSalary(pastSalary)
        );
    }

    console.log("Final Max Salary:", maxSalary);
    return maxSalary;
};
export const updatesalaryFormat = (salary: number | string): string => {
    if (salary !== undefined && salary !== null) {
        let stringValue = salary.toString();

        // Check if the number has a decimal part
        if (stringValue.includes(".") || stringValue.includes(",")) {
            // Split the string by "." or ","
            let parts = stringValue.split(/\.|,/);

            // Ensure four digits after the decimal point or comma
            if (parts[1] === undefined || parts[1].length < 4) {
                let leng = parts[1] ? parts[1].length : 0;
                // Add zeros to make it 4 characters long
                while (leng < 4) {
                    parts[1] = parts[1] ? parts[1] + "0" : "0";
                    leng++;
                }
            } else if (parts[1].length > 4) {
                parts[1] = parts[1].substring(0, 4);
            }

            // Concatenate the parts back into a string
            return parts.join(",");
        } else {
            // No decimal part, convert to float with four zeros
            return stringValue + ",0000";
        }
    } else {
        return "0";
    }
};
export const formatFloatSalary = (salary: string): number => {
    return parseFloat(salary.replace(",", ".").replace(/\s/g, ""));
};
export const formatEuroSalary = (salary: string): number => {
    return parseFloat(salary.replace(",", ".").replace(/\s/g, ""));
};

export const fetchSalary = async (
    selectedPc: PcOptions,
    functionId: number,
    employeeTypeId: number,
    employeeData: any
): Promise<string> => {
    try {
        console.log(employeeData);
        if (!employeeData.company_id || !employeeData.employee_id) {
            throw new Error("Required employee data is missing");
        }

        return await getEmployeePreviousSalaryByEmployeeType(
            selectedPc.id,
            employeeData.company_id,
            functionId,
            employeeTypeId,
            employeeData.employee_id,
        );
    } catch (error) {
        console.error("Error fetching salary:", error);
        return updatesalaryFormat(employeeData.salary || "00,000");
    }
};


export const getEmployeePreviousSalaryByEmployeeType = async (
    pcId?: number | null,
    companyId?: number | null,
    functionId?: number | null,
    employeeTypeId?: number | null,
    employeeId?: number | null,
    dob?: string | null,
): Promise<string> => {
    try {
        const params: Record<string, any> = {
            pcId,
            companyId,
            employeeTypeId,
            function: functionId,
            dob
        };

        // Filter out null or undefined values from the parameters
        const filteredParams = Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v != null)
        );

        const queryParams = new URLSearchParams(filteredParams).toString();

        // Make the API call
        const res = await ApiCall.getService(
            `get-employee-salary/${employeeId}?${queryParams}`,
            "GET"
        );

        // Validate the response and return salary or default value
        if (res.status === "success" && res.data) {
            return res.data as string; // Assuming res.data is always a string
        }

        return "00,000"; // Default value for invalid responses
    } catch (err) {
        console.error("Error in getEmployeePreviousSalaryByEmployeeType:", err);
        return "00,000"; // Default value in case of errors
    }
}

