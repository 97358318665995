import React, { useContext, useEffect } from "react";
import MultiSelect from "./SelectComponent";
import MultiSelectField from "./MultiSelectField";
import LinkCoeffDeviationEmpContext from "./LinkCoeffDeviationEmp/LinkCoeffDeviationEmpContext";
import { ApiCall } from "../../../../services/ApiServices";
import { helpers } from "./LinkCoeffEmpHelper";
import CoeffcientValuesFirstPart from "./CoeffcientValuesFirstPart";
import EmployeeTypeSecondPart from "./EmployeeTypeSecondPart";
import {
  t,
  translate,
} from "../../../CentralDataMangement/translation/Translation";

import {
  FETCH_COEFFICIENT_DEVIATIONS,
  FETCH_ALL_EMP_COEFF_VALUES,
  STORE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING,
  UPDATE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING,
  FETCH_COEFFICIENT_DEVIATION_EMPLOYEE_LINKING_PER_PC,
} from "../../../../routes/ApiEndpoints";
import Header from "../../../common/layout/Header";
import Button from "../../../common/atoms/Button";
import LabelField from "../../../common/atoms/LabelField";
import BackLink from "../../../common/atoms/BackLink";
import { CENTRAL_DATA_MANAGEMENT_MICROSERVICE } from "../../../../Constants";
var SERVER_SIDE_RENDERING = 1;

const LinkCoeffDeviationEmpComponent = (props) => {
  const { state, updateStateChanges } = useContext(
    LinkCoeffDeviationEmpContext
  );
  const { inputRef, isOverflow } = state;
  useEffect(() => {
    SERVER_SIDE_RENDERING
      ? fetchEmpCoeffValueTypesData()
      : (SERVER_SIDE_RENDERING += 1);
  }, []);

  const fetchEmpCoeffValueTypesData = async (
    url = `${FETCH_ALL_EMP_COEFF_VALUES}`,
    type = 0,
    pcid = props.pcid,
    edit = 0
  ) => {
    let res;
    try {
      const response = await ApiCall.service(
        url,
        "POST",
        { pcid: pcid, edit: edit },
        false,
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.success) {
        if (type) {
          res = response;
        } else {
          assignDataToStateVariables(response.data);
        }
      } else {
        console.error("API call failed:", response.message);
      }
    } catch (error) {
      console.error("Error in fetchEmpCoeffValueTypesData:", error);
    }

    try {
      const response = await ApiCall.getService(
        FETCH_COEFFICIENT_DEVIATIONS,
        "GET",
        CENTRAL_DATA_MANAGEMENT_MICROSERVICE
      );
      if (response.success) {
        updateStateChanges({
          ConstructedCoefficientData: response.data
            ? ConstructedCoefficientData(response.data)
            : [],
          coefficientDeviationObj: response.data
            ? ConstructedCoefficientDeviation(response.data)
            : [],
          selectedDeviation: [parseInt(props.pcid)],
          selectedDevaitionObj: [],
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }

    if (props.pcid > 0) {
      try {
        const response = await ApiCall.getService(
          FETCH_COEFFICIENT_DEVIATION_EMPLOYEE_LINKING_PER_PC +
            "/" +
            props.pcid,
          "GET",
          CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        );
        if (response.success) {
          console.log(response.data);
          updateStateChanges({
            selectedDeviation: response.data.selectedDeviation,
            CoefficientDeviationlinkingValueobj:
              response.data.CoefficientDeviationlinkingValueobj,
            selectedDevaitionObj: Object.values(
              response.data.selectedDevaitionObj
            ),
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    return res;
  };

  const ConstructedCoefficientData = (data) => {
    const { overviewData } = data;
    let dataObj = {};
    data.map((eachObj) => (dataObj[eachObj.id] = eachObj));
    return dataObj;
  };

  const ConstructedCoefficientDeviation = (data) => {
    // let dataObj = {};
    var dataObj = new Array();
    data.map((eachObj) =>
      dataObj.push({ label: eachObj.label, value: eachObj.value })
    );

    return dataObj;
  };

  const assignDataToStateVariables = (data) => {
    const {
      employeeTypes,
      coefficientTypes,
      valueTypes,
      pcLinkedValueData,
      pcArray,
    } = data;
    updateStateChanges({
      employeeTypeArray: employeeTypes,
      coefficientTypeArray: coefficientTypes,
      valueTypeArray: valueTypes,
      pclinkingValueobj: pcLinkedValueData || {},
      pcArray: [{ value: false, label: t("--- Select ---") }, ...pcArray],
      selectedPc: parseInt(props.pcid),
    });
  };

  const handleSubmit = async () => {
    if (proceedToSubmit()) {
      if (state.selectedPc > 0) {
        try {
          const response = await ApiCall.service(
            UPDATE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING,
            "POST",
            postdata(),
            false,
            CENTRAL_DATA_MANAGEMENT_MICROSERVICE
          );
          if (response.success) {
            window.location.reload();
          } else {
            console.error("API call failed:", response.message);
          }
        } catch (error) {
          console.error("Error in storing", error);
        }
      } else {
        // this code is not there
        // try {
        //     const response = await ApiCall.service(
        //         STORE_COEFFICIENT_DEVIATION_EMPLOYEE_TYPE_LINKING,
        //         "POST",
        //         postdata(),
        //         false,
        //         CENTRAL_DATA_MANAGEMENT_MICROSERVICE
        //     );
        //     if (response.success) {
        //         alert("stored successfully")
        //     } else {
        //         console.error("API call failed:", response.message);
        //     }
        // } catch (error) {
        //     console.error("Error in storing", error);
        // }
      }
    }
  };

  const proceedToSubmit = () => {
    let proceed = true;
    if (!state.selectedPc) {
      proceed = false;
      updateStateChanges({ pcWarning: true });
    }
    return proceed;
  };

  const postdata = () => {
    return {
      CoefficientDeviationlinkingValueobj:
        state.CoefficientDeviationlinkingValueobj,
      selectedPc: state.selectedPc,
      selectedDeviation: state.selectedDeviation,
      ConstructedCoefficientData: state.ConstructedCoefficientData,
    };
  };

  const onSelect = async (e) => {
    removeWarningClass();
    let response = await fetchEmpCoeffValueTypesData(
      `${FETCH_ALL_EMP_COEFF_VALUES}`,
      1,
      e.value,
      1
    );
    if (response) {
      const { employeeTypes = [] } = response.data;
      updateStateChanges({
        employeeTypeArray: employeeTypes,
        selectedPc: e.value,
        pcWarning: false,
        emptyDataWarning: false,
        regExpWarning: false,
        pclinkingValueobj: {},
        lowHighValidation: [],
        valueErrorArray: [],
        defaultValueError: [],
      });
    } else {
      console.error("Failed to fetch data");
    }
  };

  let onSelectDeviation = (values) => {
    var pc1 = [];

    values.map((val, key) => {
      pc1.push(val.value);
    });

    updateStateChanges({
      selectedDeviation: pc1,
      selectedDevaitionObj: values,
    });
  };

  const removeWarningClass = () => {
    state.lowHighValidation.map((val) => {
      let lowRef = inputRef.current[`${val}_1`];
      let highRef = inputRef.current[`${val}_3`];
      lowRef.classList.remove("warning");
      highRef.classList.remove("warning");
    });
  };

  const addMultiSelectTag = () => {
    return (
      <>
        <LabelField title={t("Select paritair comite")} />
        <MultiSelect
          options={state.pcArray}
          standards={state.pcArray.filter(
            (val) => val.value === state.selectedPc
          )}
          disabled={
            parseInt(props.pcid) && props.pcid > 0 && state.selectedPc
              ? true
              : false
          }
          handleChange={onSelect}
          isMulti={false}
          placeholder={t("Select paritair comite")}
          
        />
      </>
    );
  };

  const addMultiSelectDeviationTag = () => {
    return (
      <>
        <LabelField title={t("Select coefficient deviation")} />
        <MultiSelectField
          options={state.coefficientDeviationObj}
          standards={state.coefficientDeviationObj.filter((val) =>
            state.selectedDeviation.includes(val.value)
          )}
          disabled={false}
          handleChange={onSelectDeviation}
          isMulti={true}
          className="col-md-12 multiSelectWrapper"
          classNamePrefix="multiSelectDropdown"
          placeholder={t("Select coefficient deviation")}
        />
      </>
    );
  };

  if (SERVER_SIDE_RENDERING)
    return (
      <>
        <Header
          headerName={t("Link coefficients deviations to employee types")}
          headerClassName="myAccountCardTitle"
        />
        <div className="search-bar pb-2">
          <div className="row mb-2">
            <BackLink backLink="/config/settings" />
            <div className="col-8 align-self-center text-center d-block d-lg-none">
              <LabelField
                title={t("Link coefficients deviations to employee types")}
                className="myAccountCardTitle mb-0"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 mb-3 mb-lg-0">
              {addMultiSelectTag()}
              {state.pcWarning && (
                <div className="text--danger">
                  {t("Choose paritair comite")}
                </div>
              )}
            </div>
            <div className="col-lg-6 mb-3 mb-lg-0">
              {addMultiSelectDeviationTag()}
              {state.pcWarning && <div>{t("Choose paritair comite")}</div>}
            </div>
          </div>
        </div>
        <div
          className="bg-white"
          style={{
            paddingLeft: "2.8vw",
            paddingRight: "2.8vw",
            paddingBottom: "2vw",
            borderBottomLeftRadius: "2vw",
            borderBottomRightRadius: "2vw",
            position: "relative",
            top: "-0.5vw",
          }}
        >
          <div className="row defaultCoefficientHeight employeeTypeCoeffHeight scrollBar">
            <div className="col-lg-4 px-0 firstPart col-md-7 col-fixed">
              <CoeffcientValuesFirstPart />
            </div>
            <div className="col-lg-8 ps-0 col-scrollable col-md-5">
              <EmployeeTypeSecondPart />
            </div>
          </div>
          <div className="row pt-4">
            <div className="col-12 px-0">
              <Button
                handleClick={() => handleSubmit()}
                type="button"
                title={t("Save")}
                className="searchBtn float-end"
              />
            </div>
          </div>
        </div>
      </>
    );
  else return <></>;
};

export default translate(LinkCoeffDeviationEmpComponent);
