import React, { useState, useEffect, useRef } from "react";
import Icon from "../../../Icon";
import { useResponsiveValue } from "../atoms/ResponsivWidth";

interface PlanningEmployeeSearchProps {
  placeholder?: string;
  value: string; // Expect an array of Employee objects
  onEmployeeSelect: (values: string) => void; // Callback to handle employee selection
}

const PlanningEmployeeSearch: React.FC<PlanningEmployeeSearchProps> = ({
  placeholder,
  value,
  onEmployeeSelect,
}) => {
  const searchIconStyle = useResponsiveValue("3.5vw", "2vw", "0.9vw");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onEmployeeSelect) {
      onEmployeeSelect(event.target.value);
    }
  };

  return (
    <div className="d-flex align-items-center position-relative">
      <input
        type="text"
        value={value}
        onChange={handleSearchChange}
        placeholder={placeholder}
        className="form-control ps-2 planningSearchBtn"
      />
      <span
        onClick={() => handleSearchChange}
        className="ms-2 position-absolute planningSearchBtnIcon"
      >
        <Icon
          name="searchIcon"
          fillColor="#8571b9"
          width={searchIconStyle}
          height={searchIconStyle}
          isIcon={true}
        />
      </span>
    </div>
  );
};

export default PlanningEmployeeSearch;
