import React, { useState } from 'react';
import ManageLocationUsers from './ManageLocationUsers';
// import ManageLocationManagers from './ManageLocationManagers';
import { Tabs, Tab } from "react-bootstrap";
import { t, translate } from '../CentralDataMangement/translation/Translation';
import ManageLocationManagersUsers from './ManageLocationManagersUsers';
import Header from '../common/layout/Header';
import BackLink from '../common/atoms/BackLink';
import "./css/locationManager.css";

const ManageLocationTabs: React.FC = () => {
  const [activeTab, setActiveTab] = useState('users');

  const handleTabChange = (tab: any) => {
    setActiveTab(tab);
  };

  return (
    <>
      <Header
        headerName={`${activeTab === "managers" ? t("Manage location managers") : t("Manage location users")}`}
        headerClassName="myAccountCardTitle"
      />
      <div className='locationManagerBg'>
        <div className="search-bar pb-2">
          <div className="row">
            <BackLink backLink="/manage-location" />
          </div>
        </div>
        <div className='createpagesBg'>
          <Tabs activeKey={activeTab} onSelect={(k) => handleTabChange(k)} className="create-role-tabs border-0 location-manage-tabs">
            <Tab eventKey="managers" title={t("Manage location managers")}>
              {activeTab === 'managers' && <ManageLocationManagersUsers role={'location_manager'} />}
            </Tab>
            <Tab eventKey="users" title={t("Manage location users")}>
              {activeTab === 'users' && <ManageLocationManagersUsers role={'employee'} />}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default translate(ManageLocationTabs);
